import {
  FETCH_PAYMENT,
  FETCH_PAYMENT_SUCCESSFUL,
  FETCH_TRADE_REFERENCES,
  FETCH_TRADE_REFERENCES_SUCCESSFUL,
  FETCH_BUSINESS_EXPENDITURES,
  FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL,
  FETCH_CODAT_COMPANY,
  FETCH_PAYMENT_ERROR,
  FETCH_TRADE_REFERENCES_ERROR,
  FETCH_BUSINESS_EXPENDITURES_ERROR,
  FETCH_CODAT_COMPANY_ERROR,
  FETCH_CODAT_COMPANY_SUCCESSFUL,
} from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const fetchPayment = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;

  // const url = "http://localhost:3001/payments/US001-X-" + companyId;
  const url = `${api}/payments/${companyId}`;

  dispatch({ type: FETCH_PAYMENT });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((res) => res.json())
    .then((payload) =>{
      dispatch({
        type: FETCH_PAYMENT_SUCCESSFUL,
        payload: payload,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_PAYMENT_ERROR
      })
      console.log(err.message);
    });
};

export const fetchTradeReferences = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;

  // const url = "http://localhost:3001/trade/US001-X-" + companyId;
  const url = `${api}/trade/${companyId}`;

  dispatch({ type: FETCH_TRADE_REFERENCES });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
  .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_TRADE_REFERENCES_SUCCESSFUL,
        payload: payload,
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_TRADE_REFERENCES_ERROR
      })
      console.log(err.message);
    });
};

export const fetchBusinessExpenditures = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  // const url = "http://localhost:3001/expenditure/US001-X-" + companyId;

  const api = config.EXPRESS.URL;

  const url = `${api}/expenditure/${companyId}`;
  dispatch({ type: FETCH_BUSINESS_EXPENDITURES });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
  .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL,
        payload: payload,
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_BUSINESS_EXPENDITURES_ERROR
      })
      console.log(err.message);
    });
};

export const fetchCodatCompany = (name, status) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.EXPRESS.URL;

  // const url = "http://localhost:3001/codat/" + name + "/" + status;
  const url = `${api}/codat/${name}/${status}`;
  
  dispatch({ type: FETCH_CODAT_COMPANY});
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
  .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_CODAT_COMPANY_SUCCESSFUL,
        payload: payload,
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_CODAT_COMPANY_ERROR
      })
      console.log(err.message);
    });
};
