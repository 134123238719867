import React from "react"
import styled from "styled-components"

import StatusCircle from './StatusCircle'

export const GaugeTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
`;

export const GaugeContent = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0;
`

export const StatusHolder = styled.div`
    margin-right: 10px;    
`

const getTableData = (data) => {
    return data.map((row, index) => {
      return (
        <GaugeContent key={index}>
          <StatusHolder> <StatusCircle key={row.color} status={row.color}/></StatusHolder>
          <div> {row.description}</div>
        </GaugeContent>
      );
    });
  };

export const GaugeHelper = ({
    data
}) => {
    if (!data) {
        return null
    }
    return (
        <GaugeTable>
            {getTableData(data)}
        </GaugeTable>
    )
}

export default GaugeHelper