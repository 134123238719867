export const buildingCosts = [
  "- Select Type -",
  "Rent",
  "Mortgage",
  "Building Maintenance"
]

export const financialServices = [
  "- Select Type -",
  "Loan Repayments"
]

export const utilities = [
  "- Select Type -",
  "Telephone",
  "Internet",
  "Gas",
  "Water",
  "Electric",
  "Sanitation"
]

export const vehiclesAndEquipment = [
  "- Select Type -",
  "Vehicle Purchases",
  "Vehicle Repayments",
  "Vehicle Leases",
  "Equipment Purchases",
  "Equipment Rentals",
  "Fuel",
  "Repairs",
  "Other Fees"
]

export const employees = [
  "- Select Type -",
  "Benefits",
  "Travel",
  "Entertainment",
  "Training"
]

export const businessCosts = [
  "- Select Type -",
  "Franchise Fee",
  "Trademark",
  "Registration",
  "Trade Name",
  "License and Permits",
  "Office Supplies",
  "Stock Purchases",
  "Material Purchases",
  "Advertising and Marketing",
  "Supplies and Materials",
  "Legal Fees",
  "Accounting Fees",
  "Professional Fees",
  "Subscription Services",
  "Software Licenses"
]

export const taxes = [
  "- Select Type -",
  "Property",
  "Payroll",
  "Income Tax",
  "Sales Tax",
  "Franchise Tax",
  "Dividend Tax"
]

export const insurancePremiums = [
  "- Select Type -",
  "Health Insurance",
  "Building Insurance",
  "Commercial Auto Insurance",
  "General Liability Insurance",
  "Commercial Property Insurance",
  "Professional Liability Insurance",
  "Business Income Insurance",
  "Worker's Compenesation Insurance",
  "Other Insurance"
]