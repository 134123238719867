import React from "react";
import { withTheme } from "styled-components";
import Gauge from "./Gauge";
import { getMycPopulationScore } from "../report/utils/score";

export const MycScoreGauge = ({ theme, mycScore }) => {

  const mycStatus = getMycPopulationScore(mycScore)

  return (
    <Gauge
      width={80}
      height={60}
      colours={[theme.red7, theme.veryHighRisk, theme.highRisk,theme.moderateRisk, theme.yellow1,  theme.green1, theme.green2, theme.lowRisk, theme.greenPrimary, theme.veryLowRisk]}
      data={[10, 10, 10, 10, 10, 10, 10, 10, 10, 10]}
      radianAngle={needleAngle[mycStatus]}
    />
  );
};

const needleAngle = {
  excellent: Math.PI * 0.075,
  veryGood: Math.PI * 0.17,
  good: Math.PI * 0.27,
  notGood: Math.PI * 0.37,
  aboveAverage: Math.PI * 0.47,
  average: Math.PI * 0.55,
  belowAverage: Math.PI * 0.65,
  notBad: Math.PI * 0.75,
  bad: Math.PI * 0.85,
  veryBad: Math.PI * 0.95,
};

export default withTheme(MycScoreGauge);
