import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";

import { searchCompanies } from "../actions/searchActions";

import Button from "./core/Button";
import { InputField, SelectField } from "./core/FormComponents";
import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "./styled/DisplayAttribute";

import { usStatePackages } from "../utils/usStatePackages";

import * as Yup from "yup";
import { ILLEGAL_CHARACTERS } from "../utils/regexConstants";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;

  ${Attribute} {
    align-items: center;
  }

  h6 {
    text-align: left;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 4px;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;

  button {
    width: 20%;
    font-size: 1.6rem;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

export class SearchWithFilters extends Component {
  render() {
    const { addButton } = this.props;

    const validationSchema = () =>
      Yup.object().shape({
        companyName: Yup.string()
          .trim()
          .uppercase()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          })
          .required("Company Name Required"),
      });

    return (
      <Formik
        initialValues={{
          companyName: "",
          street: "",
          city: "",
          province: "",
          postCode: "",
        }}
        onSubmit={(values) => {
          this.props.searchCompanies(
            this.props.country,
            values.companyName,
            values.street,
            values.city,
            values.province,
            values.postCode
          );
        }}
        validationSchema={validationSchema()}
      >
        <Form>
          <DetailsContainer>
            <AttributeRow
              width={100}
              name={<label>Company Name</label>}
              value={<InputField name="companyName" />}
            />
            <AttributeRow
              width={100}
              name={<label>Address</label>}
              value={<InputField name="street" />}
            />
            <AttributeRow
              width={100}
              name={<label>City</label>}
              value={<InputField name="city" />}
            />
            <AttributeRow
              width={100}
              name={<label>State</label>}
              value={
                <SelectField name={"province"}>{provinceOptions()}</SelectField>
              }
            />
            <AttributeRow
              width={100}
              name={<label>Zip Code</label>}
              value={<InputField name="postCode" />}
            />
          </DetailsContainer>
          <ButtonBar>
            {addButton}
            <Button 
              variant="success" 
              type="submit"
              data-testid="search-form-submit-button" 
            >
              Search
            </Button>
          </ButtonBar>
        </Form>
      </Formik>
    );
  }
}

SearchWithFilters.propTypes = {
  searchCompanies: PropTypes.func,
  searchCompleted: PropTypes.object,
  country: PropTypes.string,
};

const mapStateToProps = (state) => ({
  country: state.user.info.country,
  searchCompleted: state.search,
});

export default connect(mapStateToProps, { searchCompanies })(SearchWithFilters);
