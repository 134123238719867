import React, { Component } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";
import Button from "../core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import styled from "styled-components";

export const PDFContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const PdfDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    margin: 0 auto;
    max-height: 545px !important;
  }

  .react-pdf__Page__textContent {
    width: 100% !important;
    margin: 0 auto;
    max-height: 545px !important;
  }
`;
class PDFPage extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  componentDidUpdate() {}

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePageBack(pageNumber) {
    if (pageNumber > 1) {
      this.setState({
        pageNumber: pageNumber - 1,
      });
    }
  }

  handlePageNext(pageNumber, numPages) {
    if (pageNumber !== numPages) {
      this.setState({
        pageNumber: pageNumber + 1,
      });
    }
  }

  render() {
    const { pageNumber, numPages } = this.state;

    const { pdfFile } = this.props;

    return (
      <PdfDocumentWrapper>
        <Document
          file={pdfFile}
          onLoadSuccess={this.onDocumentLoadSuccess}
          options={options}
          renderMode="canvas"
        >
          <Page pageNumber={pageNumber}>
            <PDFContainer>
              <Button
                variant="success"
                type="button"
                onClick={() => this.handlePageBack(pageNumber)}
              >
                <ArrowBackIosIcon />
              </Button>
              <span>
                {" "}
                Page {pageNumber} of {numPages}{" "}
              </span>
              <Button
                variant="success"
                type="button"
                onClick={() => this.handlePageNext(pageNumber, numPages)}
              >
                <ArrowForwardIosIcon />
              </Button>
            </PDFContainer>
          </Page>
        </Document>
      </PdfDocumentWrapper>
    );
  }
}

export default PDFPage;
