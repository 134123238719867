import { isSafeNumber, getCountryCode } from "./safeNumbers";
import {
  CONNECT_ID_IDENTIFIER,
  WX_ID_IDENTIFIER,
} from "./regexConstants";

export const isConnectId = id => !!id && CONNECT_ID_IDENTIFIER.test(id);

export const isWXId = id => !!id && WX_ID_IDENTIFIER.test(id);

export const isPlc = ({ id, companyId, safeNumber } = {}) =>
  !!(
    (id && id.startsWith("PLC")) ||
    (companyId && companyId.startsWith("PLC")) ||
    (safeNumber && safeNumber.startsWith("WW"))
  );

export const isOpenCorpsDataCompany = safeNumber =>
  !!((safeNumber && safeNumber.startsWith("WX")) || isWXId(safeNumber));

export const hasIdentifier = ({ id, companyId, safeNumber } = {}) =>
  !!(id || companyId || safeNumber);

export const generateCompanyFromId = id => {
  const isSafeNo = isSafeNumber(id);
  const country = isSafeNo ? getCountryCode(id) : id.slice(0, 2).toUpperCase();
  return {
    id: isConnectId(id) ? id : null,
    safeNumber: isSafeNo ? id : null,
    country,
  };
};

export const isUSIdentifier = id => !!(id && id.startsWith("US"));

export const generateCompanyUrl = payload => {
  let destination = `/companies/${payload.company}`;
  if (payload.tab) {
    destination = destination + `/${payload.tab}`;
  }
  return destination;
};

export const isBEIdentifier = id => !!(id && id.startsWith("BE"));

export const isBESummaryTab = (id, isSummaryTab = false) =>
  isSummaryTab && isBEIdentifier(id);
