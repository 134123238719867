import get from "lodash/get";
import has from "lodash/has";
import isNumber from "lodash/isNumber";

import { formatDateYM } from "./date";

export const navModel = (report) => {

  const incorporated = get(report.report, "companyIdentification.basicInformation.legalForm.description", null)
  const uccDetails = get(report.report, "negativeInformation.uccDetails", null)
  const latestTurnoverFigure = get(report.report, "companySummary.latestTurnoverFigure.value", null)
  const businessTaxLienCount = get(report.report, "negativeInformation.taxLienDetails", null)

  const sicCode = get(report.report, "companySummary.mainActivity.code", null)
  const naicsCode = get(report.report, "companyIdentification.activityClassifications[1].activities[0].code", null)

  const isIncorporated = incorporated === 'Incorporated' ? true : false
  const turnoverInCents = isNumber(latestTurnoverFigure) ? latestTurnoverFigure * 100 : null
  const taxLienCount = businessTaxLienCount ? businessTaxLienCount.length : null

  const monthDateStart = get(report.report, "additionalInformation.misc.establishmentDate", null)

  // let modelHasGrossRevAndTax = {
  //   businessSicCode: get(report.report, "companySummary.mainActivity.code", ""),
  //   businessIsIncorporated: isIncorporated,
  //   businessHasUCCFilings: has(uccDetails),
  //   businessNaicsCode: get(report.report, "companyIdentification.activityClassifications[1].activities[0].code", ""),
  //   businessStartDate: formatDateYM(monthDateStart),
  //   businessAnnualGrossRevenueCents: turnoverInCents,
  //   businessTaxLienCount: taxLienCount,
  // }

  let model = {
    businessIsIncorporated: isIncorporated,
    businessHasUCCFilings: has(uccDetails),
  }

  if (monthDateStart) {
    model.businessStartDate = formatDateYM(monthDateStart)
  }

  if (turnoverInCents) {
    model.businessAnnualGrossRevenueCents = turnoverInCents
  }

  if (taxLienCount) {
    model.businessTaxLienCount = taxLienCount
  }

  if (sicCode) {
    model.businessSicCode = sicCode
  }

  if (naicsCode) {
    model.businessNaicsCode = naicsCode
  }


  return model
}


export const businessContactModel = (report, userEmail) => {
  const basicInformation = get(report.report, "companyIdentification.basicInformation")
  const contactAddress = get(basicInformation, "contactAddress")

  const businessCity = get(contactAddress, "city", "")
  const businessName = get(basicInformation, "businessName", "")
  const businessPhone = get(contactAddress, "telephone", "")
  const businessState = get(contactAddress, "province", "")
  const busStreet = get(contactAddress, "street", "")
  const businessZip = get(contactAddress, "postalCode", "")
  const email = userEmail
  const userID = get(report.report, "companyId", null)

  const businessStreet = []
  businessStreet.push(busStreet)

  let contactModel = {
    businessCity,
    businessName,
    businessPhone,
    businessState,
    businessStreet,
    businessZip,
    email,
    userID
  }

  return contactModel
}
