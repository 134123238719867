import get from "lodash/get"
import has from "lodash/has"


const ReportPopulation = (report, payments, references, expenditures, codat) => {
  let score = 0.0

  const safeNumber = report.companySummary.companyNumber

  const basicInformation = get(report, "companyIdentification.basicInformation")
  const additionalInformation = get(report,"additionalInformation")
  const otherInformation = get(report,"otherInformation")
  const contactInformation = get(report,"contactInformation")
  const companySummary = get(report,"companySummary")
  const directors = get(report,"directors")
  const groupStructure = get(report,"groupStructure")
  const extendedGroupStructure = get(report,"extendedGroupStructure")
  
  const providerCode = has(basicInformation, "legalForm.providerCode") 
  if(providerCode){
    score = score + 2.94
  }

  const telephone = has(basicInformation, "contactAddress.telephone")
  const street = has(basicInformation,"contactAddress.street")
  const city = has(basicInformation,"contactAddress.city")
  const province = has(basicInformation,"contactAddress.province")
  const postalCode = has(basicInformation,"contactAddress.postalCode")
  const registrationDate = has(basicInformation, "companyRegistrationDate")
  const operationsStartDate = has(basicInformation,"operationsStartDate")
  
  if(telephone){
    score = score + 2.94
  }
  if(street){
    score = score + 2.94
  }
  if(city){
    score = score + 2.94
  }
  if(province){
    score = score + 2.94
  }
  if(postalCode){
    score = score + 2.94
  }
  if(registrationDate){
    score = score + 2.94
  }
  if(operationsStartDate){
    score = score + 2.94
  }

  const taxId = has(additionalInformation, "misc.taxID")
  const locationType = has(additionalInformation, "misc.locationType")
  const primaryCorporateRecord = has(additionalInformation, "primaryCorporateRecord")
  if(taxId){
    score = score + 2.94
  }
  if(locationType){
    score = score + 2.94
  }
  if(primaryCorporateRecord){
    score = score + 2.94
  }

  const employees = has(otherInformation, "employeesInformation[0].numberOfEmployees")
  if(employees){
    score = score + 2.94
  }

  const website = has(contactInformation,"websites")
  if(website){
    score = score + 2.94
  }

  const turnOver = has(companySummary, "latestTurnoverFigure")
  const businessName = has(companySummary, "businessName")
  const sicCode = has(companySummary,"mainActivity.code")
  if(turnOver){
    score = score + 2.94
  }
  if(businessName){
    score = score + 2.94
  }
  if(sicCode){
    score = score + 2.94
  }
  if(has(payments)){
    score = score + 2.94
  }

  if(has(references)){
    score = score + 8.33
  }

  if(has(expenditures)){
    score = score + 8.33
  }

  if(has(codat)){
    score = score + 8.33
  }

  const hasCurrentDirectors = has(directors, "currentDirectors") 
  if(hasCurrentDirectors){
    score = score + checkDirectorComplete(directors)
  }

  const hasBranchOffices = has(additionalInformation,"branchOffices")
  if(hasBranchOffices){
    score = score + checkBranchComplete(additionalInformation)
  }

  if(hasGroupStructureInfo(report, groupStructure)){
    const hasSubsidiaryCompanies = has(groupStructure,"subsidiaryCompanies")
   
    if(isUltimateParentCompany(safeNumber, extendedGroupStructure)){
      score = score + 4.16
      if(hasSubsidiaryCompanies){
        score = score + checkSubsidiariesComplete(groupStructure)
      }
    } else {
      const ultimateParentCompany = get(groupStructure,"ultimateParent")
      const immediateParentCopmany = get(groupStructure,"immediateParent")
      
      if(ultimateParentCompany != null){
        score = score + checkParentComplete(ultimateParentCompany)
      }

      if(immediateParentCopmany != null){
        score = score + checkParentComplete(immediateParentCopmany)
      }

      if(hasSubsidiaryCompanies){
        score = score + checkSubsidiariesComplete(groupStructure)
      }

    }
  }

 return parseFloat(score).toPrecision(2) 
}

function checkParentComplete(subCompany){
  let total = 2.08

  if(subCompany.safeNumber == null || subCompany.safeNumber === ""){
    total = 0.0
  }

  if(subCompany.name == null || subCompany.name === ""){
    total = 0.0
  }

  if(subCompany.country == null || subCompany.country === ""){
    total= 0.0
  }

  if(subCompany.registrationNumber == null || subCompany.registrationNumber === ""){
    total = 0.0
  }
  return total
}

function hasGroupStructureInfo(report){
  const extendedGroupStructure = has(report,"extendedGroupStructure")
  const groupStructure = get(report,"groupStructure")

  const ultimateParent = has(groupStructure,"ultimateParent.name")
  const immediateParent = has(groupStructure,"immediateParent.name")
  const subsidiaryCompanies = has(groupStructure,"subsidiaryCompanies")

  if(extendedGroupStructure){
    return true
  }
  if(ultimateParent || immediateParent || subsidiaryCompanies){
    return true
  }
  return false
}

function isUltimateParentCompany(safeNumber, extendedGroupStructure){
  let ultimate = false
  if(has(extendedGroupStructure)){
    if(extendedGroupStructure[0].safeNumber === safeNumber){
      ultimate = true
    }
  }
  
  return ultimate
}

function checkSubsidiariesComplete(subsidiariesList){
  const { subsidiaryCompanies } = subsidiariesList
  
  if(subsidiaryCompanies.length > 0){
    let name = true
    let country = true
    let regNumber = true
    let safeNumber = true
    let subsidiaryTotal = 4.0825
    for( let i = 0; i < subsidiaryCompanies.length; i++){
      if(name){
        if(subsidiaryCompanies[i].name == null || subsidiaryCompanies[i].name === ""){
          subsidiaryTotal = subsidiaryTotal - 1.02;
          name = false;
        }
      }
      if(country){
        if(subsidiaryCompanies[i].country == null || subsidiaryCompanies[i].country === ""){
          subsidiaryTotal = subsidiaryTotal - 1.02;
          country = false;
        }
      }
      if(regNumber){
        if(subsidiaryCompanies[i].registrationNumber == null || subsidiaryCompanies[i].registrationNumber === ""){
          subsidiaryTotal = subsidiaryTotal - 1.02;
          regNumber = false;
        }
      }
      if(safeNumber){
        if(subsidiaryCompanies[i].safeNumber == null || subsidiaryCompanies[i].safeNumber === ""){
          subsidiaryTotal = subsidiaryTotal - 1.02;
          safeNumber = false;
        }
      }
    }
    return subsidiaryTotal
  }
  return 0.0
}

function checkBranchComplete(branchList){
  const { branchOffices } = branchList
  if(branchOffices.length > 0){ 
    let companyName = true
    let safeNumber = true
    let streetAddress = true
    let city = true
    let zip = true
    let phone = true
    let headCount = true
    let turnOver = true
    let branchTotal = 8.33
    for ( let i = 0; i < branchOffices.length; i++){ 
      if(companyName){ 
        if(branchOffices[i].name ==  null || branchOffices[i].name === ""){ 
          branchTotal = branchTotal - 0.93
          companyName = false
        }
      }

      if(safeNumber){ 
        if(branchOffices[i].safeNumber == null || branchOffices[i].safeNumber === ""){
          branchTotal = branchTotal - 0.93
          safeNumber = false
        }
      }

      if(streetAddress){
        if(branchOffices[i].address.street == null || branchOffices[i].address.street === ""){ 
          branchTotal = branchTotal - 0.93
          streetAddress = false
        }
      }

      if(city){
        if(branchOffices[i].address.city == null || branchOffices[i].address.city === ""){
          branchTotal = branchTotal - 0.93
          city = false
        }
      }

      if(zip){
        if (branchOffices[i].address.postalCode == null || branchOffices[i].address.postalCode === "") {
            branchTotal = branchTotal - 0.93;
            zip = false;
        }
      }

      if(phone){
          if (branchOffices[i].address.telephone == null || branchOffices[i].address.telephone === "") {
              branchTotal = branchTotal - 0.93;
              phone = false;
          }
      }

      if(headCount) {
          if (branchOffices[i].numberOfEmployees == null || branchOffices[i].numberOfEmployees === "") {
              branchTotal = branchTotal - 0.93;
              headCount = false;
          }
      }

      if(turnOver) {
          if (branchOffices[i].latestTurnoverFigure == null || branchOffices[i].latestTurnoverFigure === "") {
              branchTotal = branchTotal - 0.93;
              turnOver = false;
          }
      }
    }
    return branchTotal
  }
  return 0.0
}

function checkDirectorComplete(directorList){

  const { currentDirectors } = directorList 
  if(currentDirectors.length > 0){
    let directorName = true;
    let directorAddress = true;
    let directorPosition = true;
    let directorTotal = 8.33

    for( let i = 0; i < currentDirectors.length; i++){
      if(directorName){
        if(currentDirectors[i].name == null || currentDirectors[i].name === "" ){
          directorTotal = directorTotal - 2.77
          directorName = false
        }
      }
      
      if(directorAddress){
        if(currentDirectors[i].address == null || currentDirectors[i].address.simpleValue == null || currentDirectors[i].address.simpleValue === ""){
          directorTotal = directorTotal - 2.77
          directorAddress = false
        }
      }

      if(directorPosition){
        if(currentDirectors[i].positions[0].positionName == null || currentDirectors[i].positions[0].positionName === ""){
          directorTotal = directorTotal - 2.77
          directorPosition = false
        }
      }      
    }
    return directorTotal;
  }
  return 0.0
}


export default ReportPopulation