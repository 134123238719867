import {
  FETCH_REPORT_JSON,
  FETCH_REPORT_JSON_SUCCESS,
  SET_REPORT_LOADING,
} from "../actions/types";

const initialState = {
  report: {
    report: {
      extendedGroupStructure: [],
      financialStatements: [],
    },
  },
  isLoading: false,
  loadingType: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORT_JSON:
      return {
        ...state,
        isLoading: true,
        loadingType: action.payload.loadType,
      };
    case FETCH_REPORT_JSON_SUCCESS:
      return {
        ...state,
        report: {
          ...state.report,
          report: action.payload,
        },
        isLoading: false,
        loadingType: "",
      };
    case SET_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        loadingType: action.payload.loadType,
      };
    default:
      return state;
  }
}
