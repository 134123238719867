import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import DefinitionList from "../core/DefinitionList";
import { displayValueOrSeparator, titleCase } from "../../utils/string";
import Weblinks from "../core/Weblinks";
import Address from "../core/Address";

import get from "lodash/get";

import { formatUSTelephone } from "../../utils/telephone";

import { Card } from "@creditsafe/ui-components";

import { withTranslation } from "react-i18next";

import Button from "../core/Button";
import CardTitle from "../core/CardTitle";
import { InlineLoader } from "../core/Loading";

import EditContactInformation from "../form/EditContactInformation";

import ClearIcon from '@material-ui/icons/Clear';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from '@material-ui/core/Tooltip';

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class ContactInformation extends Component {
  state = {
    editing: false,
  };

  handleClick = () => {
    this.setState((prevState) => ({
      editing: !prevState.editing,
    }));
  };

  render() {
    const { editing } = this.state;

    const {
      mycID,
      companyId,
      safeNumber,
      email,
      companyReport,
      additionalInformation,
      companyIdentification,
      contactInformation,
      otherInformation,
      companySummary,
      fetchReportJson,
      fetchUserReport,
      setReportLoading,
      accessToken,
      language,
      report,
      isLoading,
      loadingType,
      expressApi,
      userReport,
      fullName,
      boId,
      profile
    } = this.props;

    const isLoadingContactInformation =
      isLoading && loadingType === "contactInformation";

    const street = get(
      companyIdentification,
      "basicInformation.contactAddress.street","-"
    );
    const city = get(
      companyIdentification,
      "basicInformation.contactAddress.city","-"
    );
    const state = get(
      companyIdentification,
      "basicInformation.contactAddress.province","-"
    );
    const postCode = get(
      companyIdentification,
      "basicInformation.contactAddress.postalCode","-"
    );

    const simpleValueAddress = `${titleCase(street)}, ${titleCase(city)}, ${state}, ${postCode}`
    
    const sVAArray = simpleValueAddress.split(",")

    const address = simpleValueAddress;
    const locationType = get(additionalInformation, "misc.locationType", "-");
    const addressType = get(additionalInformation, "misc.addressType", "-");
    const website = get(contactInformation, "websites", []);
    const phoneNumber = get(
      companyIdentification,
      "basicInformation.contactAddress.telephone"
    );

    const fields = {
      locationType: {
        title: "Location Type",
      },
      addressType: {
        title: "Address Type",
      },
      address: {
        title: "Address",
      },

      website: {
        title: "Website",
        id:"website"
        // hidden: website[0] === "" || !(website.length > 0),
      },
      phoneNumber: {
        title: "Phone Number",
      },
    };

    const model = {
      address: displayValueOrSeparator(
        <Address address={address} linkText="View on Map" />
      ),
      locationType: displayValueOrSeparator(titleCase(locationType)),
      addressType: displayValueOrSeparator(titleCase(addressType)),
      website: displayValueOrSeparator(<Weblinks websites={website} />),
      phoneNumber: displayValueOrSeparator(formatUSTelephone(phoneNumber)),
    };

    return (
      <Card id="walkmeContactInfoCard">
        <HelpTitle>
          <CardTitle title="Contact Information" />
          <Button onClick={this.handleClick}>
          { editing ?  <Tooltip title="Close Edit"><ClearIcon /></Tooltip> : <Tooltip title="Edit Contact Information"><EditOutlinedIcon data-testid="edit-contact-info-icon" id="walkMeEditContact"/></Tooltip> }
          </Button>
        </HelpTitle>
        {editing ? (
          <EditContactInformation
            mycID={mycID}
            companyId={companyId}
            safeNumber={safeNumber}
            email={email}
            companyReport={companyReport}
            additionalInformation={additionalInformation}
            companyIdentification={companyIdentification}
            contactInformation={contactInformation}
            otherInformation={otherInformation}
            companySummary={companySummary}
            fetchReportJson={fetchReportJson}
            fetchUserReport={fetchUserReport}
            setReportLoading={setReportLoading}
            edit={this.handleClick}
            accessToken={accessToken}
            language={language}
            report={report}
            expressApi={expressApi}
            sVAArray={sVAArray}
            userReport={userReport}
            fullName={fullName}
            boId={boId}
            profile={profile}
          />
        ) : (
          ""
        )}
        {!isLoadingContactInformation && !editing ? (
          <DefinitionList fields={fields} model={model} layout="horizontal" />
        ) : (
          <InlineLoader
            isLoading={isLoadingContactInformation}
            delayMS={0}
            loadingText={"Updating..."}
          />
        )}
      </Card>
    );
  }
}

ContactInformation.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  report: state.company.report,
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  expressApi: state.app.config.EXPRESS.URL,
});

export default compose(
  connect(mapStateToProps, null),
  withTranslation("company-report")
)(ContactInformation);
