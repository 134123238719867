import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@fortawesome/fontawesome-pro/css/all.min.css";

// If the icon is being rendered on the PDF we should move it in here

export const ArrowUpCircleUp = props => (
  <i className="fas fa-arrow-alt-circle-up" {...props} />
);

export const ArrowDownCircleDown = props => (
  <i className="fas fa-arrow-alt-circle-down" {...props} />
);

export const Remove = props => <i className="fas fa-minus" {...props} />;

export const InfoOutline = () => (
  <i
    className="fal fa-info-circle"
    style={{ fontSize: "24px", cursor: "pointer" }}
  />
);

export const Check = () => <i className="fas fa-check" />;

export const SpinningCircle = () => (
  <i
    className="fas fa-circle-notch fa-spin loading-icon"
    data-fa-transform="grow-6"
  />
);

export const TimesCircle = () => (
  <i className="fas fa-times-circle fa-w-16 fa-lg" />
);

export const ArrowUp = ({ className }) => (
  <i className={`fas fa-sort-up ${className}`} />
);

export const ArrowDown = ({ className }) => (
  <i className={`fas fa-sort-down ${className}`} />
);

export const CreditCard = ({ className }) => (
  <i className={`far fa-credit-card ${className}`} />
);

export const Industry = ({ className, ...rest }) => (
  <i className={`fas fa-industry-alt ${className}`} {...rest} />
);

export const BankruptcySearchIcon = ({ className, ...rest }) => (
  <i
    style={{ fontSize: "24px", cursor: "pointer" }}
    className={`far fa-chart-line-down ${className}`}
    {...rest}
  />
);

export const Warning = () => (
  <i
    style={{ fontSize: "16px", margin: "0 8px 0" }}
    className="fas fa-exclamation-triangle"
  />
);
