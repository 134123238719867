import styled, { css } from "styled-components";
import { common } from "./Input";
import React from "react";

const TextAreaStyles = css`
  ${common}
  resize: none;

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    resize: vertical;
  }
`;

const TextArea = styled(({ field, form, variant, ...props }) => (
  <textarea {...field} {...props} />
))`
  ${TextAreaStyles}
`;

export default TextArea;
