import isNil from "lodash/isNil";
import { findSupplier } from "./supplier";
import { getScoreStatusByCountry } from "./score";

export const limitCategoriesUS = [
  {
    scoreMin: 30,
    limitMin: 1,
    colorStatus: "excellent",
  },
  {
    scoreMin: 30,
    limitMax: 1,
    colorStatus: "fair",
  },
  {
    scoreMin: 21,
    scoreMax: 30,
    colorStatus: "fair",
  },
  {
    scoreMin: 0,
    scoreMax: 21,
    colorStatus: "bad",
  },
  {
    scores: [-1, -7, -9, -10, -11, -12, -13],
    colorStatus: "fair",
  },
  {
    scores: [-2, -3],
    colorStatus: "bad",
  },
];

export const getLimitCategoriesByCountry = country => {
  switch (country) {
    case "US":
    case "GB":
    case "IE":
    case "NO":
    case "FR":
    case "DE":
    case "NL":
    case "BE":
    case "JP":
    case "IT":
      return limitCategoriesUS;
    default:
      return null;
  }
};

const notRatedAllowedSuppliers = ["MX", "WVB"];
const useScoreStatusCountries = ["MX", "CA", "WVB", "LU", "ES"];

export const getLimitStatusByCountry = (
  country,
  limit,
  score,
  negativeRating
) => {
  const countryOrSupplier = findSupplier(country) || country;

  if (useScoreStatusCountries.includes(countryOrSupplier)) {
    return getScoreStatusByCountry(country, score);
  }

  const limitCategories = getLimitCategoriesByCountry(countryOrSupplier);

  if (!limitCategories || isNil(score)) {
    return "none";
  }

  if (isNil(limit)) {
    return "n/a";
  }

  if (negativeRating) {
    score = negativeRating;
  }

  if (
    notRatedAllowedSuppliers.includes(countryOrSupplier) &&
    score === "Not Rated"
  ) {
    return "bad";
  }

  score = parseInt(score, 10);
  limit = parseInt(limit, 10);

  for (let i = 0; i < limitCategories.length; i++) {
    const c = limitCategories[i];

    if (!isNil(c.scores) && !c.scores.includes(score)) {
      continue;
    }

    if (!isNil(c.scoreMin) && score < c.scoreMin) {
      continue;
    }

    if (!isNil(c.scoreMax) && score >= c.scoreMax) {
      continue;
    }

    if (!isNil(c.limitMin) && limit < c.limitMin) {
      continue;
    }

    if (!isNil(c.limitMax) && limit >= c.limitMax) {
      continue;
    }

    return c.colorStatus;
  }

  return "none";
};

export const isZeroLimit = limit => parseInt(limit) === 0;
