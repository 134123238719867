import React from "react";
import styled from "styled-components";

import Button from "../core/Button";
import { SelectField } from "../core/FormComponents";
import { Formik, Form } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import get from "lodash/get";

import { classificationPackage } from "../../utils/classificationsPackage";
import { sicCodes } from "../../utils/sicCodes";
import { naicCodes } from "../../utils/naicCodes";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

const SelectWrapper = styled.div`
  width: 85%;

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 55%;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

const getActivityPackage = (classification) =>
  classification === "NAICS" ? naicCodes : sicCodes;

const generateSelectOptions = (list) =>
  list.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

class EditActivities extends React.Component {

  handleDelete = () => {
    const { activity, activityClassifications } = this.props;

    const activityClass = activityClassifications.find(
      (a) => a.classification === activity.classification
    );
    const index = activityClass.activities.findIndex(
      (a) => a.code === activity.code && a.description === activity.description
    );
    const removedActivity =  activityClass.activities[index]
    let primaryCode = 'Y'

    if(index > 0){
      primaryCode = "N"
    }
    
    if (index > -1) {
      activityClass.activities.splice(index, 1);
    }

    const deleteFlag = "Y"

    this.submitData(activityClassifications, undefined, removedActivity, deleteFlag, primaryCode );
  };


  handleValueRefresh = (e, setFieldValue) => {
    if(e.target.name === "classification") {
      setFieldValue("code",  getActivityPackage(e.target.value)[0])
    }
  }

  submitData = async (activityClassifications, setSubmitting, modifiedActivity, deleteFlag, primaryCode) => {
    const {
      fetchReportJson,
      fetchUserReport,
      companyId,
      email,
      companyReport,
      mycID,
      report,
      language,
      close,
      setReportLoading,
      expressApi,
      userReport,
      safeNumber,
      fullName,
      boId,
      profile
    } = this.props;

    const detClassification = modifiedActivity.code.length === 4 ? "S" : "N"

    await setReportLoading("activities");

    await fetch(`${expressApi}/reports`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        mycID,
        email,
        report,
        language,
        reportupdated_at: new Date(),
        updated_at: new Date(),
        created_at: new Date(),
        verifiedstatus: "changed",
        bo_id: boId,
        bo_name: fullName,
        reportjson: {
          ...companyReport,
          companySummary: {
            ...companyReport.companySummary,
            mainActivity : {
              code: get(activityClassifications[0], "activities[0].code", ""),
              description: get(activityClassifications[0], "activities[0].description", ""),
            }
          },
          companyIdentification: {
            ...companyReport.companyIdentification,
            basicInformation: {
              ...companyReport.companyIdentification.basicInformation,
              principalActivity: {
                description: get(activityClassifications[0], "activities[0].description", ""),
              },                                 
            },
            activityClassifications,
          },
        },
        detType: 'update_industry',
        detInfo: {
          safe_number: safeNumber,
          myc_id: userReport.id,
          company_id: mycID,
          primary_code: primaryCode,
          industry_code: modifiedActivity.code,
          classification: detClassification,
          status:'fail',
          delete_flag: deleteFlag,
          user_id: userReport.userid,
          user_verified: userReport.userstatus, 
          customer_id: profile.customer.id       
        }
      }),
    })
      .then((response) => response.json())
      .then(() => setSubmitting && setSubmitting(false))
      .then(() => fetchReportJson(companyId, email))
      .then(() => fetchUserReport(companyId))
      .then(() => close())
      .catch((err) => console.log(err));
  };

  render() {
    const { activityClassifications, activity, close, row, type } = this.props;
    
    return (
      <Formik
        initialValues={{
          classification: activity
            ? activity.classification
            : classificationPackage[0],
          code: activity
            ? `${activity.code} ${activity.description}`
            :  getActivityPackage(classificationPackage[0])[0],
        }}
        validationSchema={activitySchema()}
        onSubmit={async (values, { setSubmitting }) => {
          const [code, ...description] = values.code.value.split(/\W+/);
                    
          if(activityClassifications[0].activities || activityClassifications[1].activities){
            const duplicate = activityClassifications.some(
              (ac) =>
                ac.classification === values.classification &&
                get(ac,"activities", []).some((a) => a.code === code)
            );

            if (duplicate) {
              setSubmitting(false);
              close();
              alert("You have already submitted this activity");
              return;
            }
          } 
          
          const newActivity = {
            code,
            description: description.join(" "),
          };

          const selectedClass = activityClassifications.filter(
            (c) => c.classification === values.classification
          )[0];

          let primaryCode = 'Y'
          const deleteFlag = "N"  

          if (activity) {
            const activityClass = activityClassifications.find(
              (a) => a.classification === activity.classification
            );
            const index = activityClass.activities.findIndex(
              (a) =>
                a.code === activity.code &&
                a.description === activity.description
            );

            if(values.classification !== activityClass.classification){
              activityClass.activities.splice(index, 1);
              selectedClass.activities = [...selectedClass.activities, newActivity];
            } else {
              selectedClass.activities[index] = newActivity
            }

          } else {

            if(selectedClass.activities){
              selectedClass.activities = [...selectedClass.activities, newActivity];
              primaryCode = "N"
            } else {
              selectedClass.activities = [newActivity]
              primaryCode = "Y"
            }
            
          }       
          
          this.submitData(activityClassifications, setSubmitting, newActivity, deleteFlag, primaryCode);  
        }}                     
      >
        {({ values, setFieldTouched, setFieldValue  }) => (
          <Form
            onChange ={ e => this.handleValueRefresh(e, setFieldValue)}
          >
            <Container>
              <AttributeRow
                width={100}
                name={<label htmlFor="classification">Classification</label>}
                value={
                  <SelectField name={"classification"}>
                    {generateSelectOptions(classificationPackage)}
                  </SelectField>
                }
              />
              <AttributeRow
                width={100}
                name={<label htmlFor="code">Code</label>}
                value={
                  <SelectWrapper>
                    <Select
                      name="code"
                      placeholder={values.code}
                      onBlur={() => setFieldTouched("code", true)}
                      value={values.code}
                      onChange={(opt) => {
                        setFieldValue("code", opt);
                      }}
                      options={getActivityPackage(values.classification)}
                    />
                  </SelectWrapper>
                }
              />
              {activity ? (
                <Button 
                  type="button"
                  data-testid={`${type}-activities-delete-button-${row.index}`} 
                  onClick={this.handleDelete}
                >
                  <DeleteForeverIcon />
                </Button>
              ) : (
                <Button variant="danger" onClick={() => close()}>
                  Cancel
                </Button>
              )}
              <Button 
                variant="success" 
                type="submit"
                data-testid={`${type}-activities-submit-button-${row.index}`} 
              >
                Submit
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    );
  }
}

const activitySchema = () =>
  Yup.object().shape({
    classification: Yup.string().required("Please select a classification"),
    code: Yup.string()
      .test("codeValidation", "Please select an activity code", function (code) {
        if (code === 'Select...') {
          return false;
        } else {
          return true;
        }
      }
 ),
    });

export default EditActivities;
