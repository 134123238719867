export const naicCodes = [
  {value:"111110 Soybean Farming",label:"111110 Soybean Farming"},
{value:"111120 Oilseed (except Soybean) Farming",label:"111120 Oilseed (except Soybean) Farming"},
{value:"111130 Dry Pea and Bean Farming",label:"111130 Dry Pea and Bean Farming"},
{value:"111140 Wheat Farming",label:"111140 Wheat Farming"},
{value:"111150 Corn Farming",label:"111150 Corn Farming"},
{value:"111160 Rice Farming",label:"111160 Rice Farming"},
{value:"111191 Oilseed and Grain Combination Farming",label:"111191 Oilseed and Grain Combination Farming"},
{value:"111199 All Other Grain Farming",label:"111199 All Other Grain Farming"},
{value:"111211 Potato Farming",label:"111211 Potato Farming"},
{value:"111219 Other Vegetable (except Potato) and Melon Farming",label:"111219 Other Vegetable (except Potato) and Melon Farming"},
{value:"111310 Orange Groves",label:"111310 Orange Groves"},
{value:"111320 Citrus (except Orange) Groves",label:"111320 Citrus (except Orange) Groves"},
{value:"111331 Apple Orchards",label:"111331 Apple Orchards"},
{value:"111332 Grape Vineyards",label:"111332 Grape Vineyards"},
{value:"111333 Strawberry Farming",label:"111333 Strawberry Farming"},
{value:"111334 Berry (except Strawberry) Farming",label:"111334 Berry (except Strawberry) Farming"},
{value:"111335 Tree Nut Farming",label:"111335 Tree Nut Farming"},
{value:"111336 Fruit and Tree Nut Combination Farming",label:"111336 Fruit and Tree Nut Combination Farming"},
{value:"111339 Other Noncitrus Fruit Farming",label:"111339 Other Noncitrus Fruit Farming"},
{value:"111411 Mushroom Production",label:"111411 Mushroom Production"},
{value:"111419 Other Food Crops Grown Under Cover",label:"111419 Other Food Crops Grown Under Cover"},
{value:"111421 Nursery and Tree Production",label:"111421 Nursery and Tree Production"},
{value:"111422 Floriculture Production",label:"111422 Floriculture Production"},
{value:"111910 Tobacco Farming",label:"111910 Tobacco Farming"},
{value:"111920 Cotton Farming",label:"111920 Cotton Farming"},
{value:"111930 Sugarcane Farming",label:"111930 Sugarcane Farming"},
{value:"111940 Hay Farming",label:"111940 Hay Farming"},
{value:"111991 Sugar Beet Farming",label:"111991 Sugar Beet Farming"},
{value:"111992 Peanut Farming",label:"111992 Peanut Farming"},
{value:"111998 All Other Miscellaneous Crop Farming",label:"111998 All Other Miscellaneous Crop Farming"},
{value:"112111 Beef Cattle Ranching and Farming",label:"112111 Beef Cattle Ranching and Farming"},
{value:"112112 Cattle Feedlots",label:"112112 Cattle Feedlots"},
{value:"112120 Dairy Cattle and Milk Production",label:"112120 Dairy Cattle and Milk Production"},
{value:"112130 Dual-Purpose Cattle Ranching and Farming",label:"112130 Dual-Purpose Cattle Ranching and Farming"},
{value:"112210 Hog and Pig Farming",label:"112210 Hog and Pig Farming"},
{value:"112310 Chicken Egg Production",label:"112310 Chicken Egg Production"},
{value:"112320 Broilers and Other Meat Type Chicken Production",label:"112320 Broilers and Other Meat Type Chicken Production"},
{value:"112330 Turkey Production",label:"112330 Turkey Production"},
{value:"112340 Poultry Hatcheries",label:"112340 Poultry Hatcheries"},
{value:"112390 Other Poultry Production",label:"112390 Other Poultry Production"},
{value:"112410 Sheep Farming",label:"112410 Sheep Farming"},
{value:"112420 Goat Farming",label:"112420 Goat Farming"},
{value:"112511 Finfish Farming and Fish Hatcheries",label:"112511 Finfish Farming and Fish Hatcheries"},
{value:"112512 Shellfish Farming",label:"112512 Shellfish Farming"},
{value:"112519 Other Aquaculture",label:"112519 Other Aquaculture"},
{value:"112910 Apiculture",label:"112910 Apiculture"},
{value:"112920 Horses and Other Equine Production",label:"112920 Horses and Other Equine Production"},
{value:"112930 Fur-Bearing Animal and Rabbit Production",label:"112930 Fur-Bearing Animal and Rabbit Production"},
{value:"112990 All Other Animal Production",label:"112990 All Other Animal Production"},
{value:"113110 Timber Tract Operations",label:"113110 Timber Tract Operations"},
{value:"113210 Forest Nurseries and Gathering of Forest Products",label:"113210 Forest Nurseries and Gathering of Forest Products"},
{value:"113310 Logging",label:"113310 Logging"},
{value:"114111 Finfish Fishing",label:"114111 Finfish Fishing"},
{value:"114112 Shellfish Fishing",label:"114112 Shellfish Fishing"},
{value:"114119 Other Marine Fishing",label:"114119 Other Marine Fishing"},
{value:"114210 Hunting and Trapping",label:"114210 Hunting and Trapping"},
{value:"115111 Cotton Ginning",label:"115111 Cotton Ginning"},
{value:"115112 Soil Preparation, Planting, and Cultivating",label:"115112 Soil Preparation, Planting, and Cultivating"},
{value:"115113 Crop Harvesting, Primarily by Machine",label:"115113 Crop Harvesting, Primarily by Machine"},
{value:"115114 Postharvest Crop Activities (except Cotton Ginning)",label:"115114 Postharvest Crop Activities (except Cotton Ginning)"},
{value:"115115 Farm Labor Contractors and Crew Leaders",label:"115115 Farm Labor Contractors and Crew Leaders"},
{value:"115116 Farm Management Services",label:"115116 Farm Management Services"},
{value:"115210 Support Activities for Animal Production",label:"115210 Support Activities for Animal Production"},
{value:"115310 Support Activities for Forestry",label:"115310 Support Activities for Forestry"},
{value:"211120 Crude Petroleum Extraction ",label:"211120 Crude Petroleum Extraction "},
{value:"211130 Natural Gas Extraction",label:"211130 Natural Gas Extraction"},
{value:"212111 Bituminous Coal and Lignite Surface Mining",label:"212111 Bituminous Coal and Lignite Surface Mining"},
{value:"212112 Bituminous Coal Underground Mining",label:"212112 Bituminous Coal Underground Mining"},
{value:"212113 Anthracite Mining",label:"212113 Anthracite Mining"},
{value:"212210 Iron Ore Mining",label:"212210 Iron Ore Mining"},
{value:"212221 Gold Ore Mining",label:"212221 Gold Ore Mining"},
{value:"212222 Silver Ore Mining",label:"212222 Silver Ore Mining"},
{value:"212230 Copper, Nickel, Lead, and Zinc Mining",label:"212230 Copper, Nickel, Lead, and Zinc Mining"},
{value:"212291 Uranium-Radium-Vanadium Ore Mining",label:"212291 Uranium-Radium-Vanadium Ore Mining"},
{value:"212299 All Other Metal Ore Mining",label:"212299 All Other Metal Ore Mining"},
{value:"212311 Dimension Stone Mining and Quarrying",label:"212311 Dimension Stone Mining and Quarrying"},
{value:"212312 Crushed and Broken Limestone Mining and Quarrying",label:"212312 Crushed and Broken Limestone Mining and Quarrying"},
{value:"212313 Crushed and Broken Granite Mining and Quarrying",label:"212313 Crushed and Broken Granite Mining and Quarrying"},
{value:"212319 Other Crushed and Broken Stone Mining and Quarrying",label:"212319 Other Crushed and Broken Stone Mining and Quarrying"},
{value:"212321 Construction Sand and Gravel Mining",label:"212321 Construction Sand and Gravel Mining"},
{value:"212322 Industrial Sand Mining",label:"212322 Industrial Sand Mining"},
{value:"212324 Kaolin and Ball Clay Mining",label:"212324 Kaolin and Ball Clay Mining"},
{value:"212325 Clay and Ceramic and Refractory Minerals Mining",label:"212325 Clay and Ceramic and Refractory Minerals Mining"},
{value:"212391 Potash, Soda, and Borate Mineral Mining",label:"212391 Potash, Soda, and Borate Mineral Mining"},
{value:"212392 Phosphate Rock Mining",label:"212392 Phosphate Rock Mining"},
{value:"212393 Other Chemical and Fertilizer Mineral Mining",label:"212393 Other Chemical and Fertilizer Mineral Mining"},
{value:"212399 All Other Nonmetallic Mineral Mining",label:"212399 All Other Nonmetallic Mineral Mining"},
{value:"213111 Drilling Oil and Gas Wells",label:"213111 Drilling Oil and Gas Wells"},
{value:"213112 Support Activities for Oil and Gas Operations",label:"213112 Support Activities for Oil and Gas Operations"},
{value:"213113 Support Activities for Coal Mining",label:"213113 Support Activities for Coal Mining"},
{value:"213114 Support Activities for Metal Mining",label:"213114 Support Activities for Metal Mining"},
{value:"213115 Support Activities for Nonmetallic Minerals (except Fuels) Mining",label:"213115 Support Activities for Nonmetallic Minerals (except Fuels) Mining"},
{value:"221111 Hydroelectric Power Generation",label:"221111 Hydroelectric Power Generation"},
{value:"221112 Fossil Fuel Electric Power Generation",label:"221112 Fossil Fuel Electric Power Generation"},
{value:"221113 Nuclear Electric Power Generation",label:"221113 Nuclear Electric Power Generation"},
{value:"221114 Solar Electric Power Generation",label:"221114 Solar Electric Power Generation"},
{value:"221115 Wind Electric Power Generation",label:"221115 Wind Electric Power Generation"},
{value:"221116 Geothermal Electric Power Generation",label:"221116 Geothermal Electric Power Generation"},
{value:"221117 Biomass Electric Power Generation",label:"221117 Biomass Electric Power Generation"},
{value:"221118 Other Electric Power Generation",label:"221118 Other Electric Power Generation"},
{value:"221121 Electric Bulk Power Transmission and Control",label:"221121 Electric Bulk Power Transmission and Control"},
{value:"221122 Electric Power Distribution",label:"221122 Electric Power Distribution"},
{value:"221210 Natural Gas Distribution",label:"221210 Natural Gas Distribution"},
{value:"221310 Water Supply and Irrigation Systems",label:"221310 Water Supply and Irrigation Systems"},
{value:"221320 Sewage Treatment Facilities",label:"221320 Sewage Treatment Facilities"},
{value:"221330 Steam and Air-Conditioning Supply",label:"221330 Steam and Air-Conditioning Supply"},
{value:"236115 New Single-Family Housing Construction (except For-Sale Builders)",label:"236115 New Single-Family Housing Construction (except For-Sale Builders)"},
{value:"236116 New Multifamily Housing Construction (except For-Sale Builders)",label:"236116 New Multifamily Housing Construction (except For-Sale Builders)"},
{value:"236117 New Housing For-Sale Builders",label:"236117 New Housing For-Sale Builders"},
{value:"236118 Residential Remodelers",label:"236118 Residential Remodelers"},
{value:"236210 Industrial Building Construction",label:"236210 Industrial Building Construction"},
{value:"236220 Commercial and Institutional Building Construction",label:"236220 Commercial and Institutional Building Construction"},
{value:"237110 Water and Sewer Line and Related Structures Construction",label:"237110 Water and Sewer Line and Related Structures Construction"},
{value:"237120 Oil and Gas Pipeline and Related Structures Construction",label:"237120 Oil and Gas Pipeline and Related Structures Construction"},
{value:"237130 Power and Communication Line and Related Structures Construction",label:"237130 Power and Communication Line and Related Structures Construction"},
{value:"237210 Land Subdivision",label:"237210 Land Subdivision"},
{value:"237310 Highway, Street, and Bridge Construction",label:"237310 Highway, Street, and Bridge Construction"},
{value:"237990 Other Heavy and Civil Engineering Construction",label:"237990 Other Heavy and Civil Engineering Construction"},
{value:"238110 Poured Concrete Foundation and Structure Contractors",label:"238110 Poured Concrete Foundation and Structure Contractors"},
{value:"238120 Structural Steel and Precast Concrete Contractors",label:"238120 Structural Steel and Precast Concrete Contractors"},
{value:"238130 Framing Contractors",label:"238130 Framing Contractors"},
{value:"238140 Masonry Contractors",label:"238140 Masonry Contractors"},
{value:"238150 Glass and Glazing Contractors",label:"238150 Glass and Glazing Contractors"},
{value:"238160 Roofing Contractors",label:"238160 Roofing Contractors"},
{value:"238170 Siding Contractors",label:"238170 Siding Contractors"},
{value:"238190 Other Foundation, Structure, and Building Exterior Contractors",label:"238190 Other Foundation, Structure, and Building Exterior Contractors"},
{value:"238210 Electrical Contractors and Other Wiring Installation Contractors",label:"238210 Electrical Contractors and Other Wiring Installation Contractors"},
{value:"238220 Plumbing, Heating, and Air-Conditioning Contractors",label:"238220 Plumbing, Heating, and Air-Conditioning Contractors"},
{value:"238290 Other Building Equipment Contractors",label:"238290 Other Building Equipment Contractors"},
{value:"238310 Drywall and Insulation Contractors",label:"238310 Drywall and Insulation Contractors"},
{value:"238320 Painting and Wall Covering Contractors",label:"238320 Painting and Wall Covering Contractors"},
{value:"238330 Flooring Contractors",label:"238330 Flooring Contractors"},
{value:"238340 Tile and Terrazzo Contractors",label:"238340 Tile and Terrazzo Contractors"},
{value:"238350 Finish Carpentry Contractors",label:"238350 Finish Carpentry Contractors"},
{value:"238390 Other Building Finishing Contractors",label:"238390 Other Building Finishing Contractors"},
{value:"238910 Site Preparation Contractors",label:"238910 Site Preparation Contractors"},
{value:"238990 All Other Specialty Trade Contractors",label:"238990 All Other Specialty Trade Contractors"},
{value:"311111 Dog and Cat Food Manufacturing",label:"311111 Dog and Cat Food Manufacturing"},
{value:"311119 Other Animal Food Manufacturing",label:"311119 Other Animal Food Manufacturing"},
{value:"311211 Flour Milling",label:"311211 Flour Milling"},
{value:"311212 Rice Milling",label:"311212 Rice Milling"},
{value:"311213 Malt Manufacturing",label:"311213 Malt Manufacturing"},
{value:"311221 Wet Corn Milling",label:"311221 Wet Corn Milling"},
{value:"311224 Soybean and Other Oilseed Processing",label:"311224 Soybean and Other Oilseed Processing"},
{value:"311225 Fats and Oils Refining and Blending",label:"311225 Fats and Oils Refining and Blending"},
{value:"311230 Breakfast Cereal Manufacturing",label:"311230 Breakfast Cereal Manufacturing"},
{value:"311313 Beet Sugar Manufacturing",label:"311313 Beet Sugar Manufacturing"},
{value:"311314 Cane Sugar Manufacturing",label:"311314 Cane Sugar Manufacturing"},
{value:"311340 Nonchocolate Confectionery Manufacturing",label:"311340 Nonchocolate Confectionery Manufacturing"},
{value:"311351 Chocolate and Confectionery Manufacturing from Cacao Beans",label:"311351 Chocolate and Confectionery Manufacturing from Cacao Beans"},
{value:"311352 Confectionery Manufacturing from Purchased Chocolate",label:"311352 Confectionery Manufacturing from Purchased Chocolate"},
{value:"311411 Frozen Fruit, Juice, and Vegetable Manufacturing",label:"311411 Frozen Fruit, Juice, and Vegetable Manufacturing"},
{value:"311412 Frozen Specialty Food Manufacturing",label:"311412 Frozen Specialty Food Manufacturing"},
{value:"311421 Fruit and Vegetable Canning",label:"311421 Fruit and Vegetable Canning"},
{value:"311422 Specialty Canning",label:"311422 Specialty Canning"},
{value:"311423 Dried and Dehydrated Food Manufacturing",label:"311423 Dried and Dehydrated Food Manufacturing"},
{value:"311511 Fluid Milk Manufacturing",label:"311511 Fluid Milk Manufacturing"},
{value:"311512 Creamery Butter Manufacturing",label:"311512 Creamery Butter Manufacturing"},
{value:"311513 Cheese Manufacturing",label:"311513 Cheese Manufacturing"},
{value:"311514 Dry, Condensed, and Evaporated Dairy Product Manufacturing",label:"311514 Dry, Condensed, and Evaporated Dairy Product Manufacturing"},
{value:"311520 Ice Cream and Frozen Dessert Manufacturing",label:"311520 Ice Cream and Frozen Dessert Manufacturing"},
{value:"311611 Animal (except Poultry) Slaughtering",label:"311611 Animal (except Poultry) Slaughtering"},
{value:"311612 Meat Processed from Carcasses",label:"311612 Meat Processed from Carcasses"},
{value:"311613 Rendering and Meat Byproduct Processing",label:"311613 Rendering and Meat Byproduct Processing"},
{value:"311615 Poultry Processing",label:"311615 Poultry Processing"},
{value:"311710 Seafood Product Preparation and Packaging",label:"311710 Seafood Product Preparation and Packaging"},
{value:"311811 Retail Bakeries",label:"311811 Retail Bakeries"},
{value:"311812 Commercial Bakeries",label:"311812 Commercial Bakeries"},
{value:"311813 Frozen Cakes, Pies, and Other Pastries Manufacturing",label:"311813 Frozen Cakes, Pies, and Other Pastries Manufacturing"},
{value:"311821 Cookie and Cracker Manufacturing",label:"311821 Cookie and Cracker Manufacturing"},
{value:"311824 Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour",label:"311824 Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour"},
{value:"311830 Tortilla Manufacturing",label:"311830 Tortilla Manufacturing"},
{value:"311911 Roasted Nuts and Peanut Butter Manufacturing",label:"311911 Roasted Nuts and Peanut Butter Manufacturing"},
{value:"311919 Other Snack Food Manufacturing",label:"311919 Other Snack Food Manufacturing"},
{value:"311920 Coffee and Tea Manufacturing",label:"311920 Coffee and Tea Manufacturing"},
{value:"311930 Flavoring Syrup and Concentrate Manufacturing",label:"311930 Flavoring Syrup and Concentrate Manufacturing"},
{value:"311941 Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing",label:"311941 Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing"},
{value:"311942 Spice and Extract Manufacturing",label:"311942 Spice and Extract Manufacturing"},
{value:"311991 Perishable Prepared Food Manufacturing",label:"311991 Perishable Prepared Food Manufacturing"},
{value:"311999 All Other Miscellaneous Food Manufacturing",label:"311999 All Other Miscellaneous Food Manufacturing"},
{value:"312111 Soft Drink Manufacturing",label:"312111 Soft Drink Manufacturing"},
{value:"312112 Bottled Water Manufacturing",label:"312112 Bottled Water Manufacturing"},
{value:"312113 Ice Manufacturing",label:"312113 Ice Manufacturing"},
{value:"312120 Breweries",label:"312120 Breweries"},
{value:"312130 Wineries",label:"312130 Wineries"},
{value:"312140 Distilleries",label:"312140 Distilleries"},
{value:"312230 Tobacco Manufacturing",label:"312230 Tobacco Manufacturing"},
{value:"313110 Fiber, Yarn, and Thread Mills",label:"313110 Fiber, Yarn, and Thread Mills"},
{value:"313210 Broadwoven Fabric Mills",label:"313210 Broadwoven Fabric Mills"},
{value:"313220 Narrow Fabric Mills and Schiffli Machine Embroidery",label:"313220 Narrow Fabric Mills and Schiffli Machine Embroidery"},
{value:"313230 Nonwoven Fabric Mills",label:"313230 Nonwoven Fabric Mills"},
{value:"313240 Knit Fabric Mills",label:"313240 Knit Fabric Mills"},
{value:"313310 Textile and Fabric Finishing Mills",label:"313310 Textile and Fabric Finishing Mills"},
{value:"313320 Fabric Coating Mills",label:"313320 Fabric Coating Mills"},
{value:"314110 Carpet and Rug Mills",label:"314110 Carpet and Rug Mills"},
{value:"314120 Curtain and Linen Mills",label:"314120 Curtain and Linen Mills"},
{value:"314910 Textile Bag and Canvas Mills",label:"314910 Textile Bag and Canvas Mills"},
{value:"314994 Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills",label:"314994 Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills"},
{value:"314999 All Other Miscellaneous Textile Product Mills",label:"314999 All Other Miscellaneous Textile Product Mills"},
{value:"315110 Hosiery and Sock Mills",label:"315110 Hosiery and Sock Mills"},
{value:"315190 Other Apparel Knitting Mills",label:"315190 Other Apparel Knitting Mills"},
{value:"315210 Cut and Sew Apparel Contractors",label:"315210 Cut and Sew Apparel Contractors"},
{value:"315220 Men’s and Boys’ Cut and Sew Apparel Manufacturing",label:"315220 Men’s and Boys’ Cut and Sew Apparel Manufacturing"},
{value:"315240 Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing",label:"315240 Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing"},
{value:"315280 Other Cut and Sew Apparel Manufacturing",label:"315280 Other Cut and Sew Apparel Manufacturing"},
{value:"315990 Apparel Accessories and Other Apparel Manufacturing",label:"315990 Apparel Accessories and Other Apparel Manufacturing"},
{value:"316110 Leather and Hide Tanning and Finishing",label:"316110 Leather and Hide Tanning and Finishing"},
{value:"316210 Footwear Manufacturing",label:"316210 Footwear Manufacturing"},
{value:"316992 Women's Handbag and Purse Manufacturing",label:"316992 Women's Handbag and Purse Manufacturing"},
{value:"316998 All Other Leather Good and Allied Product Manufacturing",label:"316998 All Other Leather Good and Allied Product Manufacturing"},
{value:"321113 Sawmills",label:"321113 Sawmills"},
{value:"321114 Wood Preservation",label:"321114 Wood Preservation"},
{value:"321211 Hardwood Veneer and Plywood Manufacturing",label:"321211 Hardwood Veneer and Plywood Manufacturing"},
{value:"321212 Softwood Veneer and Plywood Manufacturing",label:"321212 Softwood Veneer and Plywood Manufacturing"},
{value:"321213 Engineered Wood Member (except Truss) Manufacturing",label:"321213 Engineered Wood Member (except Truss) Manufacturing"},
{value:"321214 Truss Manufacturing",label:"321214 Truss Manufacturing"},
{value:"321219 Reconstituted Wood Product Manufacturing",label:"321219 Reconstituted Wood Product Manufacturing"},
{value:"321911 Wood Window and Door Manufacturing",label:"321911 Wood Window and Door Manufacturing"},
{value:"321912 Cut Stock, Resawing Lumber, and Planing",label:"321912 Cut Stock, Resawing Lumber, and Planing"},
{value:"321918 Other Millwork (including Flooring)",label:"321918 Other Millwork (including Flooring)"},
{value:"321920 Wood Container and Pallet Manufacturing",label:"321920 Wood Container and Pallet Manufacturing"},
{value:"321991 Manufactured Home (Mobile Home) Manufacturing",label:"321991 Manufactured Home (Mobile Home) Manufacturing"},
{value:"321992 Prefabricated Wood Building Manufacturing",label:"321992 Prefabricated Wood Building Manufacturing"},
{value:"321999 All Other Miscellaneous Wood Product Manufacturing",label:"321999 All Other Miscellaneous Wood Product Manufacturing"},
{value:"322110 Pulp Mills",label:"322110 Pulp Mills"},
{value:"322121 Paper (except Newsprint) Mills",label:"322121 Paper (except Newsprint) Mills"},
{value:"322122 Newsprint Mills",label:"322122 Newsprint Mills"},
{value:"322130 Paperboard Mills",label:"322130 Paperboard Mills"},
{value:"322211 Corrugated and Solid Fiber Box Manufacturing",label:"322211 Corrugated and Solid Fiber Box Manufacturing"},
{value:"322212 Folding Paperboard Box Manufacturing",label:"322212 Folding Paperboard Box Manufacturing"},
{value:"322219 Other Paperboard Container Manufacturing",label:"322219 Other Paperboard Container Manufacturing"},
{value:"322220 Paper Bag and Coated and Treated Paper Manufacturing",label:"322220 Paper Bag and Coated and Treated Paper Manufacturing"},
{value:"322230 Stationery Product Manufacturing",label:"322230 Stationery Product Manufacturing"},
{value:"322291 Sanitary Paper Product Manufacturing",label:"322291 Sanitary Paper Product Manufacturing"},
{value:"322299 All Other Converted Paper Product Manufacturing",label:"322299 All Other Converted Paper Product Manufacturing"},
{value:"323111 Commercial Printing (except Screen and Books)",label:"323111 Commercial Printing (except Screen and Books)"},
{value:"323113 Commercial Screen Printing",label:"323113 Commercial Screen Printing"},
{value:"323117 Books Printing",label:"323117 Books Printing"},
{value:"323120 Support Activities for Printing",label:"323120 Support Activities for Printing"},
{value:"324110 Petroleum Refineries",label:"324110 Petroleum Refineries"},
{value:"324121 Asphalt Paving Mixture and Block Manufacturing",label:"324121 Asphalt Paving Mixture and Block Manufacturing"},
{value:"324122 Asphalt Shingle and Coating Materials Manufacturing",label:"324122 Asphalt Shingle and Coating Materials Manufacturing"},
{value:"324191 Petroleum Lubricating Oil and Grease Manufacturing",label:"324191 Petroleum Lubricating Oil and Grease Manufacturing"},
{value:"324199 All Other Petroleum and Coal Products Manufacturing",label:"324199 All Other Petroleum and Coal Products Manufacturing"},
{value:"325110 Petrochemical Manufacturing",label:"325110 Petrochemical Manufacturing"},
{value:"325120 Industrial Gas Manufacturing",label:"325120 Industrial Gas Manufacturing"},
{value:"325130 Synthetic Dye and Pigment Manufacturing",label:"325130 Synthetic Dye and Pigment Manufacturing"},
{value:"325180 Other Basic Inorganic Chemical Manufacturing",label:"325180 Other Basic Inorganic Chemical Manufacturing"},
{value:"325193 Ethyl Alcohol Manufacturing",label:"325193 Ethyl Alcohol Manufacturing"},
{value:"325194 Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing",label:"325194 Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing"},
{value:"325199 All Other Basic Organic Chemical Manufacturing",label:"325199 All Other Basic Organic Chemical Manufacturing"},
{value:"325211 Plastics Material and Resin Manufacturing",label:"325211 Plastics Material and Resin Manufacturing"},
{value:"325212 Synthetic Rubber Manufacturing",label:"325212 Synthetic Rubber Manufacturing"},
{value:"325220 Artificial and Synthetic Fibers and Filaments Manufacturing",label:"325220 Artificial and Synthetic Fibers and Filaments Manufacturing"},
{value:"325311 Nitrogenous Fertilizer Manufacturing",label:"325311 Nitrogenous Fertilizer Manufacturing"},
{value:"325312 Phosphatic Fertilizer Manufacturing",label:"325312 Phosphatic Fertilizer Manufacturing"},
{value:"325314 Fertilizer (Mixing Only) Manufacturing",label:"325314 Fertilizer (Mixing Only) Manufacturing"},
{value:"325320 Pesticide and Other Agricultural Chemical Manufacturing",label:"325320 Pesticide and Other Agricultural Chemical Manufacturing"},
{value:"325411 Medicinal and Botanical Manufacturing",label:"325411 Medicinal and Botanical Manufacturing"},
{value:"325412 Pharmaceutical Preparation Manufacturing",label:"325412 Pharmaceutical Preparation Manufacturing"},
{value:"325413 In-Vitro Diagnostic Substance Manufacturing",label:"325413 In-Vitro Diagnostic Substance Manufacturing"},
{value:"325414 Biological Product (except Diagnostic) Manufacturing",label:"325414 Biological Product (except Diagnostic) Manufacturing"},
{value:"325510 Paint and Coating Manufacturing",label:"325510 Paint and Coating Manufacturing"},
{value:"325520 Adhesive Manufacturing",label:"325520 Adhesive Manufacturing"},
{value:"325611 Soap and Other Detergent Manufacturing",label:"325611 Soap and Other Detergent Manufacturing"},
{value:"325612 Polish and Other Sanitation Good Manufacturing",label:"325612 Polish and Other Sanitation Good Manufacturing"},
{value:"325613 Surface Active Agent Manufacturing",label:"325613 Surface Active Agent Manufacturing"},
{value:"325620 Toilet Preparation Manufacturing",label:"325620 Toilet Preparation Manufacturing"},
{value:"325910 Printing Ink Manufacturing",label:"325910 Printing Ink Manufacturing"},
{value:"325920 Explosives Manufacturing",label:"325920 Explosives Manufacturing"},
{value:"325991 Custom Compounding of Purchased Resins",label:"325991 Custom Compounding of Purchased Resins"},
{value:"325992 Photographic Film, Paper, Plate, and Chemical Manufacturing",label:"325992 Photographic Film, Paper, Plate, and Chemical Manufacturing"},
{value:"325998 All Other Miscellaneous Chemical Product and Preparation Manufacturing",label:"325998 All Other Miscellaneous Chemical Product and Preparation Manufacturing"},
{value:"326111 Plastics Bag and Pouch Manufacturing",label:"326111 Plastics Bag and Pouch Manufacturing"},
{value:"326112 Plastics Packaging Film and Sheet (including Laminated) Manufacturing",label:"326112 Plastics Packaging Film and Sheet (including Laminated) Manufacturing"},
{value:"326113 Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing",label:"326113 Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing"},
{value:"326121 Unlaminated Plastics Profile Shape Manufacturing",label:"326121 Unlaminated Plastics Profile Shape Manufacturing"},
{value:"326122 Plastics Pipe and Pipe Fitting Manufacturing",label:"326122 Plastics Pipe and Pipe Fitting Manufacturing"},
{value:"326130 Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing",label:"326130 Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing"},
{value:"326140 Polystyrene Foam Product Manufacturing",label:"326140 Polystyrene Foam Product Manufacturing"},
{value:"326150 Urethane and Other Foam Product (except Polystyrene) Manufacturing",label:"326150 Urethane and Other Foam Product (except Polystyrene) Manufacturing"},
{value:"326160 Plastics Bottle Manufacturing",label:"326160 Plastics Bottle Manufacturing"},
{value:"326191 Plastics Plumbing Fixture Manufacturing",label:"326191 Plastics Plumbing Fixture Manufacturing"},
{value:"326199 All Other Plastics Product Manufacturing",label:"326199 All Other Plastics Product Manufacturing"},
{value:"326211 Tire Manufacturing (except Retreading)",label:"326211 Tire Manufacturing (except Retreading)"},
{value:"326212 Tire Retreading",label:"326212 Tire Retreading"},
{value:"326220 Rubber and Plastics Hoses and Belting Manufacturing",label:"326220 Rubber and Plastics Hoses and Belting Manufacturing"},
{value:"326291 Rubber Product Manufacturing for Mechanical Use",label:"326291 Rubber Product Manufacturing for Mechanical Use"},
{value:"326299 All Other Rubber Product Manufacturing",label:"326299 All Other Rubber Product Manufacturing"},
{value:"327110 Pottery, Ceramics, and Plumbing Fixture Manufacturing",label:"327110 Pottery, Ceramics, and Plumbing Fixture Manufacturing"},
{value:"327120 Clay Building Material and Refractories Manufacturing",label:"327120 Clay Building Material and Refractories Manufacturing"},
{value:"327211 Flat Glass Manufacturing",label:"327211 Flat Glass Manufacturing"},
{value:"327212 Other Pressed and Blown Glass and Glassware Manufacturing",label:"327212 Other Pressed and Blown Glass and Glassware Manufacturing"},
{value:"327213 Glass Container Manufacturing",label:"327213 Glass Container Manufacturing"},
{value:"327215 Glass Product Manufacturing Made of Purchased Glass",label:"327215 Glass Product Manufacturing Made of Purchased Glass"},
{value:"327310 Cement Manufacturing",label:"327310 Cement Manufacturing"},
{value:"327320 Ready-Mix Concrete Manufacturing",label:"327320 Ready-Mix Concrete Manufacturing"},
{value:"327331 Concrete Block and Brick Manufacturing",label:"327331 Concrete Block and Brick Manufacturing"},
{value:"327332 Concrete Pipe Manufacturing",label:"327332 Concrete Pipe Manufacturing"},
{value:"327390 Other Concrete Product Manufacturing",label:"327390 Other Concrete Product Manufacturing"},
{value:"327410 Lime Manufacturing",label:"327410 Lime Manufacturing"},
{value:"327420 Gypsum Product Manufacturing",label:"327420 Gypsum Product Manufacturing"},
{value:"327910 Abrasive Product Manufacturing",label:"327910 Abrasive Product Manufacturing"},
{value:"327991 Cut Stone and Stone Product Manufacturing",label:"327991 Cut Stone and Stone Product Manufacturing"},
{value:"327992 Ground or Treated Mineral and Earth Manufacturing",label:"327992 Ground or Treated Mineral and Earth Manufacturing"},
{value:"327993 Mineral Wool Manufacturing",label:"327993 Mineral Wool Manufacturing"},
{value:"327999 All Other Miscellaneous Nonmetallic Mineral Product Manufacturing",label:"327999 All Other Miscellaneous Nonmetallic Mineral Product Manufacturing"},
{value:"331110 Iron and Steel Mills and Ferroalloy Manufacturing",label:"331110 Iron and Steel Mills and Ferroalloy Manufacturing"},
{value:"331210 Iron and Steel Pipe and Tube Manufacturing from Purchased Steel",label:"331210 Iron and Steel Pipe and Tube Manufacturing from Purchased Steel"},
{value:"331221 Rolled Steel Shape Manufacturing",label:"331221 Rolled Steel Shape Manufacturing"},
{value:"331222 Steel Wire Drawing",label:"331222 Steel Wire Drawing"},
{value:"331313 Alumina Refining and Primary Aluminum Production",label:"331313 Alumina Refining and Primary Aluminum Production"},
{value:"331314 Secondary Smelting and Alloying of Aluminum",label:"331314 Secondary Smelting and Alloying of Aluminum"},
{value:"331315 Aluminum Sheet, Plate, and Foil Manufacturing",label:"331315 Aluminum Sheet, Plate, and Foil Manufacturing"},
{value:"331318 Other Aluminum Rolling, Drawing, and Extruding",label:"331318 Other Aluminum Rolling, Drawing, and Extruding"},
{value:"331410 Nonferrous Metal (except Aluminum) Smelting and Refining",label:"331410 Nonferrous Metal (except Aluminum) Smelting and Refining"},
{value:"331420 Copper Rolling, Drawing, Extruding, and Alloying",label:"331420 Copper Rolling, Drawing, Extruding, and Alloying"},
{value:"331491 Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding",label:"331491 Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding"},
{value:"331492 Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)",label:"331492 Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)"},
{value:"331511 Iron Foundries",label:"331511 Iron Foundries"},
{value:"331512 Steel Investment Foundries",label:"331512 Steel Investment Foundries"},
{value:"331513 Steel Foundries (except Investment)",label:"331513 Steel Foundries (except Investment)"},
{value:"331523 Nonferrous Metal Die-Casting Foundries",label:"331523 Nonferrous Metal Die-Casting Foundries"},
{value:"331524 Aluminum Foundries (except Die-Casting)",label:"331524 Aluminum Foundries (except Die-Casting)"},
{value:"331529 Other Nonferrous Metal Foundries (except Die-Casting)",label:"331529 Other Nonferrous Metal Foundries (except Die-Casting)"},
{value:"332111 Iron and Steel Forging",label:"332111 Iron and Steel Forging"},
{value:"332112 Nonferrous Forging",label:"332112 Nonferrous Forging"},
{value:"332114 Custom Roll Forming",label:"332114 Custom Roll Forming"},
{value:"332117 Powder Metallurgy Part Manufacturing",label:"332117 Powder Metallurgy Part Manufacturing"},
{value:"332119 Metal Crown, Closure, and Other Metal Stamping (except Automotive)",label:"332119 Metal Crown, Closure, and Other Metal Stamping (except Automotive)"},
{value:"332215 Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing",label:"332215 Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing"},
{value:"332216 Saw Blade and Handtool Manufacturing",label:"332216 Saw Blade and Handtool Manufacturing"},
{value:"332311 Prefabricated Metal Building and Component Manufacturing",label:"332311 Prefabricated Metal Building and Component Manufacturing"},
{value:"332312 Fabricated Structural Metal Manufacturing",label:"332312 Fabricated Structural Metal Manufacturing"},
{value:"332313 Plate Work Manufacturing",label:"332313 Plate Work Manufacturing"},
{value:"332321 Metal Window and Door Manufacturing",label:"332321 Metal Window and Door Manufacturing"},
{value:"332322 Sheet Metal Work Manufacturing",label:"332322 Sheet Metal Work Manufacturing"},
{value:"332323 Ornamental and Architectural Metal Work Manufacturing",label:"332323 Ornamental and Architectural Metal Work Manufacturing"},
{value:"332410 Power Boiler and Heat Exchanger Manufacturing",label:"332410 Power Boiler and Heat Exchanger Manufacturing"},
{value:"332420 Metal Tank (Heavy Gauge) Manufacturing",label:"332420 Metal Tank (Heavy Gauge) Manufacturing"},
{value:"332431 Metal Can Manufacturing",label:"332431 Metal Can Manufacturing"},
{value:"332439 Other Metal Container Manufacturing",label:"332439 Other Metal Container Manufacturing"},
{value:"332510 Hardware Manufacturing",label:"332510 Hardware Manufacturing"},
{value:"332613 Spring Manufacturing",label:"332613 Spring Manufacturing"},
{value:"332618 Other Fabricated Wire Product Manufacturing",label:"332618 Other Fabricated Wire Product Manufacturing"},
{value:"332710 Machine Shops",label:"332710 Machine Shops"},
{value:"332721 Precision Turned Product Manufacturing",label:"332721 Precision Turned Product Manufacturing"},
{value:"332722 Bolt, Nut, Screw, Rivet, and Washer Manufacturing",label:"332722 Bolt, Nut, Screw, Rivet, and Washer Manufacturing"},
{value:"332811 Metal Heat Treating",label:"332811 Metal Heat Treating"},
{value:"332812 Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers",label:"332812 Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers"},
{value:"332813 Electroplating, Plating, Polishing, Anodizing, and Coloring",label:"332813 Electroplating, Plating, Polishing, Anodizing, and Coloring"},
{value:"332911 Industrial Valve Manufacturing",label:"332911 Industrial Valve Manufacturing"},
{value:"332912 Fluid Power Valve and Hose Fitting Manufacturing",label:"332912 Fluid Power Valve and Hose Fitting Manufacturing"},
{value:"332913 Plumbing Fixture Fitting and Trim Manufacturing",label:"332913 Plumbing Fixture Fitting and Trim Manufacturing"},
{value:"332919 Other Metal Valve and Pipe Fitting Manufacturing",label:"332919 Other Metal Valve and Pipe Fitting Manufacturing"},
{value:"332991 Ball and Roller Bearing Manufacturing",label:"332991 Ball and Roller Bearing Manufacturing"},
{value:"332992 Small Arms Ammunition Manufacturing",label:"332992 Small Arms Ammunition Manufacturing"},
{value:"332993 Ammunition (except Small Arms) Manufacturing",label:"332993 Ammunition (except Small Arms) Manufacturing"},
{value:"332994 Small Arms, Ordnance, and Ordnance Accessories Manufacturing",label:"332994 Small Arms, Ordnance, and Ordnance Accessories Manufacturing"},
{value:"332996 Fabricated Pipe and Pipe Fitting Manufacturing",label:"332996 Fabricated Pipe and Pipe Fitting Manufacturing"},
{value:"332999 All Other Miscellaneous Fabricated Metal Product Manufacturing",label:"332999 All Other Miscellaneous Fabricated Metal Product Manufacturing"},
{value:"333111 Farm Machinery and Equipment Manufacturing",label:"333111 Farm Machinery and Equipment Manufacturing"},
{value:"333112 Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing",label:"333112 Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing"},
{value:"333120 Construction Machinery Manufacturing",label:"333120 Construction Machinery Manufacturing"},
{value:"333131 Mining Machinery and Equipment Manufacturing",label:"333131 Mining Machinery and Equipment Manufacturing"},
{value:"333132 Oil and Gas Field Machinery and Equipment Manufacturing",label:"333132 Oil and Gas Field Machinery and Equipment Manufacturing"},
{value:"333241 Food Product Machinery Manufacturing",label:"333241 Food Product Machinery Manufacturing"},
{value:"333242 Semiconductor Machinery Manufacturing",label:"333242 Semiconductor Machinery Manufacturing"},
{value:"333243 Sawmill, Woodworking, and Paper Machinery Manufacturing",label:"333243 Sawmill, Woodworking, and Paper Machinery Manufacturing"},
{value:"333244 Printing Machinery and Equipment Manufacturing",label:"333244 Printing Machinery and Equipment Manufacturing"},
{value:"333249 Other Industrial Machinery Manufacturing",label:"333249 Other Industrial Machinery Manufacturing"},
{value:"333314 Optical Instrument and Lens Manufacturing",label:"333314 Optical Instrument and Lens Manufacturing"},
{value:"333316 Photographic and Photocopying Equipment Manufacturing",label:"333316 Photographic and Photocopying Equipment Manufacturing"},
{value:"333318 Other Commercial and Service Industry Machinery Manufacturing",label:"333318 Other Commercial and Service Industry Machinery Manufacturing"},
{value:"333413 Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing",label:"333413 Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"},
{value:"333414 Heating Equipment (except Warm Air Furnaces) Manufacturing",label:"333414 Heating Equipment (except Warm Air Furnaces) Manufacturing"},
{value:"333415 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing",label:"333415 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing"},
{value:"333511 Industrial Mold Manufacturing",label:"333511 Industrial Mold Manufacturing"},
{value:"333514 Special Die and Tool, Die Set, Jig, and Fixture Manufacturing",label:"333514 Special Die and Tool, Die Set, Jig, and Fixture Manufacturing"},
{value:"333515 Cutting Tool and Machine Tool Accessory Manufacturing",label:"333515 Cutting Tool and Machine Tool Accessory Manufacturing"},
{value:"333517 Machine Tool Manufacturing",label:"333517 Machine Tool Manufacturing"},
{value:"333519 Rolling Mill and Other Metalworking Machinery Manufacturing",label:"333519 Rolling Mill and Other Metalworking Machinery Manufacturing"},
{value:"333611 Turbine and Turbine Generator Set Units Manufacturing",label:"333611 Turbine and Turbine Generator Set Units Manufacturing"},
{value:"333612 Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing",label:"333612 Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing"},
{value:"333613 Mechanical Power Transmission Equipment Manufacturing",label:"333613 Mechanical Power Transmission Equipment Manufacturing"},
{value:"333618 Other Engine Equipment Manufacturing",label:"333618 Other Engine Equipment Manufacturing"},
{value:"333912 Air and Gas Compressor Manufacturing",label:"333912 Air and Gas Compressor Manufacturing"},
{value:"333914 Measuring, Dispensing, and Other Pumping Equipment Manufacturing",label:"333914 Measuring, Dispensing, and Other Pumping Equipment Manufacturing"},
{value:"333921 Elevator and Moving Stairway Manufacturing",label:"333921 Elevator and Moving Stairway Manufacturing"},
{value:"333922 Conveyor and Conveying Equipment Manufacturing",label:"333922 Conveyor and Conveying Equipment Manufacturing"},
{value:"333923 Overhead Traveling Crane, Hoist, and Monorail System Manufacturing",label:"333923 Overhead Traveling Crane, Hoist, and Monorail System Manufacturing"},
{value:"333924 Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing",label:"333924 Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing"},
{value:"333991 Power-Driven Handtool Manufacturing",label:"333991 Power-Driven Handtool Manufacturing"},
{value:"333992 Welding and Soldering Equipment Manufacturing",label:"333992 Welding and Soldering Equipment Manufacturing"},
{value:"333993 Packaging Machinery Manufacturing",label:"333993 Packaging Machinery Manufacturing"},
{value:"333994 Industrial Process Furnace and Oven Manufacturing",label:"333994 Industrial Process Furnace and Oven Manufacturing"},
{value:"333995 Fluid Power Cylinder and Actuator Manufacturing",label:"333995 Fluid Power Cylinder and Actuator Manufacturing"},
{value:"333996 Fluid Power Pump and Motor Manufacturing",label:"333996 Fluid Power Pump and Motor Manufacturing"},
{value:"333997 Scale and Balance Manufacturing",label:"333997 Scale and Balance Manufacturing"},
{value:"333999 All Other Miscellaneous General Purpose Machinery Manufacturing",label:"333999 All Other Miscellaneous General Purpose Machinery Manufacturing"},
{value:"334111 Electronic Computer Manufacturing",label:"334111 Electronic Computer Manufacturing"},
{value:"334112 Computer Storage Device Manufacturing",label:"334112 Computer Storage Device Manufacturing"},
{value:"334118 Computer Terminal and Other Computer Peripheral Equipment Manufacturing",label:"334118 Computer Terminal and Other Computer Peripheral Equipment Manufacturing"},
{value:"334210 Telephone Apparatus Manufacturing",label:"334210 Telephone Apparatus Manufacturing"},
{value:"334220 Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing",label:"334220 Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"},
{value:"334290 Other Communications Equipment Manufacturing",label:"334290 Other Communications Equipment Manufacturing"},
{value:"334310 Audio and Video Equipment Manufacturing",label:"334310 Audio and Video Equipment Manufacturing"},
{value:"334412 Bare Printed Circuit Board Manufacturing ",label:"334412 Bare Printed Circuit Board Manufacturing "},
{value:"334413 Semiconductor and Related Device Manufacturing",label:"334413 Semiconductor and Related Device Manufacturing"},
{value:"334416 Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing",label:"334416 Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing"},
{value:"334417 Electronic Connector Manufacturing",label:"334417 Electronic Connector Manufacturing"},
{value:"334418 Printed Circuit Assembly (Electronic Assembly) Manufacturing",label:"334418 Printed Circuit Assembly (Electronic Assembly) Manufacturing"},
{value:"334419 Other Electronic Component Manufacturing",label:"334419 Other Electronic Component Manufacturing"},
{value:"334510 Electromedical and Electrotherapeutic Apparatus Manufacturing",label:"334510 Electromedical and Electrotherapeutic Apparatus Manufacturing"},
{value:"334511 Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing",label:"334511 Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing"},
{value:"334512 Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use",label:"334512 Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use"},
{value:"334513 Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables",label:"334513 Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables"},
{value:"334514 Totalizing Fluid Meter and Counting Device Manufacturing",label:"334514 Totalizing Fluid Meter and Counting Device Manufacturing"},
{value:"334515 Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals",label:"334515 Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals"},
{value:"334516 Analytical Laboratory Instrument Manufacturing",label:"334516 Analytical Laboratory Instrument Manufacturing"},
{value:"334517 Irradiation Apparatus Manufacturing",label:"334517 Irradiation Apparatus Manufacturing"},
{value:"334519 Other Measuring and Controlling Device Manufacturing",label:"334519 Other Measuring and Controlling Device Manufacturing"},
{value:"334613 Blank Magnetic and Optical Recording Media Manufacturing",label:"334613 Blank Magnetic and Optical Recording Media Manufacturing"},
{value:"334614 Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing",label:"334614 Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing"},
{value:"335110 Electric Lamp Bulb and Part Manufacturing",label:"335110 Electric Lamp Bulb and Part Manufacturing"},
{value:"335121 Residential Electric Lighting Fixture Manufacturing",label:"335121 Residential Electric Lighting Fixture Manufacturing"},
{value:"335122 Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing",label:"335122 Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing"},
{value:"335129 Other Lighting Equipment Manufacturing",label:"335129 Other Lighting Equipment Manufacturing"},
{value:"335210 Small Electrical Appliance Manufacturing",label:"335210 Small Electrical Appliance Manufacturing"},
{value:"335220 Major Household Appliance Manufacturing",label:"335220 Major Household Appliance Manufacturing"},
{value:"335311 Power, Distribution, and Specialty Transformer Manufacturing",label:"335311 Power, Distribution, and Specialty Transformer Manufacturing"},
{value:"335312 Motor and Generator Manufacturing",label:"335312 Motor and Generator Manufacturing"},
{value:"335313 Switchgear and Switchboard Apparatus Manufacturing",label:"335313 Switchgear and Switchboard Apparatus Manufacturing"},
{value:"335314 Relay and Industrial Control Manufacturing",label:"335314 Relay and Industrial Control Manufacturing"},
{value:"335911 Storage Battery Manufacturing",label:"335911 Storage Battery Manufacturing"},
{value:"335912 Primary Battery Manufacturing",label:"335912 Primary Battery Manufacturing"},
{value:"335921 Fiber Optic Cable Manufacturing",label:"335921 Fiber Optic Cable Manufacturing"},
{value:"335929 Other Communication and Energy Wire Manufacturing",label:"335929 Other Communication and Energy Wire Manufacturing"},
{value:"335931 Current-Carrying Wiring Device Manufacturing",label:"335931 Current-Carrying Wiring Device Manufacturing"},
{value:"335932 Noncurrent-Carrying Wiring Device Manufacturing",label:"335932 Noncurrent-Carrying Wiring Device Manufacturing"},
{value:"335991 Carbon and Graphite Product Manufacturing",label:"335991 Carbon and Graphite Product Manufacturing"},
{value:"335999 All Other Miscellaneous Electrical Equipment and Component Manufacturing",label:"335999 All Other Miscellaneous Electrical Equipment and Component Manufacturing"},
{value:"336111 Automobile Manufacturing",label:"336111 Automobile Manufacturing"},
{value:"336112 Light Truck and Utility Vehicle Manufacturing",label:"336112 Light Truck and Utility Vehicle Manufacturing"},
{value:"336120 Heavy Duty Truck Manufacturing",label:"336120 Heavy Duty Truck Manufacturing"},
{value:"336211 Motor Vehicle Body Manufacturing",label:"336211 Motor Vehicle Body Manufacturing"},
{value:"336212 Truck Trailer Manufacturing",label:"336212 Truck Trailer Manufacturing"},
{value:"336213 Motor Home Manufacturing",label:"336213 Motor Home Manufacturing"},
{value:"336214 Travel Trailer and Camper Manufacturing",label:"336214 Travel Trailer and Camper Manufacturing"},
{value:"336310 Motor Vehicle Gasoline Engine and Engine Parts Manufacturing",label:"336310 Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"},
{value:"336320 Motor Vehicle Electrical and Electronic Equipment Manufacturing",label:"336320 Motor Vehicle Electrical and Electronic Equipment Manufacturing"},
{value:"336330 Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing",label:"336330 Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"},
{value:"336340 Motor Vehicle Brake System Manufacturing",label:"336340 Motor Vehicle Brake System Manufacturing"},
{value:"336350 Motor Vehicle Transmission and Power Train Parts Manufacturing",label:"336350 Motor Vehicle Transmission and Power Train Parts Manufacturing"},
{value:"336360 Motor Vehicle Seating and Interior Trim Manufacturing",label:"336360 Motor Vehicle Seating and Interior Trim Manufacturing"},
{value:"336370 Motor Vehicle Metal Stamping",label:"336370 Motor Vehicle Metal Stamping"},
{value:"336390 Other Motor Vehicle Parts Manufacturing",label:"336390 Other Motor Vehicle Parts Manufacturing"},
{value:"336411 Aircraft Manufacturing",label:"336411 Aircraft Manufacturing"},
{value:"336412 Aircraft Engine and Engine Parts Manufacturing",label:"336412 Aircraft Engine and Engine Parts Manufacturing"},
{value:"336413 Other Aircraft Parts and Auxiliary Equipment Manufacturing",label:"336413 Other Aircraft Parts and Auxiliary Equipment Manufacturing"},
{value:"336414 Guided Missile and Space Vehicle Manufacturing",label:"336414 Guided Missile and Space Vehicle Manufacturing"},
{value:"336415 Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing",label:"336415 Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing"},
{value:"336419 Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing",label:"336419 Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing"},
{value:"336510 Railroad Rolling Stock Manufacturing",label:"336510 Railroad Rolling Stock Manufacturing"},
{value:"336611 Ship Building and Repairing",label:"336611 Ship Building and Repairing"},
{value:"336612 Boat Building",label:"336612 Boat Building"},
{value:"336991 Motorcycle, Bicycle, and Parts Manufacturing",label:"336991 Motorcycle, Bicycle, and Parts Manufacturing"},
{value:"336992 Military Armored Vehicle, Tank, and Tank Component Manufacturing",label:"336992 Military Armored Vehicle, Tank, and Tank Component Manufacturing"},
{value:"336999 All Other Transportation Equipment Manufacturing",label:"336999 All Other Transportation Equipment Manufacturing"},
{value:"337110 Wood Kitchen Cabinet and Countertop Manufacturing",label:"337110 Wood Kitchen Cabinet and Countertop Manufacturing"},
{value:"337121 Upholstered Household Furniture Manufacturing",label:"337121 Upholstered Household Furniture Manufacturing"},
{value:"337122 Nonupholstered Wood Household Furniture Manufacturing",label:"337122 Nonupholstered Wood Household Furniture Manufacturing"},
{value:"337124 Metal Household Furniture Manufacturing",label:"337124 Metal Household Furniture Manufacturing"},
{value:"337125 Household Furniture (except Wood and Metal) Manufacturing",label:"337125 Household Furniture (except Wood and Metal) Manufacturing"},
{value:"337127 Institutional Furniture Manufacturing",label:"337127 Institutional Furniture Manufacturing"},
{value:"337211 Wood Office Furniture Manufacturing",label:"337211 Wood Office Furniture Manufacturing"},
{value:"337212 Custom Architectural Woodwork and Millwork Manufacturing",label:"337212 Custom Architectural Woodwork and Millwork Manufacturing"},
{value:"337214 Office Furniture (except Wood) Manufacturing",label:"337214 Office Furniture (except Wood) Manufacturing"},
{value:"337215 Showcase, Partition, Shelving, and Locker Manufacturing",label:"337215 Showcase, Partition, Shelving, and Locker Manufacturing"},
{value:"337910 Mattress Manufacturing",label:"337910 Mattress Manufacturing"},
{value:"337920 Blind and Shade Manufacturing",label:"337920 Blind and Shade Manufacturing"},
{value:"339112 Surgical and Medical Instrument Manufacturing",label:"339112 Surgical and Medical Instrument Manufacturing"},
{value:"339113 Surgical Appliance and Supplies Manufacturing",label:"339113 Surgical Appliance and Supplies Manufacturing"},
{value:"339114 Dental Equipment and Supplies Manufacturing",label:"339114 Dental Equipment and Supplies Manufacturing"},
{value:"339115 Ophthalmic Goods Manufacturing",label:"339115 Ophthalmic Goods Manufacturing"},
{value:"339116 Dental Laboratories",label:"339116 Dental Laboratories"},
{value:"339910 Jewelry and Silverware Manufacturing",label:"339910 Jewelry and Silverware Manufacturing"},
{value:"339920 Sporting and Athletic Goods Manufacturing",label:"339920 Sporting and Athletic Goods Manufacturing"},
{value:"339930 Doll, Toy, and Game Manufacturing",label:"339930 Doll, Toy, and Game Manufacturing"},
{value:"339940 Office Supplies (except Paper) Manufacturing",label:"339940 Office Supplies (except Paper) Manufacturing"},
{value:"339950 Sign Manufacturing",label:"339950 Sign Manufacturing"},
{value:"339991 Gasket, Packing, and Sealing Device Manufacturing",label:"339991 Gasket, Packing, and Sealing Device Manufacturing"},
{value:"339992 Musical Instrument Manufacturing",label:"339992 Musical Instrument Manufacturing"},
{value:"339993 Fastener, Button, Needle, and Pin Manufacturing",label:"339993 Fastener, Button, Needle, and Pin Manufacturing"},
{value:"339994 Broom, Brush, and Mop Manufacturing",label:"339994 Broom, Brush, and Mop Manufacturing"},
{value:"339995 Burial Casket Manufacturing",label:"339995 Burial Casket Manufacturing"},
{value:"339999 All Other Miscellaneous Manufacturing",label:"339999 All Other Miscellaneous Manufacturing"},
{value:"423110 Automobile and Other Motor Vehicle Merchant Wholesalers",label:"423110 Automobile and Other Motor Vehicle Merchant Wholesalers"},
{value:"423120 Motor Vehicle Supplies and New Parts Merchant Wholesalers",label:"423120 Motor Vehicle Supplies and New Parts Merchant Wholesalers"},
{value:"423130 Tire and Tube Merchant Wholesalers",label:"423130 Tire and Tube Merchant Wholesalers"},
{value:"423140 Motor Vehicle Parts (Used) Merchant Wholesalers",label:"423140 Motor Vehicle Parts (Used) Merchant Wholesalers"},
{value:"423210 Furniture Merchant Wholesalers",label:"423210 Furniture Merchant Wholesalers"},
{value:"423220 Home Furnishing Merchant Wholesalers",label:"423220 Home Furnishing Merchant Wholesalers"},
{value:"423310 Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers",label:"423310 Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers"},
{value:"423320 Brick, Stone, and Related Construction Material Merchant Wholesalers",label:"423320 Brick, Stone, and Related Construction Material Merchant Wholesalers"},
{value:"423330 Roofing, Siding, and Insulation Material Merchant Wholesalers",label:"423330 Roofing, Siding, and Insulation Material Merchant Wholesalers"},
{value:"423390 Other Construction Material Merchant Wholesalers",label:"423390 Other Construction Material Merchant Wholesalers"},
{value:"423410 Photographic Equipment and Supplies Merchant Wholesalers",label:"423410 Photographic Equipment and Supplies Merchant Wholesalers"},
{value:"423420 Office Equipment Merchant Wholesalers",label:"423420 Office Equipment Merchant Wholesalers"},
{value:"423430 Computer and Computer Peripheral Equipment and Software Merchant Wholesalers",label:"423430 Computer and Computer Peripheral Equipment and Software Merchant Wholesalers"},
{value:"423440 Other Commercial Equipment Merchant Wholesalers",label:"423440 Other Commercial Equipment Merchant Wholesalers"},
{value:"423450 Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers",label:"423450 Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers"},
{value:"423460 Ophthalmic Goods Merchant Wholesalers",label:"423460 Ophthalmic Goods Merchant Wholesalers"},
{value:"423490 Other Professional Equipment and Supplies Merchant Wholesalers",label:"423490 Other Professional Equipment and Supplies Merchant Wholesalers"},
{value:"423510 Metal Service Centers and Other Metal Merchant Wholesalers",label:"423510 Metal Service Centers and Other Metal Merchant Wholesalers"},
{value:"423520 Coal and Other Mineral and Ore Merchant Wholesalers",label:"423520 Coal and Other Mineral and Ore Merchant Wholesalers"},
{value:"423610 Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers",label:"423610 Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers"},
{value:"423620 Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers",label:"423620 Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers"},
{value:"423690 Other Electronic Parts and Equipment Merchant Wholesalers",label:"423690 Other Electronic Parts and Equipment Merchant Wholesalers"},
{value:"423710 Hardware Merchant Wholesalers",label:"423710 Hardware Merchant Wholesalers"},
{value:"423720 Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers",label:"423720 Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers"},
{value:"423730 Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers",label:"423730 Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers"},
{value:"423740 Refrigeration Equipment and Supplies Merchant Wholesalers",label:"423740 Refrigeration Equipment and Supplies Merchant Wholesalers"},
{value:"423810 Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers",label:"423810 Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers"},
{value:"423820 Farm and Garden Machinery and Equipment Merchant Wholesalers",label:"423820 Farm and Garden Machinery and Equipment Merchant Wholesalers"},
{value:"423830 Industrial Machinery and Equipment Merchant Wholesalers",label:"423830 Industrial Machinery and Equipment Merchant Wholesalers"},
{value:"423840 Industrial Supplies Merchant Wholesalers",label:"423840 Industrial Supplies Merchant Wholesalers"},
{value:"423850 Service Establishment Equipment and Supplies Merchant Wholesalers",label:"423850 Service Establishment Equipment and Supplies Merchant Wholesalers"},
{value:"423860 Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers",label:"423860 Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers"},
{value:"423910 Sporting and Recreational Goods and Supplies Merchant Wholesalers",label:"423910 Sporting and Recreational Goods and Supplies Merchant Wholesalers"},
{value:"423920 Toy and Hobby Goods and Supplies Merchant Wholesalers",label:"423920 Toy and Hobby Goods and Supplies Merchant Wholesalers"},
{value:"423930 Recyclable Material Merchant Wholesalers",label:"423930 Recyclable Material Merchant Wholesalers"},
{value:"423940 Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers",label:"423940 Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers"},
{value:"423990 Other Miscellaneous Durable Goods Merchant Wholesalers",label:"423990 Other Miscellaneous Durable Goods Merchant Wholesalers"},
{value:"424110 Printing and Writing Paper Merchant Wholesalers",label:"424110 Printing and Writing Paper Merchant Wholesalers"},
{value:"424120 Stationery and Office Supplies Merchant Wholesalers",label:"424120 Stationery and Office Supplies Merchant Wholesalers"},
{value:"424130 Industrial and Personal Service Paper Merchant Wholesalers",label:"424130 Industrial and Personal Service Paper Merchant Wholesalers"},
{value:"424210 Drugs and Druggists' Sundries Merchant Wholesalers",label:"424210 Drugs and Druggists' Sundries Merchant Wholesalers"},
{value:"424310 Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers",label:"424310 Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers"},
{value:"424320 Men's and Boys' Clothing and Furnishings Merchant Wholesalers",label:"424320 Men's and Boys' Clothing and Furnishings Merchant Wholesalers"},
{value:"424330 Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers",label:"424330 Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers"},
{value:"424340 Footwear Merchant Wholesalers",label:"424340 Footwear Merchant Wholesalers"},
{value:"424410 General Line Grocery Merchant Wholesalers",label:"424410 General Line Grocery Merchant Wholesalers"},
{value:"424420 Packaged Frozen Food Merchant Wholesalers",label:"424420 Packaged Frozen Food Merchant Wholesalers"},
{value:"424430 Dairy Product (except Dried or Canned) Merchant Wholesalers",label:"424430 Dairy Product (except Dried or Canned) Merchant Wholesalers"},
{value:"424440 Poultry and Poultry Product Merchant Wholesalers",label:"424440 Poultry and Poultry Product Merchant Wholesalers"},
{value:"424450 Confectionery Merchant Wholesalers",label:"424450 Confectionery Merchant Wholesalers"},
{value:"424460 Fish and Seafood Merchant Wholesalers",label:"424460 Fish and Seafood Merchant Wholesalers"},
{value:"424470 Meat and Meat Product Merchant Wholesalers",label:"424470 Meat and Meat Product Merchant Wholesalers"},
{value:"424480 Fresh Fruit and Vegetable Merchant Wholesalers",label:"424480 Fresh Fruit and Vegetable Merchant Wholesalers"},
{value:"424490 Other Grocery and Related Products Merchant Wholesalers",label:"424490 Other Grocery and Related Products Merchant Wholesalers"},
{value:"424510 Grain and Field Bean Merchant Wholesalers",label:"424510 Grain and Field Bean Merchant Wholesalers"},
{value:"424520 Livestock Merchant Wholesalers",label:"424520 Livestock Merchant Wholesalers"},
{value:"424590 Other Farm Product Raw Material Merchant Wholesalers",label:"424590 Other Farm Product Raw Material Merchant Wholesalers"},
{value:"424610 Plastics Materials and Basic Forms and Shapes Merchant Wholesalers",label:"424610 Plastics Materials and Basic Forms and Shapes Merchant Wholesalers"},
{value:"424690 Other Chemical and Allied Products Merchant Wholesalers",label:"424690 Other Chemical and Allied Products Merchant Wholesalers"},
{value:"424710 Petroleum Bulk Stations and Terminals",label:"424710 Petroleum Bulk Stations and Terminals"},
{value:"424720 Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)",label:"424720 Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)"},
{value:"424810 Beer and Ale Merchant Wholesalers",label:"424810 Beer and Ale Merchant Wholesalers"},
{value:"424820 Wine and Distilled Alcoholic Beverage Merchant Wholesalers",label:"424820 Wine and Distilled Alcoholic Beverage Merchant Wholesalers"},
{value:"424910 Farm Supplies Merchant Wholesalers",label:"424910 Farm Supplies Merchant Wholesalers"},
{value:"424920 Book, Periodical, and Newspaper Merchant Wholesalers",label:"424920 Book, Periodical, and Newspaper Merchant Wholesalers"},
{value:"424930 Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers",label:"424930 Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"},
{value:"424940 Tobacco and Tobacco Product Merchant Wholesalers",label:"424940 Tobacco and Tobacco Product Merchant Wholesalers"},
{value:"424950 Paint, Varnish, and Supplies Merchant Wholesalers",label:"424950 Paint, Varnish, and Supplies Merchant Wholesalers"},
{value:"424990 Other Miscellaneous Nondurable Goods Merchant Wholesalers",label:"424990 Other Miscellaneous Nondurable Goods Merchant Wholesalers"},
{value:"425110 Business to Business Electronic Markets",label:"425110 Business to Business Electronic Markets"},
{value:"425120 Wholesale Trade Agents and Brokers",label:"425120 Wholesale Trade Agents and Brokers"},
{value:"441110 New Car Dealers",label:"441110 New Car Dealers"},
{value:"441120 Used Car Dealers",label:"441120 Used Car Dealers"},
{value:"441210 Recreational Vehicle Dealers",label:"441210 Recreational Vehicle Dealers"},
{value:"441222 Boat Dealers",label:"441222 Boat Dealers"},
{value:"441228 Motorcycle, ATV, and All Other Motor Vehicle Dealers",label:"441228 Motorcycle, ATV, and All Other Motor Vehicle Dealers"},
{value:"441310 Automotive Parts and Accessories Stores",label:"441310 Automotive Parts and Accessories Stores"},
{value:"441320 Tire Dealers",label:"441320 Tire Dealers"},
{value:"442110 Furniture Stores",label:"442110 Furniture Stores"},
{value:"442210 Floor Covering Stores",label:"442210 Floor Covering Stores"},
{value:"442291 Window Treatment Stores",label:"442291 Window Treatment Stores"},
{value:"442299 All Other Home Furnishings Stores",label:"442299 All Other Home Furnishings Stores"},
{value:"443141 Household Appliance Stores",label:"443141 Household Appliance Stores"},
{value:"443142 Electronics Stores",label:"443142 Electronics Stores"},
{value:"444110 Home Centers",label:"444110 Home Centers"},
{value:"444120 Paint and Wallpaper Stores",label:"444120 Paint and Wallpaper Stores"},
{value:"444130 Hardware Stores",label:"444130 Hardware Stores"},
{value:"444190 Other Building Material Dealers",label:"444190 Other Building Material Dealers"},
{value:"444210 Outdoor Power Equipment Stores",label:"444210 Outdoor Power Equipment Stores"},
{value:"444220 Nursery, Garden Center, and Farm Supply Stores",label:"444220 Nursery, Garden Center, and Farm Supply Stores"},
{value:"445110 Supermarkets and Other Grocery (except Convenience) Stores",label:"445110 Supermarkets and Other Grocery (except Convenience) Stores"},
{value:"445120 Convenience Stores",label:"445120 Convenience Stores"},
{value:"445210 Meat Markets",label:"445210 Meat Markets"},
{value:"445220 Fish and Seafood Markets",label:"445220 Fish and Seafood Markets"},
{value:"445230 Fruit and Vegetable Markets",label:"445230 Fruit and Vegetable Markets"},
{value:"445291 Baked Goods Stores",label:"445291 Baked Goods Stores"},
{value:"445292 Confectionery and Nut Stores",label:"445292 Confectionery and Nut Stores"},
{value:"445299 All Other Specialty Food Stores",label:"445299 All Other Specialty Food Stores"},
{value:"445310 Beer, Wine, and Liquor Stores",label:"445310 Beer, Wine, and Liquor Stores"},
{value:"446110 Pharmacies and Drug Stores",label:"446110 Pharmacies and Drug Stores"},
{value:"446120 Cosmetics, Beauty Supplies, and Perfume Stores",label:"446120 Cosmetics, Beauty Supplies, and Perfume Stores"},
{value:"446130 Optical Goods Stores",label:"446130 Optical Goods Stores"},
{value:"446191 Food (Health) Supplement Stores",label:"446191 Food (Health) Supplement Stores"},
{value:"446199 All Other Health and Personal Care Stores",label:"446199 All Other Health and Personal Care Stores"},
{value:"447110 Gasoline Stations with Convenience Stores",label:"447110 Gasoline Stations with Convenience Stores"},
{value:"447190 Other Gasoline Stations",label:"447190 Other Gasoline Stations"},
{value:"448110 Men's Clothing Stores",label:"448110 Men's Clothing Stores"},
{value:"448120 Women's Clothing Stores",label:"448120 Women's Clothing Stores"},
{value:"448130 Children's and Infants' Clothing Stores",label:"448130 Children's and Infants' Clothing Stores"},
{value:"448140 Family Clothing Stores",label:"448140 Family Clothing Stores"},
{value:"448150 Clothing Accessories Stores",label:"448150 Clothing Accessories Stores"},
{value:"448190 Other Clothing Stores",label:"448190 Other Clothing Stores"},
{value:"448210 Shoe Stores",label:"448210 Shoe Stores"},
{value:"448310 Jewelry Stores",label:"448310 Jewelry Stores"},
{value:"448320 Luggage and Leather Goods Stores",label:"448320 Luggage and Leather Goods Stores"},
{value:"451110 Sporting Goods Stores",label:"451110 Sporting Goods Stores"},
{value:"451120 Hobby, Toy, and Game Stores",label:"451120 Hobby, Toy, and Game Stores"},
{value:"451130 Sewing, Needlework, and Piece Goods Stores",label:"451130 Sewing, Needlework, and Piece Goods Stores"},
{value:"451140 Musical Instrument and Supplies Stores",label:"451140 Musical Instrument and Supplies Stores"},
{value:"451211 Book Stores",label:"451211 Book Stores"},
{value:"451212 News Dealers and Newsstands",label:"451212 News Dealers and Newsstands"},
{value:"452210 Department Stores",label:"452210 Department Stores"},
{value:"452311 Warehouse Clubs and Supercenters",label:"452311 Warehouse Clubs and Supercenters"},
{value:"452319 All Other General Merchandise Stores",label:"452319 All Other General Merchandise Stores"},
{value:"453110 Florists",label:"453110 Florists"},
{value:"453210 Office Supplies and Stationery Stores",label:"453210 Office Supplies and Stationery Stores"},
{value:"453220 Gift, Novelty, and Souvenir Stores",label:"453220 Gift, Novelty, and Souvenir Stores"},
{value:"453310 Used Merchandise Stores",label:"453310 Used Merchandise Stores"},
{value:"453910 Pet and Pet Supplies Stores",label:"453910 Pet and Pet Supplies Stores"},
{value:"453920 Art Dealers",label:"453920 Art Dealers"},
{value:"453930 Manufactured (Mobile) Home Dealers",label:"453930 Manufactured (Mobile) Home Dealers"},
{value:"453991 Tobacco Stores",label:"453991 Tobacco Stores"},
{value:"453998 All Other Miscellaneous Store Retailers (except Tobacco Stores)",label:"453998 All Other Miscellaneous Store Retailers (except Tobacco Stores)"},
{value:"454110 Electronic Shopping and Mail-Order Houses",label:"454110 Electronic Shopping and Mail-Order Houses"},
{value:"454210 Vending Machine Operators",label:"454210 Vending Machine Operators"},
{value:"454310 Fuel Dealers",label:"454310 Fuel Dealers"},
{value:"454390 Other Direct Selling Establishments",label:"454390 Other Direct Selling Establishments"},
{value:"481111 Scheduled Passenger Air Transportation",label:"481111 Scheduled Passenger Air Transportation"},
{value:"481112 Scheduled Freight Air Transportation",label:"481112 Scheduled Freight Air Transportation"},
{value:"481211 Nonscheduled Chartered Passenger Air Transportation",label:"481211 Nonscheduled Chartered Passenger Air Transportation"},
{value:"481212 Nonscheduled Chartered Freight Air Transportation",label:"481212 Nonscheduled Chartered Freight Air Transportation"},
{value:"481219 Other Nonscheduled Air Transportation",label:"481219 Other Nonscheduled Air Transportation"},
{value:"482111 Line-Haul Railroads",label:"482111 Line-Haul Railroads"},
{value:"482112 Short Line Railroads",label:"482112 Short Line Railroads"},
{value:"483111 Deep Sea Freight Transportation",label:"483111 Deep Sea Freight Transportation"},
{value:"483112 Deep Sea Passenger Transportation",label:"483112 Deep Sea Passenger Transportation"},
{value:"483113 Coastal and Great Lakes Freight Transportation",label:"483113 Coastal and Great Lakes Freight Transportation"},
{value:"483114 Coastal and Great Lakes Passenger Transportation",label:"483114 Coastal and Great Lakes Passenger Transportation"},
{value:"483211 Inland Water Freight Transportation",label:"483211 Inland Water Freight Transportation"},
{value:"483212 Inland Water Passenger Transportation",label:"483212 Inland Water Passenger Transportation"},
{value:"484110 General Freight Trucking, Local",label:"484110 General Freight Trucking, Local"},
{value:"484121 General Freight Trucking, Long-Distance, Truckload",label:"484121 General Freight Trucking, Long-Distance, Truckload"},
{value:"484122 General Freight Trucking, Long-Distance, Less Than Truckload",label:"484122 General Freight Trucking, Long-Distance, Less Than Truckload"},
{value:"484210 Used Household and Office Goods Moving",label:"484210 Used Household and Office Goods Moving"},
{value:"484220 Specialized Freight (except Used Goods) Trucking, Local",label:"484220 Specialized Freight (except Used Goods) Trucking, Local"},
{value:"484230 Specialized Freight (except Used Goods) Trucking, Long-Distance",label:"484230 Specialized Freight (except Used Goods) Trucking, Long-Distance"},
{value:"485111 Mixed Mode Transit Systems",label:"485111 Mixed Mode Transit Systems"},
{value:"485112 Commuter Rail Systems",label:"485112 Commuter Rail Systems"},
{value:"485113 Bus and Other Motor Vehicle Transit Systems",label:"485113 Bus and Other Motor Vehicle Transit Systems"},
{value:"485119 Other Urban Transit Systems",label:"485119 Other Urban Transit Systems"},
{value:"485210 Interurban and Rural Bus Transportation",label:"485210 Interurban and Rural Bus Transportation"},
{value:"485310 Taxi Service",label:"485310 Taxi Service"},
{value:"485320 Limousine Service",label:"485320 Limousine Service"},
{value:"485410 School and Employee Bus Transportation",label:"485410 School and Employee Bus Transportation"},
{value:"485510 Charter Bus Industry",label:"485510 Charter Bus Industry"},
{value:"485991 Special Needs Transportation",label:"485991 Special Needs Transportation"},
{value:"485999 All Other Transit and Ground Passenger Transportation",label:"485999 All Other Transit and Ground Passenger Transportation"},
{value:"486110 Pipeline Transportation of Crude Oil",label:"486110 Pipeline Transportation of Crude Oil"},
{value:"486210 Pipeline Transportation of Natural Gas",label:"486210 Pipeline Transportation of Natural Gas"},
{value:"486910 Pipeline Transportation of Refined Petroleum Products",label:"486910 Pipeline Transportation of Refined Petroleum Products"},
{value:"486990 All Other Pipeline Transportation",label:"486990 All Other Pipeline Transportation"},
{value:"487110 Scenic and Sightseeing Transportation, Land",label:"487110 Scenic and Sightseeing Transportation, Land"},
{value:"487210 Scenic and Sightseeing Transportation, Water",label:"487210 Scenic and Sightseeing Transportation, Water"},
{value:"487990 Scenic and Sightseeing Transportation, Other",label:"487990 Scenic and Sightseeing Transportation, Other"},
{value:"488111 Air Traffic Control",label:"488111 Air Traffic Control"},
{value:"488119 Other Airport Operations",label:"488119 Other Airport Operations"},
{value:"488190 Other Support Activities for Air Transportation",label:"488190 Other Support Activities for Air Transportation"},
{value:"488210 Support Activities for Rail Transportation",label:"488210 Support Activities for Rail Transportation"},
{value:"488310 Port and Harbor Operations",label:"488310 Port and Harbor Operations"},
{value:"488320 Marine Cargo Handling",label:"488320 Marine Cargo Handling"},
{value:"488330 Navigational Services to Shipping",label:"488330 Navigational Services to Shipping"},
{value:"488390 Other Support Activities for Water Transportation",label:"488390 Other Support Activities for Water Transportation"},
{value:"488410 Motor Vehicle Towing",label:"488410 Motor Vehicle Towing"},
{value:"488490 Other Support Activities for Road Transportation",label:"488490 Other Support Activities for Road Transportation"},
{value:"488510 Freight Transportation Arrangement",label:"488510 Freight Transportation Arrangement"},
{value:"488991 Packing and Crating",label:"488991 Packing and Crating"},
{value:"488999 All Other Support Activities for Transportation",label:"488999 All Other Support Activities for Transportation"},
{value:"491110 Postal Service",label:"491110 Postal Service"},
{value:"492110 Couriers and Express Delivery Services",label:"492110 Couriers and Express Delivery Services"},
{value:"492210 Local Messengers and Local Delivery",label:"492210 Local Messengers and Local Delivery"},
{value:"493110 General Warehousing and Storage",label:"493110 General Warehousing and Storage"},
{value:"493120 Refrigerated Warehousing and Storage",label:"493120 Refrigerated Warehousing and Storage"},
{value:"493130 Farm Product Warehousing and Storage",label:"493130 Farm Product Warehousing and Storage"},
{value:"493190 Other Warehousing and Storage",label:"493190 Other Warehousing and Storage"},
{value:"511110 Newspaper Publishers",label:"511110 Newspaper Publishers"},
{value:"511120 Periodical Publishers",label:"511120 Periodical Publishers"},
{value:"511130 Book Publishers",label:"511130 Book Publishers"},
{value:"511140 Directory and Mailing List Publishers",label:"511140 Directory and Mailing List Publishers"},
{value:"511191 Greeting Card Publishers",label:"511191 Greeting Card Publishers"},
{value:"511199 All Other Publishers",label:"511199 All Other Publishers"},
{value:"511210 Software Publishers",label:"511210 Software Publishers"},
{value:"512110 Motion Picture and Video Production",label:"512110 Motion Picture and Video Production"},
{value:"512120 Motion Picture and Video Distribution",label:"512120 Motion Picture and Video Distribution"},
{value:"512131 Motion Picture Theaters (except Drive-Ins)",label:"512131 Motion Picture Theaters (except Drive-Ins)"},
{value:"512132 Drive-In Motion Picture Theaters",label:"512132 Drive-In Motion Picture Theaters"},
{value:"512191 Teleproduction and Other Postproduction Services",label:"512191 Teleproduction and Other Postproduction Services"},
{value:"512199 Other Motion Picture and Video Industries",label:"512199 Other Motion Picture and Video Industries"},
{value:"512230 Music Publishers",label:"512230 Music Publishers"},
{value:"512240 Sound Recording Studios",label:"512240 Sound Recording Studios"},
{value:"512250 Record Production and Distribution",label:"512250 Record Production and Distribution"},
{value:"512290 Other Sound Recording Industries",label:"512290 Other Sound Recording Industries"},
{value:"515111 Radio Networks",label:"515111 Radio Networks"},
{value:"515112 Radio Stations",label:"515112 Radio Stations"},
{value:"515120 Television Broadcasting",label:"515120 Television Broadcasting"},
{value:"515210 Cable and Other Subscription Programming",label:"515210 Cable and Other Subscription Programming"},
{value:"517311 Wired Telecommunications Carriers",label:"517311 Wired Telecommunications Carriers"},
{value:"517312 Wireless Telecommunications Carriers (except Satellite)",label:"517312 Wireless Telecommunications Carriers (except Satellite)"},
{value:"517410 Satellite Telecommunications",label:"517410 Satellite Telecommunications"},
{value:"517911 Telecommunications Resellers",label:"517911 Telecommunications Resellers"},
{value:"517919 All Other Telecommunications",label:"517919 All Other Telecommunications"},
{value:"518210 Data Processing, Hosting, and Related Services",label:"518210 Data Processing, Hosting, and Related Services"},
{value:"519110 News Syndicates",label:"519110 News Syndicates"},
{value:"519120 Libraries and Archives",label:"519120 Libraries and Archives"},
{value:"519130 Internet Publishing and Broadcasting and Web Search Portals",label:"519130 Internet Publishing and Broadcasting and Web Search Portals"},
{value:"519190 All Other Information Services",label:"519190 All Other Information Services"},
{value:"521110 Monetary Authorities-Central Bank",label:"521110 Monetary Authorities-Central Bank"},
{value:"522110 Commercial Banking",label:"522110 Commercial Banking"},
{value:"522120 Savings Institutions",label:"522120 Savings Institutions"},
{value:"522130 Credit Unions",label:"522130 Credit Unions"},
{value:"522190 Other Depository Credit Intermediation",label:"522190 Other Depository Credit Intermediation"},
{value:"522210 Credit Card Issuing",label:"522210 Credit Card Issuing"},
{value:"522220 Sales Financing",label:"522220 Sales Financing"},
{value:"522291 Consumer Lending",label:"522291 Consumer Lending"},
{value:"522292 Real Estate Credit",label:"522292 Real Estate Credit"},
{value:"522293 International Trade Financing",label:"522293 International Trade Financing"},
{value:"522294 Secondary Market Financing",label:"522294 Secondary Market Financing"},
{value:"522298 All Other Nondepository Credit Intermediation",label:"522298 All Other Nondepository Credit Intermediation"},
{value:"522310 Mortgage and Nonmortgage Loan Brokers",label:"522310 Mortgage and Nonmortgage Loan Brokers"},
{value:"522320 Financial Transactions Processing, Reserve, and Clearinghouse Activities",label:"522320 Financial Transactions Processing, Reserve, and Clearinghouse Activities"},
{value:"522390 Other Activities Related to Credit Intermediation",label:"522390 Other Activities Related to Credit Intermediation"},
{value:"523110 Investment Banking and Securities Dealing",label:"523110 Investment Banking and Securities Dealing"},
{value:"523120 Securities Brokerage",label:"523120 Securities Brokerage"},
{value:"523130 Commodity Contracts Dealing",label:"523130 Commodity Contracts Dealing"},
{value:"523140 Commodity Contracts Brokerage",label:"523140 Commodity Contracts Brokerage"},
{value:"523210 Securities and Commodity Exchanges",label:"523210 Securities and Commodity Exchanges"},
{value:"523910 Miscellaneous Intermediation",label:"523910 Miscellaneous Intermediation"},
{value:"523920 Portfolio Management",label:"523920 Portfolio Management"},
{value:"523930 Investment Advice",label:"523930 Investment Advice"},
{value:"523991 Trust, Fiduciary, and Custody Activities",label:"523991 Trust, Fiduciary, and Custody Activities"},
{value:"523999 Miscellaneous Financial Investment Activities",label:"523999 Miscellaneous Financial Investment Activities"},
{value:"524113 Direct Life Insurance Carriers",label:"524113 Direct Life Insurance Carriers"},
{value:"524114 Direct Health and Medical Insurance Carriers",label:"524114 Direct Health and Medical Insurance Carriers"},
{value:"524126 Direct Property and Casualty Insurance Carriers",label:"524126 Direct Property and Casualty Insurance Carriers"},
{value:"524127 Direct Title Insurance Carriers",label:"524127 Direct Title Insurance Carriers"},
{value:"524128 Other Direct Insurance (except Life, Health, and Medical) Carriers",label:"524128 Other Direct Insurance (except Life, Health, and Medical) Carriers"},
{value:"524130 Reinsurance Carriers",label:"524130 Reinsurance Carriers"},
{value:"524210 Insurance Agencies and Brokerages",label:"524210 Insurance Agencies and Brokerages"},
{value:"524291 Claims Adjusting",label:"524291 Claims Adjusting"},
{value:"524292 Third Party Administration of Insurance and Pension Funds",label:"524292 Third Party Administration of Insurance and Pension Funds"},
{value:"524298 All Other Insurance Related Activities",label:"524298 All Other Insurance Related Activities"},
{value:"525110 Pension Funds",label:"525110 Pension Funds"},
{value:"525120 Health and Welfare Funds",label:"525120 Health and Welfare Funds"},
{value:"525190 Other Insurance Funds",label:"525190 Other Insurance Funds"},
{value:"525910 Open-End Investment Funds",label:"525910 Open-End Investment Funds"},
{value:"525920 Trusts, Estates, and Agency Accounts",label:"525920 Trusts, Estates, and Agency Accounts"},
{value:"525990 Other Financial Vehicles",label:"525990 Other Financial Vehicles"},
{value:"531110 Lessors of Residential Buildings and Dwellings",label:"531110 Lessors of Residential Buildings and Dwellings"},
{value:"531120 Lessors of Nonresidential Buildings (except Miniwarehouses)",label:"531120 Lessors of Nonresidential Buildings (except Miniwarehouses)"},
{value:"531130 Lessors of Miniwarehouses and Self-Storage Units",label:"531130 Lessors of Miniwarehouses and Self-Storage Units"},
{value:"531190 Lessors of Other Real Estate Property",label:"531190 Lessors of Other Real Estate Property"},
{value:"531210 Offices of Real Estate Agents and Brokers",label:"531210 Offices of Real Estate Agents and Brokers"},
{value:"531311 Residential Property Managers",label:"531311 Residential Property Managers"},
{value:"531312 Nonresidential Property Managers",label:"531312 Nonresidential Property Managers"},
{value:"531320 Offices of Real Estate Appraisers",label:"531320 Offices of Real Estate Appraisers"},
{value:"531390 Other Activities Related to Real Estate",label:"531390 Other Activities Related to Real Estate"},
{value:"532111 Passenger Car Rental",label:"532111 Passenger Car Rental"},
{value:"532112 Passenger Car Leasing",label:"532112 Passenger Car Leasing"},
{value:"532120 Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing",label:"532120 Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing"},
{value:"532210 Consumer Electronics and Appliances Rental",label:"532210 Consumer Electronics and Appliances Rental"},
{value:"532281 Formal Wear and Costume Rental",label:"532281 Formal Wear and Costume Rental"},
{value:"532282 Video Tape and Disc Rental",label:"532282 Video Tape and Disc Rental"},
{value:"532283 Home Health Equipment Rental",label:"532283 Home Health Equipment Rental"},
{value:"532284 Recreational Goods Rental",label:"532284 Recreational Goods Rental"},
{value:"532289 All Other Consumer Goods Rental",label:"532289 All Other Consumer Goods Rental"},
{value:"532310 General Rental Centers",label:"532310 General Rental Centers"},
{value:"532411 Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing",label:"532411 Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing"},
{value:"532412 Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing",label:"532412 Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing"},
{value:"532420 Office Machinery and Equipment Rental and Leasing",label:"532420 Office Machinery and Equipment Rental and Leasing"},
{value:"532490 Other Commercial and Industrial Machinery and Equipment Rental and Leasing",label:"532490 Other Commercial and Industrial Machinery and Equipment Rental and Leasing"},
{value:"533110 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",label:"533110 Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)"},
{value:"541110 Offices of Lawyers",label:"541110 Offices of Lawyers"},
{value:"541120 Offices of Notaries",label:"541120 Offices of Notaries"},
{value:"541191 Title Abstract and Settlement Offices",label:"541191 Title Abstract and Settlement Offices"},
{value:"541199 All Other Legal Services",label:"541199 All Other Legal Services"},
{value:"541211 Offices of Certified Public Accountants",label:"541211 Offices of Certified Public Accountants"},
{value:"541213 Tax Preparation Services",label:"541213 Tax Preparation Services"},
{value:"541214 Payroll Services",label:"541214 Payroll Services"},
{value:"541219 Other Accounting Services",label:"541219 Other Accounting Services"},
{value:"541310 Architectural Services",label:"541310 Architectural Services"},
{value:"541320 Landscape Architectural Services",label:"541320 Landscape Architectural Services"},
{value:"541330 Engineering Services",label:"541330 Engineering Services"},
{value:"541340 Drafting Services",label:"541340 Drafting Services"},
{value:"541350 Building Inspection Services",label:"541350 Building Inspection Services"},
{value:"541360 Geophysical Surveying and Mapping Services",label:"541360 Geophysical Surveying and Mapping Services"},
{value:"541370 Surveying and Mapping (except Geophysical) Services",label:"541370 Surveying and Mapping (except Geophysical) Services"},
{value:"541380 Testing Laboratories",label:"541380 Testing Laboratories"},
{value:"541410 Interior Design Services",label:"541410 Interior Design Services"},
{value:"541420 Industrial Design Services",label:"541420 Industrial Design Services"},
{value:"541430 Graphic Design Services",label:"541430 Graphic Design Services"},
{value:"541490 Other Specialized Design Services",label:"541490 Other Specialized Design Services"},
{value:"541511 Custom Computer Programming Services",label:"541511 Custom Computer Programming Services"},
{value:"541512 Computer Systems Design Services",label:"541512 Computer Systems Design Services"},
{value:"541513 Computer Facilities Management Services",label:"541513 Computer Facilities Management Services"},
{value:"541519 Other Computer Related Services",label:"541519 Other Computer Related Services"},
{value:"541611 Administrative Management and General Management Consulting Services",label:"541611 Administrative Management and General Management Consulting Services"},
{value:"541612 Human Resources Consulting Services",label:"541612 Human Resources Consulting Services"},
{value:"541613 Marketing Consulting Services",label:"541613 Marketing Consulting Services"},
{value:"541614 Process, Physical Distribution, and Logistics Consulting Services",label:"541614 Process, Physical Distribution, and Logistics Consulting Services"},
{value:"541618 Other Management Consulting Services",label:"541618 Other Management Consulting Services"},
{value:"541620 Environmental Consulting Services",label:"541620 Environmental Consulting Services"},
{value:"541690 Other Scientific and Technical Consulting Services",label:"541690 Other Scientific and Technical Consulting Services"},
{value:"541713 Research and Development in Nanotechnology",label:"541713 Research and Development in Nanotechnology"},
{value:"541714 Research and Development in Biotechnology (except Nanobiotechnology)",label:"541714 Research and Development in Biotechnology (except Nanobiotechnology)"},
{value:"541715 Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)",label:"541715 Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)"},
{value:"541720 Research and Development in the Social Sciences and Humanities",label:"541720 Research and Development in the Social Sciences and Humanities"},
{value:"541810 Advertising Agencies",label:"541810 Advertising Agencies"},
{value:"541820 Public Relations Agencies",label:"541820 Public Relations Agencies"},
{value:"541830 Media Buying Agencies",label:"541830 Media Buying Agencies"},
{value:"541840 Media Representatives",label:"541840 Media Representatives"},
{value:"541850 Outdoor Advertising",label:"541850 Outdoor Advertising"},
{value:"541860 Direct Mail Advertising",label:"541860 Direct Mail Advertising"},
{value:"541870 Advertising Material Distribution Services",label:"541870 Advertising Material Distribution Services"},
{value:"541890 Other Services Related to Advertising",label:"541890 Other Services Related to Advertising"},
{value:"541910 Marketing Research and Public Opinion Polling",label:"541910 Marketing Research and Public Opinion Polling"},
{value:"541921 Photography Studios, Portrait",label:"541921 Photography Studios, Portrait"},
{value:"541922 Commercial Photography",label:"541922 Commercial Photography"},
{value:"541930 Translation and Interpretation Services",label:"541930 Translation and Interpretation Services"},
{value:"541940 Veterinary Services",label:"541940 Veterinary Services"},
{value:"541990 All Other Professional, Scientific, and Technical Services",label:"541990 All Other Professional, Scientific, and Technical Services"},
{value:"551111 Offices of Bank Holding Companies",label:"551111 Offices of Bank Holding Companies"},
{value:"551112 Offices of Other Holding Companies",label:"551112 Offices of Other Holding Companies"},
{value:"551114 Corporate, Subsidiary, and Regional Managing Offices",label:"551114 Corporate, Subsidiary, and Regional Managing Offices"},
{value:"561110 Office Administrative Services",label:"561110 Office Administrative Services"},
{value:"561210 Facilities Support Services",label:"561210 Facilities Support Services"},
{value:"561311 Employment Placement Agencies",label:"561311 Employment Placement Agencies"},
{value:"561312 Executive Search Services",label:"561312 Executive Search Services"},
{value:"561320 Temporary Help Services",label:"561320 Temporary Help Services"},
{value:"561330 Professional Employer Organizations",label:"561330 Professional Employer Organizations"},
{value:"561410 Document Preparation Services",label:"561410 Document Preparation Services"},
{value:"561421 Telephone Answering Services",label:"561421 Telephone Answering Services"},
{value:"561422 Telemarketing Bureaus and Other Contact Centers",label:"561422 Telemarketing Bureaus and Other Contact Centers"},
{value:"561431 Private Mail Centers",label:"561431 Private Mail Centers"},
{value:"561439 Other Business Service Centers (including Copy Shops)",label:"561439 Other Business Service Centers (including Copy Shops)"},
{value:"561440 Collection Agencies",label:"561440 Collection Agencies"},
{value:"561450 Credit Bureaus",label:"561450 Credit Bureaus"},
{value:"561491 Repossession Services",label:"561491 Repossession Services"},
{value:"561492 Court Reporting and Stenotype Services",label:"561492 Court Reporting and Stenotype Services"},
{value:"561499 All Other Business Support Services",label:"561499 All Other Business Support Services"},
{value:"561510 Travel Agencies",label:"561510 Travel Agencies"},
{value:"561520 Tour Operators",label:"561520 Tour Operators"},
{value:"561591 Convention and Visitors Bureaus",label:"561591 Convention and Visitors Bureaus"},
{value:"561599 All Other Travel Arrangement and Reservation Services",label:"561599 All Other Travel Arrangement and Reservation Services"},
{value:"561611 Investigation Services",label:"561611 Investigation Services"},
{value:"561612 Security Guards and Patrol Services",label:"561612 Security Guards and Patrol Services"},
{value:"561613 Armored Car Services",label:"561613 Armored Car Services"},
{value:"561621 Security Systems Services (except Locksmiths)",label:"561621 Security Systems Services (except Locksmiths)"},
{value:"561622 Locksmiths",label:"561622 Locksmiths"},
{value:"561710 Exterminating and Pest Control Services",label:"561710 Exterminating and Pest Control Services"},
{value:"561720 Janitorial Services",label:"561720 Janitorial Services"},
{value:"561730 Landscaping Services",label:"561730 Landscaping Services"},
{value:"561740 Carpet and Upholstery Cleaning Services",label:"561740 Carpet and Upholstery Cleaning Services"},
{value:"561790 Other Services to Buildings and Dwellings",label:"561790 Other Services to Buildings and Dwellings"},
{value:"561910 Packaging and Labeling Services",label:"561910 Packaging and Labeling Services"},
{value:"561920 Convention and Trade Show Organizers",label:"561920 Convention and Trade Show Organizers"},
{value:"561990 All Other Support Services",label:"561990 All Other Support Services"},
{value:"562111 Solid Waste Collection",label:"562111 Solid Waste Collection"},
{value:"562112 Hazardous Waste Collection",label:"562112 Hazardous Waste Collection"},
{value:"562119 Other Waste Collection",label:"562119 Other Waste Collection"},
{value:"562211 Hazardous Waste Treatment and Disposal",label:"562211 Hazardous Waste Treatment and Disposal"},
{value:"562212 Solid Waste Landfill",label:"562212 Solid Waste Landfill"},
{value:"562213 Solid Waste Combustors and Incinerators",label:"562213 Solid Waste Combustors and Incinerators"},
{value:"562219 Other Nonhazardous Waste Treatment and Disposal",label:"562219 Other Nonhazardous Waste Treatment and Disposal"},
{value:"562910 Remediation Services",label:"562910 Remediation Services"},
{value:"562920 Materials Recovery Facilities",label:"562920 Materials Recovery Facilities"},
{value:"562991 Septic Tank and Related Services",label:"562991 Septic Tank and Related Services"},
{value:"562998 All Other Miscellaneous Waste Management Services",label:"562998 All Other Miscellaneous Waste Management Services"},
{value:"611110 Elementary and Secondary Schools",label:"611110 Elementary and Secondary Schools"},
{value:"611210 Junior Colleges",label:"611210 Junior Colleges"},
{value:"611310 Colleges, Universities, and Professional Schools",label:"611310 Colleges, Universities, and Professional Schools"},
{value:"611410 Business and Secretarial Schools",label:"611410 Business and Secretarial Schools"},
{value:"611420 Computer Training",label:"611420 Computer Training"},
{value:"611430 Professional and Management Development Training",label:"611430 Professional and Management Development Training"},
{value:"611511 Cosmetology and Barber Schools",label:"611511 Cosmetology and Barber Schools"},
{value:"611512 Flight Training",label:"611512 Flight Training"},
{value:"611513 Apprenticeship Training",label:"611513 Apprenticeship Training"},
{value:"611519 Other Technical and Trade Schools",label:"611519 Other Technical and Trade Schools"},
{value:"611610 Fine Arts Schools",label:"611610 Fine Arts Schools"},
{value:"611620 Sports and Recreation Instruction",label:"611620 Sports and Recreation Instruction"},
{value:"611630 Language Schools",label:"611630 Language Schools"},
{value:"611691 Exam Preparation and Tutoring",label:"611691 Exam Preparation and Tutoring"},
{value:"611692 Automobile Driving Schools",label:"611692 Automobile Driving Schools"},
{value:"611699 All Other Miscellaneous Schools and Instruction",label:"611699 All Other Miscellaneous Schools and Instruction"},
{value:"611710 Educational Support Services",label:"611710 Educational Support Services"},
{value:"621111 Offices of Physicians (except Mental Health Specialists)",label:"621111 Offices of Physicians (except Mental Health Specialists)"},
{value:"621112 Offices of Physicians, Mental Health Specialists",label:"621112 Offices of Physicians, Mental Health Specialists"},
{value:"621210 Offices of Dentists",label:"621210 Offices of Dentists"},
{value:"621310 Offices of Chiropractors",label:"621310 Offices of Chiropractors"},
{value:"621320 Offices of Optometrists",label:"621320 Offices of Optometrists"},
{value:"621330 Offices of Mental Health Practitioners (except Physicians)",label:"621330 Offices of Mental Health Practitioners (except Physicians)"},
{value:"621340 Offices of Physical, Occupational and Speech Therapists, and Audiologists",label:"621340 Offices of Physical, Occupational and Speech Therapists, and Audiologists"},
{value:"621391 Offices of Podiatrists",label:"621391 Offices of Podiatrists"},
{value:"621399 Offices of All Other Miscellaneous Health Practitioners",label:"621399 Offices of All Other Miscellaneous Health Practitioners"},
{value:"621410 Family Planning Centers",label:"621410 Family Planning Centers"},
{value:"621420 Outpatient Mental Health and Substance Abuse Centers",label:"621420 Outpatient Mental Health and Substance Abuse Centers"},
{value:"621491 HMO Medical Centers",label:"621491 HMO Medical Centers"},
{value:"621492 Kidney Dialysis Centers",label:"621492 Kidney Dialysis Centers"},
{value:"621493 Freestanding Ambulatory Surgical and Emergency Centers",label:"621493 Freestanding Ambulatory Surgical and Emergency Centers"},
{value:"621498 All Other Outpatient Care Centers",label:"621498 All Other Outpatient Care Centers"},
{value:"621511 Medical Laboratories",label:"621511 Medical Laboratories"},
{value:"621512 Diagnostic Imaging Centers",label:"621512 Diagnostic Imaging Centers"},
{value:"621610 Home Health Care Services",label:"621610 Home Health Care Services"},
{value:"621910 Ambulance Services",label:"621910 Ambulance Services"},
{value:"621991 Blood and Organ Banks",label:"621991 Blood and Organ Banks"},
{value:"621999 All Other Miscellaneous Ambulatory Health Care Services",label:"621999 All Other Miscellaneous Ambulatory Health Care Services"},
{value:"622110 General Medical and Surgical Hospitals",label:"622110 General Medical and Surgical Hospitals"},
{value:"622210 Psychiatric and Substance Abuse Hospitals",label:"622210 Psychiatric and Substance Abuse Hospitals"},
{value:"622310 Specialty (except Psychiatric and Substance Abuse) Hospitals",label:"622310 Specialty (except Psychiatric and Substance Abuse) Hospitals"},
{value:"623110 Nursing Care Facilities (Skilled Nursing Facilities)",label:"623110 Nursing Care Facilities (Skilled Nursing Facilities)"},
{value:"623210 Residential Intellectual and Developmental Disability Facilities",label:"623210 Residential Intellectual and Developmental Disability Facilities"},
{value:"623220 Residential Mental Health and Substance Abuse Facilities",label:"623220 Residential Mental Health and Substance Abuse Facilities"},
{value:"623311 Continuing Care Retirement Communities",label:"623311 Continuing Care Retirement Communities"},
{value:"623312 Assisted Living Facilities for the Elderly",label:"623312 Assisted Living Facilities for the Elderly"},
{value:"623990 Other Residential Care Facilities",label:"623990 Other Residential Care Facilities"},
{value:"624110 Child and Youth Services",label:"624110 Child and Youth Services"},
{value:"624120 Services for the Elderly and Persons with Disabilities",label:"624120 Services for the Elderly and Persons with Disabilities"},
{value:"624190 Other Individual and Family Services",label:"624190 Other Individual and Family Services"},
{value:"624210 Community Food Services",label:"624210 Community Food Services"},
{value:"624221 Temporary Shelters",label:"624221 Temporary Shelters"},
{value:"624229 Other Community Housing Services",label:"624229 Other Community Housing Services"},
{value:"624230 Emergency and Other Relief Services",label:"624230 Emergency and Other Relief Services"},
{value:"624310 Vocational Rehabilitation Services",label:"624310 Vocational Rehabilitation Services"},
{value:"624410 Child Day Care Services",label:"624410 Child Day Care Services"},
{value:"711110 Theater Companies and Dinner Theaters",label:"711110 Theater Companies and Dinner Theaters"},
{value:"711120 Dance Companies",label:"711120 Dance Companies"},
{value:"711130 Musical Groups and Artists",label:"711130 Musical Groups and Artists"},
{value:"711190 Other Performing Arts Companies",label:"711190 Other Performing Arts Companies"},
{value:"711211 Sports Teams and Clubs",label:"711211 Sports Teams and Clubs"},
{value:"711212 Racetracks",label:"711212 Racetracks"},
{value:"711219 Other Spectator Sports",label:"711219 Other Spectator Sports"},
{value:"711310 Promoters of Performing Arts, Sports, and Similar Events with Facilities",label:"711310 Promoters of Performing Arts, Sports, and Similar Events with Facilities"},
{value:"711320 Promoters of Performing Arts, Sports, and Similar Events without Facilities",label:"711320 Promoters of Performing Arts, Sports, and Similar Events without Facilities"},
{value:"711410 Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",label:"711410 Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures"},
{value:"711510 Independent Artists, Writers, and Performers",label:"711510 Independent Artists, Writers, and Performers"},
{value:"712110 Museums",label:"712110 Museums"},
{value:"712120 Historical Sites",label:"712120 Historical Sites"},
{value:"712130 Zoos and Botanical Gardens",label:"712130 Zoos and Botanical Gardens"},
{value:"712190 Nature Parks and Other Similar Institutions",label:"712190 Nature Parks and Other Similar Institutions"},
{value:"713110 Amusement and Theme Parks",label:"713110 Amusement and Theme Parks"},
{value:"713120 Amusement Arcades",label:"713120 Amusement Arcades"},
{value:"713210 Casinos (except Casino Hotels)",label:"713210 Casinos (except Casino Hotels)"},
{value:"713290 Other Gambling Industries",label:"713290 Other Gambling Industries"},
{value:"713910 Golf Courses and Country Clubs",label:"713910 Golf Courses and Country Clubs"},
{value:"713920 Skiing Facilities",label:"713920 Skiing Facilities"},
{value:"713930 Marinas",label:"713930 Marinas"},
{value:"713940 Fitness and Recreational Sports Centers",label:"713940 Fitness and Recreational Sports Centers"},
{value:"713950 Bowling Centers",label:"713950 Bowling Centers"},
{value:"713990 All Other Amusement and Recreation Industries",label:"713990 All Other Amusement and Recreation Industries"},
{value:"721110 Hotels (except Casino Hotels) and Motels",label:"721110 Hotels (except Casino Hotels) and Motels"},
{value:"721120 Casino Hotels",label:"721120 Casino Hotels"},
{value:"721191 Bed-and-Breakfast Inns",label:"721191 Bed-and-Breakfast Inns"},
{value:"721199 All Other Traveler Accommodation",label:"721199 All Other Traveler Accommodation"},
{value:"721211 RV (Recreational Vehicle) Parks and Campgrounds",label:"721211 RV (Recreational Vehicle) Parks and Campgrounds"},
{value:"721214 Recreational and Vacation Camps (except Campgrounds)",label:"721214 Recreational and Vacation Camps (except Campgrounds)"},
{value:"721310 Rooming and Boarding Houses, Dormitories, and Workers' Camps",label:"721310 Rooming and Boarding Houses, Dormitories, and Workers' Camps"},
{value:"722310 Food Service Contractors",label:"722310 Food Service Contractors"},
{value:"722320 Caterers",label:"722320 Caterers"},
{value:"722330 Mobile Food Services",label:"722330 Mobile Food Services"},
{value:"722410 Drinking Places (Alcoholic Beverages)",label:"722410 Drinking Places (Alcoholic Beverages)"},
{value:"722511 Full-Service Restaurants",label:"722511 Full-Service Restaurants"},
{value:"722513 Limited-Service Restaurants",label:"722513 Limited-Service Restaurants"},
{value:"722514 Cafeterias, Grill Buffets, and Buffets",label:"722514 Cafeterias, Grill Buffets, and Buffets"},
{value:"722515 Snack and Nonalcoholic Beverage Bars",label:"722515 Snack and Nonalcoholic Beverage Bars"},
{value:"811111 General Automotive Repair",label:"811111 General Automotive Repair"},
{value:"811112 Automotive Exhaust System Repair",label:"811112 Automotive Exhaust System Repair"},
{value:"811113 Automotive Transmission Repair",label:"811113 Automotive Transmission Repair"},
{value:"811118 Other Automotive Mechanical and Electrical Repair and Maintenance",label:"811118 Other Automotive Mechanical and Electrical Repair and Maintenance"},
{value:"811121 Automotive Body, Paint, and Interior Repair and Maintenance",label:"811121 Automotive Body, Paint, and Interior Repair and Maintenance"},
{value:"811122 Automotive Glass Replacement Shops",label:"811122 Automotive Glass Replacement Shops"},
{value:"811191 Automotive Oil Change and Lubrication Shops",label:"811191 Automotive Oil Change and Lubrication Shops"},
{value:"811192 Car Washes",label:"811192 Car Washes"},
{value:"811198 All Other Automotive Repair and Maintenance",label:"811198 All Other Automotive Repair and Maintenance"},
{value:"811211 Consumer Electronics Repair and Maintenance",label:"811211 Consumer Electronics Repair and Maintenance"},
{value:"811212 Computer and Office Machine Repair and Maintenance",label:"811212 Computer and Office Machine Repair and Maintenance"},
{value:"811213 Communication Equipment Repair and Maintenance",label:"811213 Communication Equipment Repair and Maintenance"},
{value:"811219 Other Electronic and Precision Equipment Repair and Maintenance",label:"811219 Other Electronic and Precision Equipment Repair and Maintenance"},
{value:"811310 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",label:"811310 Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"},
{value:"811411 Home and Garden Equipment Repair and Maintenance",label:"811411 Home and Garden Equipment Repair and Maintenance"},
{value:"811412 Appliance Repair and Maintenance",label:"811412 Appliance Repair and Maintenance"},
{value:"811420 Reupholstery and Furniture Repair",label:"811420 Reupholstery and Furniture Repair"},
{value:"811430 Footwear and Leather Goods Repair",label:"811430 Footwear and Leather Goods Repair"},
{value:"811490 Other Personal and Household Goods Repair and Maintenance",label:"811490 Other Personal and Household Goods Repair and Maintenance"},
{value:"812111 Barber Shops",label:"812111 Barber Shops"},
{value:"812112 Beauty Salons",label:"812112 Beauty Salons"},
{value:"812113 Nail Salons",label:"812113 Nail Salons"},
{value:"812191 Diet and Weight Reducing Centers",label:"812191 Diet and Weight Reducing Centers"},
{value:"812199 Other Personal Care Services",label:"812199 Other Personal Care Services"},
{value:"812210 Funeral Homes and Funeral Services",label:"812210 Funeral Homes and Funeral Services"},
{value:"812220 Cemeteries and Crematories",label:"812220 Cemeteries and Crematories"},
{value:"812310 Coin-Operated Laundries and Drycleaners",label:"812310 Coin-Operated Laundries and Drycleaners"},
{value:"812320 Drycleaning and Laundry Services (except Coin-Operated)",label:"812320 Drycleaning and Laundry Services (except Coin-Operated)"},
{value:"812331 Linen Supply",label:"812331 Linen Supply"},
{value:"812332 Industrial Launderers",label:"812332 Industrial Launderers"},
{value:"812910 Pet Care (except Veterinary) Services",label:"812910 Pet Care (except Veterinary) Services"},
{value:"812921 Photofinishing Laboratories (except One-Hour)",label:"812921 Photofinishing Laboratories (except One-Hour)"},
{value:"812922 One-Hour Photofinishing",label:"812922 One-Hour Photofinishing"},
{value:"812930 Parking Lots and Garages",label:"812930 Parking Lots and Garages"},
{value:"812990 All Other Personal Services",label:"812990 All Other Personal Services"},
{value:"813110 Religious Organizations",label:"813110 Religious Organizations"},
{value:"813211 Grantmaking Foundations",label:"813211 Grantmaking Foundations"},
{value:"813212 Voluntary Health Organizations",label:"813212 Voluntary Health Organizations"},
{value:"813219 Other Grantmaking and Giving Services",label:"813219 Other Grantmaking and Giving Services"},
{value:"813311 Human Rights Organizations",label:"813311 Human Rights Organizations"},
{value:"813312 Environment, Conservation and Wildlife Organizations",label:"813312 Environment, Conservation and Wildlife Organizations"},
{value:"813319 Other Social Advocacy Organizations",label:"813319 Other Social Advocacy Organizations"},
{value:"813410 Civic and Social Organizations",label:"813410 Civic and Social Organizations"},
{value:"813910 Business Associations",label:"813910 Business Associations"},
{value:"813920 Professional Organizations",label:"813920 Professional Organizations"},
{value:"813930 Labor Unions and Similar Labor Organizations",label:"813930 Labor Unions and Similar Labor Organizations"},
{value:"813940 Political Organizations",label:"813940 Political Organizations"},
{value:"813990 Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)",label:"813990 Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)"},
{value:"814110 Private Households",label:"814110 Private Households"},
{value:"921110 Executive Offices",label:"921110 Executive Offices"},
{value:"921120 Legislative Bodies",label:"921120 Legislative Bodies"},
{value:"921130 Public Finance Activities",label:"921130 Public Finance Activities"},
{value:"921140 Executive and Legislative Offices, Combined",label:"921140 Executive and Legislative Offices, Combined"},
{value:"921150 American Indian and Alaska Native Tribal Governments",label:"921150 American Indian and Alaska Native Tribal Governments"},
{value:"921190 Other General Government Support",label:"921190 Other General Government Support"},
{value:"922110 Courts",label:"922110 Courts"},
{value:"922120 Police Protection",label:"922120 Police Protection"},
{value:"922130 Legal Counsel and Prosecution",label:"922130 Legal Counsel and Prosecution"},
{value:"922140 Correctional Institutions",label:"922140 Correctional Institutions"},
{value:"922150 Parole Offices and Probation Offices",label:"922150 Parole Offices and Probation Offices"},
{value:"922160 Fire Protection",label:"922160 Fire Protection"},
{value:"922190 Other Justice, Public Order, and Safety Activities",label:"922190 Other Justice, Public Order, and Safety Activities"},
{value:"923110 Administration of Education Programs",label:"923110 Administration of Education Programs"},
{value:"923120 Administration of Public Health Programs",label:"923120 Administration of Public Health Programs"},
{value:"923130 Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)",label:"923130 Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"},
{value:"923140 Administration of Veterans' Affairs",label:"923140 Administration of Veterans' Affairs"},
{value:"924110 Administration of Air and Water Resource and Solid Waste Management Programs",label:"924110 Administration of Air and Water Resource and Solid Waste Management Programs"},
{value:"924120 Administration of Conservation Programs",label:"924120 Administration of Conservation Programs"},
{value:"925110 Administration of Housing Programs",label:"925110 Administration of Housing Programs"},
{value:"925120 Administration of Urban Planning and Community and Rural Development",label:"925120 Administration of Urban Planning and Community and Rural Development"},
{value:"926110 Administration of General Economic Programs",label:"926110 Administration of General Economic Programs"},
{value:"926120 Regulation and Administration of Transportation Programs",label:"926120 Regulation and Administration of Transportation Programs"},
{value:"926130 Regulation and Administration of Communications, Electric, Gas, and Other Utilities",label:"926130 Regulation and Administration of Communications, Electric, Gas, and Other Utilities"},
{value:"926140 Regulation of Agricultural Marketing and Commodities",label:"926140 Regulation of Agricultural Marketing and Commodities"},
{value:"926150 Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors",label:"926150 Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors"},
{value:"927110 Space Research and Technology",label:"927110 Space Research and Technology"},
{value:"928110 National Security",label:"928110 National Security"},
{value:"928120 International Affairs",label:"928120 International Affairs"},

]