import styled, { css } from "styled-components";

const label = css`
  margin-bottom: 0.2rem;
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${props => props.theme.greyEnterprise5};
`;

export const Label = styled.label`
  ${label}
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  label {
    ${label}
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  ${FormGroup} {
    padding-right: 5px;
    padding-left: 5px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointMd}px) {
    ${FormGroup} {
      flex: 0 0 50%;
      max-width: ${({ children }) => 100 / (children.length || 1)}%;
    }
  }
`;

export const FormFieldset = styled.fieldset`
  padding: 10px 5px;
  input,
  textarea,
  select {
    color: ${props =>
      props.disabled ? props.theme.greyEnterprise1 : "inherit"};
  }
`;

export const InvalidFeedback = styled.span`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  white-space: pre-wrap;
`;

export const UploadFormGroup = styled(FormGroup)`
  .k-action-buttons,
  .k-file-validation-message {
    visibility: hidden;
    height: 0;
  }

  .errorMessage {
    font-size: 80%;
    color: #dc3545;
  }
  display: flex;
  flex-direction: column;
`;
