import { default as local } from "./urls-local.json";
import { default as test } from "./urls-test.json";
import { default as stage } from "./urls-stage.json";
import { default as production} from "./urls-production.json"
import merge from "lodash/merge";

export const initConfig = () => {
  const { name: envName, ...overrides } = window.environment
    ? window.environment
    : { name: "local" };

  let urls = {};
  const envConfigOverrides = {};

  switch (envName) {
    case "local":
      urls = local;

      envConfigOverrides.DEBUG = true;

      break;

    case "test":
      urls = test;

      break;

    case "stage":
      urls = stage;

      break;
    
    case "production":
        urls = production

        break;

    default:
      break;
  }

  urls.appDomain = window.location.origin;

  const envConfig = generateEnvConfig(urls, envConfigOverrides);
  // merge window.environment values with config values
  // window.environment settings takes precendence
  return merge(envConfig, overrides);
};

const generateEnvConfig = (urls, envConfigOverrides) => {
  const config = {
    AJAX_OPTIONS: {
      JSON: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
    LOADING: {
      DEBOUNCE_TIME: 200,
    },
    NOTIFICATION: {
      TIMEOUT: 2000,
    },

    SCROLL_TO_TOP_ROUTES: ["/search"],
    //These values are only here as a fallback, the real up-to-date values come from the ubo endpoint /Reference/entityinfo

    DEBUG: false,
    UBO_API: {
      URL: `${urls.apiDomain}/ubo`,
      POLL_SERVICES_TIMER_IN_MINUTES: 5,
    },
    UPP_API: {
      URL: `${urls.apiDomain}/v1`,
    },
    CONNECT: {
      URL: `${urls.connectUrl}/v1`,
    },
    ASSETS: urls.assetUrl,
    EXPRESS: {
      URL: `${urls.expressDomain}`,
    },
    DOTNET: {
      URL: `${urls.dotNetDomain}`,
    },
    UPP_REDIRECT: {
      URL: `${urls.uppRedirection}`
    },
    NAV_HANDSHAKE: {
      URL: `${urls.navHandshake}`
    }
  };
  return merge(config, envConfigOverrides);
};
