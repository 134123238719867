import React from "react";
import { ExternalLink } from "../styled/DisplayAttribute";
import { OpenInNew } from "@material-ui/icons";

const WebLinks = ({ websites }) => {
  if (websites.length > 0 && websites[0] !== "") {
    return (
      <span>
        {websites.map((site) => {
          const normalized = site.toLowerCase();
          const anchorObj = {
            text: normalized,
            href:
              normalized.indexOf("http://") === -1 &&
              normalized.indexOf("https://") === -1
                ? "http://" + normalized
                : normalized,
          };
          return (
            <ExternalLink key={anchorObj.text}>
              <OpenInNew />
              <a
                href={anchorObj.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {anchorObj.text}
              </a>
            </ExternalLink>
          );
        })}
      </span>
    );
  }
  return "-";
};

export default WebLinks;
