import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import styled, { css } from "styled-components";
import { routes } from "../routes/routes"

// @material-ui/icons
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

import Auth from "@creditsafe/upp-authentication";

Auth.configure({ appName: "My Creditsafe"});

const open = css`
  position: inherit;
`;

const close = css`
  position: fixed;
`;

export const LeftDrawer = styled.div`
  left: -100%;
  width: 100%;
  min-height: 100vh;
  z-index: 120;
  margin-top: ${props => props.theme.appbarHeight}px;
  background-color: white;
  ${props => (props.open ? open : close)};

  ul {
    list-style: none;
    padding-inline-start: 0px;
  }
  li {
    font-size: ${props => props.theme.fsBase};
    padding: ${props => `${props.theme.spacingUnit * 2}px`};
    border-bottom: 1px solid ${props => props.theme.greyAdobe1};
    position: relative;
    cursor: pointer;
  }
  .arrow {
    position: absolute;
    top: 10px;
    right: 5px;
    color: ${props => props.theme.redPrimary};
  }
  .bottom {
    width: 100%;
    text-align: center;
    padding: ${props => `${props.theme.spacingUnit * 2}px`};
  }
`;

export const DrawerMobile = ({ open, onClose }) => {

  return (
    <LeftDrawer data-testid="mobileNavigationMenu" open={open}>
      <ul>
        {routes.map((route,i) => (
          <Link
          key={`${route.path}_${i}`}
          to={route.path}
          onClick={onClose}
          >
          <li key={`${route.key}_${i}`}>
            {route.key}
            <span className="arrow">
              <ArrowForwardIos />
            </span>
          </li>
          </Link>
        ))}
         

      </ul>
      <div className="bottom">
        <Button
          onClick={() => Auth.signOut()}
          variant="transparent"
          style={{ width: "100%" }}
        >
          Sign Out
        </Button>
      </div>
    </LeftDrawer>
  );
};

export default DrawerMobile;
