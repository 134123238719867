export const currencyObjects = {
  AFN: { symbol: "؋" },
  ALL: { symbol: "Lek" },
  ANG: { symbol: "ƒ" },
  ARS: { symbol: "$" },
  AUD: { symbol: "$" },
  AWG: { symbol: "ƒ" },
  AZN: { symbol: "₼" },
  BAM: { symbol: "KM" },
  BBD: { symbol: "$" },
  BGN: { symbol: "лв" },
  BMD: { symbol: "$" },
  BND: { symbol: "$" },
  BOB: { symbol: "$b" },
  BRL: { symbol: "R$" },
  BSD: { symbol: "$" },
  BWP: { symbol: "P" },
  BYN: { symbol: "Br" },
  BZD: { symbol: "BZ$" },
  CAD: { symbol: "Can$" },
  CHF: { symbol: "CHF" },
  CLP: { symbol: "$" },
  CNY: { symbol: "¥" },
  COP: { symbol: "$" },
  CRC: { symbol: "₡" },
  CUP: { symbol: "₱" },
  CZK: { symbol: "Kč" },
  DKK: { symbol: "kr", currencyPosition: "postfix" },
  DOP: { symbol: "RD$" },
  EGP: { symbol: "£" },
  EUR: { symbol: "€" },
  FJD: { symbol: "$" },
  FKP: { symbol: "£" },
  GBP: { symbol: "£" },
  GGP: { symbol: "£" },
  GHS: { symbol: "¢" },
  GIP: { symbol: "£" },
  GTQ: { symbol: "Q" },
  GYD: { symbol: "$" },
  HKD: { symbol: "$" },
  HNL: { symbol: "L" },
  HRK: { symbol: "kn" },
  HUF: { symbol: "Ft" },
  IDR: { symbol: "Rp" },
  ILS: { symbol: "₪" },
  IMP: { symbol: "£" },
  INR: { symbol: "₹" },
  IRR: { symbol: "﷼" },
  ISK: { symbol: "kr", currencyPosition: "postfix" },
  JEP: { symbol: "£" },
  JMD: { symbol: "J$" },
  JPY: { symbol: "¥" },
  KGS: { symbol: "лв" },
  KHR: { symbol: "៛" },
  KPW: { symbol: "₩" },
  KRW: { symbol: "₩" },
  KYD: { symbol: "$" },
  KZT: { symbol: "лв" },
  LAK: { symbol: "₭" },
  LBP: { symbol: "£" },
  LKR: { symbol: "₨" },
  LRD: { symbol: "$" },
  MKD: { symbol: "ден" },
  MNT: { symbol: "₮" },
  MUR: { symbol: "₨" },
  MXN: { symbol: "$" },
  MYR: { symbol: "RM" },
  MZN: { symbol: "MT" },
  NAD: { symbol: "$" },
  NGN: { symbol: "₦" },
  NIO: { symbol: "C$" },
  NOK: { symbol: "kr", currencyPosition: "postfix" },
  NPR: { symbol: "₨" },
  NZD: { symbol: "$" },
  OMR: { symbol: "﷼" },
  PAB: { symbol: "B/." },
  PEN: { symbol: "S/." },
  PHP: { symbol: "₱" },
  PKR: { symbol: "₨" },
  PLN: { symbol: "zł" },
  PYG: { symbol: "Gs" },
  QAR: { symbol: "﷼" },
  RON: { symbol: "lei" },
  RSD: { symbol: "Дин." },
  RUB: { symbol: "₽" },
  SAR: { symbol: "﷼" },
  SBD: { symbol: "$" },
  SCR: { symbol: "₨" },
  SEK: { symbol: "kr", currencyPosition: "postfix" },
  SGD: { symbol: "$" },
  SHP: { symbol: "£" },
  SOS: { symbol: "S" },
  SRD: { symbol: "$" },
  SVC: { symbol: "$" },
  SYP: { symbol: "£" },
  THB: { symbol: "฿" },
  TRY: { symbol: "₺" },
  TTD: { symbol: "TT$" },
  TVD: { symbol: "$" },
  TWD: { symbol: "NT$" },
  UAH: { symbol: "₴" },
  USD: { symbol: "$" },
  UYU: { symbol: "$U" },
  UZS: { symbol: "лв" },
  VEF: { symbol: "Bs" },
  VND: { symbol: "₫" },
  XCD: { symbol: "$" },
  YER: { symbol: "﷼" },
  ZAR: { symbol: "R" },
  ZWD: { symbol: "Z$" },
};

export const countryCurrency = {
  US: "USD",
  BE: "EUR",
  NL: "EUR",
  DE: "EUR",
  IT: "EUR",
  GB: "GBP",
  NO: "NOK",
  JP: "JPY",
  FR: "EUR",
  SE: "SEK",
  CA: "CAD",
};
