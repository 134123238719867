import {
  FETCH_NAV_ACCOUNT,
  FETCH_NAV_ACCOUNT_SUCCESS,
  FETCH_NAV_ACCOUNT_FAILURE,
  FETCH_NAV_AUTH_TOKEN,
  FETCH_NAV_AUTH_TOKEN_SUCCESS,
  FETCH_NAV_AUTH_TOKEN_FAILURE,
  FETCH_NAV_CTA,
  FETCH_NAV_CTA_SUCCESS,
  FETCH_NAV_CTA_FAILURE,
  CREATE_OR_UPDATE_NAV_SUCCESS,
} from '../actions/types';

const initialState = {
  account: {
    data: {
      account: null
    },
    errors: [],
  },
  accountLoading: false,
  accountError: false,
  accountSuccess: false,
  cta: {
    data: {
      cta: {
        body: "",
        imageAltText: "",
        linkText: "",
        linkURL: "",
        title: "",
        type: "",
        additionalDetails: {
          __typename: "",
          cost: "",
          fundingAmount: "",
          fundingSpeed: "",
        }
      }
    }
  },
  ctaLoading: true,
  ctaError: false,
  authToken: {
    data: {
      authToken: null
    }
  },
  authLoading: false,
  authError: false,
  createOrUpdate: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NAV_ACCOUNT:
      return {
        ...state,
        accountLoading: true
      }
    case FETCH_NAV_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload,
        accountSuccess: true,
        accountLoading: false
      }
    case FETCH_NAV_ACCOUNT_FAILURE:
      return {
        ...state,
        accountLoading: false,
        accountError: true
      }
    case FETCH_NAV_AUTH_TOKEN:
      return {
        ...state,
        authLoading: true
      }
    case FETCH_NAV_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        authToken: action.payload,
        authLoading: false
      }
    case FETCH_NAV_AUTH_TOKEN_FAILURE:
      return {
        ...state,
        authLoading: false,
        authError: true
      }
    case FETCH_NAV_CTA:
      return {
        ...state,
        ctaLoading: true
      }
    case FETCH_NAV_CTA_SUCCESS:
      return {
        ...state,
        cta: action.payload,
        ctaLoading: false
      }
    case FETCH_NAV_CTA_FAILURE:
      return {
        ...state,
        ctaLoading: false,
        ctaError: true
      }
    case CREATE_OR_UPDATE_NAV_SUCCESS:
      return {
        ...state,
        createOrUpdated: true,
      }
    default:
      return state;
  }
}