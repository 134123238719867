import isNil from "lodash/isNil";

export const formatUSTelephone = phone => {
  if (isNil(phone)) {
    return "-";
  }

  if (phone.length > 1) {
    phone = phone.replace(/[()-\s]/g, "");
  }

  if (phone.length === 10) {
    return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6)}`;
  } else if (phone.length === 11 && phone.startsWith("1")) {
    return `1 (${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7)}`;
  }

  return phone;
};
