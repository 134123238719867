import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import AddCorporpateRecord from "../form/AddCoroporateRecord";

import BusinessIcon from "@material-ui/icons/Business";
import Tooltip from '@material-ui/core/Tooltip';


import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import { formatTableData } from "./CorporateRecord";
import sortBy from "lodash/sortBy";
import PopupModal from "../core/PopupModal";
import { InlineLoader } from "../core/Loading";

import get from "lodash/get";

const CorpCard = styled(Card)`
  min-height: 115px;
`;

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class CorporateRecords extends Component {
  render() {
    const {
      mycID,
      companyId,
      email,
      companyReport,
      fetchReportJson,
      fetchUserReport,
      setReportLoading,
      accessToken,
      language,
      report,
      isLoading,
      loadingType,
      expressApi,
      safeNumber,
      userReport,
      fullName,
      boId,
      profile
    } = this.props;

    const { additionalInformation } = companyReport;

    const otherCorporateRecords = get(
      additionalInformation,
      "otherCorporateRecords"
    );

    const isLoadingCorpRecord = isLoading && loadingType === "corpRecord";

    const data = sortBy(
      formatTableData(otherCorporateRecords),
      "businessLegalName"
    ).map((corpRecord, index) => ({ ...corpRecord, index }));

    return (
      <CorpCard scrollContent>
        {isLoadingCorpRecord ? (
          <InlineLoader
            isLoading={isLoadingCorpRecord}
            delayMS={0}
            loadingText={"Updating..."}
          />
        ) : (
          <>
            <HelpTitle>
              <CardTitle title="Corporate Record" dataTestId="Corporate Record" />
              <PopupModal
                title="Add Corporate Record"
                trigger={
                  <Button>
                    <Tooltip title="Add Corporate Record">
                      <BusinessIcon data-testid="add-corporate-record-icon" />
                    </Tooltip>
                  </Button>
                }
              >
                {(close) => (
                  <AddCorporpateRecord
                    data={data}
                    close={close}
                    companyReport={companyReport}
                    additionalInformation={additionalInformation}
                    otherCorporateRecords={otherCorporateRecords}
                    email={email}
                    mycID={mycID}
                    companyId={companyId}
                    fetchReportJson={fetchReportJson}
                    fetchUserReport={fetchUserReport}
                    setReportLoading={setReportLoading}
                    accessToken={accessToken}
                    language={language}
                    report={report}
                    expressApi={expressApi}
                    userReport={userReport}
                    safeNumber={safeNumber}
                    fullName={fullName}
                    boId={boId}
                    profile={profile}
                  />
                )}
              </PopupModal>
            </HelpTitle>
            <PaginatedTable
              headings={[
                { key: "businessLegalName", text: "Name" },
                { key: "sosCharterNumber", text: "Charter Number" },
                { key: "street", text: "Street Address" },
                { key: "city", text: "City" },
                { key: "province", text: "State" },
                { key: "postalCode", text: "Zip Code" },
              ]}
              data={data}
              pageSize={PAGE_SIZE_MEDIUM}
            />
          </>
        )}
      </CorpCard>
    );
  }
}

CorporateRecords.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  report: state.company.report,
  loadingType: state.merged.loadingType,
  expressApi: state.app.config.EXPRESS.URL,
});

export default connect(mapStateToProps, null)(CorporateRecords);
