import React from "react";
import Button from "./core/Button";
import { ErrorMessage, ErrorContainer } from "./ErrorMessage";

function HiddenOn({ isHidden, fallback = null, children }) {
  if (isHidden) {
    return fallback;
  }
  return children;
}

function Error({ isHidden, message, tryAgain, children }) {
  const fallback = tryAgain ? (
    <ErrorContainer>
      <ErrorMessage errorMessage={message} className="pb-2" />
      <Button onClick={tryAgain.onClick} variant="neutral">
        {tryAgain.text}
      </Button>
    </ErrorContainer>
  ) : (
    <ErrorMessage errorMessage={message} className="pb-2" />
  );
  return (
    <HiddenOn isHidden={isHidden} fallback={fallback}>
      {children}
    </HiddenOn>
  );
}

function Info({ isHidden, message, children }) {
  return (
    <HiddenOn
      isHidden={isHidden}
      fallback={
        <p className="pb-2" role="alert">
          {message}
        </p>
      }
    >
      {children}
    </HiddenOn>
  );
}

HiddenOn.Error = Error;
HiddenOn.Info = Info;

export default HiddenOn;
