/**
 * Modify this features list to match your testing scenario.
 * You should replace the example feature names with the ones of your own splits.
 *
 * This example is built to showcase how to work with two Split clients and evaluate
 * for keys of different traffic types. If you are interested on just testing one,
 * only use the USER_FEATURES constant and just leave the ACCOUNT_FEATURES array empty.
 *
 * Also, user and account are not the only traffic types you can use. Read our docs for
 * more information or reach out to support@split.io if you need further assistance.
 */

export const UPP_602_INTERNATIONAL_MONITORING =
  "UPP-602-international-monitoring";
export const UPP_2252_CHECK_AND_DECIDE = "UPP-2252-check-and-decide";
export const CANADA_LAUNCH = "canada-launch";
export const MEDIA_SOLUTIONS_US = "media-solutions-us";
export const UPP_2487_REPORT_A_PROBLEM = "UPP-2847-report-a-problem";
export const UPP_2960_BE_DEBT_COLLECTION = "UPP-2960-be-debt-collection";
export const UK_IE_LAUNCH = "UK_IE_LAUNCH";
export const UPP_1516_VERIFICATION_REPORTS = "UPP-1516_Verification_Reports";
export const UPP_3228_ES_LIMITED_SEARCH = "UPP-3228-ES-limited-search";
export const UPP_3731_FR_CA_LANGUAGE = "UPP_3731_fr_ca_language";

export const USER_FEATURES = [
  UPP_602_INTERNATIONAL_MONITORING,
  CANADA_LAUNCH,
  UPP_2252_CHECK_AND_DECIDE,
  MEDIA_SOLUTIONS_US,
  UPP_2487_REPORT_A_PROBLEM,
  UPP_2960_BE_DEBT_COLLECTION,
  UK_IE_LAUNCH,
  UPP_1516_VERIFICATION_REPORTS,
  UPP_3228_ES_LIMITED_SEARCH,
  UPP_3731_FR_CA_LANGUAGE,
];
export const ACCOUNT_FEATURES = [];

export const isFeatureEnabled = (features, feature) =>
  !!features && !!features[feature] && features[feature] === "on";
