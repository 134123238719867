const DaysSinceUpdate = (updateAt) => {
  let days = 0;

  const updatedAt = new Date(updateAt).getTime()
  const currentDate = Date.now()
  
  const daysPassed = currentDate - updatedAt
  days = Math.round(daysPassed/(1000*60*60*24))

  return days
}

export default DaysSinceUpdate