import React from "react";
import createSvgIcon from "./createSvgIcon";

export default createSvgIcon(
  <>
    <path d="M2.16,26.58,3.62,13.51A11.38,11.38,0,0,1,14.92,3.39h13L26.48,16.46A11.38,11.38,0,0,1,15.17,26.58Z" />
    <path
      fill="#fff"
      d="M6.3,12.46A.74.74,0,0,1,7,11.7a.76.76,0,0,1,.76.76v.31a2.32,2.32,0,0,1,2-1.13,2.06,2.06,0,0,1,2,1.15A2.65,2.65,0,0,1,14,11.64a2.2,2.2,0,0,1,2.29,2.5v3.49a.75.75,0,0,1-1.5,0v-3c0-1-.48-1.6-1.32-1.6s-1.4.57-1.4,1.62v3a.75.75,0,0,1-1.5,0v-3c0-1-.5-1.59-1.32-1.59a1.43,1.43,0,0,0-1.4,1.62v3a.74.74,0,0,1-.76.74.73.73,0,0,1-.74-.74Z"
    />
    <path
      fill="#fff"
      d="M18.1,20.14a.64.64,0,0,1-.47-.62.61.61,0,0,1,.61-.62.87.87,0,0,1,.27.05A1.66,1.66,0,0,0,19,19c.45,0,.68-.19.93-.69l.05-.14-2.43-5.42a1,1,0,0,1-.08-.36.72.72,0,0,1,.74-.73.75.75,0,0,1,.73.57l1.76,4.34,1.63-4.34a.76.76,0,0,1,.72-.57.71.71,0,0,1,.71.71,1.23,1.23,0,0,1-.06.36L21.34,18.5c-.55,1.33-1.15,1.83-2.2,1.83A2.54,2.54,0,0,1,18.1,20.14Z"
    />
  </>,
  "MyCreditsafe",
  "0 0 29.41 29.41"
);
