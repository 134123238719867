import React, { Component, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import styled from "styled-components";

import { resetSearch } from "../../actions/searchActions";
import { fetchPayment } from "../../actions/paymentActions";

import { Typography } from "@creditsafe/ui-components";
import { Jumbotron } from "../core/Jumbotron";

import SearchWithFilters from "../SearchWithFilters";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import { noNumber } from "../../utils/noNumber";

import { Formik, Form, Field } from "formik";
import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";
import { InputField } from "../core/FormComponents";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import Codat from "../core/Codat";
import Invoices from "./Invoices";
import PaymentSummary from "./PaymentSummary";
import { InlineLoader } from "../core/Loading";
import { DollarSign } from "./TradeReferenceForm";
import Disclaimer from "../../components/core/Disclaimer";

import { HelpTitle } from "../report/KeyInformation";
import PublishIcon from "@material-ui/icons/Publish";

import isEmpty from "lodash/isEmpty";

import * as Yup from "yup";
import {
  POSITIVE_NUMBER,
  ILLEGAL_CHARACTERS,
} from "../../utils/regexConstants";
import { startCasing } from "../../utils/string";
import { formatDateToUtc } from "../../utils/dateFormatter";
import PDFPage from "./PDFPage";

import { isValidService } from "../../utils/services";
import { MYC_PAYMENTS_SVC_ID } from "../../utils/serviceConstants";
import { ErrorMessage } from "../ErrorMessage";

import { initConfig } from "../../config";

const config = initConfig();

const CustomErrorMessage = styled(ErrorMessage)`
  margin-top: 10px;
`;

export const PaymentFormLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.blue2};
  text-align: left;
  margin: 1rem 0;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    /* > div {
      width: 50%;
    } */
  }
`;

export const DashboardContent = styled.div`
  padding: 1rem 3rem;
  text-align: right;

  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    padding: 0.5em;
  }
`;

export const InvoiceHeader = styled.div`
  padding: 1rem 3rem 0;
`;

const PageContainer = styled.div`
  text-align: left;
`;

export const ButtonBar = styled.div`
  align-self: center;
  justify-content: center;
  display: flex;
  margin: 1rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Attribute} {
    align-items: center;
  }
`;

export const ProofList = styled.ul`
  text-align: left;

  li {
    margin-bottom: 0.5rem;
  }
`;

export const TableContainer = styled.div`
  /* overflow-y: scroll; */
`;

export const DzSection = styled.section`
  background-color: ${({ theme }) => theme.sidebarBackgroundColor};
  padding: 2rem 0;
`;

export const DzContent = styled.div`
  .dz-drop-header {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 24px;
  }

  .dz-drop-button {
    margin: 2rem 0 0;
    border-color: #474647;
  }
`;

export const InvoiceWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: column;
  }
`;

export const InvoiceInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  button {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 100%;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  button {
    font-size: 1.4rem;
  }


  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 100%;
  }
`;

export const DzCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;

  > div {
    width: 75%;
    justify-content: center;
  }
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    > div {
      width: 85%;
    }
  }
`;

export const CheckboxCell = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  input {
    margin-right: 5px;
  }
`;

export const ImageCard = styled(Card)`
  height: 100%;
`;

export const ThumbParent = styled.div`
  width: 100%;
  height: 85%;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    height: 300px;
  }
`;
export const ThumbChild = styled.div`
  width: 100%;
`;

export const ThumbImage = styled.img`
  width: 100%;
`;

export const InputFieldWrapper = styled(Field)`
  width: 3px;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const AttributeRowPayee = styled(AttributeRow)`
  height: 85%;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
  }
`;

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;

      @media only screen and (min-width: ${(props) =>
          `${props.theme.breakpointSm}px`}) {
        width: 55%;
      }
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  position: "relative",
};

const thumbImage = {
  maxHeight: "100%",
  maxWidth: "100%",
  width: "auto",
  height: "auto",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
};

const TableWrapper = styled.div`
  table {
    width: 90%;
    margin: auto;

    tr:hover {
      cursor: pointer;
    }
  }
`;

function MyDropZone({
  email,
  selectedCompany,
  safeNumber,
  companyId,
  companyName,
  close,
  resetCompany,
  enableSubmission,
  disabled,
  payments,
  onError,
  error,
  expressApi,
  userReport,
  profile
}) {
  const maxSize = 5242880;
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState("");

  const api = config.EXPRESS.URL;

  const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
    accept: ".png, .jpg, .jpeg, .pdf",
    maxSize,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropAccepted: (files) => {
      let fd = new FormData();
      fd.append("file", files[0]);
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };
      axios
        .post(`${api}/sign_s3`, fd, {
          headers: headers,
        })
        .then(function (response) {
          setUrl(response.data);
        })
        .catch((err) => console.error(err));
    },
  });

  const fileWasRejected = rejectedFiles.length > 0;

  const thumbs = files.map((file) => (
    <ThumbParent className="thumbParent" style={thumb} key={file.name}>
      <ThumbChild className="thumbChild" style={thumbInner}>
        {file.type === "application/pdf" ? (
          <PDFPage className="pdfPage" pdfFile={file} style={thumbImage} />
        ) : (
          <img src={file.preview} style={thumbImage} alt="Submitted document" />
        )}
      </ThumbChild>
    </ThumbParent>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      {thumbs.length < 1 && (
        <>
          <DzCardWrapper>
            <Card>
              <HelpTitle>
                <CardTitle title="Submit Payment Information" />
              </HelpTitle>
              <ProofList>
                <li>
                  In order to show how well you pay your suppliers, please
                  provide details of your recently paid invoice.
                </li>
                <li>
                  We accept scanned documents such as order confirmations,
                  invoices, reciepts, email confirmation etc.
                </li>
                <li>
                  Please ensure that any document you provide clearly displays
                  the payees name, address and that a balance has been paid (not
                  outstanding or due)
                </li>
                <li>
                  We will verify payments with your supplier or through out
                  trade payment program databases.
                </li>
              </ProofList>

              <DzSection className="container">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <DzContent>
                    <PublishIcon fontSize="large" />
                    <p className="dz-drop-header">Drag and drop files here</p>
                    <p className="dz-drop-sub">
                      supported file types: .png, .img, .bmp, .jpg, .pdf{" "}
                    </p>
                    <Button className="dz-drop-button" variant="border">
                      Browse Files
                    </Button>
                  </DzContent>
                  {fileWasRejected && (
                    <div>
                      File not accepted, please use .pdf, .jpg, .jpeg, or .png.
                      Make sure file is under 5MB
                    </div>
                  )}
                </div>
              </DzSection>
            </Card>
          </DzCardWrapper>
          <DzCardWrapper>
            <Card>
              <Codat
                companyId={companyId}
                safeNumber={safeNumber}
                companyName={companyName}
              />
            </Card>
          </DzCardWrapper>
        </>
      )}
      {thumbs.length > 0 &&
        url.length > 1 &&
        invoiceForm(
          email,
          selectedCompany,
          safeNumber,
          thumbs,
          url,
          companyId,
          setFiles,
          resetCompany,
          enableSubmission,
          disabled,
          close,
          payments,
          onError,
          error,
          expressApi, 
          userReport,
          profile
        )}
    </>
  );
}

function invoiceForm(
  email,
  selectedCompany,
  safeNumber,
  thumbs,
  url,
  companyId,
  setFiles,
  resetCompany,
  enableSubmission,
  disabled,
  close,
  payments,
  onError,
  error,
  expressApi,
  userReport,
  profile
) {
  const duplicateCheck = (submission, payments) => {
    const duplicate = payments.filter(
      (payment) =>
        payment.payeeid === submission.payeeid &&
        payment.companyname === submission.companyname &&
        payment.value.toString() === submission.value.toString() &&
        payment.invoiceno === submission.invoiceno &&
        payment.paymentdate.slice(0,10) === submission.paymentdate.toISOString().slice(0,10)
    )

    return duplicate;
  };

  const validationSchema = () =>
    Yup.object().shape({
      companyname: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        })
        .required("Company Name Required"),
      value: Yup.string()
        .matches(POSITIVE_NUMBER, { message: "Please enter a whole number" })
        .required("Amount Required"),
      invoiceno: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      details: Yup.string().matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
      paymentterms: Yup.string()
        .matches(POSITIVE_NUMBER, { message: "Please enter a whole number" })
        .required("Payment terms Required"),
    });

  return (
    <Formik
      initialValues={{
        userid: userReport.userid,
        companyid: companyId,
        attachmenturls: url,
        companyname: selectedCompany.name,
        currency: "USD",
        street: selectedCompany.address.street,
        city: selectedCompany.address.city,
        province: selectedCompany.address.province,
        postalcode: selectedCompany.address.postCode,
        details: "",
        invoiceno: "",
        payeeid: selectedCompany.safeNo,
        paymentdate: new Date(),
        duedate: new Date(),
        invoicedate: new Date(),
        paymentterms: "",
        safenumber: safeNumber,
        value: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const duplicate = duplicateCheck(values, payments);
        if (duplicate.length < 1) {
          await fetch(`${expressApi}/payments`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify({
              userid: values.userid,
              companyid: values.companyid,
              payeeid: values.payeeid,
              attachmenturls: values.attachmenturls,
              companyname: values.companyname,
              currency: values.currency,
              street: values.street,
              city: values.city,
              province: values.province,
              postalcode: values.postalcode,
              value: values.value,
              invoiceno: values.invoiceno,
              invoicedate: formatDateToUtc(values.invoicedate),
              duedate: formatDateToUtc(values.duedate),
              details: values.details,
              paymentterms: values.paymentterms,
              paymentdate: formatDateToUtc(values.paymentdate),
              safenumber: values.safenumber,
              created_at: new Date(),
              status_at: new Date(),
              updated_at: new Date(),
              status: "pending",
              userverified: userReport.userstatus,
              customer_id: profile.customer.id
            }),
          })
            .then((response) => {
              if (response.status >= 300) {
                onError(response.status);
              } else {
                close(companyId);
              }
            })
            .catch((err) => console.error(err));
        } else {
          alert("You have already submitted this payment.");
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <DashboardContent>
            <InvoiceWrapper>
              <InvoiceInputs>
                <Card>
                  <HelpTitle>
                    <CardTitle title="Payee Information" />
                  </HelpTitle>
                  <PageContainer>
                    <Container>
                      <DetailsContainer>
                        <AttributeRowPayee
                          width={100}
                          name={<label>Safe Number</label>}
                          value={<div>{selectedCompany.safeNo}</div>}
                        />
                        <AttributeRowPayee
                          width={100}
                          name={<label>Company Name</label>}
                          value={<div>{startCasing(selectedCompany.name)}</div>}
                        />
                        <AttributeRowPayee
                          width={100}
                          name={<label>Street</label>}
                          value={
                            <div>
                              {startCasing(selectedCompany.address.street)}
                            </div>
                          }
                        />
                        <AttributeRowPayee
                          width={100}
                          name={<label>City</label>}
                          value={
                            <div>
                              {startCasing(selectedCompany.address.city)}
                            </div>
                          }
                        />
                        <AttributeRowPayee
                          width={100}
                          name={<label>State</label>}
                          value={<div>{selectedCompany.address.province}</div>}
                        />
                        <AttributeRowPayee
                          width={100}
                          name={<label>Zip</label>}
                          value={<div>{selectedCompany.address.postCode}</div>}
                        />
                        <ButtonBar>
                          <Button
                            variant="success"
                            onClick={() => resetCompany()}
                          >
                            Change Payee Information
                          </Button>
                        </ButtonBar>
                      </DetailsContainer>
                    </Container>
                  </PageContainer>
                </Card>
                <Card>
                  <HelpTitle>
                    <CardTitle title="Payment Information" />
                  </HelpTitle>
                  <PageContainer>
                    <Container>
                      <DetailsContainer>
                        <AttributeRow
                          className="one"
                          width={100}
                          name={<label>Invoice Number</label>}
                          value={
                            <InputField className="where" name="invoiceno" />
                          }
                        />
                        <AttributeRowDate
                          width={100}
                          name={<label>Invoice Date</label>}
                          value={
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                maxDate={new Date()}
                                maxDateMessage="Date can not be set in future"
                                value={values.invoicedate}
                                onChange={(value) =>
                                  setFieldValue("invoicedate", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          }
                        />
                        <AttributeRowDate
                          width={100}
                          name={<label>Due Date</label>}
                          value={
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                value={values.duedate}
                                onChange={(value) =>
                                  setFieldValue("duedate", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          }
                        />
                        <AttributeRowDate
                          width={100}
                          name={<label>Date Paid</label>}
                          value={
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                maxDate={new Date()}
                                maxDateMessage="Date can not be set in future"
                                value={values.paymentdate}
                                onChange={(value) =>
                                  setFieldValue("paymentdate", value)
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          }
                        />

                        <AttributeRow
                          width={100}
                          name={<label>Amount Paid</label>}
                          value={
                            <DollarSign>
                              <InputField
                                onKeyPress={noNumber}
                                type="number"
                                name="value"
                                placeholder="0"
                              />
                            </DollarSign>
                          }
                        />

                        <AttributeRow
                          width={100}
                          name={<label>Details</label>}
                          value={<InputField name="details" />}
                        />
                        <AttributeRow
                          width={100}
                          name={<label>Payment Terms</label>}
                          value={
                            <InputField
                              onKeyPress={noNumber}
                              type="number"
                              name="paymentterms"
                            />
                          }
                        />
                      </DetailsContainer>
                    </Container>
                  </PageContainer>
                  <CheckboxCell>
                    <input type="checkbox" onChange={enableSubmission} />I
                    confirm that all information provided is true and accurate
                  </CheckboxCell>
                  <ButtonBar>
                    <Button
                      variant="success"
                      type="submit"
                      disabled={disabled || error.outOfCredits}
                    >
                      Confirm Payment Information
                    </Button>
                  </ButtonBar>
                  {error.outOfCredits && (
                    <CustomErrorMessage
                      errorMessage={
                        "You are out of Invoice credits, please contact your account manager to add more."
                      }
                    />
                  )}
                </Card>
              </InvoiceInputs>
              <ImageContainer>
                <ImageCard>
                  <ButtonBar>
                    <Button variant="success" onClick={() => setFiles([])}>
                      Select Different Invoice
                    </Button>
                  </ButtonBar>
                  {thumbs}
                </ImageCard>
              </ImageContainer>
            </InvoiceWrapper>
          </DashboardContent>
        </Form>
      )}
    </Formik>
  );
}

class InvoiceForm extends Component {
  state = {
    selectedCompany: {},
    searching: false,
    disabled: true,
    error: {},
  };

  componentDidMount() {
    this.props.resetSearch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searched !== this.props.searched) {
    }
  }

  handleClick = (company) => {
    this.setState({ selectedCompany: company });
  };

  resetCompany = () => {
    this.setState({
      selectedCompany: {},
    });
  };

  onClose = (companyId) => {
    this.setState({
      searching: !this.state.searching,
      disabled: !this.state.disabled,
      selectedCompany: {},
      error: {},
    });
    this.setState({ selectedCompany: {} });
    this.props.resetSearch();
    this.props.fetchPayment(companyId);
  };

  onError = (status) => {
    if (status === 403) {
      this.setState({
        error: { outOfCredits: true },
      });
    }
  };

  handleSearch = () => {
    this.setState((prevState) => ({
      searching: !prevState.searching,
    }));
    if (this.state.searching) {
      this.props.resetSearch();
      this.setState({ selectedCompany: {} });
    }
  };

  enableSubmission = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  render() {
    const payments = this.props.payments.payment;

    const {
      isLoading,
      isSearching,
      searched,
      email,
      safeNumber,
      companyId,
      companyReport,
      companyName,
      company,
      sub,
      customerId,
      services,
      expressApi,
      userReport,
      profile
    } = this.props;
    const { selectedCompany, searching, disabled, error } = this.state;

    const hasPayments = isValidService(services, MYC_PAYMENTS_SVC_ID);

    return (
      <>
        {!searching && (
          <InvoiceHeader>
            <Disclaimer sub={sub} customerId={customerId} report={company} />
            <Card  id="walkMePaymentSummary">
              <HelpTitle>
                <CardTitle title="Paid Invoice Summary" />
                <Button
                  variant="success"
                  onClick={this.handleSearch}
                  disabled={!hasPayments}
                  className="addButton"
                  id="walkMeAddPayment"
                  title={
                    !hasPayments
                      ? "You are out of Invoices. Please contact your account manager to get more."
                      : null
                  }
                >
                  Upload New Invoice <PublishIcon />
                </Button>
              </HelpTitle>
              <PaymentSummary
                isLoading={isLoading}
                payments={payments}
                companyReport={companyReport}
              />
            </Card>
          </InvoiceHeader>
        )}

        {searching ? (
          <>
            {!isEmpty(selectedCompany) ? (
              <MyDropZone
                email={email}
                selectedCompany={selectedCompany}
                safeNumber={safeNumber}
                companyId={companyId}
                companyName={companyName}
                close={this.onClose}
                resetCompany={this.resetCompany}
                enableSubmission={this.enableSubmission}
                disabled={disabled}
                payments={payments}
                onError={this.onError}
                error={error}
                expressApi={expressApi}
                userReport={userReport}
                profile={profile}
              />
            ) : (
              <>
                <Jumbotron>
                  <PageHeader>
                    <Typography variant="h1" font="secondary">
                      Search Company
                    </Typography>
                    <ButtonBar>
                      <Button variant="danger" onClick={this.handleSearch}>
                        Go Back
                      </Button>
                    </ButtonBar>
                  </PageHeader>
                  <SearchWithFilters />
                </Jumbotron>

                {isSearching ? (
                  <InlineLoader
                    isLoading={isSearching}
                    delayMS={0}
                    loadingText={"Searching..."}
                  />
                ) : (
                  <TableWrapper>
                    <PaginatedTable
                      headings={[
                        { key: "name", text: "Name" },
                        { key: "address.simpleValue", text: "Address" },
                      ]}
                      data={searched.results.companies}
                      formatters={{
                        name: (data) => data.toLowerCase(),
                        "address.simpleValue": (data) => data,
                      }}
                      pageSize={PAGE_SIZE_MEDIUM}
                      onClick={this.handleClick}
                      actions={
                        <><p>Unable to find the company you're looking for?</p><p>Please refine the search criteria above or speak to your account manager for help adding a company to our database</p></>
                      }
                    />
                  </TableWrapper>
                )}
              </>
            )}
          </>
        ) : (
          <Invoices isLoading={isLoading} />
        )}
      </>
    );
  }
}

InvoiceForm.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  searched: PropTypes.object,
  results: PropTypes.object,
  companies: PropTypes.array,
  resetSearch: PropTypes.func,
  fetchPayment: PropTypes.func,
  sub: PropTypes.string,
  customerId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.payment.paymentsLoading,
  searched: state.search,
  isSearching: state.search.isSearching,
  payments: state.payment,
  services: state.user.services,
  expressApi: state.app.config.EXPRESS.URL,
  sub: state.user.info.sub,
  customerId: state.user.info.customerId,
});

export default connect(mapStateToProps, { resetSearch, fetchPayment })(
  InvoiceForm
);
