import React from "react"
import { Search as SearchIcon } from "@material-ui/icons"
import Input from "./Input"

const CountrySelectorItemRenderer = ({ index, style, data }) => {
  const {
    listItem,
    selectedItems,
    t,
    currentLocation,
    userService,
    getItemProps,
    config,
    handleChange,
    handleClick,
    itemComponent,
    handleSearchInputChange,
    inputRef,
    features,
    productSearchCode,
  } = data;
  let isNewGroup;

  console.log(listItem)

  if (listItem[index] !== "search") {
    const previousCountry = listItem[index - 1] || {};
    isNewGroup = listItem[index].group !== previousCountry.group;
  }
  const CountrySelectorItem = itemComponent;

  return (
    <div style={style}>
      {listItem[index] === "search" && (
        <li className="dropdown-item--divider">
          <div className="inner-addon left-addon">
            <SearchIcon className="icon" />
            <Input
              data-testid="country-search-input"
              type="text"
              placeholder="Type a country"
              onChange={e => handleSearchInputChange(e)}
              id="countryInputBox"
              inputRef={inputRef}
            />
          </div>
        </li>
      )}
      {isNewGroup && (
        <li className="dropdown-header" key={listItem[index].group}>
          {t("global:countryGroups." + listItem[index].group)}
        </li>
      )}
      {listItem[index].countryCode && (
        <CountrySelectorItem
          key={`item-${listItem[index].countryCode}`}
          item={listItem[index]}
          getItemProps={getItemProps}
          selectedItems={selectedItems}
          currentLocation={currentLocation}
          t={t}
          handleChange={handleChange}
          config={config}
          userService={userService}
          onClose={handleClick}
          features={features}
          productSearchCode={productSearchCode}
        />
      )}
    </div>
  );
};

export default CountrySelectorItemRenderer;