import React from "react";
import styled from "styled-components";

import Button from "../core/Button";
import { InputField, SelectField } from "../core/FormComponents";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import { ILLEGAL_CHARACTERS } from "../../utils/regexConstants";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { usStatePackages } from "../../utils/usStatePackages";
import { countryCheck } from "../../utils/countryCheck"

export const DirectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
    `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const DirectorInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

function AddDirectors({
  close,
  email,
  mycID,
  companyId,
  currentDirectors,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  language,
  report,
  companyReport,
  expressApi,
  userReport,
  safeNumber,
  fullName,
  boId,
  profile
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={{ id: randomIdGenerator(mycID), name: "", positionName: "", address: {} }}
      onSubmit={async (values, { setSubmitting }) => {
        const duplicate = currentDirectors.some(
          (director) =>
            director.name.trim().toUpperCase() ===
            values.name.trim().toUpperCase() &&
            director.positions[0].positionName.trim().toUpperCase() ===
            values.positionName.trim().toUpperCase()
        );
        if (!duplicate) {
          const newDirector = {
            ...values,
            positions: [{ positionName: values.positionName }],
            positionName: undefined,
          };
          await setReportLoading("directors");
          await fetch(`${expressApi}/reports`, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify({
              mycID,
              email,
              report,
              reportjson: {
                ...companyReport,
                directors: {
                  currentDirectors: [
                    ...currentDirectors,
                    newDirector,
                  ],
                },
              },
              detType: 'update_officers_info',
              detInfo: [{
                myc_id: userReport.id,
                company_id: companyId,
                director_id: newDirector.id,
                safe_number: safeNumber,
                name: newDirector.name,
                street: newDirector.address.street,
                city: newDirector.address.city,
                state: newDirector.address.province,
                zip: newDirector.address.postalCode,
                position: newDirector.positions[0].positionName,
                gender: newDirector.gender,
                delete_flag: "N",
                status: 'Fail',
                user_id: userReport.userid,
                user_verified: userReport.userstatus,
                customer_id: profile.customer.id
              }],
              reportupdated_at: new Date(),
              updated_at: new Date(),
              created_at: new Date(),
              verifiedstatus: "changed",
              language,
              bo_id: boId,
              bo_name: fullName,
            }),
          })
            .then((response) => response.json())
            .then(() => setSubmitting(false))
            .then(() => fetchReportJson(companyId, "director"))
            .then(() => fetchUserReport(companyId))
            .then(() => close())
            .catch((err) => console.log(err));
        } else {
          close();
          alert("You have already submitted this director");
        }
      }}
      validationSchema={directorSchema()}
    >
      {() => (
        <Form>
          <DirectorContainer>
            <AttributeRow
              width={100}
              name={<label htmlFor="name">Full Name</label>}
              value={<InputField name={"name"} />}
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="positionName">Position</label>}
              value={<InputField name={"positionName"} />}
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="address.street">Street</label>}
              value={<InputField name={"address.street"} />}
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="address.city">City</label>}
              value={<InputField name={"address.city"} />}
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="address.province">State</label>}
              value={
                <SelectField name={"address.province"}>
                  {provinceOptions()}
                </SelectField>
              }
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="address.postalCode">Postal Code</label>}
              value={<InputField name={"address.postalCode"} />}
            />
            <AttributeRow
              width={100}
              name={<label htmlFor="gender">Gender</label>}
              value={<InputField name={"gender"} />}
            />
            <ButtonBar>
              <Button variant="success" type="submit" data-testid="add-director-submit-button" >
                Submit
              </Button>
            </ButtonBar>
          </DirectorContainer>
        </Form>
      )}
    </Formik>
  );
}

const randomIdGenerator = (mycID) => {
  const randomId = Math.floor((Math.random() * 10000000) + 1)
  const country = countryCheck(mycID.substring(0, 2))

  return `${country}-S${randomId}`
}



const directorSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .required("Please enter their full name")
      .min(1, "Please enter their full name")
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
    positionName: Yup.string()
      .required("Please provide position name")
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
    gender: Yup.string()
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
    address: Yup.object().shape({
      street: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      city: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      province: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      postalCode: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
    })
  });

export default AddDirectors;
