export const routes = [
  {
    key: "My Report",
    path: "/"
  },
  {
    key: "Proofs of Payment",
    path: "/payments"
  },
  {
    key: "Trade References",
    path: "/trade"
  },    
  {
    key: "Expenditure",
    path: "/expenditure"
  },    
  {
    key: "Certificate",
    path: "/certificate"
  }
]
