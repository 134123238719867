import React from "react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import Card from "../core/Card";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_EXTRA_LARGE } from "../../utils/constants";


export const formatTableData = directors => {
  if (!directors) {
    return [];
  }

  return directors.reduce((r, directorClass) => {
    const directorId = get(directorClass, "id", "")
    const directorNameText =get(directorClass, "name","");
    const streetText = get(directorClass,"address.street", "");
    const stateText = get(directorClass,"address.province","");
    const cityText = get(directorClass,"address.city", "");
    const zipText = get(directorClass,"address.postalCode", "");
    const genderText = get(directorClass,"gender","");
    

    if(directorClass.positions){
      r = r.concat(
        directorClass.positions.map(position => {
          return {
            id: directorId,
            name: directorNameText.toLowerCase(),
            position: position.positionName.toLowerCase(),
            street: streetText.toLowerCase(),
            city: cityText.toLowerCase(),
            province: stateText,
            postalCode: zipText,
            gender: genderText.toLowerCase()
          }
        })
      )
    }

    return r;
  }, []);
};

export const Directors = ({ report, options = {}, t }) => {
  const directorData = get(
    report,
    "directors.currentDirectors"
  );

  const data = sortBy(formatTableData(directorData, "name"), "currentDirectors");

  return (
    <Card scrollContent title="Directors" data-testid={"directorssCard"}>
      <PaginatedTable
        data-testid={"directorsTable"}
        headings={[
          { key: "name", text: "Name" },
          { key: "position", text: "Position" },
          { key: "street", text: "Street" },
          { key: "city", text: "City" },
          { key: "province", text: "State" },
          { key: "postalCode", text: "Zip Code" },
          { key: "gender", text: "Gender"}
        ]}
        data={data}
        pageSize={PAGE_SIZE_EXTRA_LARGE}
        options={options}
      />
    </Card>
  );
};

export default Directors;