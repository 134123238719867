import React from "react";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";
import { withTranslation } from "react-i18next";

const fadeinPercentage = 66;

const pulse = keyframes`
  0% {transform: scale(1);opacity: 1}
  45% {transform: scale(0.5);opacity: 1}
  80% {transform: scale(1);opacity: 1}
`;

const fadein = keyframes`{
  0% { opacity:0; height: 0em; margin: auto;}
  ${fadeinPercentage}% { opacity:0; height: 0em; margin: auto;  }
  100% { opacity:1; height: 8em;margin: 2em auto; }
}`;

export const LoaderWrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.modal};
  height: 8em;
  width: 20em;
  margin: auto;
  overflow: show;
  top: 30%;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadein} ${props => props.animationDelay}s ease 0s normal forwards;
`;

export const LoadingDot = styled.div`
  width: 25px;
  height: 25px;
  margin: 4px;
  background-color: ${props => props.theme.greyAdobe1};
  border-radius: 100%;
  display: inline-block;
  animation: ${pulse} 1.4s ${props => props.i * 0.12}s infinite
    cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation-fill-mode: both;
`;

export const LoadingDotsWrapper = styled.div`
  width: 180px;
`;

export const LoadingDots = ({ dotsCount }) => (
  <LoadingDotsWrapper>
    {[...Array(dotsCount)].map((_, index) => (
      <LoadingDot key={index} i={index} />
    ))}
  </LoadingDotsWrapper>
);

export const LoaderBase = ({
  isLoading,
  loadingText,
  className,
  t,
  children,
  delayMS = 500,
}) => {
  const animationDelay = delayMS / 1000 / (fadeinPercentage / 100);
  return isLoading ? (
    <LoaderWrapper className={className} animationDelay={animationDelay}>
      <LoadingDots dotsCount={5} />
      {loadingText ? <p>{loadingText}</p> : <p>{t("loading")}</p>}
    </LoaderWrapper>
  ) : children ? (
    <>{children}</>
  ) : null;
};

export const Loader = withTranslation("global")(LoaderBase);

export const InlineLoader = withTranslation("global")(styled(LoaderBase)`
  position: relative;
  left: 0 !important;
`);

export const MainLoader = styled(InlineLoader)`
  padding-top: 20%;
`;

export const mapStateToProps = state => ({
  isLoading: state.company.isLoading,
});

export default connect(mapStateToProps, null)(Loader);
