import React, { Component } from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


import { BrowserRouter as Router, Link } from "react-router-dom";

import { SignOutIcon } from "../../assets/icons";
import cslogo from "../../assets/img/cslogo.svg";

import Auth from "@creditsafe/upp-authentication";

Auth.configure({ appName: "My Creditsafe"});

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  grow4: {
    flexGrow: 4,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 101,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[800],
  },
  logo: {
    width: "198px",
  },
  logoImg: { height: "100%" },
  button: {
    marginRight: "2px",
  },
  leftIcon: {
    marginRight: "2px",
  },
  multilineButtonText: {
    lineHeight: 1.2,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  smallText: {
    fontSize: "0.8em",
  },
});

class AppBar extends Component {
  render() {
    const { classes, handleDrawerToggle } = this.props;

    return (
      <MuiAppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar variant="dense">
          <Router>
            <Link to="/" className={"brand " + classes.logo}>
              <img
                src={cslogo}
                alt="Creditsafe logo"
                className={classes.logoImg}
              />
            </Link>
          </Router>
          <div className={`${classes.sectionDesktop} ${classes.grow4}`} />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              color="inherit"
              onClick={() => Auth.signOut()}
              className={"signout " + classes.button}
            >
              <SignOutIcon className={classes.leftIcon} />
              Sign out
            </Button>
          </div>
          <div className={classes.sectionMobile}>
          <IconButton
            aria-label={"openDrawer"}
            onClick={handleDrawerToggle}
            color="inherit"
            data-testid="hamburgerMenuButton"
            edge="end"
          >
            <MenuIcon />
          </IconButton>
        </div>
        </Toolbar>
      </MuiAppBar>
    );
  }
}

export default withStyles(styles)(AppBar);
