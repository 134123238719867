import React from "react";
import MuiDrawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { theme } from "../../../src/theme";

const styles = () => ({
  root: {
    width: theme.drawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: theme.drawerWidth,
    backgroundColor: theme.sidebarBackgroundColor,
  },
});

const Drawer = React.forwardRef(function Drawer(props, ref) {
  const { classes } = props;
  return (
    <MuiDrawer
      ref={ref}
      className={classes.root}
      classes={{ paper: classes.paper }}
      {...props}
    />
  );
});

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Drawer);
