import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import Popup from "reactjs-popup";
import { Formik, Form } from "formik";

import Button from "../core/Button";

import { fetchDisclaimer } from "../../actions/disclaimerActions";

import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";

import { initConfig } from "../../config";

const config = initConfig();

const redirectionUrl = config.UPP_REDIRECT.URL;


export const StyledPopup = styled(Popup)`
  
  &-content{
      width: 90% !important;
    }
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpointMd}px) {
    &-content{
      width: 50% !important;
    }
  }

`; 
  

export const Header = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const Container = styled.div`
  text-align: left;
  padding: 0 2rem;

  a {
    color: ${({ theme }) => theme.blue2};
  }
  input {
    margin-right: 10px;
  }
`;

export const CheckboxCell = styled.div`
  display: flex;
  justify-content: start;
  align-items: left;
  text-align: left;
  padding: 0.5rem 2rem;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
`;

export const DisclaimerNote = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: left;
  padding-bottom: 1rem;
`;

class Disclaimer extends Component {
  state = {
    open: true,
  };

  handleClick = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleDecline = () => {
    window.open(`${redirectionUrl}`, "_self");
  };

  completeSubmission = (companyId, customerId, sub, ipAddress) => {
    this.props.fetchDisclaimer(companyId, customerId, sub, ipAddress);
    this.setState({
      open: false,
    });
  };

  renderContent(
    disclaimer,
    sub,
    companyId,
    safeNumber,
    customerId,
    companyName,
    handleClick,
    handleDecline,
    completeSubmission,
    ipAddress,
    expressApi
  ) {


    if (disclaimer.disclaimer < 1) {
      return (
        <StyledPopup
          open={this.state.open}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <Formik
            enableReinitialize
            initialValues={{
              company_id: companyId,
              customer_id: customerId,
              user_id: sub,
              timestamp: new Date(),
              ip: ipAddress,
            }}
            onSubmit={(values) => {
              fetch(`${expressApi}/disclaimer`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                },
                body: JSON.stringify({
                  company_id: values.company_id,
                  customer_id: values.customer_id,
                  user_id: values.user_id,
                  timestamp: values.timestamp,
                  ip: values.ip,
                }),
              })
                .then((response) => response.json())
                .then(() =>
                  completeSubmission(companyId, customerId, sub, ipAddress)
                )
                .catch((err) => console.error(err));
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Header>
                  Please certify that each of the following statements is true
                  and correct
                </Header>
                <Container>
                  I attest that I am a current registered executive officer of {companyName} and that I am authorized to update information held by Creditsafe.
Any information provided on behalf of {companyName} will be legitimate and accurate.
I have read and accepted both the <a href="https://www.creditsafe.com/us/en/product/terms/myc-general-terms-and-conditions.html" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions </a> and <a href="https://www.creditsafe.com/us/en/product/privacy/privacy-policy.html" target="_blank" rel="noopener noreferrer"> Private Policy</a>.

                </Container>
                <ButtonBar>
                  <Button variant="success" type="submit">
                    Accept
                  </Button>
                  <Button variant="danger" onClick={() => handleDecline()}>
                    Decline
                  </Button>
                </ButtonBar>
                <DisclaimerNote>
                  <InfoTwoToneIcon />
                  Acceptance is required on a per user and per IP basis.
                </DisclaimerNote>
              </Form>
            )}
          </Formik>
        </StyledPopup>
      );
    }
    return <div></div>;
  }

  render() {
    const {
      disclaimer,
      sub,
      companyId,
      safeNumber,
      customerId,
      companyName,
      ipAddress,
      expressApi,
    } = this.props;



    return (
      <div>
        {this.renderContent(
          disclaimer,
          sub,
          companyId,
          safeNumber,
          customerId,
          companyName,
          this.handleClick,
          this.handleDecline,
          this.completeSubmission,
          ipAddress,
          expressApi
        )}
      </div>
    );
  }
}

Disclaimer.propTypes = {
  disclaimer: PropTypes.object,
  sub: PropTypes.string,
  companyId: PropTypes.string,
  safeNumber: PropTypes.string,
  customerId: PropTypes.string,
  companyName: PropTypes.string,
  fetchDisclaimer: PropTypes.func,
  ipAddress: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  disclaimer: state.disclaimer,
  companyId: state.company.report.companyId,
  safeNumber: state.safeNumber.profile.registrationNumber,
  companyName: state.company.report.report.companySummary.businessName,
  ipAddress: state.disclaimer.ip.ip,
  expressApi: state.app.config.EXPRESS.URL,
});

export default connect(mapStateToProps, { fetchDisclaimer })(Disclaimer);
