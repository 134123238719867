import React, { Component } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Form } from "formik";

import { searchCompanies } from "../actions/searchActions";

import Button from "./core/Button";
import CountrySelector from './core/CountrySelector'
import { InputField } from "./core/FormComponents";
import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "./styled/DisplayAttribute";

import * as Yup from "yup";

import { groupCountriesByOnlineStatus } from "../utils/countrySelectorOptions";
import { ILLEGAL_CHARACTERS } from "../utils/regexConstants";
import { usStatePackages } from "../utils/usStatePackages";

import memoize from "lodash/memoize";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;

  ${Attribute} {
    align-items: center;
  }

  h6 {
    text-align: left;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 4px;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;

  button {
    width: 20%;
    font-size: 1.6rem;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

const countryGroups = memoize(groupCountriesByOnlineStatus);

export class ParentSearch extends Component {
  
  state = {
    countries: this.props.country ? [this.props.country] : []
  }
  

  handleCountryChange = value => {
    this.setState(
      { countries: value },
      this.props.onChange("countries", value)
    );
  };

  isControlledProp(propName) {
    return this.props[propName] !== undefined;
  }

  getState() {
    return Object.keys(this.state).reduce((state, key) => {
      state[key] = this.isControlledProp(key)
        ? this.props[key]
        : this.state[key];
      return state;
    }, {});
  }
  
  render() {
    const { t, i18n, addButton, searchableCountries } = this.props;

    const validationSchema = () =>
      Yup.object().shape({
        companyName: Yup.string()
          .trim()
          .uppercase()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          })
          .required("Company Name Required"),
      });

    return (
      <Formik
        initialValues={{
          countries: this.props.country,

        }}
        onSubmit={(values) => {
          this.props.searchCompanies(
            values.countries,
            values.companyName,
          );
        }}
        validationSchema={validationSchema()}
      >
        <Form>
          <DetailsContainer>
            <AttributeRow
              width={100}
              name={<label>Country</label>}
              value={
                <CountrySelector 
                  className="country-selector"  
                  value={this.getState().countries || []}
                  currentLocation={this.props.country}
                  onChange={this.handleCountryChange}
                  searchableCountries={[
                    ...countryGroups({
                      countries: searchableCountries,
                      t,
                      userLanguage: i18n.language
                    })
                  ]} 
                />
              // <InputField name="countries" />
            }
            />
            <AttributeRow
              width={100}
              name={<label>Company Name</label>}
              value={<InputField name="companyName" />}
            />
            
          </DetailsContainer>
          <ButtonBar>
            {addButton}
            <Button variant="success" type="submit">
              Search
            </Button>
          </ButtonBar>
        </Form>
      </Formik>
    );
  }
}

ParentSearch.propTypes = {
  searchCompanies: PropTypes.func,
  searchCompleted: PropTypes.object,
  country: PropTypes.string,
};

const mapStateToProps = (state) => ({
  country: state.user.info.country,
  searchCompleted: state.search,
});

export default compose(
  withTranslation(["global", "search"]),
  connect(mapStateToProps, { searchCompanies })
)(ParentSearch);
