import { 
  FETCH_SAFE_NUMBER,
  FETCH_SAFE_NUMBER_SUCCESS,  
  FETCH_REPORT_BY_SAFE_NUMBER, 
  FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL,
  FETCH_SAFE_NUMBER_ERROR
} from '../actions/types';

const initialState = {
    profile:{
      contact:{
        firstName:"",
        surname:""
      },
      registrationNumber: "",
    },
    company:{
      content:[
        {
          id:"",
        },
      ],
    },
    profileLoading: false,
    companyLoading: false,
    error: false,
}


export default function( state = initialState, action){
  switch(action.type){
    case FETCH_SAFE_NUMBER:
      return {
        ...state,
        profileLoading: true,
      }
    case FETCH_SAFE_NUMBER_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profile: action.payload
      }
    case FETCH_REPORT_BY_SAFE_NUMBER:
      return {
        ...state,
        companyLoading: true
      }
    case FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL:
      return {
        ...state,
        company: action.payload,
        companyLoading: false
      }
    case FETCH_SAFE_NUMBER_ERROR:
      return {
        ...state,
        error: true,
      }
    default:
      return state;
  }
}