import React from "react";
import { withTheme } from "styled-components";
import Gauge from "./Gauge";
import { getUpdateFrequency } from "../report/utils/score";

export const UpdatedGauge = ({ updatedAt, theme }) => {
  const daysSinceUpdate = getUpdateFrequency(updatedAt)
  

  return (
    <Gauge
      width={80}
      height={60}
      colours={[theme.veryHighRisk, theme.highRisk, theme.moderateRisk, theme.lowRisk, theme.veryLowRisk]}
      data={[20, 20, 20, 20, 20]}
      radianAngle={needleAngle[daysSinceUpdate]}
    />
  );
};

const needleAngle = {
  A: Math.PI * .125,
  B: Math.PI * .3,
  C: Math.PI * .5,
  D: Math.PI * .7,
  E: Math.PI * .875,
};

export default withTheme(UpdatedGauge);
