import { 
  FETCH_PAYMENT, 
  FETCH_PAYMENT_SUCCESSFUL, 
  FETCH_PAYMENT_ERROR,
  FETCH_TRADE_REFERENCES,
  FETCH_TRADE_REFERENCES_SUCCESSFUL,
  FETCH_TRADE_REFERENCES_ERROR, 
  FETCH_BUSINESS_EXPENDITURES, 
  FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL,
  FETCH_BUSINESS_EXPENDITURES_ERROR, 
  FETCH_CODAT_COMPANY,
  FETCH_CODAT_COMPANY_SUCCESSFUL,
  FETCH_CODAT_COMPANY_ERROR 
} from '../actions/types';

const initialState = {
  payment: [],
  paymentsLoading: false,
  paymentsError: false,
  references: [],
  referencesLoading: false,
  referencesError: false,
  expenditures: [],
  expendituresLoading: false,
  expendituresError: false,
  codat:[],
  codatLoading: false,
  codatError: false,
}

export default function( state = initialState, action){
  switch(action.type){
    case FETCH_PAYMENT:
      return {
        ...state,
        paymentsLoading: true
      }
    case FETCH_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        payment: action.payload,
        paymentsLoading: false
      }
    case FETCH_PAYMENT_ERROR:
      return {
        ...state,
        paymentsLoading: false,
        paymentsError: true,
      }
    case FETCH_TRADE_REFERENCES:
      return {
        ...state,
        referencesLoading: true,
      }
    case FETCH_TRADE_REFERENCES_SUCCESSFUL:
      return {
        ...state,
        references: action.payload,
        referencesLoading: false
      }
    case FETCH_TRADE_REFERENCES_ERROR:
      return {
        ...state,
        referencesLoading: false,
        referencesError: true
      }
    case FETCH_BUSINESS_EXPENDITURES:
      return {
        ...state,
        expendituresLoading: true,
      }
    case FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL:
      return {
        ...state,
        expenditures: action.payload,
        expendituresLoading: false,
      }
    case FETCH_BUSINESS_EXPENDITURES_ERROR:
      return {
        ...state,
        expendituresLoading: false,
        expendituresError: true,
      }
      case FETCH_CODAT_COMPANY:
      return {
        ...state,
        codatLoading: true,
      }
    case FETCH_CODAT_COMPANY_SUCCESSFUL:
      return {
        ...state,
        codatLoading: false,
        codat: action.payload
      }
    case FETCH_CODAT_COMPANY_ERROR:
      return {
        ...state,
        codatLoading: false,
        codatError: true,
      }
    default:
      return state;
  }
}