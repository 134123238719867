import React from "react"
import { Typography } from "@creditsafe/ui-components"


export const CardTitle = ({ title, dataTestId }) => {
  return (
    <Typography variant="h4" font="secondary" component="h3" data-testid={dataTestId}>
      {title}
    </Typography>
  );
};



export default CardTitle