import { withTheme } from "styled-components";
import { connect } from "react-redux";

export const mapStateToProps = state => ({
  width: state.app.windowWidth,
});


const Hidden = ({
  width,
  theme,
  smDown,
  smUp,
  mdDown,
  mdUp,
  lgDown,
  children,
}) => {
  if (smDown) {
    return width >= theme.breakpointSm ? children : null;
  }
  if (smUp) {
    return width < theme.breakpointSm ? children : null;
  }
  if (mdDown) {
    return width >= theme.breakpointMd ? children : null;
  }
  if (mdUp) {
    return width < theme.breakpointMd ? children : null;
  }
  if (lgDown) {
    return width >= theme.breakpointLg ? children : null;
  }
  return null;
};

export default connect(mapStateToProps, null)(withTheme(Hidden));
