import { theme as globalTheme } from "@creditsafe/ui-components";

export const theme = {
  ...globalTheme,

  veryHighRisk: globalTheme.redPrimary,
  highRisk: globalTheme.amber,
  moderateRisk: globalTheme.yellow,
  lowRisk: globalTheme.green3,
  veryLowRisk: globalTheme.green4,

  active: globalTheme.green4,
  nonactive: "#ff1e26",
  nonActive: "#ff1e26",
  inactive: "#ff1e26",
  NonActive: "#ff1e26",
  pending: globalTheme.redPrimary,

  percentageBarRed: "#e61610",
  percentageBarOrange: "#ffa834",
  percentageBarGreen1: "#72bb53",
  percentageBarGreen2: "#4c7a34",

  logoRed: "#E41F13",
  logoGray: "#D3D3D3",

  excellent: globalTheme.green4,
  veryGood: globalTheme.green3,
  good: globalTheme.yellow,
  fair: globalTheme.amber,
  bad: globalTheme.redPrimary,
  none: "transparent",
  creditRatioExcellent: globalTheme.blue3,
  creditRatioGood: globalTheme.blue2,
  creditRatioBad: globalTheme.blue1,
  unknown: globalTheme.greyLight1,
  NA: globalTheme.greyAdobe1,
  na: globalTheme.greyAdobe1,
  other: globalTheme.amber,

  recentBankruptcy: globalTheme.redPrimary,
  veryHighRiskUs: globalTheme.redPrimary,
  lowRiskBe: globalTheme.green3,
  veryHighRiskBe: globalTheme.redPrimary,
  released: globalTheme.greyAdobe1,
  notRatedOrange: globalTheme.amber,
  invisible: "rgba(0, 0, 0, 0)",
  turquoise: "rgba(82, 170, 197, 1)",
  orange: "rgba(248, 148, 6, 1)",
};
