import React from "react";
import styled from "styled-components";
import { identity } from "../utils/identity";
import { TimesCircle } from "../assets/icons/fontAwesomeIcons";

export const ErrorMessageContainer = styled.div`
  align-self: center;
  background-color: ${({ theme }) => theme.red1};
  display: flex;
  border-radius: 5px;
  border: 2px solid ${({ theme }) => theme.red2};
  padding: 10px;
  justify-content: center;
  color: ${({ theme }) => theme.white};
  font-weight: ${({ theme }) => theme.fontWeightBold};
  margin-top: 10px;
  align-items: center;

  p {
    padding: 0px 5px;
  }
  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    margin-top: 0px;
    width: 600px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  button {
    margin: 10px 0px;
  }
`;

export const ErrorMessage = ({ errorMessage, t = identity, className }) => {
  return (
    <ErrorContainer className={className}>
      <ErrorMessageContainer>
        <TimesCircle />
        <p role="alert">{t(errorMessage)}</p>
      </ErrorMessageContainer>
    </ErrorContainer>
  );
};
