import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { Formik, Form } from "formik";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { InlineLoader } from "../core/Loading";
import { InputField } from "../core/FormComponents";
import Button from "../core/Button";

import ParentSearch from "../ParentSearch"
import SearchWithFilters from "../SearchWithFilters";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

import { resetSearch } from "../../actions/searchActions";

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const UltimateTrigger = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-left: 5px;
  }
  :hover {
    cursor: pointer;
  }
`;

function AddImmediateParent({
  hasImmediate,
  sameUltimate,
  close,
  companyReport,
  groupStructure,
  email,
  mycID,
  companyId,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  accessToken,
  language,
  report,
  resetSearch,
  searched,
  isSearching,
  expressApi,
  userReport,
  safeNumber,
  fullName,
  boId,
  profile,
  searchableCountries
}) {
  const [selectedCompany, setSelectedCompany] = useState({});
  const [toggled, setToggled] = useState(false);
  const [searchSelected, setSearchSelected] = useState(false);

  const rename = ({
    safeNumber: safeNo,
    registrationNumber: regNo,
    ...rest
  }) => ({
    safeNo,
    regNo,
    ...rest,
  });

  useEffect(() => {
    if (hasImmediate && hasImmediate.name !== "") {
      const newImmediateCompany = rename(hasImmediate);
      setSelectedCompany(newImmediateCompany);
    }
  }, [hasImmediate]);

  const handleClick = (company) => {
    setSelectedCompany(company);
    setSearchSelected(true);
  };

  const handleGoBack = () => {
    setSelectedCompany({});
    setSearchSelected(false);
    setToggled(false);
  };

  const handleToggle = () => {
    !toggled
      ? setSelectedCompany(rename(sameUltimate))
      : setSelectedCompany(rename(hasImmediate));

    toggled && selectedCompany.name === sameUltimate.name
      ? setSelectedCompany({})
      : setSelectedCompany(rename(sameUltimate));

    setToggled(!toggled);
  };


  const setSameUltimate = (
    <UltimateTrigger>
      Same as Ultimate Holding
      <input type="checkbox" checked={toggled} onChange={handleToggle} />
    </UltimateTrigger>
  );
  
  const deleteButton = hasImmediate && !toggled && !searchSelected;

  const ultimateParent =  get(groupStructure, "ultimateParent")

  const detImmediateParent = get(report.groupStructure, "immediateParent")
  const detUltimateParent = get(report.groupStructure, "ultimateParent")

  const detUltimateName = get(detUltimateParent, "name", "")
  const detUltimateSafeNumber = get(detUltimateParent, "safeNumber", "")
  const detUltimateCompanyId = get(detUltimateParent, "id", "")
  const detUltimateCountry = get(detUltimateParent, "country", "")
  const detImmediateName = get(detImmediateParent, "name", "")
  const detImmediateSafeNumber = get(detImmediateParent, "safeNumber", "")
  const detImmediateCompanyId = get(detImmediateParent, "id", "")
  const detImmediateCountry = get(detImmediateParent, "country", "")

  const ultimateName = get(ultimateParent, "name", "")
  const ultimateSafeNumber = get(ultimateParent, "safeNumber", "")
  const ultimateCompanyId = get(ultimateParent, "id", "")
  const ultimateCountry = get(ultimateParent, "country", "")

  const editButton = searchSelected ? "Go Back" : "Update"

  const detValueCheck = (newValue, originalValue) => newValue === "" ? originalValue : newValue 


  return (
    <>
      {!isEmpty(selectedCompany) ? (
        <Formik
          enableReinitialize
          initialValues={{
            accessToken: accessToken,
            language: language,
            report: report,
            companyId: mycID,
            userid: email,
            companyReport: companyReport,
            safeNumber: safeNumber,
            ultimateParent: get(groupStructure, "ultimateParent"),
            parentName: selectedCompany.name,
            parentSafeNumber: selectedCompany.safeNo,
            parentCompanyId: selectedCompany.id,
            parentRegistrationNumber: selectedCompany.regNo,
            parentCountry: selectedCompany.country,
            ultimateName: ultimateName,
            ultimateSafeNumber: ultimateSafeNumber,
            ultimateCompanyId: ultimateCompanyId,
            ultimateCountry: ultimateCountry
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await setReportLoading("immediateParent");
            await fetch(`${expressApi}/reports`, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + values.accessToken,
              },
              body: JSON.stringify({
                mycID: values.companyId,
                email: values.userid,
                report: values.report,
                reportjson: {
                  ...companyReport,
                  groupStructure: {
                    ultimateParent: values.ultimateParent,
                    immediateParent: {
                      country: values.parentCountry,
                      name: values.parentName,
                      safeNumber: values.parentSafeNumber,
                      registrationNumber: values.parentRegistrationNumber,
                      id: values.parentCompanyId,
                    },
                  },
                },
                reportupdated_at: new Date(),
                updated_at: new Date(),
                created_at: new Date(),
                verifiedstatus: "changed",
                language: values.language,
                detType: 'update_groupstructure',
                detInfo: {
                  myc_id: userReport.id,
                  safe_number: values.safeNumber,
                  company_id: values.companyId,
                  company_name: values.businessName,
                  immediate_holding_company_safenumber: detValueCheck(values.parentSafeNumber, detImmediateSafeNumber),
                  immediate_holding_company_ID: detValueCheck(values.parentCompanyId, detImmediateCompanyId),
                  immediate_holding_company_name: detValueCheck(values.parentName, detImmediateName),
                  immediate_holding_company_address: detValueCheck(values.parentCountry, detImmediateCountry),
                  immediate_holding_company_country: detValueCheck(values.parentCountry, detImmediateCountry),
                  ultimate_holding_company_safenumber: detValueCheck(values.ultimateSafeNumber, detUltimateSafeNumber),
                  ultimate_holding_company_ID: detValueCheck(values.ultimateCompanyId, detUltimateCompanyId),
                  ultimate_holding_company_name: detValueCheck(values.ultimateName, detUltimateName),
                  ultimate_holding_company_address: detValueCheck(values.ultimateCountry, detUltimateCountry),
                  ultimate_holding_company_country: detValueCheck(values.ultimateCountry, detUltimateCountry),
                  status:"fail",
                  delete_flag: "N",
                  user_id: userReport.userid,
                  user_verified : userReport.userstatus,
                  customer_id: profile.customer.id
                },
                bo_id: boId,
                bo_name: fullName,
              }),
            })
              .then(() => setSubmitting(false))
              .then(() => fetchReportJson(companyId, "immediateParent"))
              .then(() => fetchUserReport(companyId))
              .then(() => resetSearch())
              .then(() => close())
              .catch((err) => console.log(err));
          }}
        >
          {({ handleSubmit }) => (
            <Form>
              <AttributeRow
                width={100}
                name={<label>Business Name</label>}
                value={<InputField name={`parentName`} disabled />}
              />
              <AttributeRow
                width={100}
                name={<label>Safe Number</label>}
                value={<InputField name={`parentSafeNumber`} disabled />}
              />
              <AttributeRow
                width={100}
                name={<label>Registration Number</label>}
                value={
                  <InputField name={`parentRegistrationNumber`} disabled />
                }
              />
              <AttributeRow
                width={100}
                name={<label>Country</label>}
                value={<InputField name={`parentCountry`} disabled />}
              />
              <ButtonBar>
                {toggled ? (
                  setSameUltimate
                ) : (
                  <Button 
                    variant="danger" 
                    type="button" 
                    data-testid={`immediate-cancel-button`} 
                    onClick={handleGoBack}
                  >
                    {editButton}
                  </Button>
                )}
                {!deleteButton ? (
                  <Button 
                    variant="success" 
                    type="submit"
                    data-testid={`add-immediate-submit-button`}   
                  >
                    Submit
                  </Button>
                ) : (
                    <UltimateTrigger>
                      Same as Ultimate Holding
                      <input
                        type="checkbox"
                        checked={toggled}
                        onChange={handleToggle}
                      />
                    </UltimateTrigger>
                )}
              </ButtonBar>
            </Form>
          )}
        </Formik>
      ) : (
        <>
        <SearchWithFilters addButton={setSameUltimate}/>
          {/* <ParentSearch 
            addButton={setSameUltimate} 
            searchableCountries={searchableCountries} 
            
          /> */}
          {isSearching ? (
            <InlineLoader
              isLoading={isSearching}
              delayMS={0}
              loadingText={"Searching..."}
            />
          ) : (
            <PaginatedTable
              headings={[
                { key: "name", text: "Name" },
                { key: "address.simpleValue", text: "Address" },
              ]}
              data={searched.results.companies}
              pageSize={PAGE_SIZE_MEDIUM}
              onClick={handleClick}
              actions={
                <><p>Unable to find the company you're looking for?</p><p>Please refine the search criteria above or speak to your account manager for help adding a company to our database</p></>                      
              }
            />
          )}
        </>
      )}
    </>
  );
}

AddImmediateParent.propTypes = {
  searched: PropTypes.object,
  resetSearch: PropTypes.func,
  searchableCountries: PropTypes.array,
};

const mapStateToProps = (state) => ({
  searched: state.search,
  isSearching: state.search.isSearching,
  searchableCountries: state.user.searchableCountries,
});

export default compose(
  withTranslation(["global", "search"]),
  connect(mapStateToProps, { resetSearch })
)(AddImmediateParent);
