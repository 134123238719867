export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const FETCH_USER_REPORT = "FETCH_USER_REPORT";
export const FETCH_USER_REPORT_SUCCESS = "FETCH_USER_REPORT_SUCCESS";

export const RESET_REPORT_JSON = "RESET_REPORT_JSON";
export const RESET_REPORT_JSON_SUCCESS = "RESET_REPORT_JSON_SUCCESS"
export const RESET_REPORT_JSON_ERROR = "RESET_REPORT_JSON_ERROR"

export const FETCH_COMPANY_REPORT = "FETCH_COMPANY_REPORT";
export const FETCH_COMPANY_REPORT_SUCCESS = "FETCH_COMPANY_REPORT_SUCCESS";
export const FETCH_COMPANY_REPORT_ERROR = "FETCH_COMPANY_REPORT_ERROR";

export const FETCH_SAFE_NUMBER = "FETCH_SAFE_NUMBER";
export const FETCH_SAFE_NUMBER_SUCCESS = "FETCH_SAFE_NUMBER_SUCCESS";
export const FETCH_SAFE_NUMBER_ERROR = "FETCH_SAFE_NUMBER_ERROR";

export const FETCH_REPORT_BY_SAFE_NUMBER = "FETCH_REPORT_BY_SAFE_NUMBER"
export const FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL = "FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL"

export const FETCH_REPORT_JSON = "FETCH_REPORT_JSON";
export const FETCH_REPORT_JSON_SUCCESS = "FETCH_REPORT_JSON_SUCCESS";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";

export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const FETCH_PAYMENT_SUCCESSFUL = "FETCH_PAYMENT_SUCCESSFUL";
export const FETCH_PAYMENT_ERROR = "FETCH_PAYMENT_ERROR";

export const FETCH_TRADE_REFERENCES = "FETCH_TRADE_REFERENCES";
export const FETCH_TRADE_REFERENCES_SUCCESSFUL =
  "FETCH_TRADE_REFERENCES_SUCCESSFUL";
export const FETCH_TRADE_REFERENCES_ERROR =
  "FETCH_TRADE_REFERENCES_ERROR";

export const FETCH_BUSINESS_EXPENDITURES = "FETCH_BUSINESS_EXPENDITURES";
export const FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL =
  "FETCH_BUSINESS_EXPENDITURES_SUCCESSFUL";
export const FETCH_BUSINESS_EXPENDITURES_ERROR =
  "FETCH_BUSINESS_EXPENDITURES_ERROR";

export const FETCH_CODAT_COMPANY = "FETCH_CODAT_COMPANY";
export const FETCH_CODAT_COMPANY_SUCCESSFUL = "FETCH_CODAT_COMPANY_SUCCESSFUL";
export const FETCH_CODAT_COMPANY_ERROR = "FETCH_CODAT_COMPANY_ERROR";

export const FETCH_APP = "FETCH_APP";
export const EDIT_MODE = "EDIT_MODE";

export const SEARCH_COMPANIES = "SEARCH_COMPANIES";
export const SEARCH_COMPANIES_SUCCESSFUL = "SEARCH_COMPANIES_SUCCESSFUL";

export const RESET_SEARCH = "RESET_SEARCH";
export const WINDOW_RESIZE = "WINDOW_RESIZE";

export const FETCH_DISCLAIMER = "FETCH_DISCLAIMER";
export const FETCH_DISCLAIMER_SUCCESS = "FETCH_DISCLAIMER_SUCCESS";
export const FETCH_DISCLAIMER_ERROR = "FETCH_DISCLAIMER_ERROR";

export const FETCH_IP_ADDRESS = "FETCH_IP_ADDRESS";
export const FETCH_IP_ADDRESS_SUCCESS = "FETCH_IP_ADDRESS_SUCCESS";
export const FETCH_IP_ERROR = "FETCH_IP_ERROR";

export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";

export const FETCH_PROVENIR_HISTORY = "FETCH_PROVENIR_HISTORY";
export const FETCH_PROVENIR_HISTORY_SUCCESS = "FETCH_PROVENIR_HISTORY_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";

export const FETCH_SEARCHABLE_COUNTRIES = "FETCH_SEARCHABLE_COUNTRIES";
export const FETCH_SEARCHABLE_COUNTRIES_SUCCESS = "FETCH_SEARCHABLE_COUNTRIES_SUCCESS";
export const FETCH_SEARCHABLE_COUNTRIES_ERROR = "FETCH_SEARCHABLE_COUNTRIES_ERROR";

export const FETCH_NAV_ACCOUNT = "FETCH_NAV_ACCOUNT";
export const FETCH_NAV_ACCOUNT_SUCCESS = "FETCH_NAV_ACCOUNT_SUCCESS";
export const FETCH_NAV_ACCOUNT_FAILURE = "FETCH_NAV_ACCOUNT_FAILURE";

export const FETCH_NAV_AUTH_TOKEN = "FETCH_NAV_AUTH_TOKEN";
export const FETCH_NAV_AUTH_TOKEN_SUCCESS = "FETCH_NAV_AUTH_TOKEN_SUCCESS";
export const FETCH_NAV_AUTH_TOKEN_FAILURE = "FETCH_NAV_AUTH_TOKEN_FAILURE";

export const FETCH_NAV_CTA = "FETCH_NAV_CTA";
export const FETCH_NAV_CTA_SUCCESS = "FETCH_NAV_SUCCESS";
export const FETCH_NAV_CTA_FAILURE = "FETCH_NAV_FAILURE";

export const CREATE_NAV_ACCOUNT = "CREATE_NAV_ACCOUNT";
export const CREATE_NAV_FAILURE = "CREATE_NAV_FAILURE";

export const UPDATE_NAV_ACCOUNT = "UPDATE_NAV_ACCOUNT";
export const UPDATE_NAV_FAILURE = "UPDATE_NAV_FAILURE";

export const CREATE_OR_UPDATE_NAV_SUCCESS = "CREATE_OR_UPDATE_NAV_SUCCESS";
