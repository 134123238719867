import React from "react";
import { Room as MyLocation } from "@material-ui/icons";
import styled from "styled-components";

export const OnlineIndicator = ({ currentLocation, isOnline, item }) => {
  if (currentLocation && currentLocation === item) {
    return <MyLocation className="location" />;
  }

  return isOnline === undefined ? null : (
    <OnlineIndicatorStyle onlineStatus={isOnline} />
  );
};

export const OnlineIndicatorStyle = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${({ theme, onlineStatus }) =>
    onlineStatus ? theme.greenPrimary : theme.greyAdobe1};
  border-radius: 50%;
  margin-top: 0.75rem;
  position: absolute;
  right: 10px;
`;

export default OnlineIndicator;
