import React from "react";
import styled, { css } from "styled-components";

export const common = css`
  box-sizing: border-box;
  padding: 0.8rem 1.4rem 0.8rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.3;
  width: 100%;
  background-color: ${props =>
    props.disabled ? props.theme.greyLight3 : props.theme.white};
  border: ${props => (props.variant === "outlined" ? "1px" : "0")} solid
    ${props => props.theme.greyLight4};
  ${props => (props.variant === "outlined" ? "border-radius: 0.4rem;" : "")}
  &:hover:enabled {
    background-color: ${props => props.theme.white};
  }
  &:focus {
    outline: 0;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Input = styled(({ field, form, variant, inputRef, ...props }) => (
  <input {...field} {...props} ref={inputRef} />
))`
  ${common}
`;

export default Input;
