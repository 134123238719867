import React from "react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import Card from "../core/Card";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

export const formatTableData = branchOfficeName => {
  if (!branchOfficeName) {
    return [];
  }

  return branchOfficeName.reduce((r, branchClass) => {
    const branchNameText = branchClass.name;
    const safeNumberText = branchClass.safeNumber;
    const streetText = branchClass.address.street;
    const cityText = branchClass.address.city;
    const stateText = branchClass.address.province;
    const zipText = branchClass.address.postalCode

    r = r.concat(
      [{
        name: branchNameText.toLowerCase(),
        safeNumber: safeNumberText,
        street: streetText.toLowerCase(),
        city: cityText.toLowerCase(),
        province: stateText,
        postalCode: zipText.toLowerCase()
      }]
    )

    return r;
  }, []);
};

export const branches = ({ report, options = {}, t }) => {
  const branchData = get(report, "additionalInformation.branchOffices");
  const data = sortBy(formatTableData(branchData), "name");
  return (
    <Card scrollContent title="BranchLocations" data-testid={"branchLocationsCard"}>
      <PaginatedTable
        data-testid={"branchLocationsTable"}
        headings={[
          { key: "name", text: "Name" },
          { key: "safeNumber", text: "Safe Number" },
          { key: "street", text: "Street Address" },
          { key: "city", text: "City" },
          { key: "province", text: "State" },
          { key: "postalCode", text: "Zip Code" },
          
        ]}
        data={data}
        pageSize={PAGE_SIZE_MEDIUM}
        options={options}
      />
    </Card>
  );
};

export default branches;