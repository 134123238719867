import styled, { css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const linkStyles = css`
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: black;
  }
`;

export const Link = styled(RouterLink)`
  ${linkStyles}
`;

export const Anchor = styled.a`
  ${linkStyles}
`;
