import React from "react";
import styled from "styled-components";

import InfoTile from "../InfoTile";
import { InlineLoader } from "../core/Loading";
import StatusColorTwo from "../report/utils/StatusColorTwo";
import StatusColorTwoReversed from "../report/utils/StatusColorTwoReversed";

import { displayValueOrSeparator } from "../../utils/string";
import Currency from "../../utils/Currency";

import uniq from "lodash/uniq";

export const SummaryContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.theme.spacingUnit}px 0;

  ${InfoTile} {
    width: 50%;
    height: 65px;
    margin: 1rem 0;
    > div {
      width: 80%;
      > div {
        > canvas {
          margin-top: -20px;
        }
      }
    }
    :nth-child(odd) {
      border-right: 1px solid ${(props) => props.theme.greyAdobe1};
    }
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpointTablet}px) {
    ${InfoTile} {
      width: 33%;
      border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      :nth-child(3n + 3) {
        border-right: none;
      }
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpointLg}px) {
    flex-wrap: nowrap;
    ${InfoTile} {
      max-width: 200px;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :last-child {
        border-right: none;
        margin-right: 4px;
      }
    }
  }
`;

export const HeaderText = styled.span`
  width: 100%;
`;

export const PaymentSummary = ({ isLoading, payments, companyReport}) => {
    
  const pendingPayments = payments.filter(
    (payment) =>
      payment.status === "pending" ||
      payment.status === null ||
      payment.status === ""
  );

  const rejectedPayments = payments.filter(
    (payment) => payment.status === "rejected"
  );

  const paymentLength = payments.length >= 1 ? payments.length : "0"

  const amountRejected =
    rejectedPayments.length >= 1 ? rejectedPayments.length : "0";

  const verifiedPayments = payments.filter(
    (payment) => payment.status === "verified"
  );

  const totalPopValue = verifiedPayments.reduce(
    (verifiedPayment, value) => verifiedPayment + value.value, 0
  )

  const totalPopValueDollars = displayValueOrSeparator(totalPopValue, (value) => (
    <Currency value={value} code="USD" truncate={false} />
  ))


  const amountVerified =
    verifiedPayments.length >= 1 ? verifiedPayments.length : "0";

  const amountPending =
    pendingPayments.length >= 1 ? pendingPayments.length : "0";

  const paymentPayeeId = payments.map((paymentPayee) => {
    return paymentPayee.payeeid;
  });

  const uniqueCompanies = uniq(paymentPayeeId);

  const amountUnique =
    uniqueCompanies.length >= 1 ? uniqueCompanies.length : "0"

  return isLoading ? (
    <InlineLoader
      isLoading={isLoading}
      delayMS={0}
      loadingText={"Updating..."}
    />
  ) : (
    <SummaryContainer>
      <InfoTile
        title="Submitted Payments"
        id="walkMeProofsSub"
        status={StatusColorTwo(paymentLength)}
        children={paymentLength}
      />
      <InfoTile
        title="Pending Payments"
        status={StatusColorTwo(amountPending)}
        children={amountPending}
      />
      <InfoTile
        title="Verified Payments"
        status={StatusColorTwo(amountVerified)}
        children={amountVerified}
      />
      <InfoTile
        title="Rejected Payments"
        status={StatusColorTwoReversed(amountRejected)}
        children={amountRejected}
      />
      <InfoTile
        title="Unique Companies"
        id="walkMeProofsUnique"
        status={StatusColorTwo(amountUnique)}
        children={amountUnique}
      />
      <InfoTile
        title="Total Verified Value"
        id="walkMeProofsTotal"
        status={StatusColorTwo(totalPopValue)}
      >
        {totalPopValueDollars}
      </InfoTile>
    </SummaryContainer>
  );
};

export default PaymentSummary;
