import React from "react";
import partial from "lodash/partial";
import isNil from "lodash/isNil";
import get from "lodash/get";
import { Typography, Table as CoreTable } from "@creditsafe/ui-components";
import styled, { css } from "styled-components";

/**
 * Render a table with a header title
 * @param {*} title The translated title
 * @param {*} headings An array of key value pairs, where the key is the property name and the value is the associated translated display text
 * @param {*} data An array of data to render
 * @param {*} formatters An object containing functions that will be applied to a column key
 */

export const BasicTable = ({
  title,
  headings,
  data,
  rowTestIds = [],
  className,
  formatters = {},
  onClick,
  displayHeader = true,
  dynamicHeaders,
  rowStyle = () => "",
  ...rest
}) => {
  if (!data || !headings) {
    return null;
  }

  const displayedHeaders = dynamicHeaders
    ? headings.filter(obj => data.filter(e => obj.key in e).length > 0)
    : headings;

  return (
    <TableContainer className={className}>
      {title && <h1>{title}</h1>}

      <Table data-testid={rest["data-testid"]}>
        {displayHeader && <TableHeader headings={displayedHeaders} />}
        <Table.Body>
          {data.map((dataItem, index) => (
            <TableRow
              key={index}
              headings={displayedHeaders}
              formatters={formatters}
              onClick={onClick}
              rowTestIds={rowTestIds}
              dataItem={dataItem}
              index={index}
              rowStyle={rowStyle}
            />
          ))}
        </Table.Body>
      </Table>
    </TableContainer>
  );
};

export const TableHeader = ({ headings }) => (
  <Table.Head>
    <Table.Row>
      {headings.map((heading, i) => (
        <Typography key={heading.key + "_" + i} variant="h6" component="th">
          {heading.text}
        </Typography>
      ))}
    </Table.Row>
  </Table.Head>
);

export const TableRow = ({
  headings,
  formatters,
  onClick,
  rowTestIds,
  dataItem,
  index,
  rowStyle,
}) => {
  const optionalDataTestId = !!rowTestIds[index]
    ? { "data-testid": rowTestIds[index] }
    : {};

  return (
    <Table.Row
      className={rowStyle(dataItem)}
      {...optionalDataTestId}
      onClick={onClick && (() => onClick(dataItem))}
    >
      {headings.map(partial(renderCell, dataItem, formatters))}
    </Table.Row>
  );
};

const renderCell = (dataItem, formatters, heading, idx) => {
  const key = heading.key;
  const formatter = formatters[key];
  let cellValue = get(dataItem, key);
  if (formatter) {
    cellValue = formatter(cellValue, dataItem);
  }
  const content = isNil(cellValue) ? "-" : cellValue;
  return (
    <Table.Cell
      key={`${key}_${idx}`}
      data-testid={`basic-table-cell-${key}-${idx}`}
    >
      {content}
    </Table.Cell>
  );
};

export const TableContainer = styled.div`
  flex-grow: 2;
  overflow: auto;

  h1 {
    font-size: ${props => props.theme.fsH3};
  }
`;

const tableStyle = type => {
  switch (type) {
    case "financial":
      return `
      td,
      thead th {
        text-align: right;
        white-space: nowrap;
      }
      tbody th {
        width: 300px;
      }
      tbody td {
        flex-grow: 1;
      }`;
    default:
      return ``;
  }
};

export const Table = styled(CoreTable)`
  ${props => tableStyle(props.type)};

  tr {
    cursor: ${props => (props.onClick ? "pointer" : "auto")};
  }

  td {
    text-transform: capitalize;
  }
`;

export default styled(BasicTable)`
  ${({ scrollContent = false }) =>
    scrollContent
      ? css`
          overflow: auto hidden;
        `
      : css`
          overflow: auto;
        `};

  .offlineReport {
    background: ${props => props.theme.greyLight1};
    border-top: 1.5px solid ${props => props.theme.greyLight3};
    border-bottom: 1.5px solid ${props => props.theme.greyLight3};
  }

  table {
    width: ${props => props.width || 100}%;

    tr {
      ${props =>
        props.columnWidths
          ? props.columnWidths.map(c => {
              return css`
                th:nth-child(${c.index}) {
                  width: ${c.width};
                }
              `;
            })
          : null}
      ${props =>
        props.wrapText
          ? props.wrapText.map(w => {
              return css`
                td:nth-child(${w.index}) {
                  word-wrap: break-word;
                  white-space: pre-wrap;
                  ${isDesktop =>
                    isDesktop &&
                    css`
                      @media only screen and (min-width: ${props =>
                          `${props.theme.breakpointMd}px`}) {
                        div {
                          display: inline-block;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden;
                          min-width: 95%;
                          width: 200px;
                          max-width: 1000px;
                        }
                      }
                    `};
                }
              `;
            })
          : null}
        td {
        text-overflow: ellipsis;
      }
    }
  }
`;
