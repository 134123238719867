import React from "react";
import styled from "styled-components";
import selectArrow from "../../assets/img/icons/select-arrow.svg";
import { common } from "./Input";

// https://github.com/filamentgroup/select-css

export default styled(({ field, form, ...props }) => (
  <select {...field} {...props} />
)).attrs(props => ({
  variant: props.variant || "outlined",
}))`
  ${common}
  display: block;
  font-family: inherit;
  color: #444;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
    for the icon, if you want to change the color, be sure to use '%23' instead of '#', since it's a url. You can also swap in a different svg icon or an external image reference
  */
  background-image: url("${selectArrow}"),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
  /* Hide arrow icon in IE browsers */

  &::-ms-expand {
    display: none;
  }

  /* Set options to normal weight */
  & option {
    font-weight: normal;
  }

  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir="rtl"] &,
  :root:lang(ar) &,
  :root:lang(iw) & {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }
`;
