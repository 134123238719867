import {
  FETCH_REPORT_JSON,
  FETCH_REPORT_JSON_SUCCESS,
  SET_REPORT_LOADING
} from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const fetchReportJson = (
  companyId,
  loadType,
  email,
  creditScore,
  creditLimit,
  boExpiry
) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;

  // const url = `http://localhost:3001/reports/merge/${companyId}/${email}/${creditScore}/${creditLimit}/${boExpiry}`;
  const url = `${api}/reports/merge/${companyId}/${email}/${creditScore}/${creditLimit}/${boExpiry}`;

  dispatch({ type: FETCH_REPORT_JSON, payload: { loadType } });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + accessToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((response) => response.json())
    .then((payload) =>
      dispatch({
        type: FETCH_REPORT_JSON_SUCCESS,
        payload: payload,
      })
    )
    .catch((err) => {
      console.log(err.message);
    });
};

export const setReportLoading = (loadType) => (dispatch) => {
  dispatch({
    type: SET_REPORT_LOADING,
    payload: { loadType },
  });
};
