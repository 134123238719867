import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_REPORT,
  FETCH_USER_REPORT_SUCCESS,
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_SEARCHABLE_COUNTRIES,
  FETCH_SEARCHABLE_COUNTRIES_SUCCESS,
  FETCH_SEARCHABLE_COUNTRIES_ERROR
} from "../actions/types";

import { mapCountries } from "../utils/mappers";

const initialState = {
  info: {},
  isAuthed: false,
  isLoading: false,
  error: false,
  report: {
    verified_at: "",
    verifiedstatus: "",
  },
  searchableCountries: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        info: action.payload,
        isAuthed: true,
      };
    case FETCH_USER_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        isLoading: false,
      };
    case FETCH_SERVICES:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        isLoading: false,
      };
    case FETCH_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true
      }
    case FETCH_SEARCHABLE_COUNTRIES:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_SEARCHABLE_COUNTRIES_SUCCESS:
      return {
        ...state,
        searchableCountries: mapCountries(action.payload),
        isLoading: false,
      }
    case FETCH_SEARCHABLE_COUNTRIES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    default:
      return state;
  }
}
