import {
  FETCH_DISCLAIMER,
  FETCH_DISCLAIMER_ERROR,
  FETCH_DISCLAIMER_SUCCESS,
  FETCH_IP_ADDRESS,
  FETCH_IP_ADDRESS_SUCCESS,
  FETCH_IP_ERROR
} from "../actions/types";

const initialState = {
  disclaimer: [],
  disclaimerError: false,
  ip: "",
  isLoading: false,
  ipError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DISCLAIMER:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DISCLAIMER_SUCCESS:
      return {
        ...state,
        disclaimer: action.payload,
        isLoading: false,
      };
    case FETCH_DISCLAIMER_ERROR:
      return {
        ...state,
        isLoading: false,
        disclaimerError: true,
      }
    case FETCH_IP_ADDRESS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_IP_ADDRESS_SUCCESS:
      return {
        ...state,
        ip: action.payload,
        isLoading: false,
      };
    case FETCH_IP_ERROR:
      return {
        ...state,
        isLoading: false,
        ipError: true,
      }
    default:
      return state;
  }
}
