import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { withTranslation } from "react-i18next";
import { SentimentVeryDissatisfied, Refresh } from "@material-ui/icons";
import cslogo from "../../assets/img/cslogo.svg";

const SomethingWentWrong = ({ t }) => {
  return (
    <Container>
      <img src={cslogo} alt="creditsafe" />
      <SentimentVeryDissatisfied style={{ fontSize: "5rem" }} />
      <h1>Something went wrong loading your report, please hit refresh below</h1>
      <h2>If this problem persists please contact your Creditsafe Account Manager</h2>
      <ButtonBar>
        <Button variant="neutral" onClick={() => window.location.reload()}>
          <Refresh />
        </Button>
      </ButtonBar>
    </Container>
  );
};

export const ButtonBar = styled.div`
  padding: 10px;
`;

const Container = styled.div`
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    width: 250px;
    margin-bottom: 3rem;
  }
`;

export default withTranslation()(SomethingWentWrong);
