import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import styled from "styled-components";

export const FooterBase = ({ country, className, telephone, t }) => (
  <footer className={className} data-testid="footer"></footer>
);

export const Footer = styled(FooterBase)`
  margin-top: 1rem;
  flex-shrink: 0;
  padding: 3rem 0 3rem 0;
  text-align: center;
  margin-left: 0;
  background-color: ${({ theme }) => theme.greyLight1};

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointMd}px`}) {
    margin-left: ${({ theme }) => theme.drawerWidth};
  }
`;

export const mapStateToProps = (state) => ({});

export default compose(withTranslation(), connect(mapStateToProps))(Footer);
