import React from "react";
import styled, { css } from "styled-components";
import { Typography } from "@creditsafe/ui-components";
import { Link } from "./core/Link";

export const BaseInfoTile = ({
  title,
  gauge,
  titleHelper,
  children,
  className,
  tileDescription,
  link,
  ...rest
}) => {
  return link ? (
    <Link
      to={link}
      data-testid={rest["data-testid"]}
      className={className}
      title={tileDescription ? tileDescription : null}
    >
      {title && (
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
      )}
      <div>{children}</div>
    </Link>
  ) : (
    <div
      data-testid={rest["data-testid"]}
      className={className}
      title={tileDescription ? tileDescription : null}
    >
      {title && (
        <Typography variant="h6" component="h3">
          {title} {titleHelper}
        </Typography>
      )}
      {children && (
        <ChildText className="info-tile-child">
          <div>{children}</div>
        </ChildText>
      )}
      {gauge && <div>{gauge}</div>}
    </div>
  );
};

export const InfoTile = styled(BaseInfoTile)`
  color: ${({ theme }) => theme.greyAdobe13};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  h3 {
    min-height: 30px;
    margin: 0;
    white-space: wrap;
    line-height: 1;
    font-size: ${({ theme }) => theme.fsBase};
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    white-space: wrap;
    width: fit-content;
    min-width: 50px;
    min-height: 50px;
    border-bottom: 4px solid;
    border-bottom-color: transparent;
    font-size: ${({ theme }) => theme.fsH2};
    font-weight: 200;
    padding: 0;

    ${({ theme, status }) =>
      status &&
      css`
        border-bottom-color: ${theme[status]};
      `}

    h3 {
      font-size: ${({ theme }) => theme.fsBase};
      text-align: center;
      min-height: 45px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpointMd}px) {
    margin-top: 0;

    h3 {
      margin: 0;
      font-size: ${({ theme }) => theme.fsSmall};
    }

    > div {
      font-size: ${({ theme }) => theme.fsH3};
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpointXXl}px) {
    h3 {
      font-size: ${({ theme }) => theme.fsBase};
    }

    > div {
      font-size: ${({ theme }) => theme.fsH2};
    }
  }
`;

export const ChildText = styled.div`
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  > div {
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;
  }
`;

export default InfoTile;
