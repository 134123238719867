import { FETCH_PROVENIR_HISTORY, FETCH_PROVENIR_HISTORY_SUCCESS } from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const fetchProvenirHistory = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.EXPRESS.URL;

  // const url = `http://localhost:3001/provenir/${companyId}`
  const url = `${api}/provenir/${companyId}`;

  dispatch({ type: FETCH_PROVENIR_HISTORY });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + accessToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((response) => response.json())
    .then((payload) =>
      dispatch({
        type: FETCH_PROVENIR_HISTORY_SUCCESS,
        payload: payload,
      })
    )
    .catch((err) => {
      console.log(err.message);
    });
};
