export default {
  AF: { group: "asia" },
  AS: { group: "oceania" },
  AU: { group: "oceania" },
  AT: { group: "europe" },
  AX: { group: "europe" },
  AW: { group: "centralAndSouthAmerica" },
  BE: { group: "europe" },
  BN: { group: "asia" },
  BQ: { group: "centralAndSouthAmerica" },
  BR: { group: "centralAndSouthAmerica" },
  BV: { group: "oceania" },
  CA: { group: "northAmerica" },
  CH: { group: "europe" },
  CL: { group: "centralAndSouthAmerica" },
  DK: { group: "europe" },
  EC: { group: "centralAndSouthAmerica" },
  ES: { group: "europe" },
  FI: { group: "europe" },
  FR: { group: "europe" },
  DE: { group: "europe" },
  MP: { group: "oceania" },
  NO: { group: "europe" },
  GF: { group: "centralAndSouthAmerica" },
  GP: { group: "northAmerica" },
  GU: { group: "oceania" },
  IE: { group: "europe" },
  IN: { group: "asia" },
  IQ: { group: "middleEast" },
  IR: { group: "middleEast" },
  IT: { group: "europe" },
  LA: { group: "asia" },
  LU: { group: "europe" },
  IS: { group: "europe" },
  JP: { group: "asia" },
  KH: { group: "asia" },
  KR: { group: "asia" },
  LI: { group: "europe" },
  LT: { group: "europe" },
  MH: { group: "oceania" },
  FM: { group: "oceania" },
  MT: { group: "europe" },
  MM: { group: "asia" },
  MX: { group: "northAmerica" },
  MY: { group: "asia" },
  NL: { group: "europe" },
  PR: { group: "caribbeanIslands" },
  PT: { group: "europe" },
  PW: { group: "oceania" },
  SA: { group: "middleEast" },
  SE: { group: "europe" },
  TH: { group: "asia" },
  TM: { group: "asia" },
  TR: { group: "asia" },
  TW: { group: "asia" },
  GB: { group: "europe" },
  US: { group: "northAmerica" },
  UZ: { group: "asia" },
  VI: { group: "northAmerica" },
  VN: { group: "asia" },
  AL: { group: "europe" },
  BA: { group: "europe" },
  BG: { group: "europe" },
  HR: { group: "europe" },
  CZ: { group: "europe" },
  EE: { group: "europe" },
  HU: { group: "europe" },
  XK: { group: "europe" },
  LV: { group: "europe" },
  ME: { group: "europe" },
  MK: { group: "europe" },
  PL: { group: "europe" },
  RO: { group: "europe" },
  RS: { group: "europe" },
  SK: { group: "europe" },
  SI: { group: "europe" },
  UA: { group: "europe" },
  CY: { group: "europe" },
  PK: { group: "asia" },
  GR: { group: "europe" },
  AD: { group: "europe" },
  AE: { group: "middleEast" },
  AG: { group: "northAmerica" },
  AI: { group: "caribbeanIslands" },
  AM: { group: "europe" },
  AO: { group: "africa" },
  AQ: { group: "antartica" },
  AR: { group: "centralAndSouthAmerica" },
  AZ: { group: "europe" },
  BB: { group: "northAmerica" },
  BD: { group: "africa" },
  BF: { group: "africa" },
  BH: { group: "middleEast" },
  BI: { group: "africa" },
  BJ: { group: "africa" },
  BL: { group: "caribbeanIslands" },
  BO: { group: "centralAndSouthAmerica" },
  BS: { group: "northAmerica" },
  BT: { group: "asia" },
  BW: { group: "africa" },
  BY: { group: "europe" },
  BZ: { group: "centralAndSouthAmerica" },
  CC: { group: "asia" },
  CD: { group: "africa" },
  CF: { group: "africa" },
  CG: { group: "africa" },
  CI: { group: "africa" },
  CK: { group: "oceania" },
  CM: { group: "africa" },
  CN: { group: "asia" },
  CO: { group: "centralAndSouthAmerica" },
  CR: { group: "northAmerica" },
  CV: { group: "africa" },
  CU: { group: "centralAndSouthAmerica" },
  CW: { group: "caribbeanIslands" },
  CX: { group: "asia" },
  DJ: { group: "africa" },
  DM: { group: "northAmerica" },
  DO: { group: "northAmerica" },
  DZ: { group: "africa" },
  EG: { group: "africa" },
  EH: { group: "africa" },
  ER: { group: "africa" },
  ET: { group: "africa" },
  FJ: { group: "oceania" },
  FK: { group: "centralAndSouthAmerica" },
  FO: { group: "europe" },
  GA: { group: "africa" },
  GD: { group: "caribbeanIslands" },
  GE: { group: "europe" },
  GG: { group: "europe" },
  GH: { group: "africa" },
  GI: { group: "europe" },
  GL: { group: "europe" },
  GM: { group: "africa" },
  GN: { group: "centralAndSouthAmerica" },
  GQ: { group: "africa" },
  GT: { group: "centralAndSouthAmerica" },
  GW: { group: "centralAndSouthAmerica" },
  GY: { group: "centralAndSouthAmerica" },
  HK: { group: "asia" },
  HN: { group: "centralAndSouthAmerica" },
  HT: { group: "northAmerica" },
  IL: { group: "middleEast" },
  IM: { group: "europe" },
  JE: { group: "europe" },
  JM: { group: "northAmerica" },
  JO: { group: "middleEast" },
  KE: { group: "africa" },
  KG: { group: "asia" },
  KI: { group: "africa" },
  KM: { group: "africa" },
  KN: { group: "northAmerica" },
  KW: { group: "middleEast" },
  KY: { group: "caribbeanIslands" },
  KZ: { group: "asia" },
  LB: { group: "middleEast" },
  LC: { group: "northAmerica" },
  LK: { group: "asia" },
  LR: { group: "africa" },
  LS: { group: "africa" },
  LY: { group: "middleEast" },
  MA: { group: "africa" },
  MC: { group: "europe" },
  MD: { group: "europe" },
  MF: { group: "caribbeanIslands" },
  MG: { group: "africa" },
  ML: { group: "africa" },
  MN: { group: "asia" },
  NA: { group: "africa" },
  NC: { group: "oceania" },
  NE: { group: "africa" },
  NI: { group: "centralAndSouthAmerica" },
  NP: { group: "asia" },
  NR: { group: "oceania" },
  NZ: { group: "oceania" },
  OM: { group: "middleEast" },
  PA: { group: "centralAndSouthAmerica" },
  PF: { group: "oceania" },
  PG: { group: "oceania" },
  PH: { group: "asia" },
  PN: { group: "oceania" },
  PS: { group: "middleEast" },
  PY: { group: "centralAndSouthAmerica" },
  QA: { group: "middleEast" },
  RU: { group: "asia" },
  RW: { group: "africa" },
  SB: { group: "oceania" },
  SC: { group: "africa" },
  SD: { group: "africa" },
  SG: { group: "asia" },
  SL: { group: "africa" },
  SM: { group: "europe" },
  SN: { group: "africa" },
  SR: { group: "centralAndSouthAmerica" },
  SS: { group: "africa" },
  ST: { group: "africa" },
  SV: { group: "centralAndSouthAmerica" },
  SX: { group: "caribbeanIslands" },
  SY: { group: "middleEast" },
  SZ: { group: "africa" },
  TC: { group: "caribbeanIslands" },
  TD: { group: "africa" },
  TF: { group: "europe" },
  TG: { group: "africa" },
  TJ: { group: "asia" },
  TL: { group: "asia" },
  TN: { group: "africa" },
  TT: { group: "northAmerica" },
  TZ: { group: "africa" },
  UG: { group: "africa" },
  UY: { group: "centralAndSouthAmerica" },
  VA: { group: "europe" },
  VC: { group: "northAmerica" },
  VE: { group: "centralAndSouthAmerica" },
  VG: { group: "caribbeanIslands" },
  VU: { group: "oceania" },
  WF: { group: "oceania" },
  WS: { group: "oceania" },
  YE: { group: "middleEast" },
  YT: { group: "africa" },
  ZA: { group: "africa" },
  ZM: { group: "africa" },
  ZW: { group: "africa" },
  BM: { group: "caribbeanIslands" },
  ID: { group: "asia" },
  MU: { group: "africa" },
  PE: { group: "centralAndSouthAmerica" },
  NG: { group: "africa" },
  MZ: { group: "africa" },
  MW: { group: "africa" },
  MR: { group: "africa" },
  SH: { group: "africa" },
  SO: { group: "africa" },
  RE: { group: "africa" },
  TO: { group: "oceania" },
  GS: { group: "europe" },
  HM: { group: "oceania" },
  IO: { group: "europe" },
  KP: { group: "asia" },
  MO: { group: "asia" },
  MQ: { group: "northAmerica" },
  MS: { group: "northAmerica" },
  MV: { group: "asia" },
  NF: { group: "oceania" },
  NU: { group: "oceania" },
  PM: { group: "northAmerica" },
  SJ: { group: "europe" },
  TK: { gropup: "oceania" },
  TV: { group: "oceania" },
  UM: { group: "northAmerica" },
};
