import moment from "moment";

export const formatDateToUtc = (date) => {

  if(typeof date === "string"){
    date = new Date(date)
  }

  if (date) {
    return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
  }

  return null;
};
