import { isArrayWithValues } from "./validation";
import moment from "moment";

export const isValidService = (userServices, serviceId) => {
  const services = getServices(userServices, serviceId);
  const now = moment.utc();

  return (
    !!services.length &&
    services.some(
      (s) =>
        (s.used < s.paid || s.isUnlimited) &&
        now.isSameOrBefore(moment.utc(s.expireDate))
    )
  );
};

const getServices = (userServices, serviceId) => {
  if (!serviceId || !isArrayWithValues(userServices)) {
    return [];
  }

  return userServices.filter((s) => s.serviceId === serviceId);
};
