import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import Button from "../core/Button";
import CardTitle from "../core/CardTitle";
import DefinitionList from "../core/DefinitionList";
import { InlineLoader } from "../core/Loading";
import EditKeyInformation from "../form/EditKeyInformation";

import { formatDateL } from "../../utils/date";
import { displayValueOrSeparator, titleCase } from "../../utils/string";
import { formatNumber } from "../../utils/currencyFormatter";
import Currency from "../../utils/Currency";

import get from "lodash/get";

import { withTranslation } from "react-i18next";

import { Card } from "@creditsafe/ui-components";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  .addButton {
    padding: 1px 6px;
    font-size: 1.6rem;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class KeyInformation extends Component {
  state = {
    editing: false,
  };

  handleClick = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  render() {
    const { editing } = this.state;

    const {
      mycID,
      companyId,
      safeNumber,
      email,
      companyReport,
      additionalInformation,
      companyIdentification,
      contactInformation,
      companySummary,
      otherInformation,
      longSICDescript,
      fetchReportJson,
      fetchUserReport,
      updateReport,
      setReportLoading,
      accessToken,
      language,
      report,
      isLoading,
      loadingType,
      expressApi,
      userReport,
      fullName,
      boId,
      profile
    } = this.props;

    const isLoadingKeyInformation =
      isLoading && loadingType === "keyInformation";

    const businessName = get(companySummary, "businessName", "-");
    const otherLegalName = get(
      additionalInformation,
      "primaryCorporateRecord.otherLegalName", "-"
    );
    const charterNumber = get(
      additionalInformation,
      "primaryCorporateRecord.sosCharterNumber", "-"
    );
    const establishmentDate = get(
      additionalInformation,
      "misc.establishmentDate", "-"
    );
    const registrationDate = get(
      companyIdentification,
      "basicInformation.companyRegistrationDate", "-"
    );
    const operationsStartDate = get(
      companyIdentification,
      "basicInformation.operationsStartDate", "-"
    );
    const companyType = get(
      companyIdentification,
      "basicInformation.legalForm.providerCode", "-"
    );
    const sicDescription = get(companyIdentification, "basicInformation.principalActivity.description", "-");
    const sicCode = get(companySummary, "mainActivity.code", "-");
    const annualSalesAmount = get(companySummary, "latestTurnoverFigure.value", "-");
    const numberEmployees = get(
      otherInformation,
      "employeesInformation[0].numberOfEmployees", "-"
    );
    const taxId = get(additionalInformation, "misc.taxID", "-");

    const fields = {
      companyName: {
        title: "Company Name",
      },
      otherLegalName: {
        title: "Other Legal Name",
      },
      charterNumber: {
        title: "Charter Number",
      },
      taxId: {
        title: "EIN",
      },
      annualSalesAmount: {
        title: "Annual Sales Amount",
        id: "walkMeRecentRevenue",
      },
      numberEmployees: {
        title: "Number of Employees",
        id: "walkMeEmployeeCount",
      },
      establishmentDate: {
        title: "Establishment Date",
      },
      registrationDate: {
        title: "Registration Date",
      },
      operationsStartDate: {
        title: "Operation Start Date",
      },
      sicCode: {
        title: "Industry Code (SIC)",
        id: "walkMeIndustryCode",
      },
      sicDescription: {
        title: "Industry Description (SIC)",
      },
      companyType: {
        title: "Company Type",
      },
    };


    const model = {
      companyName: displayValueOrSeparator(titleCase(businessName)),
      otherLegalName: displayValueOrSeparator(titleCase(otherLegalName)),
      charterNumber: displayValueOrSeparator(charterNumber),
      taxId: displayValueOrSeparator(taxId),
      annualSalesAmount: displayValueOrSeparator(annualSalesAmount, (value) => (
        <Currency value={value} code="USD" truncate={false} />
      )),
      numberEmployees: displayValueOrSeparator(numberEmployees, formatNumber),
      establishmentDate: displayValueOrSeparator(
        formatDateL(establishmentDate)
      ),
      registrationDate: displayValueOrSeparator(formatDateL(registrationDate)),
      operationsStartDate: displayValueOrSeparator(
        formatDateL(operationsStartDate)
      ),
      sicCode: displayValueOrSeparator(sicCode),
      sicDescription: displayValueOrSeparator(titleCase(sicDescription)),
      companyType: displayValueOrSeparator(titleCase(companyType)),
    };

    return (
      <Card id="walkMeKeyInfoCard">
        <HelpTitle>
          <CardTitle title="Key Information" />
          <Button onClick={this.handleClick}>
            { editing ?  <Tooltip title="Close Edit"><ClearIcon /></Tooltip> : <Tooltip title="Edit Key Information"><EditOutlinedIcon data-testid="edit-key-info-icon" id="walkMeEditKeyInfo"/></Tooltip> }
          </Button>
        </HelpTitle>
        {editing ? (
          <EditKeyInformation
            companyReport={companyReport}
            additionalInformation={additionalInformation}
            email={email}
            mycID={mycID}
            companyId={companyId}
            safeNumber={safeNumber}
            companyIdentification={companyIdentification}
            contactInformation={contactInformation}
            companySummary={companySummary}
            otherInformation={otherInformation}
            longSICDescript={longSICDescript}
            fetchReportJson={fetchReportJson}
            fetchUserReport={fetchUserReport}
            updateReport={updateReport}
            setReportLoading={setReportLoading}
            edit={this.handleClick}
            accessToken={accessToken}
            language={language}
            report={report}
            annualSales={annualSalesAmount}
            expressApi={expressApi}
            userReport={userReport}
            fullName={fullName}
            boId={boId}
            profile={profile}
          />
        ) : (
          ""
        )}

        {!isLoadingKeyInformation && !editing ? (
          <DefinitionList fields={fields} model={model} layout="horizontal" />
        ) : (
          <InlineLoader
            isLoading={isLoadingKeyInformation}
            delayMS={0}
            loadingText={"Updating..."}
          />
        )}
      </Card>
    );
  }
}

KeyInformation.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  expressApi: state.app.config.EXPRESS.URL,
});

export default compose(
  connect(mapStateToProps, null),
  withTranslation("company-report")
)(KeyInformation);
