import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";

const StyledSvgIcon = withStyles({
  root: {
    fontSize: "24px",
  },
})(SvgIcon);

export default function createSvgIcon(path, displayName, viewBox) {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <StyledSvgIcon ref={ref} viewBox={viewBox} {...props}>
        {path}
      </StyledSvgIcon>
    ))
  );

  if (process.env.NODE_ENV !== "production") {
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
}
