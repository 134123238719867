import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import LoadingPage from "../../components/core/LoadingPage";

const sendRedirect = (navHandshake, redirectURL, navToken) => {
  window.location.replace(`${navHandshake}?token=${navToken}&redirect_uri=${redirectURL}`)
}


class NavRedirect extends Component {

  render() {

    const { isLoading, loadingType, nav, navHandshake } = this.props;
    const { authToken } = nav;
    const token = authToken.data.authToken

    const isLoadingReport = isLoading && loadingType === 'totalReport';

    const redirectLocation = new URLSearchParams(window.location.search).get('redirect_url')

    if (!redirectLocation) {
      return <Redirect to="/" />
    }

    return isLoadingReport || !token ? (
      <LoadingPage />
    ) : sendRedirect(navHandshake, redirectLocation, token)
  }
}


const mapStateToProps = (state) => ({
  nav: state.nav,
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  navHandshake: state.app.config.NAV_HANDSHAKE.URL,
});

export default connect(mapStateToProps, null)(NavRedirect);