import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import Card from "../core/Card";
import { InlineLoader } from "../core/Loading";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

import { formatDateFullMonth } from "../../utils/date";
import { startCasing, lowerCasing } from "../../utils/string";

const PageContainer = styled.div`
  text-align: left;
  display: flex;
  padding: 0 3rem;
  > div {
    width: 100%;
  }
`;

class TradeReferences extends Component {
  render() {
    const { isLoading, report } = this.props;
    const references = this.props.payments.references;

    const tradeReferenceIndustries = get(
      report,
      "additionalInformation.extendedPaymentData.tradeReferences.tradeReferencesIndustries"
    );
    const tradeReferenceMonthly = get(
      report,
      "additionalInformation.extendedPaymentData.tradeReferences.tradeReferencesMonthly"
    );

    return (
      <PageContainer>
        <Card scrollContent title="Trade Reference Details">
          {isLoading ? (
            <InlineLoader
              isLoading={isLoading}
              delayMS={0}
              loadingText={"Updating..."}
            />
          ) : (
            <PaginatedTable
              headings={[
                { key: "companyname", text: "Name" },
                { key: "referencesafenumber", text: "Safe #" },
                { key: "street", text: "Address" },
                { key: "accountnumber", text: "Account #" },
                { key: "status", text: "Status" },
                { key: "updated_at", text: "Last Updated" },
              ]}
              data={orderBy(references, ["created_at"], ["desc"])}
              pageSize={PAGE_SIZE_MEDIUM}
              formatters={{
                updated_at: (data) => formatDateFullMonth(data),
                companyname: (data) =>lowerCasing(data),
                street: (data) => lowerCasing(data),
                status: (data) => {
                  if (data === null || data === "") {
                    return "Pending";
                  } else {
                    return startCasing(data);
                  }
                },
              }}
            />
          )}
        </Card>
        {!isEmpty(tradeReferenceMonthly) ? (
          <Card scrollContent title="Trade References Per Month">
            <PaginatedTable
              headings={[
                { key: "date", text: "Date" },
                { key: "totalPayments", text: "Trade References" },
                {
                  key: "numberOfUniqueTradeLineProviders",
                  text: "Unique Companies",
                },
                { key: "combinedCreditLimit", text: "Combined Credit LImit" },
                { key: "totalValue", text: "Total Value" },
              ]}
              data={orderBy(tradeReferenceMonthly, ["created_at"], ["desc"])}
              pageSize={PAGE_SIZE_MEDIUM}
              formatters={{
                date: (data) => formatDateFullMonth(data),
              }}
            />
          </Card>
        ) : (
          ""
        )}
        {!isEmpty(tradeReferenceIndustries) ? (
          <Card scrollContent title="Trade References Per Industry">
            <PaginatedTable
              headings={[
                { key: "industrySector", text: "Industry" },
                { key: "numberOfTotalTradeLines", text: "Trade References" },
                {
                  key: "numberOfUniqueTradeLineProviders",
                  text: "Unique Companies",
                },
                { key: "accountnumber", text: "Combined Credit Limit" },
                { key: "valueOfTotalTradeLines", text: "Total Value" },
              ]}
              data={orderBy(tradeReferenceIndustries, ["created_at"], ["desc"])}
              pageSize={PAGE_SIZE_MEDIUM}
            />
          </Card>
        ) : (
          " "
        )}
      </PageContainer>
    );
  }
}

TradeReferences.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
  payments: PropTypes.object,
};

const mapStateToProps = (state) => ({
  report: state.company.report,
  payments: state.payment,
});

export default connect(mapStateToProps, null)(TradeReferences);
