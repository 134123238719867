import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TradeReferenceForm from './TradeReferenceForm'
import LoadingPage from '../../components/core/LoadingPage'
import SomethingWentWrong from "../../components/core/SomethingWentWrong";

class Trade extends Component {
  render() {
    const { 
      email, 
      safeNumber, 
      companyId, 
      report,
      referencesError,
      userReport,
      profile
    } = this.props;
    

      if(referencesError){
        return (
          <SomethingWentWrong />
        )
      }

    return !companyId ? <LoadingPage /> :(
        <TradeReferenceForm
          title={"Submit Trade References"}
          email={email}
          safeNumber={safeNumber}
          companyId={companyId}
          report={report}
          userReport={userReport}
          profile={profile}
        />   
    )
  }
}

Trade.propTypes = {
  email: PropTypes.string,
  payments: PropTypes.object,
  safeNumber: PropTypes.string,
  companyId: PropTypes.string,
  report: PropTypes.object,
  userReport: PropTypes.object,
}

const mapStateToProps = state => ({
  email: state.user.info.email,
  payments: state.payment,
  referencesError: state.payment.referencesError,
  safeNumber: state.company.report.report.companySummary.companyNumber,
  companyId: state.company.report.report.companyId,
  report: state.company.report.report,
  userReport: state.user.report,
  profile: state.safeNumber.profile,
})

export default connect( mapStateToProps, null )(Trade);
