import React from "react";
import PropTypes from "prop-types";
import Paginator from "./Paginator";
import styled from "styled-components";
import HiddenOn from "./HiddenOn";
import { RowsTruncatedMessage } from "./pdf/RowsTruncatedMessage";

export class DataPager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };
  }

  getTotalPages = () => {
    return Math.ceil(this.props.data.length / this.props.pageSize);
  };

  componentDidMount() {
    this.emitDataAtPage(this.state.currentPage);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    const dataHasChanged = data !== prevProps.data;

    if (dataHasChanged) {
      this.setState({ currentPage: 1 });
      this.emitDataAtPage(1);
    }
  }

  handleChange = page => {
    this.setState({ currentPage: page });
    this.emitDataAtPage(page);
  };

  emitDataAtPage = page => {
    let currentPageData = this.getData(page);
    this.props.onChange(currentPageData);
  };

  getData = page => {
    const { data, pageSize } = this.props;
    let start = (page - 1) * pageSize;
    return [...data].slice(start, start + pageSize);
  };

  render() {
    const { currentPage } = this.state;
    const { className, options = {}, pageSize, ...rest } = this.props;
    return (
      <HiddenOn
        isHidden={options.isPDF}
        fallback={<RowsTruncatedMessage totalSize={pageSize} />}
        className={className}
      >
        <Paginator
          data-testid={rest["data-testid"]}
          page={currentPage}
          onPageChange={this.handleChange}
          totalPages={this.getTotalPages()}
        />
      </HiddenOn>
    );
  }
}

export default DataPager;

DataPager.propTypes = {
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
};

DataPager.defaultProps = {
  data: [],
  pageSize: 10,
};

export const StyledDataPager = styled(DataPager)`
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  margin-left: auto;
`;
