import React, { Component } from 'react'
import styled from "styled-components";

import NavDisclosure from "./NavDisclosure"
import CardTitle from "../core/CardTitle";
import Button from "../core/Button";
import { InlineLoader } from "../core/Loading";
import SomethingWentWrong from "../../components/core/SomethingWentWrong";

import { toTitleCase } from "../../utils/string"

import { Card } from "@creditsafe/ui-components";

import navlogo from "../../assets/img/navLogo.png";

import get from "lodash/get"

export const NavCard = styled(Card)`
  height: 100%;
`
export const HelpTitle = styled.div`
  display: flex;
  align-items: center;

  &.offersFound{
    justify-content: space-between;
      
  }
  
  &.noOffersFound {
    justify-content: flex-end;
  }

  .topOffer{
    background-color: #e1e1e1;
    border-radius: 3px;
    padding: 2px 4px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 90%;

  &.navMessage{
    margin-top: 1.125rem;
  }

   &.offers {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;

    .offersButton{
      display:flex;
      justify-content: space-between;
      margin-top: 1.125rem;
    }
  }

  .imgWrapper{
    display:flex;
    align-items: flex-start;
  }

  button{
    a.linkText{
      color: white;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.17;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;

  &.navSoloButton{
    margin-top: 2rem;
    justify-content: space-between;
  }
  
  button{
    a.linkText{
      color: white;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.17;
    }
  }
  
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
`
export const CtaBody = styled.div`
  margin-top: 1rem;
`

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
`

export const CTA = styled.div`
  font-weight: bold;
  padding: 0 2rem;
  min-width: 20%;

  .header {
    color: rgb(112, 114, 123);
    text-transform: uppercase;
    font-weight: bold;
  }
  
  :nth-child(1){
    border-color: rgb(203,203,203);
   padding-left: 0rem;
  }

  :nth-child(2){
    border-color: rgb(203,203,203);
    border-style: solid;
    border-width: 0 1px;
    
  }

`

export const Divider = styled.div`
  border-top: 4px solid rgb(225,225,225);
`

export const ImgBox = styled.img`
  max-width: 94px; 
  margin-right: 1rem;

  &.noCorpImage{
    height: 100%;
    max-width: 150px;
  }
`
class Nav extends Component {

  render() {

    const { nav, navHandshake } = this.props;

    const {
      cta,
      authToken,
      ctaLoading,
      accountError,
      ctaError,
      authError
    } = nav

    const navToken = authToken.data.authToken

    const {
      title,
      linkText,
      linkURL,
      imageURL,
      imageAltText,
      body,
      additionalDetails
    } = cta.data.cta

    const cost = get(additionalDetails, 'cost')
    const fundingAmount = get(additionalDetails, 'fundingAmount')
    const fundingSpeed = get(additionalDetails, 'fundingSpeed')
    const offers = get(additionalDetails, 'cost')

    const navLink = `${navHandshake}?token=${navToken}&redirect_uri=${linkURL}`
    const navError = accountError || ctaError || authError

    if (navError) {
      return (
        <SomethingWentWrong />
      )
    }

    const ctaOffers = (
      <Container className="offers">
        <CtaWrapper>
          <CTA>
            <div className="header">Amount</div>
            <div>{fundingAmount}</div>
          </CTA>
          <CTA>
            <div className="header">Cost</div>
            <div>{cost}</div>
          </CTA>
          <CTA>
            <div className="header">Speed</div>
            <div>{fundingSpeed}</div>
          </CTA>
        </CtaWrapper>
        <div className="offersButton">
          <NavDisclosure />
          <Button variant="nav">
            <a className="linkText" href={navLink} target="_blank" rel="noopener noreferrer">{toTitleCase(linkText)}</a>
          </Button>
        </div>
      </Container>
    )

    const soloButton = (
      <ButtonContainer className="navSoloButton">
        <NavDisclosure />
        <Button variant="nav">
          <a className="linkText" href={navLink} target="_blank" rel="noopener noreferrer">{toTitleCase(linkText)}</a>
        </Button>
      </ButtonContainer>
    )

    const topOffer = (
      <div className="topOffer">Top Offer</div>

    )

    const ctaContent = (
      <div>
        <HelpTitle className={offers ? "offersFound" : "noOffersFound"} >
          {offers ? topOffer : ""}
          <img
            src={navlogo}
            alt="Nav logo"
            height="27px"
          />
        </HelpTitle>
        <Container className="navMessage">
          <div className="imgWrapper">
            <ImgBox
              className={offers ? "" : "noCorpImage"}
              src={imageURL}
              alt={imageAltText}
            />
          </div>
          <div>
            <CardTitle title={toTitleCase(title)} />
            <CtaBody>
              {body}
            </CtaBody>
          </div>
        </Container>
        {offers ? ctaOffers : soloButton}
      </div>
    )

    return (
      <NavCard>
        {ctaLoading ? <InlineLoader
          isLoading={ctaLoading}
          delayMS={0}
          loadingText={"Finding best offer..."}
        /> : ctaContent}
      </NavCard>
    )
  }
}

export default Nav