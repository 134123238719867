import React, { useState } from "react";
import styled from "styled-components";

import Button from "../core/Button";
import { InputField, SelectField } from "../core/FormComponents";
import { Formik, Form, FieldArray } from "formik";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { formatTableData } from "../report/Director";

import { usStatePackages } from "../../utils/usStatePackages";
import sortBy from "lodash/sortBy";

import * as Yup from "yup";
import { ILLEGAL_CHARACTERS } from "../../utils/regexConstants";

export const DirectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const DirectorInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

const EditDirectors = ({
  row,
  close,
  additionalInformation,
  companyIdentification,
  companySummary,
  contactInformation,
  otherInformation,
  email,
  mycID,
  companyId,
  currentDirectors,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  accessToken,
  language,
  report,
  expressApi,
  userReport,
  safeNumber,
  companyReport,
  data,  
  fullName,
  boId,
  profile
}) => {

  const [deleteFlag, setDeleteFlag] = useState("N")
  
  let detValues = {};
  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        accessToken: accessToken,
        language: language,
        report: report,
        companyId: mycID,
        userid: email,
        currentDirectors: currentDirectors,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const newDirectors = values.currentDirectors;
        const result = newDirectors.reduce((unique, o) => {
          if (
            !unique.some(
              (director) =>
                director.name.trim().toUpperCase() ===
                  o.name.trim().toUpperCase() &&
                director.positions[0].positionName.trim().toUpperCase() ===
                  o.positions[0].positionName.trim().toUpperCase() &&
                director.address.street.trim().toUpperCase() ===
                  o.address.street.trim().toUpperCase() &&
                director.address.city.trim().toUpperCase() ===
                  o.address.city.trim().toUpperCase() &&
                director.address.postalCode.trim().toUpperCase() ===
                  o.address.postalCode.trim().toUpperCase() &&
                director.address.province.trim().toUpperCase() ===
                  o.address.province.trim().toUpperCase()
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);
        
        if(deleteFlag === "Y"){
         detValues = data
        } else {
         detValues = sortBy(formatTableData(values.currentDirectors),"name")
        }
        const detItem = detValues.find(detObj => detObj.id === row.id )
        if (result.length === newDirectors.length) {
          await setReportLoading("directors");
          await fetch(`${expressApi}/reports`, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify({
              mycID: values.companyId,
              email: values.userid,
              report: values.report,
              reportjson: {
                ...companyReport,
                directors: {
                  currentDirectors: values.currentDirectors,
                },
              },
              detType: 'update_officers_info',
              detInfo: [{
                myc_id: userReport.id,
                company_id: companyId,
                director_id: detItem.id,
                safe_number: safeNumber,
                name: detItem.name,
                street: detItem.street,
                city: detItem.city,
                state: detItem.province,
                zip: detItem.postalCode,
                position: detItem.position,
                gender: detItem.gender,
                delete_flag: deleteFlag,
                status:'fail',
                user_id: userReport.userid,
                user_verified: userReport.userstatus,
                customer_id: profile.customer.id
              }],
              reportupdated_at: new Date(),
              updated_at: new Date(),
              created_at: new Date(),
              verifiedstatus: "changed",
              language: values.language,
              bo_id: boId,
              bo_name: fullName,
            }),
          })
            .then((response) => response.json())
            .then(setSubmitting(false))
            .then(() => fetchReportJson(companyId, "director"))
            .then(() => fetchUserReport(companyId))
            .then(() => close())
            .catch((err) => console.log(err));
        } else {
          close();
          alert("You have already submitted this director");
        }
      }}
      validationSchema={directorSchema()}
      render={({ values, handleSubmit }) => (
        <Form>
          <FieldArray
            name="currentDirectors"
            render={(arrayHelpers) => (
              <DirectorContainer key={row.index}>
                <AttributeRow
                  width={100}
                  name={<label>Full Name</label>}
                  value={
                    <InputField name={`currentDirectors[${row.index}].name`} />
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Position</label>}
                  value={
                    <InputField
                      name={`currentDirectors[${row.index}].positions[0].positionName`}
                    />
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Street</label>}
                  value={
                    <InputField
                      name={`currentDirectors[${row.index}].address.street`}
                    />
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>City</label>}
                  value={
                    <InputField
                      name={`currentDirectors[${row.index}].address.city`}
                    />
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>State</label>}
                  value={
                    <SelectField
                      name={`currentDirectors[${row.index}].address.province`}
                    >
                      {provinceOptions()}
                    </SelectField>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Postal Code</label>}
                  value={
                    <InputField
                      name={`currentDirectors[${row.index}].address.postalCode`}
                    />
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Gender</label>}
                  value={
                    <InputField
                      name={`currentDirectors[${row.index}].gender`}
                    />
                  }
                />
                <Button
                  type="button"
                  data-testid={`edit-director-delete-button-${row.index}`}
                  onClick={() => {
                    arrayHelpers.remove(row.index);
                    setDeleteFlag("Y")
                    handleSubmit();
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
                <ButtonBar>
                  <Button
                    variant="success"
                    type="button"
                    data-testid={`edit-director-submit-button-${row.index}`}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </ButtonBar>
              </DirectorContainer>
            )}
          />
        </Form>
      )}
    ></Formik>
  );
}

const positions = Yup.object({
  positionName: Yup.string()
    .required("Please provide position name")
    .matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    }),
})

const directorSchema = () =>
  Yup.object().shape({
    currentDirectors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Please enter their full name")
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          }),
        positions: Yup.array().of(positions),
        gender: Yup.string()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          }),
        address: Yup.object().shape({
          street: Yup.string()
            .matches(ILLEGAL_CHARACTERS, {
              message: "Your entry contains illegal characters",
            }),
          city: Yup.string()
            .matches(ILLEGAL_CHARACTERS, {
              message: "Your entry contains illegal characters",
            }),
          province: Yup.string()
            .matches(ILLEGAL_CHARACTERS, {
              message: "Your entry contains illegal characters",
            }),
          postalCode: Yup.string()
            .matches(ILLEGAL_CHARACTERS, {
              message: "Your entry contains illegal characters",
            }),
        })
      })
    ),
  });

export default EditDirectors;
