import { 
  FETCH_SAFE_NUMBER,
  FETCH_SAFE_NUMBER_SUCCESS, 
  FETCH_REPORT_BY_SAFE_NUMBER, 
  FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL,
  FETCH_SAFE_NUMBER_ERROR
} from "./types";

import { countryCheck } from "../utils/countryCheck";

import { initConfig } from "../config";

const config = initConfig();

export const fetchSafeNumber = (sub) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.UPP_API.URL;

  const url = `${api}/users/${sub}`;
  dispatch({ type: FETCH_SAFE_NUMBER });
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_SAFE_NUMBER_SUCCESS,
        payload: payload,
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_SAFE_NUMBER_ERROR
      })
      console.log(err.message);
    });
};


export const fetchReportBySafeNumber = (companyId) => (dispatch) =>  {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.UPP_API.URL;
  const countryCode = companyId.substring(0,2)

  const country = countryCheck(countryCode)

  const url = `${api}/search/companies?safeNumber=${companyId}&country=${country}`;

  dispatch({type: FETCH_REPORT_BY_SAFE_NUMBER})
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_REPORT_BY_SAFE_NUMBER_SUCCESSFUL,
        payload: payload,
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_SAFE_NUMBER_ERROR
      })
      console.log(err.message);
    });
}
