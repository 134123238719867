import { SEARCH_COMPANIES, SEARCH_COMPANIES_SUCCESSFUL, RESET_SEARCH } from '../actions/types';

const initialState = {
  results: {},
  isSearching: false,
}


export default function( state = initialState, action){
  switch(action.type){
    case SEARCH_COMPANIES:
      return {
        ...state,
        isSearching: true
      }
    case SEARCH_COMPANIES_SUCCESSFUL:
      return {
        ...state,
        results: action.payload,
        isSearching: false
      }  
      
    case RESET_SEARCH:
      return initialState
    default:
      return state;
  }
}