import React from "react";
import styled from "styled-components";

import { InlineLoader } from "../core/Loading";
import InfoTile from "../InfoTile";
import uniq from "lodash/uniq";
import StatusColorTwo from "../report/utils/StatusColorTwo";

import { displayValueOrSeparator } from "../../utils/string";
import Currency from "../../utils/Currency";


export const SummaryContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${(props) => props.theme.spacingUnit}px 0;

  ${InfoTile} {
    width: 50%;
    height: 65px;
    margin: 1rem 0;
    > div {
      width: 80%;
      > div {
        > canvas {
          margin-top: -20px;
        }
      }
    }
    :nth-child(1n + 1) {
      border-right: 1px solid ${(props) => props.theme.greyAdobe1};
    }
    :nth-child(even) {
      border-right: none;
    }
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpointTablet}px) {
    ${InfoTile} {
      width: 25%;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :nth-child(4n + 4) {
        border-right: none;
      }
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpointLg}px) {
    flex-wrap: nowrap;
    ${InfoTile} {
      max-width: 200px;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :last-child {
        border-right: none;
        margin-right: 4px;
      }
    }
  }
`;

export const HeaderText = styled.span`
  width: 100%;
`;

export const TradeReferenceSummary = ({
  isLoading,
  references,
  companyReport,
}) => {
  const pendingReferences = references.filter(
    (reference) =>
      reference.status === "confirmed" ||
      reference.status === null ||
      reference.status === ""
  );
  const verifiedReferences = references.filter(
    (reference) => reference.status === "verified"
  );


  const totalTRValue = verifiedReferences.reduce(
    (verifiedReference, value) => verifiedReference + parseFloat(value.creditrequestsbalance), 0
  )

  const totalTRValueDollars = displayValueOrSeparator(totalTRValue, (value) => (
    <Currency value={value} code="USD" truncate={false} />
  ))

  const referenceValueOfTotalTradeLines = totalTRValue >= 1 ? totalTRValueDollars : "0"



  const totalTRCombined = verifiedReferences.reduce(
    (verifiedReference, value) => verifiedReference + parseFloat(value.creditlimit), 0
  )

  const totalTRCombinedDollars = displayValueOrSeparator(totalTRCombined, (value) => (
    <Currency value={value} code="USD" truncate={false} />
  ))

  const referenceCombinedCreditLimit = totalTRCombined >= 1 ? totalTRCombinedDollars : "0" 


  const rejectedReferences = references.filter(
    (reference) => reference.status === "rejected"
  );

  const referenceLength = references.length >= 1 ? references.length : "0"

  const amountPending =
  pendingReferences.length >= 1 ? pendingReferences.length : "0";

  const amountVerified =
  verifiedReferences.length >= 1 ? verifiedReferences.length : "0";

  const amountRejected =
  rejectedReferences.length >= 1 ? rejectedReferences.length : "0";

  const refSafeNo = references.map((refSafeNumber) => {
    return refSafeNumber.referencesafenumber;
  });

  const uniqueCompanies = uniq(refSafeNo);

  const amountUnique =
    uniqueCompanies.length >= 1 ? uniqueCompanies.length : "0"

  return isLoading ? (
    <InlineLoader
      isLoading={isLoading}
      delayMS={0}
      loadingText={"Updating..."}
    />
  ) : (
    <SummaryContainer>
      <InfoTile
        title="Submitted"
        status={StatusColorTwo(referenceLength )}
        id="walkMeRefSubmitted"
        children={referenceLength }
      />
      <InfoTile
        title="Pending"
        status={StatusColorTwo(amountPending)}
        children={amountPending}
      />
      <InfoTile
        title="Verified"
        status={StatusColorTwo(amountVerified)}
        children={amountVerified}
      />
      <InfoTile
        title="Rejected"
        status={StatusColorTwo(amountRejected)}
        children={amountRejected}
      />
      <InfoTile
        title="Unique Companies"
        status={StatusColorTwo(amountUnique)}
        id="walkMeRefUniqueProivders"
        children={amountUnique}
      />
      <InfoTile
        title="Total Verified Value"
        status={StatusColorTwo(totalTRValue)}
        id="walkMeRefTotalValue"
      >
        {referenceValueOfTotalTradeLines}
      </InfoTile>
      <InfoTile
        title="Combined Credit Limit"
        status={StatusColorTwo(totalTRCombined)}
        id="walkMeRefCombinedCreditLimit"
      >
        {referenceCombinedCreditLimit}
      </InfoTile>
    </SummaryContainer>
  );
};

export default TradeReferenceSummary;
