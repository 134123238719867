import React from "react";
// import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { PDF_PAGE_SIZE } from "../../utils/constants";

export const RowsTruncatedMessage = ({ totalSize, t }) => {
  if (totalSize <= PDF_PAGE_SIZE) {
    return null;
  }
  return (
    <h2>{t("pdf.rowsTruncatedMessage", { numberOfRows: PDF_PAGE_SIZE })}</h2>
  );
};

export default withTranslation("company-report")(RowsTruncatedMessage);
