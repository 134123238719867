import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { Formik, Form } from "formik";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import AreYouSure from "../core/AreYouSure";
import { InlineLoader } from "../core/Loading";
import { InputField } from "../core/FormComponents";
import Button from "../core/Button";
import PopupModal from "../core/PopupModal";

import ParentSearch from "../ParentSearch"

import SearchWithFilters from "../SearchWithFilters";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

import { resetSearch } from "../../actions/searchActions";

import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ContactsOutlined } from "@material-ui/icons";

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

function AddUltimateParent({
  hasUltimate,
  close,
  companyReport,
  groupStructure,
  email,
  mycID,
  companyId,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  accessToken,
  language,
  report,
  resetSearch,
  searched,
  isSearching,
  expressApi,
  userReport,
  safeNumber, 
  fullName,
  boId,
  profile,
  searchableCountries
}) {
  const [selectedCompany, setSelectedCompany] = useState({});
  const [searchSelected, setSearchSelected] = useState(false);
  const [isDeleting, setIsDeletingState] = useState(false)

  const immediateParent =  get(groupStructure, "immediateParent")
  
  const detImmediateParent = get(report.groupStructure, "immediateParent")
  const detUltimateParent = get(report.groupStructure, "ultimateParent")

  const ultimateName = get(detUltimateParent, "name", "")
  const ultimateSafeNumber = get(detUltimateParent, "safeNumber", "")
  const ultimateCompanyId = get(detUltimateParent, "id", "")
  const ultimateCountry = get(detUltimateParent, "country", "")
  const detImmediateName = get(detImmediateParent, "name", "")
  const detImmediateSafeNumber = get(detImmediateParent, "safeNumber", "")
  const detImmediateCompanyId = get(detImmediateParent, "id", "")
  const detImmediateCountry = get(detImmediateParent, "country", "")
  
  const immediateName = get(immediateParent, "name", "")
  const immediateSafeNumber = get(immediateParent, "safeNumber", "")
  const immediateCompanyId = get(immediateParent, "id", "")
  const immediateCountry = get(immediateParent, "country", "")
  const immediateRegisration = get(immediateParent, "registrationNumber", "")

  const [immediateCompany, setImmediateCompany] = useState(
    {
      name: immediateName, 
      safeNumber: immediateSafeNumber, 
      id: immediateCompanyId, 
      country: immediateCountry,
      registrationNumber: immediateRegisration,
    })

  const rename = ({
    safeNumber: safeNo,
    registrationNumber: regNo,
    ...rest
  }) => ({
    safeNo,
    regNo,
    ...rest,
  });

  useEffect(() => {
    if (hasUltimate && hasUltimate.name !== "") {
      const newUltimateCompany = rename(hasUltimate);
      setSelectedCompany(newUltimateCompany);
    }
  }, [hasUltimate]);

  const handleClick = (company) => {
    setSelectedCompany(company);
    setSearchSelected(true);
  };

  const handleGoBack = () => {
    setSelectedCompany({});
  };

  const handleDelete = () => {
    setSelectedCompany({
      name: "",
      safeNo: "",
      regNo: "",
      country: "",
      id: ""
    });
    setImmediateCompany({
      name:"",
      safeNumber:"",
      id:"",
      country:"",
      registrationNumber:""
    })
    setIsDeletingState(true)
  };

  const deleteButton = hasUltimate && !searchSelected;

  const deleteClicked = isDeleting ? "Y" : "N";

  const editButton = searchSelected ? "Go Back" : "Update"

  const removeMessage = "This will remove both the Ultimate and Immediate Parent companies"

  const detValueCheck = (newValue, originalValue) => newValue === "" ? originalValue : newValue 

  return (
    <>
      {!isEmpty(selectedCompany) ? (
        <Formik
          enableReinitialize
          initialValues={{
            accessToken: accessToken,
            language: language,
            report: report,
            companyId: mycID,
            userid: email,
            companyReport: companyReport,
            safeNumber: safeNumber,
            immediateParent: immediateCompany,
            parentName: selectedCompany.name,
            parentSafeNumber: selectedCompany.safeNo,
            parentCompanyId: selectedCompany.id,
            parentRegistrationNumber: selectedCompany.regNo,
            parentCountry: selectedCompany.country,
            immediateName: immediateCompany.name,
            immediateSafeNumber: immediateCompany.safeNumber,
            immediateCompanyId: immediateCompany.id,
            immediateCountry: immediateCompany.country
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log(detValueCheck(values.immediateName, immediateName))
            await setReportLoading("ultimateParent");
            await close();
            await fetch(`${expressApi}/reports`, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + values.accessToken,
              },
              body: JSON.stringify({
                mycID: values.companyId,
                email: values.userid,
                report: values.report,
                reportjson: {
                  ...companyReport,
                  groupStructure: {
                    ultimateParent: {
                      country: values.parentCountry,
                      name: values.parentName,
                      safeNumber: values.parentSafeNumber,
                      registrationNumber: values.parentRegistrationNumber,
                      id: values.parentCompanyId,
                    },
                    immediateParent: values.immediateParent,
                  },
                },
                reportupdated_at: new Date(),
                updated_at: new Date(),
                created_at: new Date(),
                verifiedstatus: "changed",
                language: values.language,
                detType: 'update_groupstructure',
                detInfo: {
                  myc_id: userReport.id,
                  safe_number: values.safeNumber,
                  company_id: values.companyId,
                  company_name: values.businessName,
                  immediate_holding_company_safenumber: detValueCheck(values.immediateSafeNumber, detImmediateSafeNumber),
                  immediate_holding_company_ID: detValueCheck(values.immediateCompanyId, detImmediateCompanyId),
                  immediate_holding_company_name: detValueCheck(values.immediateName, detImmediateName),
                  immediate_holding_company_address: detValueCheck(values.immediateCountry, detImmediateCountry),
                  immediate_holding_company_country: detValueCheck(values.immediateCountry, detImmediateCountry),
                  ultimate_holding_company_safenumber: detValueCheck(values.parentSafeNumber, ultimateSafeNumber),
                  ultimate_holding_company_ID: detValueCheck(values.parentCompanyId, ultimateCompanyId),
                  ultimate_holding_company_name: detValueCheck(values.parentName, ultimateName),
                  ultimate_holding_company_address: detValueCheck(values.parentCountry, ultimateCountry),
                  ultimate_holding_company_country: detValueCheck(values.parentCountry, ultimateCountry),
                  status:"fail",
                  delete_flag: deleteClicked,
                  user_id: userReport.userid,
                  user_verified : userReport.userstatus,
                  customer_id: profile.customer.id
                },
                bo_id: boId,
                bo_name: fullName,
              }),
            })
              .then(() => setSubmitting(false))
              .then(() => fetchReportJson(companyId, "ultimateParent"))
              .then(() => fetchUserReport(companyId))
              .then(() => resetSearch())
              .catch((err) => console.log(err));
          }}
        >
          {({ handleSubmit }) => (
            <Form>
              <AttributeRow
                width={100}
                name={<label>Business Name</label>}
                value={<InputField name={`parentName`} disabled />}
              />
              <AttributeRow
                width={100}
                name={<label>Safe Number</label>}
                value={<InputField name={`parentSafeNumber`} disabled />}
              />
              <AttributeRow
                width={100}
                name={<label>Registration Number</label>}
                value={
                  <InputField name={`parentRegistrationNumber`} disabled />
                }
              />
              <AttributeRow
                width={100}
                name={<label>Country</label>}
                value={<InputField name={`parentCountry`} disabled />}
              />
              <ButtonBar>
                <Button 
                  variant="danger" 
                  type="button" 
                  data-testid={`ultimate-cancel-button`} 
                  onClick={handleGoBack}
                >
                  {editButton}
                </Button>

                {!deleteButton ? (
                  <Button 
                    variant="success" 
                    type="submit"
                    data-testid={`add-ultimate-submit-button`}   
                  >
                    Submit
                  </Button>
                ) : (
                  <PopupModal title={"Are you sure?"} trigger={
                    <Button>
                      <Tooltip title="Are you sure?">
                        <DeleteForeverIcon />
                      </Tooltip>
                    </Button>} >
                    {(close) => (
                      <AreYouSure
                        close={close}
                        message={removeMessage}
                        handleDelete={handleDelete}
                        handleSubmit={handleSubmit}
                        testid={`edit-ultimate-delete-button`}
                      />
                    )}
                  </PopupModal>
                )}
              </ButtonBar>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <SearchWithFilters />
          {/* <ParentSearch searchableCountries={searchableCountries}/> */}
          {isSearching ? (
            <InlineLoader
              isLoading={isSearching}
              delayMS={0}
              loadingText={"Searching..."}
            />
          ) : (
            <PaginatedTable
              headings={[
                { key: "name", text: "Name" },
                { key: "address.simpleValue", text: "Address" },
              ]}
              data={searched.results.companies}
              pageSize={PAGE_SIZE_MEDIUM}
              onClick={handleClick}
              actions={
                <><p>Unable to find the company you're looking for?</p><p>Please refine the search criteria above or speak to your account manager for help adding a company to our database</p></>                      
              }
            />
          )}
        </>
      )}
    </>
  );
}

AddUltimateParent.propTypes = {
  searched: PropTypes.object,
  resetSearch: PropTypes.func,
  searchableCountries: PropTypes.array,
};

const mapStateToProps = (state) => ({
  searched: state.search,
  isSearching: state.search.isSearching,
  searchableCountries: state.user.searchableCountries,
});

export default connect(mapStateToProps, { resetSearch })(AddUltimateParent);
