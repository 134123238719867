import React from "react";
import styled from "styled-components";

import { InlineLoader } from "../core/Loading";

import InfoTile from "../InfoTile";
import Currency from "../../utils/Currency";
import StatusColorTwo from "../report/utils/StatusColorTwo";

export const SummaryContainer = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${(props) => props.theme.spacingUnit}px 0;

  ${InfoTile} {
    width: 75%;
    height: 65px;
    margin: 1rem 0 3rem;

    > div {
      width: 80%;
    }
    :last-child {
      border-right: none;
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpointSm}px) {
    ${InfoTile} {
      width: 33%;
      margin: 1rem 0;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :last-child {
        border-right: none;
      }
    }
  }
`;

export const HeaderText = styled.span`
  width: 100%;
`;

export const ExpenditureSummary = ({ isLoading, expenditures }) => {
  
  const expenditureLength = expenditures.length >= 1 ? expenditures.length : "0"

  const weeklyExpenditure = expenditures.filter(
    (expenditure) => expenditure.expenditurefrequency === 1
  );
  const weeklyValues = weeklyExpenditure.map(
    (expenditure) => expenditure.expenditurevalue
  );
  const weeklyTotal = weeklyValues.reduce((a, b) => a + b, 0);

  const biweeklyExpenditure = expenditures.filter(
    (expenditure) => expenditure.expenditurefrequency === 2
  );
  const biweeklyValues = biweeklyExpenditure.map(
    (expenditure) => expenditure.expenditurevalue
  );
  const biweeklyTotal = biweeklyValues.reduce((a, b) => a + b, 0);

  const monthlyExpenditure = expenditures.filter(
    (expenditure) => expenditure.expenditurefrequency === 3
  );
  const monthlyValues = monthlyExpenditure.map(
    (expenditure) => expenditure.expenditurevalue
  );
  const monthlyTotal = monthlyValues.reduce((a, b) => a + b, 0);

  const quarterlyExpenditure = expenditures.filter(
    (expenditure) => expenditure.expenditurefrequency === 4
  );
  const quarterlyValues = quarterlyExpenditure.map(
    (expenditure) => expenditure.expenditurevalue
  );
  const quarterlyTotal = quarterlyValues.reduce((a, b) => a + b, 0);

  const annualExpenditure = expenditures.filter(
    (expenditure) => expenditure.expenditurefrequency === 5
  );
  const annualValues = annualExpenditure.map(
    (expenditure) => expenditure.expenditurevalue
  );
  const annualTotal = annualValues.reduce((a, b) => a + b, 0);

  const monthlyExpenditreTotal =
    weeklyTotal * 4 + biweeklyTotal * 2 + monthlyTotal;
  const annualExpenditureTotal =
    weeklyTotal * 52 +
    biweeklyTotal * 26 +
    monthlyTotal * 12 +
    quarterlyTotal * 4 +
    annualTotal;

  return isLoading ? (
    <InlineLoader
      isLoading={isLoading}
      delayMS={0}
      loadingText={"Updating..."}
    />
  ) : (
    <SummaryContainer>
      <InfoTile title="Expenditures" status={StatusColorTwo(monthlyTotal)}>
        {expenditureLength}
      </InfoTile>
      <InfoTile
        title="Total Monthly Cost"
        status={StatusColorTwo(monthlyTotal)}
      >
        <Currency value={monthlyExpenditreTotal} code="USD" truncate={false} />
      </InfoTile>
      <InfoTile title="Total Annual Cost" status={StatusColorTwo(annualTotal)}>
        <Currency value={annualExpenditureTotal} code="USD" truncate={false} />
      </InfoTile>
    </SummaryContainer>
  );
};

export default ExpenditureSummary;
