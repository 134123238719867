const StatusColorTen = (reportPop) => {
  if (reportPop >= 91) {
    return "veryLowRisk";
  } else if (reportPop >= 81 && reportPop <= 90) {
    return "greenPrimary";
  } else if (reportPop >= 71 && reportPop <= 80) {
    return "lowRisk";
  } else if (reportPop >= 61 && reportPop <= 70) {
    return "green2";
  } else if (reportPop >= 51 && reportPop <= 60) {
    return "green1";
  } else if (reportPop >= 41 && reportPop <= 50) {
    return "yellow1";
  } else if (reportPop >= 31 && reportPop <= 40) {
    return "moderateRisk";
  } else if (reportPop >= 21 && reportPop <= 30) {
    return "highRisk";
  } else if (reportPop >= 11 && reportPop <= 20) {
    return "veryHighRisk";
  } else {
    return "red7";
  }
};

export default StatusColorTen;
