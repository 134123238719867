import { combineReducers } from 'redux';
import userReducer from './userReducer';
import companyReducer from './companyReducer';
import appReducer from './appReducer';
import mergeReducer from './mergeReducer';
import paymentReducer from './paymentReducer';
import searchReducer from './searchReducer';
import safeNumberReducer from './safeNumberReducer';
import disclaimerReducer from './disclaimerReducer';
import provenirReducer from './provenirReducer';
import navReducer from './navReducer';

export default combineReducers({
  user: userReducer,
  company: companyReducer,
  app: appReducer,
  merged: mergeReducer,
  payment: paymentReducer,
  search: searchReducer,
  safeNumber: safeNumberReducer,
  disclaimer: disclaimerReducer,
  provenir: provenirReducer,
  nav: navReducer
})