import React from "react";
import styled from "styled-components";

import Button from "../core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { usStatePackages } from "../../utils/usStatePackages";
import sortBy from "lodash/sortBy";
import { Formik, Form, FieldArray } from "formik";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

const EditBranches = ({
  email,
  mycID,
  companyReport,
  companyId,
  branchOffices,
  row,
  close,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  accessToken,
  language,
  report,
  expressApi,
  safeNumber,
  userReport,
  fullName,
  boId,
  profile
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        accessToken: accessToken,
        language: language,
        report: report,
        companyId: mycID,
        userid: email,
        branchOffices: sortBy(branchOffices, "name"),
        safeNumber: safeNumber,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await setReportLoading("branch");
        await fetch(`${expressApi}/reports`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            mycID: values.companyId,
            email: values.userid,
            report: values.report,
            reportjson: {
              ...companyReport,
              additionalInformation: {
                ...companyReport.additionalInformation,
                branchOffices: values.branchOffices,
              },
            },
            reportupdated_at: new Date(),
            updated_at: new Date(),
            created_at: new Date(),
            verifiedstatus: "changed",
            language: values.language,
            detType: 'update_branch_info',
            detInfo: {
              safe_number: values.safeNumber,
              company_id: values.companyId,
              branch_safe_number: branchOffices[`${row.index}`].safeNumber,
              branch_connect_id: branchOffices[`${row.index}`].safeNumber,
              delete_flag:"Y",
              myc_id: userReport.id,
              status:"fail",
              user_id: userReport.userid,
              user_verified : userReport.userstatus,
              customer_id: profile.customer.id
            },
            bo_id: boId,
            bo_name: fullName,
          }),
        })
          .then((response) => response.json())
          .then(setSubmitting(false))
          .then(() => fetchReportJson(companyId, "branch"))
          .then(() => fetchUserReport(companyId))
          .then(() => close())
          .catch((err) => console.log(err));
      }}
      render={({ values, handleSubmit }) => (
        <Form>
          <FieldArray
            name="branchOffices"
            render={(arrayHelpers) => (
              <Container key={row.index}>
                <AttributeRow
                  width={100}
                  name={<label>Name</label>}
                  value={<div>{branchOffices[`${row.index}`].name}</div>}
                />
                <AttributeRow
                  width={100}
                  name={<label>Safe Number</label>}
                  value={<div>{branchOffices[`${row.index}`].safeNumber}</div>}
                />
                <AttributeRow
                  width={100}
                  name={<label>Street</label>}
                  value={
                    <div>{branchOffices[`${row.index}`].address.street}</div>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>City</label>}
                  value={
                    <div>{branchOffices[`${row.index}`].address.city}</div>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>State</label>}
                  value={
                    <div>{branchOffices[`${row.index}`].address.province}</div>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Postal Code</label>}
                  value={
                    <div>
                      {branchOffices[`${row.index}`].address.postalCode}
                    </div>
                  }
                />
                <Button
                  type="button"
                  data-testid={`edit-branch-delete-button-${row.index}`}
                  onClick={() => {
                    arrayHelpers.remove(row.index);
                    handleSubmit();
                  }}
                >
                  <DeleteForeverIcon />
                </Button>
              </Container>
            )}
          />
        </Form>
      )}
    ></Formik>
  );
}

export default EditBranches;
