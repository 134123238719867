import { EDIT_MODE, WINDOW_RESIZE } from './types';

export const editMode = ( editMode ) => dispatch => {
  let payload = editMode;
  
  if(payload === false){
    payload = true;
  } else {
    payload = false;
  }

  dispatch({
    type: EDIT_MODE,
    payload: payload
  })
}

export const windowResizeAction = () => dispatch => {  
  dispatch({
    type:WINDOW_RESIZE,
  })
}