import React from 'react'
import styled from "styled-components";

import Button from "./Button";

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const MessageWrapper = styled.div`
  text-align: center;
  padding: 2rem;
`

const AreYouSure = ({close, message, handleDelete, handleSubmit, testid}) => {
  return(
    <>
      <MessageWrapper>
        {message}
      </MessageWrapper>
      <ButtonBar>
        <Button
          variant="danger"
          onClick={close}
        >
          No
        </Button>
        <Button 
          variant="success" 
          onClick={()=>{
            handleDelete();
            handleSubmit();
          }}
          data-testid={testid}   
        >
          Yes
        </Button>
      </ButtonBar>
      
    </>
  )
}

export default AreYouSure