import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import CompanyReport from "./CompanyReport";
import Disclaimer from "../../components/core/Disclaimer";
import LoadingPage from "../../components/core/LoadingPage";

export const DashboardContent = styled.div`
  padding: 1rem 3rem;
  text-align: right;

  @media only screen and (max-width: ${(props) =>
    `${props.theme.breakpointSm}px`}) {
    padding: 0.5em;
  }
`;

class Report extends Component {
  render() {
    const {
      isLoading,
      loadingType,
      company,
      email,
      mycID,
      companyId,
      companyName,
      payments,
      references,
      expenditures,
      codat,
      sub,
      customerId,
      safeNumber,
      report,
      nav
    } = this.props;

    const isLoadingReport = isLoading && loadingType === "totalReport";

    return isLoadingReport ? (
      <LoadingPage />
    ) : (
      <DashboardContent>
        <Disclaimer sub={sub} customerId={customerId} report={company} />
        <CompanyReport
          email={email}
          report={company}
          userReport={report}
          mycID={mycID}
          companyId={companyId}
          safeNumber={safeNumber}
          payments={payments}
          references={references}
          expenditures={expenditures}
          codat={codat}
          nav={nav}
          companyName={companyName}
        />
      </DashboardContent>
    );
  }
}

Report.propTypes = {
  company: PropTypes.object,
  report: PropTypes.object,
  sub: PropTypes.string,
  customerId: PropTypes.string,
  email: PropTypes.string,
  companyId: PropTypes.string,
  mycID: PropTypes.string,
  payments: PropTypes.array,
  references: PropTypes.array,
  expenditures: PropTypes.array,
  codat: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  email: state.user.info.email,
  sub: state.user.info.sub,
  customerId: state.user.info.customerId,
  report: state.user.report,
  company: state.company.report,
  mycID: state.company.report.companyId,
  companyId: state.company.report.report.companyId,
  companyName: state.company.report.report.companySummary.businessName,
  safeNumber: state.company.report.report.companySummary.companyNumber,
  payments: state.payment.payment,
  references: state.payment.references,
  expenditures: state.payment.expenditures,
  codat: state.payment.codat,
  nav: state.nav
});

export default connect(mapStateToProps, null)(Report);
