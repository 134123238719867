import React from "react";
import { withTheme } from "styled-components";

import GaugeHelper from "./GaugeHelper";

export const ExplainMyc = () => {
  const data = [
    {
      color: `red7`,
      description: "Between 0 - 10%",
    },
    {
      color: `veryHighRisk`,
      description: "Between 11 - 20%",
    },
    {
      color: `highRisk`,
      description: "Between 21 - 30%",
    },
    {
      color: `moderateRisk`,
      description: "Between 31 - 40%",
    },
    {
      color: `yellow1`,
      description: "Between 41 - 50%",
    },
    {
      color: `green1`,
      description: "Between 51 - 60%",
    },
    {
      color: `green2`,
      description: "Between 61 - 70%",
    },
    {
      color: `lowRisk`,
      description: "Between 71 - 80%",
    },
    {
      color: `greenPrimary`,
      description: "Between 81 - 90%",
    },
    {
      color: `veryLowRisk`,
      description: "Between 91 - 100%",
    },
  ];

  const helpTextTable = (height = "auto") => (
    <GaugeHelper height={height} data={data} />
  );

  return <div>{helpTextTable(400)}</div>;
};

export default withTheme(ExplainMyc);
