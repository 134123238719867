export const getMycPopulationScore = mycScore => {
  const scoreValue = parseFloat(mycScore);
  if (!scoreValue && scoreValue !== 0) {
    return "unknown";
  }
  if (scoreValue >= 90) {
    return "excellent";
  }
  if (scoreValue >= 80) {
    return "veryGood";
  }
  if (scoreValue >= 70) {
    return "good";
  }
  if (scoreValue >= 60) {
    return "notGood";
  }
  if (scoreValue >= 50) {
    return "aboveAverage";
  }
  if (scoreValue >= 40) {
    return "average";
  }
  if (scoreValue >= 30) {
    return "belowAverage";
  }
  if (scoreValue >= 20) {
    return "notBad";
  }
  if (scoreValue >= 10) {
    return "bad";
  }
  return "veryBad";
};

export const getUpdateFrequency = updatedAt => {
  const updateValue = parseFloat(updatedAt);

  if (!updateValue && updateValue !== 0) {
    return "unknown";
  }
  if (updateValue > 730) {
    return "E";
  }
  if (updateValue > 365) {
    return "D";
  }
  if (updateValue > 180) {
    return "C";
  }
  if (updateValue > 91) {
    return "B";
  }
  return "A";
};