import React from 'react'
import styled from "styled-components";


import PopupModal from "../core/PopupModal";
import NavDisclosureMessage from './NavDisclosureMessage';


export const AdvertiserHeader = styled.div`
  color: rgb(0,110,250);
  display: flex;
  align-items: center;
`

const NavDisclosure = () => {
  return (
    <PopupModal
      title="Advertiser Disclosure"
      trigger={<AdvertiserHeader>Advertiser's Disclosure</AdvertiserHeader>}
    >
      {(close) => <NavDisclosureMessage close={close} />}
    </PopupModal>
  )
}

export default NavDisclosure