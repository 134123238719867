import React from "react";
import styled from "styled-components";
import { InputField, SelectField } from "../core/FormComponents";
import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";
import Select from "react-select";

import Button from "../core/Button";
import get from "lodash/get";
import { Formik, Form } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { companyTypePackages } from "../../utils/companyTypePackages";
import { sicCodes } from "../../utils/sicCodes";
import { noNumber } from "../../utils/noNumber";
import { addEINHyphen } from "../../utils/addHyphen";

import * as Yup from "yup";
import {
  POSITIVE_NUMBER,
  ILLEGAL_CHARACTERS,
  EIN_CHECK
} from "../../utils/regexConstants";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SelectWrapper = styled.div`
  width: 85%;

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 55%;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DollarSign = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    padding-left: 15px;
  }
  :before {
    position: absolute;
    content: "$";
    padding: 0.8rem 5px;
    text-align: center;
  }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;

      @media only screen and (min-width: ${(props) =>
          `${props.theme.breakpointSm}px`}) {
        width: 55%;
      }
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const companyTypeOptions = () =>
  companyTypePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

const EditKeyInformation = ({
  companyReport,
  additionalInformation,
  email,
  mycID,
  companyId,
  safeNumber,
  companyIdentification,
  contactInformation,
  companySummary,
  otherInformation,
  longSICDescript,
  fetchReportJson,
  fetchUserReport,
  updateReport,
  setReportLoading,
  edit,
  accessToken,
  language,
  report,
  annualSales,
  expressApi,
  userReport,
  fullName,
  boId,
  profile
}) => (
  <div>
    <Formik
      enableReinitialize
      initialValues={{
        accessToken: accessToken,
        language: language,
        companyId: mycID,
        safeNumber: safeNumber,
        userid: email,
        report: report,
        businessName: get(companySummary, "businessName"),
        otherLegalName: get(
          additionalInformation,
          "primaryCorporateRecord.otherLegalName",
          ""
        ),
        charterNumber: get(
          additionalInformation,
          "primaryCorporateRecord.sosCharterNumber",
          ""
        ),
        taxID: get(additionalInformation, "misc.taxID", ""),
        annualSalesAmount: get(companySummary, "latestTurnoverFigure.value", ""),
        numberEmployees: get(
          otherInformation,
          "employeesInformation[0].numberOfEmployees"
        ),
        establishmentDate: get(
          additionalInformation,
          "misc.establishmentDate",
          ""
        ),
        registrationDate: get(
          companyIdentification,
          "basicInformation.companyRegistrationDate",
          ""
        ),
        operationsStartDate: get(
          companyIdentification,
          "basicInformation.operationsStartDate",
          ""
        ),
        longSicCode: longSICDescript,
        companyType: get(
          companyIdentification,
          "basicInformation.legalForm.providerCode"
        ),
        website: get(contactInformation, "websites", ""),
        phoneNumber: get(
          companyIdentification,
          "basicInformation.contactAddress.telephone", ""
        ),
        addressType: get(additionalInformation, "misc.addressType",""),
        locationType: get(additionalInformation, "misc.locationType",""),
        street: get(
          companyIdentification,
          "basicInformation.contactAddress.street", ""
        ),
        city: get(
          companyIdentification,
          "basicInformation.contactAddress.city", ""
        ),
        province: get(
          companyIdentification,
          "basicInformation.contactAddress.province", ""
        ),
        postalCode: get(
          companyIdentification,
          "basicInformation.contactAddress.postalCode", ""
        ),
      }}
      validationSchema={keyInformationValidation(annualSales)}
      onSubmit={async (values, actions) => {
        await edit();
        await setReportLoading("keyInformation");
        
        const newActivity =   {
                code: values.longSicCode.value.split(" ")[0],
                description: values.longSicCode.value
                .split(" ")
                .splice(1)
                .join(" ")
        }

        const sicObject = get(companyReport, "companyIdentification.activityClassifications[0]", {classification : "U.S. Standard Industrial Classification 1987", activities:[]} )
        const naicObject = get(companyReport, "companyIdentification.activityClassifications[1]", {classification : "NAICS", activities:[{code:"",description:""},]})

        if(!sicObject.activities){
          sicObject.activities = []
        }

        const sicActivities = sicObject.activities

        if(sicActivities.length > 0){
          sicActivities.shift()
          sicActivities.unshift(newActivity)
        } else {
          sicActivities.unshift(newActivity)
        }

        await fetch(`${expressApi}/reports`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.accessToken,
          },
          body: JSON.stringify({
            mycID: values.companyId,
            email: values.userid,
            report: values.report,
            reportjson: {
              ...companyReport,
              companySummary: {
                businessName: values.businessName,
                mainActivity: {
                  code: values.longSicCode.value.split(" ")[0],
                  description: values.longSicCode.value
                  .split(" ")
                  .splice(1)
                  .join(" "),
                },
                latestTurnoverFigure: {
                  value: values.annualSalesAmount,
                },
              },
              companyIdentification: {
                ...companyReport.companyIdentification,
                basicInformation: {
                  ...companyReport.companyIdentification.basicInformation,
                  legalForm: {
                    providerCode: values.companyType,
                  },
                  principalActivity: {
                    description: values.longSicCode.value
                      .split(" ")
                      .splice(1)
                      .join(" "),
                  },                 
                  companyRegistrationDate: values.registrationDate,
                  operationsStartDate: values.operationsStartDate,                 
                },
                activityClassifications: [
                  sicObject,
                  naicObject
                ],
              },
              additionalInformation: {
                ...companyReport.additionalInformation,
                misc: {
                  ...companyReport.additionalInformation.misc,
                  establishmentDate: values.establishmentDate,
                  taxID: values.taxID,
                },
                primaryCorporateRecord: {
                  otherLegalName: values.otherLegalName,
                  sosCharterNumber: values.charterNumber,
                },
              },
              ...companyReport.contactInformation,
              otherInformation: {
                employeesInformation: [
                  {
                    numberOfEmployees: values.numberEmployees,
                  },
                ],
              },
            },
            reportupdated_at: new Date(),
            updated_at: new Date(),
            created_at: new Date(),
            verifiedstatus: "changed",
            language: values.language,
            detType: 'update_company_info',
            detInfo: {
              myc_id: userReport.id,
              company_id: values.companyId,
              safe_number: values.safeNumber,
              company_name: values.businessName,
              legal_form: values.companyType,
              date_of_company_registration: values.registrationDate,
              date_company_founded: values.establishmentDate,
              operations_start_date: values.operationsStartDate,
              ein: values.taxID,
              street: values.street,
              city: values.city,
              state: values.province,
              postal_code: values.postalCode,
              location_type:values.locationType,
              address_type: values.addressType,
              phone: values.phoneNumber,
              url: values.website,
              annual_sales: values.annualSalesAmount,
              number_of_employees: values.numberEmployees,
              status:'fail',
              user_id: userReport.userid,
              user_verified: userReport.userstatus,
              customer_id: profile.customer.id
            },
            bo_id: boId,
            bo_name: fullName,
          }),
        })
          .then((response) => response.json())
          .then(actions.setSubmitting(false))
          .then(() => fetchReportJson(companyId, "keyInformation"))
          .then(() => updateReport(companyId))
          .catch((err) => console.log(err));
      }}
    >
      {({ values, setFieldTouched, setFieldValue }) => (
        <Form>
          <DetailsContainer>
            <AttributeRow
              width={100}
              name={<label>Company Name</label>}
              value={<InputField name="businessName" />}
            />
            <AttributeRow
              width={100}
              name={<label>Other Legal Name</label>}
              value={<InputField name="otherLegalName" />}
            />
            <AttributeRow
              width={100}
              name={<label>Charter Number</label>}
              value={<InputField name="charterNumber" />}
            />
            <AttributeRow
              width={100}
              name={<label>EIN</label>}
              value={<InputField name="taxID" placeholder="XX-XXXXXXX" onKeyUp={addEINHyphen}/>}
            />
            <AttributeRow
              width={100}
              name={<label>Annual Sales Amount</label>}
              value={
                <DollarSign>
                  <InputField
                    onKeyPress={noNumber}
                    type="number"
                    name="annualSalesAmount"
                  />
                </DollarSign>
              }
            />
            <AttributeRow
              width={100}
              name={<label>Number of Employees</label>}
              value={
                <InputField onKeyPress={noNumber} name="numberEmployees" />
              }
            />
            <AttributeRowDate
              width={100}
              name={<label>Establishment Date</label>}
              value={
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    placeholder="MM/DD/YYYY"
                    maxDate={new Date()}
                    maxDateMessage="Date can not be set in future"
                    value={
                      values.establishmentDate ? values.establishmentDate  : null
                    }
                    onChange={(value) =>
                      setFieldValue("establishmentDate", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <AttributeRowDate
              width={100}
              name={<label>Registration Date</label>}
              value={
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    maxDate={new Date()}
                    maxDateMessage="Date can not be set in future"
                    value={
                      values.registrationDate
                        ? values.registrationDate
                        : null
                    }
                    placeholder="MM/DD/YYYY"
                    onChange={(value) =>
                      setFieldValue("registrationDate", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <AttributeRowDate
              width={100}
              name={<label>Operations Start Date</label>}
              value={
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    maxDate={new Date()}
                    maxDateMessage="Date can not be set in future"
                    placeholder="MM/DD/YYYY"
                    value={
                      values.operationsStartDate
                        ? values.operationsStartDate
                        : null
                    }
                    onChange={(value) =>
                      setFieldValue("operationsStartDate", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <AttributeRow
              width={100}
              name={<label>Industry Code & Description (SIC)</label>}
              value={
                <SelectWrapper>
                  <Select
                    name="longSicCode"
                    placeholder={values.longSicCode.value}
                    onBlur={() => setFieldTouched("longSicCode", true)}
                    value={values.longSicCode.value}
                    onChange={(opt) => {
                      setFieldValue("longSicCode", opt);
                    }}
                    options={sicCodes}
                  />
                </SelectWrapper>
              }
            />
            <AttributeRow
              width={100}
              name={<label>Company Type</label>}
              value={
                <SelectField name="companyType">
                  {companyTypeOptions()}
                </SelectField>
              }
            />
          </DetailsContainer>
          <ButtonBar>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  </div>
);

const keyInformationValidation = (annualSales) =>
  Yup.object().shape({
    businessName: Yup.string()
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .required("Company Name Required"),
    otherLegalName: Yup.string().matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    }),
    charterNumber: Yup.string().matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    }),
    taxID: Yup.string()
      .matches(EIN_CHECK, {message: "EIN, ex: XX-XXXXXXX \n(not Social Security Number)"})
      .matches(ILLEGAL_CHARACTERS, {message: "Your entry contains illegal characters"}),
    annualSalesAmount: Yup.string()
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .matches(POSITIVE_NUMBER, { message: "Please enter a whole number" })
      .test(
        "salesValidation",
        "Please enter your annual sales figure.",
        function (annualSalesAmount) {
          if (!annualSales && !annualSalesAmount) {
            return true;
          }

          if (!annualSales && annualSalesAmount === "0") {
            return false;
          }

          if (annualSales && !annualSalesAmount) {
            return false;
          }

          if (annualSalesAmount === "0") {
            return false;
          }

          return true;
        }
      ),
    numberEmployees: Yup.string()
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .matches(POSITIVE_NUMBER, { message: "Please enter a whole number" }),
  });


export default EditKeyInformation;
