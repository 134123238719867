import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { resetSearch } from "../../actions/searchActions";
import { fetchTradeReferences } from "../../actions/paymentActions";

import SearchWithFilters from "../SearchWithFilters";
import TradeReferenceSummary from "./TradeReferenceSummary";
import TradeReference from "./TradeReference";
import { InlineLoader } from "../core/Loading";
import Disclaimer from "../../components/core/Disclaimer";

import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

import { Typography } from "@creditsafe/ui-components";
import { Jumbotron } from "../core/Jumbotron";
import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import TradeReferences from "./TradeReferences";
import { frequency } from "../../utils/frequency";

import { HelpTitle } from "../report/KeyInformation";

import isEmpty from "lodash/isEmpty";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { isValidService } from "../../utils/services";
import { MYC_TRADE_REFERENCES_SVC_ID } from "../../utils/serviceConstants";

export const Attribute = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

export const AttributeName = styled((props) => (
  <Typography {...props} variant="h6" />
))`
  width: 100%;
  color: ${(props) => props.theme.greyEnterprise5};
  margin: 0;
`;

export const AttributeValue = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.greyEnterprise4};
  font-weight: 300;
  width: 100%;
  padding: 5px 0 0 0;
`;

export const TradeReferenceFormLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.blue2};
  text-align: left;
  margin: 1rem 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    > div {
      width: 50%;
    }
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    > div {
      width: 100%;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Attribute} {
    align-items: center;
  }
`;

export const TradeHeader = styled.div`
  padding: 1rem 3rem 0;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
`;

export const CheckboxCell = styled.div`
  display: flex;
  justify-content: start;
  align-items: left;

  input {
    margin-right: 5px;
  }
`;

export const DashboardContent = styled.div`
  padding: 1rem 3rem;
  text-align: right;

  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    padding: 0.5em;
  }
`;

export const SelectWrapper = styled.div`
  width: 85%;

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 55%;
  }
`;

export const DollarSign = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    padding-left: 15px;
  }
  :before {
    position: absolute;
    content: "$";
    padding: 0.8rem 5px;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const TableWrapper = styled.div`
  table {
    width: 90%;
    margin: auto;

    tr:hover {
      cursor: pointer;
    }
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;

      @media only screen and (min-width: ${(props) =>
          `${props.theme.breakpointSm}px`}) {
        width: 55%;
      }
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

export const frequencyOptions = () =>
  frequency.map((val, index) => (
    <option value={index} key={val}>
      {val}
    </option>
  ));

class TradeReferenceForm extends Component {
  state = {
    selectedCompany: {},
    searching: false,
    disabled: true,
    error: {},
  };
  componentDidMount() {
    this.props.resetSearch();
  }

  handleClick = (company) => {
    this.setState({ selectedCompany: company });
  };

  noCompanySelected = () => {
    this.setState({
      selectedCompany: {
        address: {
          city: "",
          postCode: "",
          province: "",
          street: "",
          simpleValue: "",
        },
        country: "",
        id: "",
        id2: "",
        name: "",
        officeType: "",
        regNo: "",
        safeNo: "",
        status: "",
        types: "",
      },
    });
  };

  onClose = (companyId) => {
    this.setState({
      searching: !this.state.searching,
      disabled: !this.state.disabled,
      error: {},
    });
    this.props.resetSearch();
    this.setState({ selectedCompany: {} });
    this.props.fetchTradeReferences(companyId);
  };

  onError = (status) => {
    if (status === 403) {
      this.setState({ error: { outOfCredits: true } });
    }
  };

  handleSearch = () => {
    this.setState({
      searching: !this.state.searching,
    });
    if (this.state.searching) {
      this.props.resetSearch();
      this.setState({ selectedCompany: {} });
    }
  };

  backToSearch = () => {
    this.setState({
      selectedCompany: {},
      disabled: true,
    });
  };

  enableSubmission = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  render() {
    const {
      isLoading,
      isSearching,
      companyId,
      searched,
      email,
      safeNumber,
      report,
      payments,
      firstName,
      lastName,
      company,
      sub,
      customerId,
      services,
      expressApi,
      userReport,
      profile
    } = this.props;
    const { error, selectedCompany, searching, disabled } = this.state;
    const fullName = firstName + " " + lastName;
    const companyName = report.companySummary.businessName;
    const { references } = payments;

    const hasTrade = isValidService(services, MYC_TRADE_REFERENCES_SVC_ID);

    return (
      <>
        {!searching && (
          <TradeHeader>
            <Disclaimer sub={sub} customerId={customerId} report={company} />
            <Card  id="walkMeTradeSummary">
              <HelpTitle>
                <CardTitle title="Trade Reference Summary" />
                <Button
                  variant="success"
                  onClick={this.handleSearch}
                  disabled={!hasTrade}
                  className="addButton"
                  id="walkMeAddTrade"
                  title={
                    !hasTrade
                      ? "You are out of Trade Reference credits, please contact your account manager to add more."
                      : null
                  }
                >
                  Add Reference <AddCircleOutlineIcon />
                </Button>
              </HelpTitle>
              <TradeReferenceSummary
                isLoading={isLoading}
                references={references}
                companyReport={report}
              />
            </Card>
          </TradeHeader>
        )}

        {searching ? (
          <>
            {!isEmpty(selectedCompany) ? (
              <TradeReference
                companyId={companyId}
                email={email}
                selectedCompany={selectedCompany}
                disabled={disabled}
                safeNumber={safeNumber}
                companyName={companyName}
                fullName={fullName}
                backToSearch={this.backToSearch}
                close={this.onClose}
                enableSubmission={this.enableSubmission}
                fetchTradeReferences={fetchTradeReferences}
                onError={this.onError}
                error={error}
                expressApi={expressApi}
                userReport={userReport}
                profile={profile}
              />
            ) : (
              <>
                <Jumbotron>
                  <PageHeader>
                    <Typography variant="h1" font="secondary">
                      Search Company
                    </Typography>
                    <ButtonBar>
                      <Button variant="danger" onClick={this.handleSearch}>
                        Go Back
                      </Button>
                    </ButtonBar>
                  </PageHeader>
                  <SearchWithFilters />
                </Jumbotron>

                {isSearching ? (
                  <InlineLoader
                    isLoading={isSearching}
                    delayMS={0}
                    loadingText={"Searching..."}
                  />
                ) : (
                  <TableWrapper>
                    <PaginatedTable
                      headings={[
                        { key: "name", text: "Name" },
                        { key: "address.simpleValue", text: "Address" },
                      ]}
                      data={searched.results.companies}
                      formatters={{
                        name: (data) => (
                          <div style={{ textTransform: "capitalize" }}>
                            {data.toLowerCase()}
                          </div>
                        ),
                      }}
                      pageSize={PAGE_SIZE_MEDIUM}
                      onClick={this.handleClick}
                      actions={
                        <><p>Unable to find the company you're looking for?</p><p>Please refine the search criteria above or speak to your account manager for help adding a company to our database</p></>                      }
                    />
                  </TableWrapper>
                )}
              </>
            )}
          </>
        ) : (
          <TradeReferences isLoading={isLoading} report={report} />
        )}
      </>
    );
  }
}

TradeReferenceForm.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  searched: PropTypes.object,
  results: PropTypes.object,
  companies: PropTypes.array,
  resetSearch: PropTypes.func,
  fetchTradeReferences: PropTypes.func,
  payments: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  sub: PropTypes.string,
  customerId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.payment.referencesLoading,
  searched: state.search,
  isSearching: state.search.isSearching,
  payments: state.payment,
  firstName: state.safeNumber.profile.contact.firstName,
  lastName: state.safeNumber.profile.contact.surname,
  services: state.user.services,
  expressApi: state.app.config.EXPRESS.URL,
  customerId: state.user.info.customerId,
  sub: state.user.info.sub,
});

export default connect(mapStateToProps, { resetSearch, fetchTradeReferences })(
  TradeReferenceForm
);
