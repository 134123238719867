export const companyTypePackages = [
  "Corporation",
  "Government",
  "Incorporated",
  "Limited Liability",
  "Non Profit",
  "Partnership",
  "Sole Proprietor",
  "Professional Corporation",
  "Public",
];