import styled from "styled-components";

export const Jumbotron = styled.div`
  background-color: ${props => props.theme.greyLight1};
  padding-bottom: 0.1px; /* margin bottom of child overflowing */
  h1 {
    margin: 0;
    padding: 1.8rem 0 0.6rem;
    font-size: ${props => props.theme.fsH1};
  }

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointMd}px`}) {
    padding-right: 3rem;
    padding-left: 3rem;
  }
`;

export const ReportJumbotron = styled.div`
  background-color: ${props => props.theme.greyLight1};
  padding: 5px 0;
  width: 100%;

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointMd}px`}) {
    padding: 5px ${({ theme }) => theme.desktopPagePadding}px;
  }
`;
