import { isArrayWithValues } from "./validation";

export const mapCountries = countries => {
  if (!isArrayWithValues(countries)) {
    return [];
  }
  return countries.map(x => {
    return {
      countryCode: x.countryCode,
      isOnline: x.sortId === "Online",
      vrStatus: x.vrStatus,
    };
  });
};
