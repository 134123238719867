import React from "react";
import { OpenInNew } from "@material-ui/icons";
import { ExternalLink } from "../styled/DisplayAttribute";
import PropTypes from "prop-types";

export const getMapAddress = address =>
  `https://www.google.com/maps/place/${address}`;

function Address({ address, mapLink = getMapAddress(address), linkText }) {
  if (!address) {
    return "-";
  }
  if (!linkText) {
    return address;
  }
  return (
    <div style={{textTransform:'capitalize'}}>
      {address}
      <ExternalLink>
        <OpenInNew />
        <a href={mapLink} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      </ExternalLink>
    </div>
  );
}

Address.propTypes = {
  address: PropTypes.string,
  mapLink: PropTypes.string,
  linkText: PropTypes.string,
};

export default Address;
