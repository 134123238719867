import {
  FETCH_COMPANY_REPORT,
  FETCH_COMPANY_REPORT_SUCCESS,
  FETCH_COMPANY_REPORT_ERROR
} from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const fetchCompanyReport = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.CONNECT.URL;

  const url = `${api}/companies/${companyId}`;

  dispatch({ type: FETCH_COMPANY_REPORT });
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + accessToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response.json()
    })
    .then((payload) => {
      dispatch({
        type: FETCH_COMPANY_REPORT_SUCCESS,
        payload: payload,
      })
    }
    )
    .catch((err) => {
      dispatch({
        type: FETCH_COMPANY_REPORT_ERROR
      })
      console.log(err.message);
    });
};
