import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { resetSearch } from "../../actions/searchActions";
import { InlineLoader } from "../core/Loading";

import Button from "../core/Button";
import { InputField } from "../core/FormComponents";
import { Formik, Form, FieldArray } from "formik";

import isEmpty from "lodash/isEmpty";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { usStatePackages } from "../../utils/usStatePackages";
import SearchWithFilters from "../SearchWithFilters";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));


const AddBranchLocation = ({
  data,
  close,
  companyReport,
  additionalInformation,
  companyIdentification,
  companySummary,
  contactInformation,
  otherInformation,
  email,
  mycID,
  companyId,
  safeNumber,
  currentDirectors,
  groupStructure,
  longSICDescript,
  branchOffices,
  otherCorporateRecords,
  fetchReportJson,
  fetchUserReport,
  resetSearch,
  searched,
  isSearching,
  setReportLoading,
  edit,
  accessToken,
  language,
  report,
  expressApi,
  userReport,
  fullName,
  boId,
  profile
}) => {
  
  const index = data.length;

  const [selectedCompany, setSelectedCompany] = useState({});

  const handleClick = (company) => {
    const rename = ({
      safeNo: safeNumber,
      address: { postCode: postalCode, city, province, street },
      ...rest
    }) => ({
      safeNumber,
      address: { postalCode, street, province, city },
      ...rest,
    });
    const newCompany = rename(company);
    setSelectedCompany(company);
    branchOffices.push(newCompany);
  };

  const handleGoBack = () => {
    setSelectedCompany({});
    resetSearch();
    branchOffices.pop();
  };

  return (
    <>
      {!isEmpty(selectedCompany) ? (
        <Formik
          enableReinitialize
          initialValues={{
            accessToken: accessToken,
            language: language,
            report: report,
            companyId: mycID,
            userid: email,
            branchOffices: branchOffices,
            safeNumber: safeNumber,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const offices = values.branchOffices;
            const lastOffice = offices.slice(-1);
            const duplicate = offices.filter(
              (office) => office.id === lastOffice[0].id
            );
            if (duplicate.length <= 1) {
              await setReportLoading("branch");
              await fetch(`${expressApi}/reports`, {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                },
                body: JSON.stringify({
                  mycID: values.companyId,
                  email: values.userid,
                  report: values.report,
                  reportjson: {
                    ...companyReport,
                    additionalInformation: {
                      ...companyReport.additionalInformation,
                      branchOffices: values.branchOffices,
                    },
                  },
                  reportupdated_at: new Date(),
                  updated_at: new Date(),
                  created_at: new Date(),
                  verifiedstatus: "changed",
                  language: values.language,
                  detType: 'update_branch_info',
                  detInfo: {
                    safe_number: values.safeNumber,
                    company_id: values.companyId,
                    branch_safe_number: lastOffice[0].safeNumber,
                    branch_connect_id: lastOffice[0].safeNumber,
                    delete_flag:"N",
                    myc_id: userReport.id,
                    status:"fail",
                    user_id: userReport.userid,
                    user_verified : userReport.userstatus,
                    customer_id: profile.customer.id
                  },
                  bo_id: boId,
                  bo_name: fullName,
                }),
              })
                .then((response) => response.json())
                .then(setSubmitting(false))
                .then(() => fetchReportJson(companyId, "branch"))
                .then(() => fetchUserReport(companyId))
                .then(() => setSelectedCompany({}))
                .then(() => resetSearch())
                .then(() => close())
                .catch((err) => console.log(err));
            } else {
              setSelectedCompany({});
              resetSearch();
              branchOffices.pop();
              close();
              alert("You have already submitted this branch office.");
            }
          }}
          render={({ values, handleSubmit }) => (
            <Form>
              <FieldArray
                name="branchOffices"
                render={(arrayHelpers) => (
                  <Container key={index}>
                    <AttributeRow
                      width={100}
                      name={<label>Business Name</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].name`}
                          disabled
                        />
                      }
                    />
                    <AttributeRow
                      width={100}
                      name={<label>Safe Number</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].safeNumber`}
                          disabled
                        />
                      }
                    />
                    <AttributeRow
                      width={100}
                      name={<label>Street</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].address.street`}
                          disabled
                        />
                      }
                    />
                    <AttributeRow
                      width={100}
                      name={<label>City</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].address.city`}
                          disabled
                        />
                      }
                    />
                    <AttributeRow
                      width={100}
                      name={<label>State</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].address.province`}
                          disabled
                        />
                      }
                    />
                    <AttributeRow
                      width={100}
                      name={<label>Zip Code</label>}
                      value={
                        <InputField
                          name={`branchOffices[${index}].address.postalCode`}
                          disabled
                        />
                      }
                    />
                    <ButtonBar>
                      <Button
                        variant="danger"
                        type="button"
                        data-testid={`add-branch-cancel-button`} 
                        onClick={handleGoBack}
                      >
                        Go Back
                      </Button>
                      <Button
                        variant="success"
                        type="button"
                        data-testid={`add-branch-submit-button`} 
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                    </ButtonBar>
                  </Container>
                )}
              />
            </Form>
          )}
        ></Formik>
      ) : (
        <>
          <SearchWithFilters />
          {isSearching ? (
            <InlineLoader
              isLoading={isSearching}
              delayMS={0}
              loadingText={"Searching..."}
            />
          ) : (
            <PaginatedTable
              headings={[
                { key: "name", text: "Name" },
                { key: "address.simpleValue", text: "Address" },
              ]}
              data={searched.results.companies}
              pageSize={PAGE_SIZE_MEDIUM}
              onClick={handleClick}
            />
          )}
        </>
      )}
    </>
  );
}

AddBranchLocation.propTypes = {
  searched: PropTypes.object,
  resetSearch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  searched: state.search,
  isSearching: state.search.isSearching,
});

export default connect(mapStateToProps, { resetSearch })(AddBranchLocation);
