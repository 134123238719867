import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Card from "../core/Card";
import { InlineLoader } from "../core/Loading";
import { PaginatedTable } from "../PaginatedTable";

import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import { isUSIdentifier } from "../../utils/companies";
import { isZeroLimit } from "../../utils/limit";
import Currency from "../../utils/Currency";
import { formatDateFullMonth } from "../../utils/date";
import { startCasing, lowerCasing } from "../../utils/string";

import get from "lodash/get";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";

import { Attribute } from "../styled/DisplayAttribute";

const PageContainer = styled.div`
  text-align: left;
  display: flex;
  padding: 0 3rem;
  > div {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    > div {
      width: 50%;
    }
  }
`;

export const ButtonBar = styled.div`
  align-self: flex-end;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const getValueText = (value, data) =>
  isZeroLimit(value) && isUSIdentifier(data.safeNumber) ? (
    "Cash Transactions"
  ) : (
    <Currency value={value} code={"USD"} />
  );

class Invoices extends Component {
  render() {
    const { isLoading, report } = this.props;
    const payments = this.props.payments.payment;

    const paymentsIndustries = get(
      report,
      "additionalInformation.extendedPaymentData.paidInvoices.paidInvoicesIndustry"
    );
    const paymentsMonthly = get(
      report,
      "additionalInformation.extendedPaymentData.paidInvoices.paidInvoicesMonthly"
    );

    return (
      <>
        <PageContainer>
          <Card scrollContent title="Submitted Payments Details">
            {isLoading ? (
              <InlineLoader
                isLoading={isLoading}
                delayMS={0}
                loadingText={"Updating..."}
              />
            ) : (
              <PaginatedTable
                headings={[
                  { key: "companyname", text: "Name" },
                  { key: "invoiceno", text: "Invoice #" },
                  { key: "value", text: "Value" },
                  { key: "status", text: "Status" },
                  { key: "paymentdate", text: "Payment Date" },
                  { key: "updated_at", text: "Last Updated" },
                ]}
                data={orderBy(payments, ["created_at"], ["desc"])}
                pageSize={PAGE_SIZE_MEDIUM}
                formatters={{
                  value: (value, data) => getValueText(value, data),
                  paymentdate: (data) => formatDateFullMonth(data),
                  updated_at: (data) => formatDateFullMonth(data),
                  companyname: (data) => lowerCasing(data),
                  status: (data) => {
                    if (data === null || data === "") {
                      return "Pending";
                    } else {
                      return startCasing(data);
                    }
                  },
                }}
              />
            )}
          </Card>

          {!isEmpty(paymentsMonthly) ? (
            <Card scrollContent title="Payments Per Month">
              <PaginatedTable
                headings={[
                  { key: "date", text: "Date" },
                  { key: "totalTradeLines", text: "Payments" },
                  { key: "uniqueCompanies", text: "Unique Companies" },
                  { key: "totalValue", text: "Total Value" },
                ]}
                data={orderBy(paymentsMonthly, ["created_at"], ["desc"])}
                pageSize={PAGE_SIZE_MEDIUM}
                formatters={{
                  date: (data) => formatDateFullMonth(data),
                }}
              />
            </Card>
          ) : (
            ""
          )}

          {!isEmpty(paymentsIndustries) ? (
            <Card scrollContent title="Payments Per Month">
              <PaginatedTable
                headings={[
                  { key: "industrySector", text: "Industry" },
                  { key: "totalTradeLines", text: "Payments" },
                  { key: "uniqueCompanies", text: "Unique Companies" },
                  { key: "totalValue", text: "Total Value" },
                ]}
                data={orderBy(paymentsIndustries, ["created_at"], ["desc"])}
                pageSize={PAGE_SIZE_MEDIUM}
                formatters={{
                  date: (data) => formatDateFullMonth(data),
                }}
              />
            </Card>
          ) : (
            ""
          )}
        </PageContainer>
      </>
    );
  }
}

Invoices.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
  payments: PropTypes.object,
};

const mapStateToProps = (state) => ({
  report: state.company.report,
  payments: state.payment,
});

export default connect(mapStateToProps, null)(Invoices);
