import React from "react";
import styled from "styled-components";

import ReferenceInputs from "./ReferenceInputs";
import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import StatusCircle from "../core/StatusCircle";
import { InputField, SelectField } from "../core/FormComponents";
import Select from "react-select";

import { PaginatedTable } from "../PaginatedTable";

import { BANNED_DOMAINS } from "../../utils/bannedDomains";
import { formatDateToUtc } from "../../utils/dateFormatter";
import { upperCaseFirstLetter } from "../../utils/string";
import { frequency } from "../../utils/frequency";
import { noNumber } from "../../utils/noNumber";
import { sicCodes } from "../../utils/sicCodes";

import {
  POSITIVE_NUMBER,
  ILLEGAL_CHARACTERS,
  PHONE_NUMBER
} from "../../utils/regexConstants";

import startCase from "lodash/startCase";
import words from "lodash/words";
import first from "lodash/first";

import UndoIcon from '@material-ui/icons/Undo';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { Typography } from "@creditsafe/ui-components";

import * as Yup from "yup";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import isEmpty from "lodash/isEmpty";

import { Formik, Form, FieldArray } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { ErrorMessage } from "../ErrorMessage";

export const Attribute = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 2rem;
  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointSm + "px"}) {
    flex-direction: row;
  }
`;

const PageContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem 0;

  .contactMessage {
    width: 85%;
    margin: 1rem 0 1.5rem;
  }
`;

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin: 0.5rem 0;

  .addButton {
    padding: 1px 6px;
    font-size: 1.6rem;
  }
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;

  .referenceInput > div > div {
    width: 85%;
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointSm + "px"}) {
    flex-direction: row;
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    > div {
      width: 100%;
      display: flex;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Attribute} {
    align-items: center;
    justify-content: space-between;
  }

  .tradeInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 80%;

    .tradeInfo-row {
      > div > div {
        width: 85%;
        height: 55px;
      }
    }

    .tradeInfo-row__dollar {
      > div > div > div {
        width: 85%;
        height: 55px;
      }
    }
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointSm + "px"}) {
    width: 33%;
    align-items: center;

    .tradeInfo-row,
    .tradeInfo-row__dollar {
      height: 126px;
    }
    :nth-child(2) {
      border-right: 1px solid #cbcbcb;
      border-left: 1px solid #cbcbcb;
    }
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointSm + 50 + "px"}) {
    .tradeInfo-row,
    .tradeInfo-row__dollar {
      height: 104px;
    }
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointXl + "px"}) {
    .tradeInfo-row,
    .tradeInfo-row__dollar {
      justify-content: center;
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 85%;
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  button {
    font-size: 1.6rem;
  }
`;

export const DollarSign = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    padding-left: 15px;
  }
  :before {
    position: absolute;
    content: "$";
    padding: 0.8rem 5px;
    text-align: center;
  }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const AttributeName = styled((props) => (
  <Typography {...props} variant="h6" />
))`
  width: 100%;
  color: ${(props) => props.theme.greyEnterprise5};
  margin: 0;
`;

export const AttributeValue = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.greyEnterprise4};
  font-weight: 300;
  width: 100%;
  padding: 5px 0 0 0;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

export const CheckboxCell = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 2rem 0;

  input {
    margin-right: 5px;
  }
`;

export const frequencyOptions = () =>
  frequency.map((val, index) => (
    <option value={index} key={val}>
      {val}
    </option>
  ));

function TradeReference({
  companyId,
  email,
  selectedCompany,
  disabled,
  safeNumber,
  companyName,
  fullName,
  backToSearch,
  close,
  enableSubmission,
  fetchTradeReferences,
  onError,
  error,
  expressApi,
  userReport,
  profile
}) {
  const emptyContacts = [
    {
      firstname: null,
      lastname: null,
      email: null,
      telephone: null,
      departmentname: null,
    },
    {
      firstname: null,
      lastname: null,
      email: null,
      telephone: null,
      departmentname: null,
    },
    {
      firstname: null,
      lastname: null,
      email: null,
      telephone: null,
      departmentname: null,
    },
    {
      firstname: null,
      lastname: null,
      email: null,
      telephone: null,
      departmentname: null,
    },
  ];

  const blankContact = {
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    departmentname: "",
  };

  const emptyContact = [
    {
      firstname: "",
      lastname: "",
      email: "",
      telephone: "",
      departmentname: "",
    },
  ];

  const capitalizedCompany = startCase(selectedCompany.name.toLowerCase());
  const capYourComp = startCase(companyName.toLowerCase());

  const sameDomainCheck = (user) => {
    const lowerEmail = email.toLowerCase();
    const lowerUser = user.toLowerCase();
    const userDomain = email.toLowerCase().substring(lowerEmail.lastIndexOf("@") + 1);
    const refDomain = user.toLowerCase().substring(lowerUser.lastIndexOf("@") + 1)
    if(userDomain === 'creditsafe.com'){
      return true
    } else if ( userDomain === refDomain){
      return false
    } return true
  }

  const contacts = Yup.object({
    firstname: Yup.string().required("Required").matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    }),
    lastname: Yup.string().required("Required").matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    }),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required")
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .test("domainValidation", "Disallowed email domain", function (email) {
        if (email !== undefined) {          
          const lowerEmail = email.toLowerCase();
          const domain = lowerEmail.substring(lowerEmail.lastIndexOf("@") + 1);
          const sameDomain = sameDomainCheck(email)
          if(sameDomain){
            if (BANNED_DOMAINS.includes(domain)) {
              return false;
            } else {
              return true;
            }
          } else {
            return false
          }       
        }
        return true;
      }),
    telephone: Yup.string()
      .matches(PHONE_NUMBER, {
        message: "Enter valid 10 digit #",
      })
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
    departmentname: Yup.string()
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
  });

  const tradeValidation = () =>
    Yup.object().shape({
      creditrequestfrequency: Yup.number()
        .required("Please select a payment frequency")
        .min(1, "Please select a payment frequency"),
      creditrequestsbalance: Yup.string()
        .required("Please enter your requested credit balance.")
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        })
        .matches(POSITIVE_NUMBER, {
          message: "Please enter a whole number greater than zero",
        }),
      creditlimit: Yup.string()
        .required("Please enter your credit limit.")
        .matches(POSITIVE_NUMBER, {
          message: "Please enter a whole number greater than zero",
        })
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      paymentterms: Yup.string()
        .required("Please enter your payment terms.")
        .matches(POSITIVE_NUMBER, {
          message: "Please enter a whole number greater than zero",
        })
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      outstandingbalance: Yup.string()
        .required("Please enter your outstanding balance.")
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        })
        .matches(POSITIVE_NUMBER, {
          message: "Please enter a whole number greater than zero",
        }),
      highestbalance: Yup.string()
        .required("Please enter your outstanding balance.")
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        })
        .matches(POSITIVE_NUMBER, {
          message: "Please enter a whole number greater than zero",
        }),
      accountnumber: Yup.string().matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      }),
      referenceContacts: Yup.array().of(contacts),
    });

  function customizer(objValue, srcValue) {
    if (isEmpty(srcValue)) {
      return srcValue;
    } else {
      merge(objValue, srcValue);
    }
  }

  return (
    <Formik
      initialValues={{
        userid: email,
        companyid: companyId,
        fullname: fullName,
        companyname: selectedCompany.name,
        currency: "USD",
        street: selectedCompany.address.street,
        city: selectedCompany.address.city,
        province: selectedCompany.address.province,
        postalcode: selectedCompany.address.postCode,
        sharingconsent: false,
        referencesafenumber: selectedCompany.safeNo,
        referenceContacts: emptyContact,
        creditrequestfrequency: "",
        creditrequestsbalance: "",
        paymentterms: "",
        highestbalance: "",
        outstandingbalance: "",
        creditlimit: "",
        datesalefirst: new Date(),
        datesalelast: new Date(),
        balanceduedate: new Date(),
        ratingoverall: 3,
        ratingpaymentperformance: 3,
        confirmindustry: "",
        yourCompany: companyName,
        customer_id: profile.customer.id,
      }}
      validationSchema={tradeValidation()}
      onSubmit={(values) => {
        fetch(`${expressApi}/trade`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            userid: userReport.userid,
            companyid: values.companyid,
            companyname: values.companyname,
            street: values.street,
            city: values.city,
            province: values.province,
            postalcode: values.postalcode,
            referenceContacts: mergeWith(
              emptyContacts,
              values.referenceContacts,
              customizer
            ),
            accountnumber: values.accountnumber,
            datesalefirst: formatDateToUtc(values.datesalefirst),
            datesalelast: formatDateToUtc(values.datesalelast),
            paymentfrequency: values.paymentfrequency,
            creditlimit: values.creditlimit,
            paymentterms: values.paymentterms,
            outstandingbalance: values.outstandingbalance,
            balanceduedate: formatDateToUtc(values.balanceduedate),
            creditrequestsbalance: values.creditrequestsbalance,
            creditrequestfrequency: values.creditrequestfrequency,
            highestbalance: values.highestbalance,
            confirmindustry: first(words(values.confirmindustry.value)),
            sharingconsent: values.sharingconsent,
            referencesafenumber: values.referencesafenumber,
            fullname: values.fullname,
            created_at: new Date(),
            statusat: new Date(),
            updated_at: new Date(),
            ratingoverall: values.ratingoverall,
            ratingpaymentperformance: values.ratingpaymentperformance,
            userverified: userReport.userstatus,
            yourCompany: values.yourCompany
          }),
        })
          .then((response) => {
            if (response.status >= 300) {
              onError(response.status);
            } else {
              close(companyId);
            }
          })
          .catch((err) => console.error(err));
      }}
    >
      {({ values, setFieldTouched, setFieldValue }) => (
        <Form>
          <PageContainer>
            <Card>
              <HelpTitle>
                <CardTitle title="Reference" />
                <Button>
                  <UndoIcon onClick={backToSearch} />
                </Button>
              </HelpTitle>
              <PaginatedTable
                headings={[
                  { key: "name", text: "Company Name" },
                  { key: "regNo", text: "Registration Number" },
                  { key: "safeNo", text: "Safe Number" },
                  { key: "address.simpleValue", text: "Address" },
                  { key: "status", text: "Status" },
                  { key: "officeType", text: "Location Type" },
                ]}
                data={[selectedCompany]}
                formatters={{
                  name: (data) => (
                    <div style={{ textTransform: "capitalize" }}>
                      {data.toLowerCase()}
                    </div>
                  ),
                  status: (x) =>
                    x && (
                      <StatusCircle
                        status={x}
                        text={`${upperCaseFirstLetter(x)}`}
                      />
                    ),
                }}
              />
            </Card>
            <Card>
              <HelpTitle>
                <CardTitle title="Contact Information" />
                {values.referenceContacts.length < 4 && (
                  <FieldArray
                    name="referenceContacts"
                    render={(arrayHelpers) => (
                      <div>
                        <Button
                          className="addButton"
                          variant="success"
                          onClick={() =>
                            arrayHelpers.insert(
                              values.referenceContacts.length,
                              blankContact
                            )
                          }
                        >
                          Add Additional Contact <AddCircleOutlineIcon />
                        </Button>
                      </div>
                    )}
                  />
                )}
              </HelpTitle>
              <div className="contactMessage">
                In order to process a trade reference, we require confirmation
                from {capitalizedCompany} in order to verify that the
                information submitted below is correct. Please provide us with
                all the necessary contact information, so that we are able to
                confirm the information in a timely manner.
              </div>
              <FieldArray
                name="referenceContacts"
                render={(arrayHelpers) => (
                  <div>
                    {values.referenceContacts.map((val, idx) => {
                      return (
                        <ReferenceInputs
                          arrayHelpers={arrayHelpers}
                          key={`contact-${idx}`}
                          idx={idx}
                          referenceContacts={values.referenceContacts}
                        />
                      );
                    })}
                  </div>
                )}
              />
            </Card>
            <Card>
              <HelpTitle>
                <CardTitle title="Trading Information" />
              </HelpTitle>
              <Container>
                <DetailsContainer>
                  <div className="tradeInfo">
                    <AttributeRow
                      className="tradeInfo-row"
                      width={100}
                      name={
                        <label>What is your customer/account number?</label>
                      }
                      value={<InputField name="accountnumber" />}
                    />
                    <AttributeRowDate
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>
                          When was your first purchase from this lender?
                        </label>
                      }
                      value={
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="date-picker-dialog"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                            maxDateMessage="Date can not be set in future"
                            value={values.datesalefirst}
                            onChange={(value) =>
                              setFieldValue("datesalefirst", value)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      }
                    />
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row__dollar"
                      name={
                        <label>
                          What credit limit has your lender set for you?
                        </label>
                      }
                      value={
                        <DollarSign>
                          <InputField
                            onKeyPress={noNumber}
                            type="number"
                            name="creditlimit"
                            placeholder="0"
                          />
                        </DollarSign>
                      }
                    />
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>
                          What are your lenders payment terms? (Days)
                        </label>
                      }
                      value={
                        <InputField
                          onKeyPress={noNumber}
                          type="number"
                          name="paymentterms"
                        />
                      }
                    />
                  </div>
                </DetailsContainer>
                <DetailsContainer>
                  <div className="tradeInfo">
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row__dollar"
                      name={
                        <label>What is your current outstanding balance?</label>
                      }
                      value={
                        <DollarSign>
                          <InputField
                            onKeyPress={noNumber}
                            type="number"
                            name="outstandingbalance"
                            placeholder="0"
                          />
                        </DollarSign>
                      }
                    />
                    <AttributeRowDate
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>
                          When was your last purchase from this lender?
                        </label>
                      }
                      value={
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="date-picker-dialog"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                            maxDateMessage="Date can not be set in future"
                            value={values.datesalelast}
                            onChange={(value) =>
                              setFieldValue("datesalelast", value)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      }
                    />
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row__dollar"
                      name={
                        <label>
                          How much do you typically request on credit?
                        </label>
                      }
                      value={
                        <DollarSign>
                          <InputField
                            onKeyPress={noNumber}
                            type="number"
                            name="creditrequestsbalance"
                            placeholder="0"
                          />
                        </DollarSign>
                      }
                    />
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>How frequently do you pay this lender?</label>
                      }
                      value={
                        <SelectField name="paymentfrequency">
                          {frequencyOptions()}
                        </SelectField>
                      }
                    />
                  </div>
                </DetailsContainer>
                <DetailsContainer>
                  <div className="tradeInfo">
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row__dollar"
                      name={
                        <label>What's been your highest outstanding balance?</label>
                      }
                      value={
                        <DollarSign>
                          <InputField
                            onKeyPress={noNumber}
                            type="number"
                            name="highestbalance"
                            placeholder="0"
                          />
                        </DollarSign>
                      }
                    />

                    <AttributeRowDate
                      width={100}
                      className="tradeInfo-row"
                      name={<label>When is your balance due date?</label>}
                      value={
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="date-picker-dialog"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={values.balanceduedate}
                            onChange={(value) =>
                              setFieldValue("balanceduedate", value)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      }
                    />

                    <AttributeRow
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>What industry does you lender work in?</label>
                      }
                      value={
                        <SelectWrapper>
                          <Select
                            name="confirmindustry"
                            placeholder="Select Industry"
                            onBlur={() =>
                              setFieldTouched("confirmindustry", true)
                            }
                            value={values.confirmindustry}
                            onChange={(opt) => {
                              setFieldValue("confirmindustry", opt);
                            }}
                            options={sicCodes}
                          />
                        </SelectWrapper>
                      }
                    />
                    <AttributeRow
                      width={100}
                      className="tradeInfo-row"
                      name={
                        <label>How frequently do you request credit?</label>
                      }
                      value={
                        <SelectField name="creditrequestfrequency">
                          {frequencyOptions()}
                        </SelectField>
                      }
                    />
                  </div>
                </DetailsContainer>
              </Container>
              <CheckboxCell>
                <input
                  type="checkbox"
                  name="sharingconsent"
                  onChange={enableSubmission}
                />
                I {fullName}, confirm that the information provided above is a
                true and accurate representation of the trade dealing's between{" "}
                {capYourComp} and {capitalizedCompany}
              </CheckboxCell>
              <ButtonBar>
                <Button
                  variant="danger"
                  type="submit"
                  disabled={disabled || error.outOfCredits}
                >
                  Submit Reference
                </Button>
              </ButtonBar>
              {error.outOfCredits && (
                <ErrorMessage
                  errorMessage={
                    "You are out of Trade References. Please contact your account manager to get more."
                  }
                />
              )}
            </Card>
          </PageContainer>
        </Form>
      )}
    </Formik>
  );
}

export default TradeReference;
