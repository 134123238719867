import React from "react";
import styled from "styled-components";

import { InputField } from "../core/FormComponents";
import { noNumber } from "../../utils/noNumber";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Typography } from "@creditsafe/ui-components";

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  .referenceInput {
    height: 55px;
    margin-bottom: 0;
    > div > div {
      width: 85%;
    }
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointSm + "px"}) {
    flex-direction: row;
  }

  @media only screen and (min-width: ${(props) =>
      props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    > div {
      width: 100%;
      display: flex;
    }
  }
`;

export const Attribute = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

export const AttributeName = styled((props) => (
  <Typography {...props} variant="h6" />
))`
  width: 100%;
  color: ${(props) => props.theme.greyEnterprise5};
  margin: 0;
`;

export const AttributeValue = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.greyEnterprise4};
  font-weight: 300;
  width: 100%;
  padding: 5px 0 0 0;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

const ReferenceInputs = ({ arrayHelpers, idx, referenceContacts }) => {
  return (
    <ContactsContainer>
      <AttributeRow
        width={100}
        className="referenceInput"
        value={
          <InputField
            placeholder="First Name"
            name={`referenceContacts[${idx}].firstname`}
            className="firstname"
          />
        }
      />
      <AttributeRow
        width={100}
        className="referenceInput"
        value={
          <InputField
            placeholder="Last Name"
            name={`referenceContacts[${idx}].lastname`}
            className="lastname"
          />
        }
      />
      <AttributeRow
        width={100}
        className="referenceInput"
        value={
          <InputField
            placeholder="Email"
            name={`referenceContacts[${idx}].email`}
            className="email"
          />
        }
      />
      <AttributeRow
        width={100}
        className="referenceInput"
        value={
          <InputField
            onKeyPress={noNumber}
            placeholder="Phone Number"
            type="number"
            name={`referenceContacts[${idx}].telephone`}
            className="telephone"
          />
        }
      />
      <AttributeRow
        width={100}
        className="referenceInput"
        value={
          <InputField
            placeholder="Department"
            name={`referenceContacts[${idx}].departmentname`}
            className="departmentname"
          />
        }
      />
      {referenceContacts.length !== 1 && (
        <DeleteForeverIcon onClick={() => arrayHelpers.remove(idx)} />
      )}
    </ContactsContainer>
  );
};

export default ReferenceInputs;
