import React from "react";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import Popup from "reactjs-popup";
import styled from "styled-components";
import PropTypes from "prop-types";
import { isBrowserInternetExplorer } from "../../utils/browserUtils";
import { Typography } from "@creditsafe/ui-components";
import { identity } from "../../utils/identity";
import { compose } from "redux";
import { withTheme } from "styled-components";

import { resetSearch } from "../../actions/searchActions";

export const PopupContainer = styled.div`
  background-color: ${({ theme }) => theme.greyLight1};
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: normal;

  h2 {
    padding-left: 10px;
  }

  svg {
    cursor: pointer;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  padding: 5px;
  h3 {
    align-self: flex-start;
    margin: 0.5em 0;
  }
`;

const overlayStyle = (theme, isMobile) => {
  return {
    
    ...(isBrowserInternetExplorer()
      ? {
        display: "inline",
        textAlign: "centre",
        padding: isMobile ? "0.5% 5% 5% 5%" : "0.5% 20% 20% 20%",
        zIndex: "99999",
        }
      : {
        zIndex: "99999",
      }),
  };
};


export const getWidth = ({ isMobile }) => {
  if (isMobile) {
    return "90%";
  } else if (isBrowserInternetExplorer()) {
    return "100%";
  } else {
    return "50%";
  }
};


export const PopupModal = ({
  onOpen = () => {},
  trigger,
  t = identity,
  title,
  isMobile,
  children,
  open,
  resetSearch,
  onClose = () => {
    resetSearch();
  },
  closeOnOuterClick = false,
  theme,
  searchCompleted,
  ...rest
}) => {
  return (
    <Popup
      modal
      contentStyle={{
        border: "none",
        padding: "0px",
        width: getWidth({ isMobile: isMobile }),
      }}
      closeOnDocumentClick={closeOnOuterClick}
      trigger={trigger}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      lockScroll
      overlayStyle={overlayStyle(isMobile)}
    >
      {(close) => (
        <PopupContainer data-testid={rest["data-testid"]}>
          <Header id="header">
            <Typography
              variant="h4"
              font="secondary"
              component="h2"
              gutterBottom
            >
              {title}
            </Typography>
            <Close
              data-testid={rest["data-testid"] + "Close"}
              onClick={close}
            />
          </Header>
          <ContentsContainer>
            {typeof children === "function" ? children(close) : children}
          </ContentsContainer>
        </PopupContainer>
      )}
    </Popup>
  );
};

PopupModal.propTypes = {
  title: PropTypes.string.isRequired,
  searchCompleted: PropTypes.object,
  resetSearch: PropTypes.func,
};

export const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
  country: state.user.info.country,
  searchCompleted: state.search,
});

export default compose(
  connect(mapStateToProps, { resetSearch }),
  withTheme
)(PopupModal);
