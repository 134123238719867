import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchReportJson, setReportLoading } from "../../actions/mergeActions";
import { fetchUserReport } from "../../actions/userActions";

import CompanyReportHeader from "./CompanyReportHeader";
import KeyInformation from "./KeyInformation";
import ContactInformation from "./ContactInformation";
import Activities from "./Activities";
import CorporateRecords from "./CorporateRecords";
import Directors from "./Directors";
import Codat from "../core/Codat";
import Card from "../core/Card";
import Branches from "./Branches";
import UltimateParent from "./UltimateParent";
import ImmediateParent from "./ImmediateParent";
import Nav from "../vendor/Nav"

import { withTranslation } from "react-i18next";

import get from "lodash/get";
import isEqual from "lodash/isEqual";

const PageContainer = styled.div`
  text-align: left;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${(props) =>
    props.theme.breakpointMd + "px"}) {
    flex-direction: row;
    > div {
      width: 50%;
      :first-child {
        margin-right: 0.75rem;
      }
      :nth-child(2) {
        margin-left: 0.75rem;
      }
    }
  }
`;

export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBar = styled.div`
  align-self: flex-end;
`;

export function EditReport({
  companyReport,
  profile,
  email,
  mycID,
  companyId,
  companyName,
  safeNumber,
  fetchReportJson,
  fetchUserReport,
  updateReport,
  setReportLoading,
  accessToken,
  language,
  report,
  userReport,
  nav,
  navHandshake
}) {
  let {
    companySummary,
    additionalInformation,
    companyIdentification,
    contactInformation,
    otherInformation,
    directors,
    groupStructure
  } = companyReport;

  const firstName = profile.contact.firstName;
  const surName = profile.contact.surname;
  const fullName = firstName + " " + surName;
  const boId = profile.id

  const mainActivity = get(companySummary, "mainActivity");
  const sicCode = get(mainActivity, "code");
  const basicInformation = get(companyIdentification, "basicInformation");
  const principalActivity = get(basicInformation, "principalActivity");
  const description = get(principalActivity, "description");

  let currentDirectors = get(directors, "currentDirectors", []);

  const otherCorporateRecords = get(
    additionalInformation,
    "otherCorporateRecords"
  );
  const branchOffices = get(additionalInformation, "branchOffices");
  const activityClassifications = get(
    companyIdentification,
    "activityClassifications"
  );

  const ultimateCompany = get(groupStructure, "ultimateParent");

  let hasUltimate = false

  const longSICDescript = { value: sicCode + " " + description };
  if (ultimateCompany) {
    hasUltimate = ultimateCompany.name !== "" ? true : false
  }

  const immediateParent = hasUltimate ? (
    <ImmediateParent
      mycID={mycID}
      safeNumber={safeNumber}
      companyId={companyId}
      email={email}
      companyReport={companyReport}
      fetchReportJson={fetchReportJson}
      fetchUserReport={fetchUserReport}
      setReportLoading={setReportLoading}
      accessToken={accessToken}
      language={language}
      report={report}
      userReport={userReport}
      fullName={fullName}
      boId={boId}
      profile={profile}
    />
  ) : <></>;
  return (
    <PageContainer>
      <Container>
        <KeyInformation
          mycID={mycID}
          companyId={companyId}
          safeNumber={safeNumber}
          email={email}
          companyReport={companyReport}
          additionalInformation={additionalInformation}
          companyIdentification={companyIdentification}
          contactInformation={contactInformation}
          companySummary={companySummary}
          otherInformation={otherInformation}
          longSICDescript={longSICDescript}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          updateReport={updateReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          report={report}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
        <NavContainer>
          <ContactInformation
            mycID={mycID}
            companyId={companyId}
            safeNumber={safeNumber}
            email={email}
            companyReport={companyReport}
            additionalInformation={additionalInformation}
            companyIdentification={companyIdentification}
            contactInformation={contactInformation}
            companySummary={companySummary}
            otherInformation={otherInformation}
            fetchReportJson={fetchReportJson}
            fetchUserReport={fetchUserReport}
            setReportLoading={setReportLoading}
            accessToken={accessToken}
            language={language}
            report={report}
            userReport={userReport}
            fullName={fullName}
            boId={boId}
            profile={profile}
          />
          <Nav
            contentSpace="creditsafe_dashboard"
            companyId={companyId}
            nav={nav}
            navHandshake={navHandshake}
          />
        </NavContainer>
      </Container>
      <FlexBox>
        <Card>
          <Codat
            companyId={companyId}
            safeNumber={safeNumber}
            companyName={companyName}
          />
        </Card>
        <Branches
          mycID={mycID}
          companyId={companyId}
          safeNumber={safeNumber}
          email={email}
          companyReport={companyReport}
          branchOffices={branchOffices}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          report={report}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
        <Directors
          companyId={companyId}
          safeNumber={safeNumber}
          mycID={mycID}
          email={email}
          companyReport={companyReport}
          currentDirectors={currentDirectors}
          longSICDescript={longSICDescript}
          otherCorporateRecords={otherCorporateRecords}
          branchOffices={branchOffices}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          report={report}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
        <UltimateParent
          mycID={mycID}
          safeNumber={safeNumber}
          companyId={companyId}
          email={email}
          companyReport={companyReport}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          report={report}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
        {immediateParent}
        <CorporateRecords
          mycID={mycID}
          safeNumber={safeNumber}
          companyId={companyId}
          email={email}
          companyReport={companyReport}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          report={report}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
        <Activities
          mycID={mycID}
          safeNumber={safeNumber}
          email={email}
          report={report}
          companyId={companyId}
          companyReport={companyReport}
          language={language}
          activityClassifications={activityClassifications}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          setReportLoading={setReportLoading}
          userReport={userReport}
          fullName={fullName}
          boId={boId}
          profile={profile}
        />
      </FlexBox>
    </PageContainer>
  );
}

class CompanyReport extends Component {
  checkMerged(merged, noJson) {
    if (typeof merged === "undefined" || isEqual(merged, noJson)) {
      let check = this.props.report;
      return check;
    } else {
      let check = merged;
      return check;
    }
  }

  updateReport = (companyId) => {
    this.props.fetchReportJson(companyId)
    this.props.fetchUserReport(companyId)
  }

  render() {
    const noJson = { extendedGroupStructure: [], financialStatements: [] };
    const merged = this.props.merged.report.report;

    const companyReport = this.checkMerged(merged, noJson);
    // const companyReport = this.props.report;

    const {
      userReport,
      email,
      mycID,
      companyId,
      companyName,
      report,
      profile,
      payments,
      references,
      expenditures,
      codat,
      fetchReportJson,
      fetchUserReport,
      setReportLoading,
      language,
      safeNumber,
      provenir,
      nav,
      navHandshake
    } = this.props;

    const accessToken = localStorage.getItem("accessToken");

    return (
      <>
        <CompanyReportHeader
          report={report}
          companyReport={companyReport}
          userReport={userReport}
          profile={profile}
          payments={payments}
          references={references}
          expenditures={expenditures}
          codat={codat}
          provenir={provenir}
        />

        <EditReport
          report={report}
          profile={profile}
          companyReport={companyReport}
          email={email}
          mycID={mycID}
          companyId={companyId}
          companyName={companyName}
          safeNumber={safeNumber}
          userReport={userReport}
          fetchReportJson={fetchReportJson}
          fetchUserReport={fetchUserReport}
          updateReport={this.updateReport}
          setReportLoading={setReportLoading}
          accessToken={accessToken}
          language={language}
          nav={nav}
          navHandshake={navHandshake}
        />
      </>
    );
  }
}

CompanyReport.propTypes = {
  fetchReportJson: PropTypes.func.isRequired,
  fetchUserReport: PropTypes.func.isRequired,
  updateReport: PropTypes.func,
  setReportLoading: PropTypes.func.isRequired,
  userReport: PropTypes.object,
};

const mapStateToProps = (state) => ({
  report: state.company.report.report,
  language: state.company.report.language,
  merged: state.merged,
  userReport: state.user.report,
  profile: state.safeNumber.profile,
  provenir: state.provenir.result,
  navHandshake: state.app.config.NAV_HANDSHAKE.URL,
});

export default compose(
  connect(mapStateToProps, { fetchReportJson, setReportLoading, fetchUserReport }),
  withTranslation("company-report")
)(CompanyReport);
