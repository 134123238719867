import React from "react";

import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Drawer from "./Drawer";
import AppDrawerNavItem from "./AppDrawerNavItem";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ReceiptIcon from "@material-ui/icons/Receipt";
import RepeatIcon from "@material-ui/icons/Repeat";

import { CompanySearchIcon, MyCreditsafeIcon, PeopleSearchIcon } from "../../assets/icons";

import { initConfig } from "../../config";

const config = initConfig();

const redirectionUrl = config.UPP_REDIRECT.URL;

export default function AppDrawer() {
  return (
    <nav>
      <Drawer variant="permanent" anchor="left">
        <Toolbar variant="dense" />
        <div id="walkMeMyCNav">
        <span>
          <AppDrawerNavItem
            text="My Report"
            icon={<MyCreditsafeIcon />}
            to="/"
          />
        </span>
        <div id="walkMeSubmittals">
        <span>
          <AppDrawerNavItem
            text="Proof of Payments"
            icon={<ReceiptIcon />}
            to="/payments"
          />
        </span>
        <span>
          <AppDrawerNavItem
            text="Trade Reference"
            icon={<RateReviewIcon />}
            to="/trade"
          />
        </span>
        <span>
          <AppDrawerNavItem
            text="Expenditure"
            icon={<RepeatIcon />}
            to="/expenditure"
          />
        </span>
        </div>
        <span>
          <AppDrawerNavItem
            text="Certificate"
            icon={<VerifiedUserIcon />}
            to="/certificate"
          />
        </span>
        </div>
        <Divider />
        <div id="walkMeCreditsafe">
          <span>
            <AppDrawerNavItem
              text="Company Search"
              icon={<CompanySearchIcon />}
              to={`${redirectionUrl}/search`}
              target="_blank"
            />
          </span>
          <span>
            <AppDrawerNavItem
              text="People Search"
              icon={<PeopleSearchIcon />}
              to={`${redirectionUrl}/peopleSearch`}
              target="_blank"
            />
          </span>
        </div>
      </Drawer>
    </nav>
  );
}
