import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "../core/Button";
import Card from "../core/Card";
import Disclaimer from "../../components/core/Disclaimer";
import { InlineLoader } from "../core/Loading";
import { PaginatedTable } from "../PaginatedTable";
import PopupModal from "../core/PopupModal";

import EditExpenditure from "../form/EditExpenditure";

import { isUSIdentifier } from "../../utils/companies";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import Currency from "../../utils/Currency";
import { formatDateFullMonth } from "../../utils/date";
import { isZeroLimit } from "../../utils/limit";
import { startCasing, lowerCasing } from "../../utils/string";

import orderBy from "lodash/orderBy";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from '@material-ui/core/Tooltip';


const PageContainer = styled.div`
  text-align: left;
  padding: 0 3rem;
`;

const getValueText = (value, data) =>
  isZeroLimit(value) && isUSIdentifier(data.safeNumber) ? (
    "Cash Transactions"
  ) : (
    <Currency value={value} code={"USD"} />
  );

class Expenditures extends Component {
  render() {
    const { 
      isLoading, 
      payments, 
      sub, 
      customerId, 
      company, 
      email,
      expressApi,
      onClose,
      onError,
      error 
    } = this.props;
    const { expenditures } = payments;

    return (
      <PageContainer>
        <Disclaimer sub={sub} customerId={customerId} report={company} />

        <Card scrollContent title="Submitted Expenditures">
          {isLoading ? (
            <InlineLoader
              isLoading={isLoading}
              delayMS={0}
              loadingText={"Updating..."}
            />
          ) : (
            <PaginatedTable
              headings={[
                { key: "expenditureprovider", text: "Name" },
                { key: "expenditurecategory", text: "Category" },
                { key: "expendituretype", text: "Description" },
                { key: "expenditurestartdate", text: "Start Date" },
                { key: "expenditureenddate", text: "End Date" },
                { key: "expenditurevalue", text: "Installments" },
                { key: "edit", text: "" },
              ]}
              data={orderBy(expenditures, ["created_at"], ["desc"])}
              pageSize={PAGE_SIZE_MEDIUM}
              formatters={{
                expenditureprovider: (data) => (
                  <div style={{ textTransform: "capitalize" }}>
                    {lowerCasing(data)}
                  </div>
                ),
                expenditurecategory: (data) => startCasing(data),
                expendituretype: (data) => startCasing(data),
                expenditurevalue: (value, data) => getValueText(value, data),
                expenditurestartdate: (data) => formatDateFullMonth(data),
                expenditureenddate: (data) => formatDateFullMonth(data),
                edit: (edit, row) => (
                  <PopupModal
                    title="Edit Expenditure"
                    trigger={
                      <Button>
                        <Tooltip title="Edit Expenditure">
                          <EditOutlinedIcon />
                        </Tooltip>
                      </Button>
                    }
                  >
                    {(close) => (
                      <EditExpenditure
                        row={row}
                        close={close}
                        onClose={onClose}
                        email={email}
                        expressApi={expressApi}
                        onError={onError}
                        error={error}

                      />
                    )}
                  </PopupModal>
                ),
              }}
            />
          )}
        </Card>
      </PageContainer>
    );
  }
}

Expenditures.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
  payments: PropTypes.object,
  sub: PropTypes.string,
  customerId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  report: state.company.report,
  payments: state.payment,
  sub: state.user.info.sub,
  customerId: state.user.info.customerId,
});

export default connect(mapStateToProps, null)(Expenditures);
