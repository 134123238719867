export const addEINHyphen = (e) => {
  const {value} = e.target;
  const numbersLength = value.replace(`-`, ``).length;

  if (numbersLength > 2 && value[2] !== `-`)
    { 
        e.target.value = `${value.slice(0, 2)}-${value.slice(2)}`;
    }
    else if (numbersLength === 2 && value[2] === `-`)
    {
	e.target.value = value.slice(0, 2);
  }
}
