import {
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_SUCCESSFUL,
  RESET_SEARCH,
} from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const searchCompanies = (
  countries,
  companyName,
  street,
  city,
  state,
  zipCode
) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const country = countries ? "countries=" + countries : "";
  const name = companyName ? "&name=" + encodeURI(companyName) : "";
  const streetAddress = street ? "&street=" + encodeURI(street) : "";
  const searchCity = city ? "&city=" + encodeURI(city) : "";
  const searchState = state ? "&province=" + state : "";
  const searchZip = zipCode ? "&postCode=" + encodeURI(zipCode) : "";

  const api = config.CONNECT.URL;

  const url = `${api}/companies?${country}${name}${streetAddress}${searchCity}${searchState}${searchZip}`;

  dispatch({ type: SEARCH_COMPANIES });
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + accessToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((response) => response.json())
    .then((payload) =>
      dispatch({
        type: SEARCH_COMPANIES_SUCCESSFUL,
        payload: payload,
      })
    )
    .catch((err) => {
      console.log(err.message);
    });
};

export const resetSearch = () => (dispatch) => {
  try {
    dispatch({
      type: RESET_SEARCH,
      payload: {},
    });
  } catch (err) {
    console.log(err);
  }
};
