import React from "react";
import styled from "styled-components";
import { Typography } from "@creditsafe/ui-components";

export const Attribute = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    display: inline-flex;
    flex-direction: row;
    width: ${props => props.width || 100}%;
    align-items: baseline;
  }
`;

// https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
export const AttributeName = styled(props => (
  <Typography {...props} variant="h6" />
))`
  width: 100%;
  color: ${props => props.theme.greyEnterprise5};
  margin: 0;

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    width: ${props => props.width || 40}%;
  }
`;

export const AttributeNameWithIcon = styled(AttributeName)`
  display: inline-flex;
`;

export const AttributeValue = styled.div`
  display: inline-flex;
  color: ${props => props.theme.greyEnterprise4};
  font-weight: 300;
  width: 100%;
  padding: 5px 0 0 15px;

    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    width: ${props => props.width || 60}%;
    margin-left: 5px;
    margin-top: 0px;
    padding: 0;
  }
`;

const externalLinkStyle = props => {
  return `      
  svg {
    font-size: ${props.theme.fsH3};
  }
  a {
    color: ${props.theme.blue2};
    margin-left: 5px;
    word-break: break-all;
  }
  `;
};

export const ExternalLink = styled.div`
  ${props => externalLinkStyle(props)};
  display: flex;
  margin-top: 5px;
`;
