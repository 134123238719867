import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";

import { fetchReportJson } from "../../actions/mergeActions";

import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import { formatTableData } from "./Director";
import EditDirectors from "../form/EditDirectors";
import AddDirectors from "../form/AddDirectors";
import PopupModal from "../core/PopupModal";
import { InlineLoader } from "../core/Loading";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Tooltip from '@material-ui/core/Tooltip';

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class Directors extends Component {
  render() {
    const {
      companyReport,
      companyId,
      safeNumber,
      email,
      mycID,
      currentDirectors,
      fetchReportJson,
      fetchUserReport,
      isLoading,
      loadingType,
      setReportLoading,
      accessToken,
      language,
      report,
      expressApi,
      userReport,
      fullName,
      boId,
      profile
    } = this.props;

    const isLoadingDirector = isLoading && loadingType === "directors";

    const indexedDirectors = currentDirectors.map((director, index) => ({
      ...director,
      index,
    }));
    const data = sortBy(
      formatTableData(indexedDirectors),
      "name"
    ).map((director, index) => ({ ...director, index }));

      const nameSorter = currentDirectors => currentDirectors.name.toLowerCase()
      const orderedCurrentDirectors = orderBy(currentDirectors, [nameSorter], ['asc'])

    return (
      <Card scrollContent>
        {isLoadingDirector ? (
          <InlineLoader
            isLoading={isLoadingDirector}
            delayMS={0}
            loadingText={"Updating..."}
          />
        ) : (
          <>
            <HelpTitle>
              <CardTitle title="Directors" />
              <PopupModal 
                title="Add Director" 
                trigger={
                  <Button>
                    <Tooltip title="Add Director">
                      <PersonAddIcon data-testid="add-director-icon"/>
                    </Tooltip>
                  </Button>
                }
              >
                {(close) => (
                  <AddDirectors
                    close={close}
                    email={email}
                    mycID={mycID}
                    companyId={companyId}
                    currentDirectors={currentDirectors}
                    fetchReportJson={fetchReportJson}
                    fetchUserReport={fetchUserReport}
                    setReportLoading={setReportLoading}
                    language={language}
                    report={report}
                    companyReport={companyReport}
                    expressApi={expressApi}
                    userReport={userReport}
                    safeNumber={safeNumber}                    
                    fullName={fullName}
                    boId={boId}
                    profile={profile}
                  />
                )}
              </PopupModal>
            </HelpTitle>
            <PaginatedTable
              headings={[
                { key: "name", text: "Name" },
                { key: "position", text: "Position" },
                { key: "street", text: "Street" },
                { key: "city", text: "City" },
                { key: "province", text: "State" },
                { key: "postalCode", text: "Zip Code" },
                { key: "gender", text: "Gender" },
                { key: "edit", text: "" },
              ]}
              data={data}
              formatters={{
                gender: (gender) => (gender ? gender : "Unknown"),
                edit: (edit, row) => (
                  <PopupModal
                    title="Edit Director"
                    trigger={
                      <Button>
                        <Tooltip 
                          title="Edit Director"
                          data-testid={`edit-directors-button-${row.index}`}
                        >
                          <EditOutlinedIcon />
                        </Tooltip>
                      </Button>
                    }
                  >
                    {(close) => (
                      <EditDirectors
                        row={row}
                        close={close}
                        email={email}
                        mycID={mycID}
                        companyId={companyId}
                        currentDirectors={orderedCurrentDirectors}
                        fetchReportJson={fetchReportJson}
                        fetchUserReport={fetchUserReport}
                        setReportLoading={setReportLoading}
                        accessToken={accessToken}
                        language={language}
                        report={report}
                        expressApi={expressApi}
                        userReport={userReport}
                        safeNumber={safeNumber}  
                        companyReport={companyReport}
                        data={data}
                        fullName={fullName}
                        boId={boId}
                        profile={profile}
                      />
                    )}
                  </PopupModal>
                ),
              }}
              pageSize={PAGE_SIZE_MEDIUM}
            />
          </>
        )}
      </Card>
    );
  }
}

Directors.propTypes = {
  fetchReportJson: PropTypes.func,
  fetchUserReport: PropTypes.func,
  report: PropTypes.object,
  companyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  report: state.company.report,
  expressApi: state.app.config.EXPRESS.URL,
});

export default connect(mapStateToProps, { fetchReportJson })(Directors);
