import React from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";
import CountrySelectorDefault from "./CountrySelectorItem";
import Downshift from 'downshift'
import Button from "./Button";

import { FixedSizeList as List } from "react-window";
import CountrySelectorItemRenderer from "./CountrySelectorItemRenderer";

import sortBy from "lodash/sortBy";
import reduce from "lodash/reduce";
import isNil from "lodash/isNil";
import groupBy from "lodash/groupBy";
import deburr from "lodash/deburr";

export class CountrySelector extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      selectedItems: props.value,
      searchText: "",
      inputRef: null
    }
  }

  static propTypes = {
    value: PropTypes.array,
  };

  static defaultProps = {
    value: [],
  };

  handleClick = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  setStateOnChangeCallback = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.selectedItems);
    }
  };

  handleChange = item => {
    if (!item.isDisabled) {
      this.setState(
        { selectedItems: [item.countryCode], isOpen: false },
        this.setStateOnChangeCallback
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedItems: this.props.value,
      });
    }

    if (!prevState.isOpen && this.state.isOpen) {
      this.setState({
        inputRef: input => input && input.focus(),
      });
    }
  }

  getSelectedItemDetails = () => {
    return this.props.searchableCountries.find(
      x => x.countryCode === this.state.selectedItems[0]
    ) || {};
  }
    

  handleSearchInputChange = event => {
    this.setState({ searchText: event.target.value });
  };
  
  render(){
    const {
      searchableCountries,
      itemComponent = CountrySelectorDefault,
      groupOrder,
      features,
    } = this.props;

    const { selectedItems, isOpen, searchText } = this.state;

    const countries = searchableCountries.filter(searchTextFilter(searchText));
    let groupHeader = [];

    if (!isNil(groupOrder)) {
      groupHeader = groupOrder.map(header => {
        return { group: header };
      });
    }

    const groups = groupBy([...groupHeader, ...countries], "group");
    const displayedCountries = reduce(
      groupOrder,
      (countries, groupName) => [
        ...countries,
        ...sortBy(groups[groupName] || [], ["name"]),
      ],
      groups["undefined"] || []
    );

      console.log(displayedCountries)

    const selectedItemDetails = this.getSelectedItemDetails();

    return(
      <Downshift
        selectedItem={selectedItems}
        isOpen={isOpen}
        onChange={this.handleChange}
        onOuterClick={()=> this.setState({isOpen: false})}
        itemToString={item =>(item ? item.countryCode : "")}
      >
        {({getItemProps, getMenuProps, isOpen}) => (
          <span
            className={this.props.className}
            style={{ position: "relative" }}
          >
            <DropdownButton
              onClick={this.handleClick}
            >
              {selectedItemDetails.icon}
              <span>{selectedItemDetails.text}</span>
            </DropdownButton>
            <StyledList
              isOpen={isOpen}
              height={1000}
              width={400}
              itemCount={displayedCountries.length + 1}
              itemSize={35}
              itemData={{
                listItem: ["search", ...displayedCountries],
                  selectedItems: this.state.selectedItems,
                  t: this.props.t,
                  currentLocation: this.props.currentLocation,
                  userService: this.props.userService,
                  getItemProps,
                  config: this.props.config,
                  handleChange: this.handleChange,
                  handleClick: this.handleClick,
                  itemComponent,
                  handleSearchInputChange: this.handleSearchInputChange,
                  inputRef: this.state.inputRef,
                  features,
                  productSearchCode: this.props.productSearchCode,
              }}
            >
              {CountrySelectorItemRenderer}
            </StyledList>
          </span>
        )}
        
      </Downshift>
      
    )
  }
}

const searchTextFilter = searchText => country => {
  return (
    searchText === "" ||
    country.countryCode.toLowerCase() === searchText.toLowerCase() ||
    deburr(country.text)
      .toLowerCase()
      .includes(deburr(searchText).toLowerCase())
  );
};

export const DropdownButton = styled(Button)`
  text-align: left;
  position: relative;
  padding: 0.8rem 2.4rem 0.8rem 1rem;
  box-sizing: border-box;
  display: inline-block;
  white-space: nowrap;
  display: flex;
  .caret {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  .flag {
    width: 24px;
    margin-right: 4px;
    vertical-align: middle;
    margin-left: 16px;
    @media only screen and (min-width: ${props =>
        `${props.theme.breakpointSm}px`}) {
      margin-left: 0px;
    }
  }
  .count {
    position: absolute;
    left: 17px;
    top: 8px;
    color: white;
    background-color: red;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: inline-block;
    text-align: center;
    border: 1px solid white;
    font-size: 10px;
    font-weight: bold;
  }
  .selection-text {
    padding: 5px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 24px);
    &:after {
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-bottom: none;
      border-top-color: #333;
      opacity: 0.8;
      display: inline-block;
      content: "";
      left: 100%;
      margin-left: -14px;
      margin-top: -1px;
      position: absolute;
      top: 50%;
    }
  }
`;

export const StyledList = styled(List)`
  display: ${props => (props.isOpen ? "block" : "none")};
  list-style-type: none;
  padding: 0;
  padding-inline-start: 0;
  margin-top: 0;
  width: 300px;
  border: 0 solid ${props => props.theme.greyLight4};
  box-shadow: 0 20px 66px 0 rgba(34, 48, 73, 0.2);
  min-height: 280px;
  max-height: 70vh;
  overflow-x: hidden;
  position: absolute !important;
  background-color: #fff;
  text-align: left;
  z-index: 1;
  left: 0;

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    width: 380px;
  }

  img.flag {
    width: 24px;
    margin-right: 4px;
    vertical-align: top;
  }
  svg.location {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    color: ${({ theme }) => theme.greyLight2};
  }
  li {
    font-size: 1.2rem;
    padding: 9px 40px 9px 16px;
    margin: 0.2rem;
    .inner-addon {
      color: #a8aaac;
      .icon {
        left: calc(1.6rem - 0.2rem);
      }
      input {
        padding-left: calc(1.6rem + 2.4rem);
      }
    }
  }
  li.dropdown-header {
    padding: 8px 16px 0;
    font-size: 1.4rem;
    line-height: 24px;
    color: #5d7079;
    white-space: nowrap;
  }
  li.dropdown-item--clickable {
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: #37517e14;
    }
    span.text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    span.globalText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 5px;
    }
    &.active {
      color: #fff;
      background-color: #2e4369;
      svg.location {
        color: #a8aaac;
      }
    }
    &.disabled {
      cursor: default;
      background: ${({ theme }) => theme.greyLight1};
    }
  }
  li.dropdown-item--divider {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #f2f5f7;
    padding: 0;
  }
`;

export default CountrySelector