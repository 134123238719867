export const getFavouriteCountries = country => {
  switch (country) {
    case "US":
      return ["US", "CA"];
    case "BE":
      return [
        "BE",
        "NL",
        "LU",
        "FR",
        "DE",
        "GB",
        "IE",
        "IT",
        "SE",
        "NO",
        "US",
        "JP",
      ];
    case "CA":
      return ["CA", "US"];
    case "DE":
      return ["DE", "AT", "BE", "CZ", "DK", "FR", "LU", "NL", "PL", "CH"];
    case "GB":
      return ["GB", "IE"];
    case "IE":
      return ["IE", "GB"];
    default:
      return null;
  }
};
