import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import InvoiceForm from './InvoiceForm'
import LoadingPage from '../../components/core/LoadingPage'
import SomethingWentWrong from "../../components/core/SomethingWentWrong";


class Payment extends Component {
  render() {
    const { 
      email, 
      safeNumber, 
      companyId, 
      companyName, 
      companyReport, 
      paymentsError, 
      userReport, 
      profile 
    } = this.props;
    if(paymentsError){
      return (
        <SomethingWentWrong />
      )
    }
 
    return !companyId ? <LoadingPage /> : (
      <InvoiceForm 
        title={"Submit Paid Invoices"}
        email={email}
        companyReport={companyReport}
        safeNumber = {safeNumber}
        companyId = {companyId}
        companyName = {companyName}
        userReport = {userReport}
        profile={profile}
      />
    )
  }
}

Payment.propTypes = {
  email: PropTypes.string,
  payments: PropTypes.object,
  companyReport: PropTypes.object,
  safeNumber: PropTypes.string,
  companyId: PropTypes.string,
  userReport: PropTypes.object,
}

const mapStateToProps = state => ({
  email: state.user.info.email,
  payments: state.payment,
  paymentsError: state.payment.paymentsError,
  companyReport: state.company.report.report,
  companyName: state.company.report.report.companySummary.businessName, 
  safeNumber: state.company.report.report.companySummary.companyNumber,
  companyId: state.company.report.report.companyId,
  userReport: state.user.report,
  profile: state.safeNumber.profile,
})

export default connect( mapStateToProps, null )(Payment);
