export const SEARCH_RESULT_LIST_VIEW = "SEARCH_RESULT_LIST_VIEW";
export const SEARCH_RESULT_GRID_VIEW = "SEARCH_RESULT_GRID_VIEW";

export const DEFAULT_PAGE = 1;
export const POSSIBLE_MATCHES_START_PAGE = 1;

export const SEARCH_RESULT_PAGE_LIMIT = 10;
export const SEARCH_RESULT_PAGE_SIZE = 24;

export const FINANCES_VIEW_MODE_FULL = "FULL";
export const FINANCES_VIEW_MODE_INTERIM = "INTERIM";
export const STANDARDISED_FINANCES_VIEW_MODE_FULL = "STANDARDISED_FULL";
export const STANDARDISED_FINANCES_VIEW_MODE_INTERIM = "STANDARDISED_INTERIM";
export const LOCAL_FINANCIALS_INTERIM = "LocalFinancialsInterim";
export const LOCAL_FINANCIALS_PLC_INTERIM = "LocalFinancialsPLCInterim";
export const LOCAL_FINANCIALS_PLC_GLOBAL_INTERIM =
  "LocalFinancialsPLCGlobalInterim";

export const GLOBAL_SEARCH = "GLOBAL";
export const WW_PLC_SEARCH = "PLC";
export const CONNECT_LTD_TYPE = "ltd";
export const CONNECT_NON_LTD_TYPE = "nonltd";

export const PAGE_SIZE_SMALL = 5;
export const PAGE_SIZE_MEDIUM = 10;
export const PAGE_SIZE_LARGE = 15;
export const PAGE_SIZE_EXTRA_LARGE = 20;
export const PAGE_SIZE_XXL = 25;
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_SMALL;
export const PDF_PAGE_SIZE = 25;
export const PDF_PAGE_SIZE_LARGE = 50;
export const EXPORT_PAGE_SIZE_LARGE = 1000;
export const PDF_GROUP_STRUCTURE_SIZE = 100;

export const ACTIVE_TYPE = "default";
export const RELEASE_TYPE = "released";
export const CAUTIONARY_TYPE = "cautionary";

export const DEBT_COLLECTION_PRODUCT_CODE = "DEBT_COLLECTION";
export const COMPANY_SEARCH_PRODUCT_CODE = "COMPANY_SEARCH";
export const SEARCH_SUPPORT_PRODUCT_CODE = "SEARCH_SUPPORT";
export const CHECK_AND_DECIDE_PRODUCT_CODE = "CHECK_AND_DECIDE";
export const PEOPLE_SEARCH_PRODUCT_CODE = "PEOPLE_SEARCH";
export const BANKRUPTCY_SEARCH_PRODUCT_CODE = "BANKRUPTCY_SEARCH";

export const BUSINESS_PRINCIPAL_REPORT_PATH = "/businessPrincipal";
export const CS_ZONE = "CSZONE";

export const parentTypes = {
  CURRENT: "CURRENT",
  IMMEDIATE_PARENT: "IMMEDIATE_PARENT",
  ULTIMATE_HOLDING_COMPANY: "ULTIMATE_HOLDING_COMPANY",
};

export const SEND_PDF_AS_EMAIL = "SEND_PDF_AS_EMAIL";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const PDF_COMMENTS_MAX_CHARACTER_LIMIT = 1000;
export const PDF_TEXT_REFERENCE_MAX_CHARACTER_LIMIT = 50;

export const linkagesHopTypes = {
  COMPANY_SEARCH: "CompanySearch",
  DIRECTOR_SEARCH: "DirectorSearch",
};

export const PDF_GROUP_STRUCTURE = 27;
export const US_FTD_DEMO_COMPANY = "US001-X-US106434618";

export const MAX_FILE_UPLOAD_SIZE = 4194304;
export const VALID_FILE_UPLOAD_EXTENSIONS = [
  ".jpg",
  ".png",
  ".gif",
  ".jpeg",
  ".txt",
  ".doc",
  ".docx",
  ".rtf",
  ".pdf",
  ".xls",
  ".xlsx",
  ".odt",
  ".csv",
  ".tiff",
  ".bmp",
  ".ppt",
  ".pptx",
];

export const MAX_CREDIT_INFORMATION_SCORE = 70;
export const RISK_CLASS_5 = 5;
export const RISK_CLASS_4 = 4;
export const RISK_CLASS_3 = 3;
export const RISK_CLASS_2 = 2;
export const RISK_CLASS_1 = 1;

export const PREVIEW_SIZE_SMALL = 1;
export const PREVIEW_SIZE_MEDIUM = 3;
export const PREVIEW_SIZE_LARGE = 5;

export const FINANCIAL_TABLE_PDF_CHUNK = 5;
export const FINANCIAL_TABLE_MIN_ACCOUNTS_REQUIRED = 5;
export const LOCAL_FINANCIALS_HGB = "LocalFinancialsHGB";
export const LOCAL_FINANCIALS_CSUK = "LocalFinancialsCSUK";

export const FINANCE_TABLE_FULL_YEARS_COUNT = 3;
export const FINANCE_TABLE_FULL_YEARS_COUNT_GB = 5;
