import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import refs from "./FlagRefs";


export const FlagIcon = ({
  code = "",
  t,
  width = "32",
  height = "32",
  className,
  config,
}) => {
  const upper = code.toUpperCase();
  if (!code || !refs[upper]) {
    return null;
  }

  return (
    <img
      src={`${config.ASSETS}/flags/${upper}.png`}
      height={height}
      width={width}
      alt={code}
      title={t(`countryNames.${upper}`)}
      className={className}
    />
  );
};

export const mapStateToProps = state => ({
  config: state.app.config,
});

export default compose(withTranslation(), connect(mapStateToProps))(FlagIcon);
