import React from "react";
import { Card as CoreCard } from "@creditsafe/ui-components";
import styled from "styled-components";

const Card = ({ children, ...props }) => {
  return <CoreCard {...props}>{children}</CoreCard>;
};

export default styled(Card)`
  overflow: ${({ scrollContent = false }) => {
    switch (scrollContent) {
      case "auto":
        return "auto";
      case true:
        return "auto hidden";
      default:
        return "visible";
    }
  }};

  > div {
    flex-grow: 1;
  }
`;
