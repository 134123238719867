import React from "react";
import styled from "styled-components";
import BasicTable from "./core/BasicTable";
import { StyledDataPager } from "./DataPager";
import { DEFAULT_PAGE_SIZE, PDF_PAGE_SIZE } from "../utils/constants";
import RowsTruncatedMessage from "./pdf/RowsTruncatedMessage";

export class PaginatedTable extends React.Component {
  state = {
    displayedData: [],
  };

  setDisplayedData = (data) => {
    this.setState({ displayedData: data });
  };
   
  render() {
    const {
      title,
      headings,
      data,
      columnWidths,
      formatters,
      wrapText,
      pageSize = DEFAULT_PAGE_SIZE,
      displayHeader = true,
      renderPager,
      dynamicHeaders,
      actions,
      options = {},
      rowStyle,
      onClick,
      ...rest
    } = this.props;
    const { displayedData } = this.state;

    if (!headings || !data) {
      return null;
    }
    const displayedHeaders = dynamicHeaders
      ? headings.filter(
          (obj) => displayedData.filter((e) => obj.key in e).length > 0
        )
      : headings;

    return (
      <>
        <BasicTable
          title={title}
          headings={displayedHeaders}
          data={renderPager ? data : displayedData}
          formatters={formatters}
          columnWidths={columnWidths}
          wrapText={wrapText}
          displayHeader={displayHeader}
          rowStyle={rowStyle}
          onClick={onClick}
        />
        <TableFooter customPager={!!renderPager}>
          {actions && <div className="actions">{actions}</div>}
          {renderPager ? (
            renderPager()
          ) : (
            <StyledDataPager
              data-testid={rest["data-testid"]}
              data={data}
              onChange={this.setDisplayedData}
              pageSize={options.isPDF ? PDF_PAGE_SIZE : pageSize}
              justifyContent={"flex-end"}
              options={options}
            />
          )}
        </TableFooter>
        {options.isPDF && <RowsTruncatedMessage totalSize={data.length} />}
      </>
    );
  }
}

export const TableFooter = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.customPager ? "space-between" : "flex-end"};
  margin: 4px 0;

  .actions {
    margin: auto;
    padding: 1rem;
  }
`;

export default PaginatedTable;
