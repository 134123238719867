import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Hidden from "./Hidden";

const getPageRange = (currentPage, offset, totalPages) => {
  const maxButtonsCount = offset * 2 + 1;
  const firstButtonValue = currentPage - offset;
  const withinPageRange = page => page > 1 && page <= totalPages - 1;

  return Array(maxButtonsCount)
    .fill()
    .map((_, idx) =>
      firstButtonValue <= 1
        ? Math.max(firstButtonValue, 2) + idx
        : Math.min(firstButtonValue, totalPages - maxButtonsCount) + idx
    )
    .filter(withinPageRange);
};

export class Paginator extends React.Component {
  static propTypes = {
    totalPages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  setPage = page => {
    this.props.onPageChange(page);
  };

  render() {
    const { page, totalCount, totalPages, offset = 1, t, ...rest } = this.props;

    if (!totalPages || totalPages <= 1) {
      return null;
    }

    const pageRange = getPageRange(page, offset, totalPages);

    return (
      <Pagination role="navigation" aria-label="Pagination Navigation">
        <PageIndicator
          type="button"
          id={`${rest["data-testid"]}-previousButton`}
          onClick={() => this.setPage(page - 1)}
          disabled={page <= 1}
          aria-label={t("previous")}
        >
          {t("previous")}
        </PageIndicator>
        <Hidden smDown>
          <PageButton
            key={"first-page"}
            value={1}
            active={page === 1}
            onClick={this.setPage}
          />

          {pageRange[0] > 2 && <Ellipsis>...</Ellipsis>}

          {pageRange.map(n => (
            <PageButton
              key={"page" + n}
              value={n}
              active={n === page}
              onClick={this.setPage}
            />
          ))}

          {pageRange[pageRange.length - 1] < totalPages - 1 && (
            <Ellipsis>...</Ellipsis>
          )}

          <PageButton
            key={"last-page"}
            value={totalPages}
            active={page === totalPages}
            onClick={this.setPage}
          />
        </Hidden>
        <PageIndicator
          type="button"
          id={`${rest["data-testid"]}-nextButton`}
          onClick={() => this.setPage(page + 1)}
          disabled={page >= totalPages}
          aria-label={t("next")}
        >
          {t("next")}
        </PageIndicator>
      </Pagination>
    );
  }
}

export const Ellipsis = styled.span`
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  font-size: 2.2rem;
`;

export const PageButton = ({ value, active, onClick }) => {
  return (
    <PageIndicator type="button" active={active} onClick={onClick.bind(null, value)}>
      {value}
    </PageIndicator>
  );
};

export default withTranslation()(Paginator);

export const Pagination = styled.div`
  display: flex;
  justify-content: ${props => props.justify};
  flex-wrap: wrap;
`;

Pagination.defaultProps = {
  justify: "flex-end",
};

export const PageIndicator = styled.button`
  cursor: pointer;
  border: 1px solid ${props => props.theme.greyAdobe1};
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: ${props => props.theme.spacingUnit * 0.5}px
    ${props => props.theme.spacingUnit * 1}px;
  margin: ${props => props.theme.spacingUnit * 0.5}px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${props => props.theme.greyAdobe1};
      text-decoration: underline;
    `};
`;
