import React from 'react'
import { withTheme } from "styled-components";

import GaugeHelper from './GaugeHelper';


export const ExplainUpdate = () => {
    
    const data = 
			[
				{
					color: `veryHighRisk`,
					description: "Report updated over 24 months ago"
				}, 
				{
					color: `highRisk`,
					description: "Report updated between 12 - 24 months ago"
				}, 
				{
					color: `moderateRisk`,
					description: "Report updated between 6 - 12 months ago"
				},
				{
					color: `lowRisk`,
					description: "Report updated between 3 - 6 months ago"
				}, 
				{
					color: `veryLowRisk`,
					description: " Report updated within the last 3 months"
				},  
			]
		

    const helpTextTable = ( height = "auto") => (
        <GaugeHelper
            height={height}
            data={data}
        />
    );
    
    return (
        <div>
            {helpTextTable(400)}
        </div>
        
    )
}

export default withTheme(ExplainUpdate)