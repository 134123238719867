const SUPPLIER_TO_COUNTRY_MAP = {
  WVB: ["AF", "KH", "LA", "MY", "MM", "TH", "VN", "PLC"],
  CRIF: ["CH", "LI"],
  EMIS: ["CO", "EC", "VE"],
  CoFace: [
    "AL",
    "AM",
    "AZ",
    "BA",
    "BG",
    "BY",
    "GE",
    "HR",
    "CZ",
    "EE",
    "HU",
    "HK",
    "KG",
    "KZ",
    "XK",
    "LV",
    "MD",
    "ME",
    "MK",
    "PL",
    "RO",
    "RS",
    "RU",
    "SK",
    "SI",
    "TJ",
    "TM",
    "UA",
    "UZ",
  ],
  US: ["US", "AS", "GU", "MH", "FM", "MP", "PW", "PR", "VI"],
  LU: ["LU"],
  CreditInfo: ["IS", "LT", "MT"],
  CedarRose: [
    "BH",
    "BJ",
    "BF",
    "CD",
    "EG",
    "JO",
    "KW",
    "LB",
    "OM",
    "PS",
    "QA",
    "SA",
    "SD",
    "SY",
    "AE",
    "EH",
    "YE",
  ],
};

const COUNTRY_TO_SUPPLIER_MAP = Object.entries(SUPPLIER_TO_COUNTRY_MAP).reduce(
  (acc, [supplier, countries]) => {
    return {
      ...acc,
      ...countries.reduce(
        (acc, country) => ({ ...acc, [country]: supplier }),
        {}
      ),
    };
  },
  {}
);

export const findSupplier = country => COUNTRY_TO_SUPPLIER_MAP[country] || null;
