import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { noop } from "../../utils/noop";

export const BaseButton = React.forwardRef(({
  className,
  value,
  disabled,
  children,
  onClick = noop,
  ...rest
}, ref) => (
  <button
    className={className}
    onClick={e => onClick(e, value)}
    disabled={disabled}
    ref={ref}
    {...rest}
  >
    {children}
  </button>
));

const boxShadowSize = "0 0 0 0.2rem";

const successVariant = css`
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.greenPrimary};
  border-color: ${props => props.theme.greenPrimary};
  :hover:enabled {
    background-color: ${props => props.theme.green4};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(72, 180, 97, 0.5);
  }
`;

const greyVariant = css`
  background-color: ${props => props.theme.greyBackground};
  :hover:enabled {
    background-color: ${props => props.theme.greyAdobe1};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(146, 146, 152, 0.5);
  }
`;

const transparentVariant = css`
  background-color: transparent;
  :hover:enabled {
    background-color: ${props => props.theme.greyAdobe1};
  }
`;

const dangerVariant = css`
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.redPrimary};
  border-color: ${props => props.theme.redPrimary};
  :hover:enabled {
    background-color: ${props => props.theme.red4};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(225, 83, 97, 0.5);
  }
`;

const infoVariant = css`
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.blue3};
  border-color: ${props => props.theme.blue3};
  :hover:enabled {
    background-color: ${props => props.theme.blue2};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(58, 176, 195, 0.5);
  }
`;

const skyBlueVariant = css`
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.blue5};
  border-color: ${props => props.theme.blue4};
  :hover:enabled {
    background-color: ${props => props.theme.blue4};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(58, 188, 210, 0.5);
  }
`;

const navVariant = css`
  color: ${props => props.theme.white};
  background-color: ${`#006EFA`};
  border-color: ${`#006EFA`};
  :hover:enabled {
    background-color: ${props => props.theme.blue2};
  }
  :focus {
    box-shadow: ${boxShadowSize} rgba(58, 176, 195, 0.5);
  }
`;

const borderVariant = css`
  background-color: transparent;
  border-color: ${props => props.theme.greyAdobe1};
`

const unstyledVariant = css`
  background-color: transparent;
  border: none;
`;

const defaultVariant = css`
  border: none;
  background-color: transparent;
  :hover:enabled {
    background-color: ${props => props.theme.headerBackgroundColor};
  }
  &:focus {
    outline: 0;
    border-color: #80bdff;
    box-shadow: ${boxShadowSize} rgba(0, 123, 255, 0.25);
  }
`;

const getButtonVariant = variant => {
  switch (variant) {
    case "success":
      return successVariant;
    case "neutral":
      return greyVariant;
    case "danger":
      return dangerVariant;
    case "transparent":
      return transparentVariant;
    case "info":
      return infoVariant;
    case "skyblue":
      return skyBlueVariant;
    case "unstyled":
      return unstyledVariant;
    case "border":
      return borderVariant;
    case "nav":
      return navVariant;
    default:
      return defaultVariant;
  }
};

const baseButtonStyles = css`
  cursor: pointer;
  outline: none;
  border: 1px solid ${props => props.theme.greyAdobe1};
  border-radius: 5px;
  padding: ${props => props.theme.spacingUnit}px
    ${props => props.theme.spacingUnit * 2}px;
  transition: background-color 0.3s;
  justify-content: center;
  align-items: center;
  display: inline-block;
  font-size: 1.1rem;
  ${props => getButtonVariant(props.variant)};
  svg {
    vertical-align: middle;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const LinkButton = styled(Link)`
  ${baseButtonStyles}
`;
export const AnchorButton = styled.a`
  ${baseButtonStyles}
`;

const Button = styled(BaseButton)`
  ${baseButtonStyles}
`;

Button.propTypes = {
  variant: PropTypes.oneOf([
    "success",
    "neutral",
    "danger",
    "transparent",
    "info",
    "skyblue",
    "unstyled",
    "none",
    "outlined",
    "border",
    "nav"
  ]),
};

Button.defaultProps = {
  variant: "none",
  type: "button",
};

export const IconButton = styled(Button)`
  border-radius: 50%;
  padding: ${props => props.theme.spacingUnit}px;
  border: none;
  ${props =>
    props.variant === "transparent"
      ? null
      : css`
          box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        `};
`;

export default Button;
