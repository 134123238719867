import React from 'react'

const NavDisclosureMessage = () => {
  return (
    <>
      <div>The credit card, financing and service products that appear on this site are from credit card, financing and service companies from which this site receives compensation. This compensation may impact how and where products appear on this site (including, for example, the order in which they appear). This site does not include all credit card, financing and service products or all available credit card, financing and service products. All images and trademarks are the property of their respective owners. Editorial and review content is the property of Nav, and has not been approved, provided, or reviewed by the company providing the credit card, financing, or service.</div>
      <br />
      <div>For complete information, see the terms and conditions on the credit card, financing and service issuer’s website. While Nav always strives to present the most accurate information, we show a summary to help you choose a product, not the full legal terms. You should understand the full terms of products as stated by the issuer itself.</div>
      <br />
      <div>Nav uses the VantageScore 3.0 credit score to determine which credit offers are recommended which may differ from the credit score used by lenders and service providers. However, credit score alone does not guarantee or imply approval for any credit card, financing, or service offer.</div>
    </>
  )
}

export default NavDisclosureMessage