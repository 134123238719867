export const FRESH_INVESTIGATION_ZIP_CODE = /(^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$)/;
export const FRESH_INVESTIGATIONS_WEBSITE = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
export const FRESH_INVESTIGATION_PHONE_NUMBER = /^\d{10}$/;
export const DEBT_INVOICE_VALUE = /^[0-9]\d*(\.\d{1,2})?$/;
export const SEARCH_SUPPORT_POSTCODE = /^\d{5}$/;
export const SEARCH_SUPPORT_PHONE_NUMBER = /^\d{10}$/;
export const SEARCH_SUPPORT_WEBSITE = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
export const BE_REG_NUMBER = /^\d{9}$/;
export const CURRENCY_ABBREVIATION = /[\d+\s][k|m|b|t]/;
export const HTTP_PROTOCOL = /^http[s]?:[/]{2}/;
export const CONNECT_ID_IDENTIFIER = /^([A-Z]{2})[0-9]{0,3}-[X10]-.+/;
export const WX_ID_IDENTIFIER = /^([A-Z]{2})[0-9]{0,3}-[X10]-WX.+/;
export const REG_NO_IDENTIFIER = /^([A-Z]{2})\d+$/;

export const PHONE_NUMBER = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
export const POSITIVE_NUMBER = /^\d+$/;
export const ILLEGAL_CHARACTERS = /^((?![<>!*^%;]).)*$/
export const EIN_CHECK = /^[0-9]\d?-\d{7}$/

const ukCompanyNumberCodes = [
  "AC",
  "ZC",
  "FC",
  "GE",
  "LP",
  "OC",
  "SE",
  "SA",
  "SZ",
  "SF",
  "GS",
  "SL",
  "SO",
  "SC",
  "NF",
  "GN",
  "NL",
  "NC",
  "R0",
  "NI",
  "IP",
  "SP",
  "IC",
  "SI",
  "NP",
  "RC",
  "SR",
  "NO",
];

export const UK_REG_NO_IDENTIFIER = new RegExp(
  `^(${ukCompanyNumberCodes.join("|")})\\d+$`
);
