import React from "react";
import styled from "styled-components";
import { Field, ErrorMessage as FormikErrorMessage } from "formik";
import Input from "./Input";
import Select from "./Select";
import TextArea from "./TextArea";
import { InvalidFeedback } from "./Form";
import has from "lodash/has";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  /* min-width: 150px;
  max-width: 200px; */

  @media only screen and (min-width: ${props =>
      `${props.theme.breakpointSm}px`}) {
    width: 55%;
  }
`;

export const InputField = ({ ...props }) => (
  <ComponentField
    {...props}
    component={InputWithErrorMessageComponent}
    variant="outlined"
  />
);

export const SelectField = ({ ...props }) => (
  <ComponentField
    {...props}
    component={SelectWithErrorMessageComponent}
    variant="outlined"
  />
);

export const TextAreaField = ({ ...props }) => (
  <ComponentField {...props} component={TextAreaWithErrorMessageComponent} />
);

export const ComponentField = ({ component, ...props }) => (
  <Field
    {...props}
    /*To ensure our input fields are W3C accessible they require an `id` field, if one is not passed in use the same value as the `name` prop */
    id={props.id || props.name}
    component={component}
  />
);

const NonWarningMessage = styled.span`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: gray;
`;

const renderNonWarning = (name, errors) => {
  if (name === 'taxID' && !has(errors,'errors.taxID')  ){
    return <NonWarningMessage>EIN, ex: XX-XXXXXXX <br /> (not Social Security Number)</NonWarningMessage>
  }
}

export const InputWithErrorMessageComponent = ({ field, form, ...props }) => (
  <InputContainer>
    <Input {...field} {...props} />
    {renderNonWarning(field.name, form)}
    <FormikErrorMessage name={field.name} component={InvalidFeedback} />
  </InputContainer>
);

export const SelectWithErrorMessageComponent = ({ field, form, ...props }) => (
  <InputContainer>
    <Select {...field} {...props} />
    <FormikErrorMessage name={field.name} component={InvalidFeedback} />
  </InputContainer>
);

export const TextAreaWithErrorMessageComponent = ({
  field,
  form,
  ...props
}) => (
  <InputContainer>
    <TextArea {...field} {...props} />
    <FormikErrorMessage name={field.name} component={InvalidFeedback} />
  </InputContainer>
);

export const CheckboxField = ({ name, value }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <input
          name={name}
          id={name}
          type="checkbox"
          value={field.value}
          checked={field.value === value}
          onChange={() => {
            form.setFieldValue(name, !field.value);
          }}
        />
      )}
    </Field>
  );
};
