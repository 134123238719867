import {
  FETCH_PROVENIR_HISTORY,
  FETCH_PROVENIR_HISTORY_SUCCESS,
} from "../actions/types";

const initialState = {
  result: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROVENIR_HISTORY:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PROVENIR_HISTORY_SUCCESS:
      return {
        ...state,
        result: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
