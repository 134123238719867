import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Typography } from "@creditsafe/ui-components";
import PopupHelpButton from "./PopupHelpButton";

const HelpIcon = styled(PopupHelpButton)`
  cursor: auto;  

  .testing {
    font-size:300%;
  }


`;

const DefinitionList = styled(({ fields, model, className }) => {
  return (
    <dl className={className}>
      {Object.keys(fields).map((key, index) => {
        const field = fields[key];
        if (!model[key] || field.hidden) {
          return " ";
        }

        return (
          <div key={index} className="item" id={field.id}>
            <Typography variant="h6" component="dt">
              {field.title}
              {field.helpText && (
                  <span data-testid="field-help-text" title={field.helpText}>
                    <HelpIcon style={{fontSize: "100%"}}/>
                  </span>
              )}
            </Typography>
            <dd>{model[key]}</dd>
          </div>
        );
      })}
    </dl>
  );
})`
  ${({ layout }) =>
    layout === "horizontal" &&
    css`
      .item {
        dt {
          float: left;
          width: 40%;
          clear: left;
          text-align: left;
        }
        dd {
          margin-left: calc(40% + 0.25rem);
          margin-bottom: 16px;
          text-transform: capitalize;
        }
        &:last-child {
          dd {
            margin-bottom: 0;
          }
        }
      }
      #website {
        a {
          text-transform: lowercase;
        }
      }
    `}

  ${({ layout }) =>
    layout === "vertical" &&
    css`
      .item {
        dt {
          width: 100%;
        }
        dd {
          margin-bottom: 16px;
        }
        &:last-child {
          dd {
            margin-bottom: 0;
          }
        }
      }
    `}
`;

DefinitionList.propTypes = {
  model: PropTypes.shape({}).isRequired,
  fields: PropTypes.shape({}).isRequired,
};

export default DefinitionList;
