import React from "react"
import styled from "styled-components"

import Button from "../core/Button";
import { InputField } from "../core/FormComponents";
import { ErrorMessage } from "../ErrorMessage";



import { Formik, Form } from "formik";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";
import { setReportLoading } from "../../actions/mergeActions";

import { formatDateMDY } from "../../utils/date";
import { noNumber } from "../../utils/noNumber";
import {
  POSITIVE_NUMBER,
  ILLEGAL_CHARACTERS,
} from "../../utils/regexConstants";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as Yup from "yup";

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;

      @media only screen and (min-width: ${(props) =>
          `${props.theme.breakpointSm}px`}) {
        width: 55%;
      }
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const DollarSign = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    padding-left: 15px;
  }
  :before {
    position: absolute;
    content: "$";
    padding: 0.8rem 5px;
    text-align: center;
  }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

function EditExpenditure({
  row,
  email,
  expressApi,
  onClose,
  onError,
  error
}){

  const expenditureValidation = Yup.object().shape({
    expenditurevalue: Yup.string()
      .matches(POSITIVE_NUMBER, {
        message: "Please enter a whole number greater than zero",
      })
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .required("Please enter the amount of your expenditure."),
    
  });

  return(
    <Formik
      initialValues={{
        userid: email,
        rowid: row.id,
        expenditurevalue: row.expenditurevalue,
        expenditureenddate: row.expenditureenddate,
      }}
      onSubmit={async (values, actions) => {
        await setReportLoading("expenditures");
        await fetch(`${expressApi}/expenditure/${row.id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          },
          body: JSON.stringify({
            userid: values.userid,
            rowid: values.rowid,
            expenditurevalue: values.expenditurevalue,
            expenditureenddate: values.expenditureenddate
          })
        })
          .then((response) => {
            if (response.status >= 300) {
              onError(response.status);
            } else {
              onClose(row.companyid);
            }
          })
          .catch((err) => console.error(err));
      }}
      validationSchema={expenditureValidation}
    >
      {({ values, setFieldTouched, setFieldValue }) => (
        <Form>
          <Container>
            <AttributeRow
              width={100}
              name={<label>Provider</label>}
              value={
                <div 
                  style={{ textTransform: "capitalize" }}
                >
                  {row.expenditureprovider.toLowerCase()}
                </div>}
            />
            <AttributeRow
              width={100}
              name={<label>Category</label>}
              value={<div>{row.expenditurecategory}</div>}
            />
            <AttributeRow
              width={100}
              name={<label>Description</label>}
              value={<div>{row.expendituretype}</div>}
            />
            <AttributeRow
              width={100}
              name={<label>Start Date</label>}
              value={<div>{formatDateMDY(row.expenditurestartdate)}</div>}
            />
            <AttributeRowDate
              width={100}
              name={<label>End Date</label>}
              value={
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  clearable
                  value={formatDateMDY(values.expenditureenddate)}
                  onChange={(value) =>
                    setFieldValue("expenditureenddate", value)
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              }
            />
            <AttributeRow
              width={100}
              name={<label>Installments</label>}
              value={
                <DollarSign>
                  <InputField 
                    name="expenditurevalue"
                    type="number"
                    onKeyPress={noNumber} 
                  />
                </DollarSign>
                
              }
            />
          </Container>
          <ButtonBar>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </ButtonBar>
          {error.outOfCredits && (
                  <ErrorMessage
                    errorMessage={
                      "You are out of Expenditure credits, please contact your account manager to add more."
                    }
                  />
                )}
        </Form>
      )}
    </Formik>
  )
}

export default EditExpenditure;