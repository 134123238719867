import {
  FETCH_COMPANY_REPORT,
  FETCH_COMPANY_REPORT_ERROR,
  FETCH_COMPANY_REPORT_SUCCESS,
} from "../actions/types";

const initialState = {
  report: {
    report: {
      companySummary: {
        businessName: "",
        companyNumber: "",
        country: "",
        mainActivity: {
          code: "",
        },
        latestTurnoverFigure: {
          currency: "",
          value: "",
        },
      },
      companyIdentification: {
        basicInformation: {
          legalForm: {
            providerCode: "",
            description: "",
          },
          principalActivity: {
            description: "",
          },
          contactAddress: {
            simpleValue: "",
            telephone: "",
          },
          companyRegistrationDate: "",
          operationsStartDate: "",
        },
        activityClassifications: [
          {
            classification: "",
            activities: [
              {
                code: "",
                description: "",
              },
            ],
          },
        ],
      },
      creditScore: {
        currentCreditRating: {
          creditLimit: {
            curreny: "",
            value: "",
          },
          providerValue: {
            value: "",
          },
        },
      },
      additionalInformation: {
        misc: {
          yearsInBusiness: "",
          addressType: "",
          locationType: "",
          corporatePrimaryAddress: "",
          taxID: "",
          establishmentDate: "",
        },
        corporateRecordAdditionalInformation: [
          {
            address: {
              simpleValue: "",
              city: "",
              postalCode: "",
              province: "",
            },
          },
        ],
        primaryCorporateRecord: {
          otherLegalName: "",
          sosCharterNumber: "",
        },
        branchOffices: [
          {
            branchType: "",
            safeNumber: "",
            name: "",
            district: "",
            address: {
              simpleValue: "",
              street: "",
              city: "",
              postalCode: "",
              province: "",
              telephone: "",
              country: "",
            },
          },
        ],
        extendedPaymentData: {
          paidInvoices: {
            paidInvoicesSummary: {
              totalValue: 0,
              uniqueCompanies: 0,
            },
          },
          tradeReferences: {
            tradeReferenceSummary: {
              numberOfTradeLines: 0,
              valueOfTotalTradeLines: 0,
              numberOfUniqueTradeLineProviders: 0,
              combinedCreditLimit: 0,
              experienceScore: 0,
              paymentScore: 0,
            },
          },
        },
        mycData:{
          lastDataUpdate: "",
        }
      },
      contactInformation: {
        websites: [],
      },
      otherInformation: {
        employeesInformation: [
          {
            numberOfEmployees: "",
          },
        ],
      },
      groupStructure: {
        ultimateParent: {
          country: "",
          name: "",
          safeNumber: "",
          registrationNumber: "",
        },
        immediateParent: {
          country: "",
          name: "",
          safeNumber: "",
          registrationNumber: "",
        },
      },
      directors: {
        currentDirectors: [
          {
            id: "",
            name: "",
            address: {
              simpleValue: "",
              street: "",
              city: "",
              postalCode: "",
              province: "",
            },
            gender: "",
            directorType: "",
            positions: [
              {
                positionName: "",
              },
            ],
            additionalData: {
              primaryOfficer: false,
            },
          },
        ],
      },
    },
  },
  isLoading: false,
  reportError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_REPORT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_COMPANY_REPORT_SUCCESS:
      return {
        ...state,
        report:{
          ...action.payload,
          report: {
            ...state.report.report,
            ...action.payload.report
          }
        },
        isLoading: false,
      };
    case FETCH_COMPANY_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        reportError: true,
      }
    default:
      return state;
  }
}
