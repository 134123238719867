import React from "react";

import styled from "styled-components";
import queryString from "query-string";

import CardTitle from "../core/CardTitle";
import Button from "../core/Button";
import codatImg from "../../assets/img/icons/codatAsset.svg";

export const CodatContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 157px;
`;

export const HelpTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: space-around;
  width: 75%;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    display: none;
  }
`;

export const CodatImage = styled.img`
  max-height: 157px;
  width: 100%;
  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    display: none;
  }
`;

export const CardText = styled.span`
  width: 100%;
`;

export default function Codat({ companyId, safeNumber, companyName }) {
  const query = {
    companyName: `${safeNumber} - ${companyName}`,
    UniqueId: companyId,
    redirect: window.location.href,
  };
  const codatLink = `https://creditsafe.codat.io/link?${queryString.stringify(
    query
  )}`;
  return (
    <CodatContainer id="walkMeCodat">
      <HelpTitle>
        <CardTitle title="Connect your data" />
        <CardText>
          In order to provide an even more accurate picture of your company and
          your business partners, please click here to link the data within your
          accountancy software with your credit report.
        </CardText>
        <Button variant="border">
          <a href={codatLink}>Click here</a>
        </Button>
      </HelpTitle>
      <ImageContainer>
        <CodatImage src={codatImg} alt="Codat" />
      </ImageContainer>
    </CodatContainer>
  );
}
