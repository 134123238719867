import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import camelCase from "lodash/camelCase";
import isNil from "lodash/isNil";

const BaseStatusCircle = ({ status, text, className, ...rest }) => (
  <span className={className}>
    <StatusCircleContainer status={status} />
    {!isNil(text) && (
      <span className="status-text" data-testid={rest["data-testid"]}>
        {text}
      </span>
    )}
  </span>
);

export const StatusCircleContainer = styled.div`
  display: inline-block;
  border-radius: 50%;
  max-width: 10px;
  max-height: 10px;
  width: 10px;
  height: 10px;
  ${({ theme, status }) =>
    status &&
    css`
      background-color: ${theme[camelCase(status)]};
    `};
`;

export const StatusCircle = styled(BaseStatusCircle)`
  text-align: middle;
  white-space: nowrap;
  .status-text {
    margin-left: ${props => props.theme.spacingUnit * 1}px;
  }
`;

StatusCircle.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusCircle;
