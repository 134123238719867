import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import Codat from "../core/Codat";
import { ErrorMessage } from "../ErrorMessage";
import Expenditures from "./Expenditures";
import ExpenditureSummary from "./ExpenditureSummary";
import { InputField, SelectField } from "../core/FormComponents";
import { Jumbotron } from "../core/Jumbotron";
import { HelpTitle } from "../report/KeyInformation";
import { InlineLoader } from "../core/Loading";
import LoadingPage from "../../components/core/LoadingPage";
import { PaginatedTable } from "../PaginatedTable";
import SearchWithFilters from "../SearchWithFilters";
import StatusCircle from "../core/StatusCircle";
import SomethingWentWrong from "../../components/core/SomethingWentWrong";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import {
  fetchBusinessExpenditures,
  fetchPayment,
} from "../../actions/paymentActions";
import { resetSearch } from "../../actions/searchActions";

import { PAGE_SIZE_MEDIUM } from "../../utils/constants";
import { formatDateToUtc } from "../../utils/dateFormatter";
import {
  buildingCosts,
  financialServices,
  utilities,
  vehiclesAndEquipment,
  employees,
  businessCosts,
  taxes,
  insurancePremiums,
} from "../../utils/expenditureType";
import { frequency } from "../../utils/frequency";
import { expenditureCategory } from "../../utils/expenditureCategory";
import { noNumber } from "../../utils/noNumber";
import {
  POSITIVE_NUMBER,
  ILLEGAL_CHARACTERS,
} from "../../utils/regexConstants";
import { MYC_EXPENDITURES_SVC_ID } from "../../utils/serviceConstants";
import { isValidService } from "../../utils/services";
import { upperCaseFirstLetter } from "../../utils/string";

import { Typography } from "@creditsafe/ui-components";
import DateFnsUtils from "@date-io/date-fns";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import UndoIcon from '@material-ui/icons/Undo';

import { Formik, Form } from "formik";

import isEmpty from "lodash/isEmpty";

import * as Yup from "yup";

export const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
`;

export const DashboardContent = styled.div`
  padding: 1rem 3rem;
  text-align: left;

  @media only screen and (max-width: ${(props) =>
    `${props.theme.breakpointSm}px`}) {
    padding: 0.5em;
  }
`;

export const CertificateHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem 0;
  > div {
    width: 99%;
  }
  @media (min-width: 1400px) {
    flex-direction: row;
    > div {
      width: 50%;

      :first-child {
        margin-right: 0.75rem;
      }

      :nth-child(2) {
        margin-left: 0.75rem;
      }
    }
  }
`;

export const TableWrapper = styled.div`
  table {
    width: 90%;
    margin: auto;

    tr:hover {
      cursor: pointer;
    }
  }
`;

export const CheckboxCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;

  input {
    margin-right: 5px;
  }
`;

export const ExpenditureInputs = styled.div`
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    justify-content: center;

    @media only screen and (min-width: ${(props) =>
    `${props.theme.breakpointMd}px`}) {
      width: 60%;
    }
  }
`;

export const DollarSign = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    padding-left: 15px;
  }
  :before {
    position: absolute;
    content: "$";
    padding: 0.8rem 5px;
    text-align: center;
  }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const AttributeRowDate = styled(AttributeRow)`
  > div {
    > div {
      width: 85%;

      @media only screen and (min-width: ${(props) =>
    `${props.theme.breakpointSm}px`}) {
        width: 55%;
      }
    }
  }

  input {
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    max-height: 33.6px;
  }
`;

export const frequencyOptions = () =>
  frequency.map((val, index) => (
    <option value={index} key={val}>
      {val}
    </option>
  ));

export const expenditureOptions = () =>
  expenditureCategory.map((val, index) => (
    <option value={(index === 0 ? "" : val)} key={val}>
      {val}
    </option>
  ));

export const expendTypes = (a) =>
  a.map((val, index) => (
    <option value={index === 0 ? "" : val} key={val}>
      {val}
    </option>
  ));

function expenditureForm(
  companyId,
  email,
  selectedCompany,
  disabled,
  safeNumber,
  backToSearch,
  close,
  enableSubmission,
  // fetchBusinessExpenditures,
  // fetchPayment,
  onError,
  error,
  expressApi,
  userReport,
  profile,
  expenditures
) {
  const expenditureValidation = Yup.object().shape({
    expenditurefrequency: Yup.string()
      .required(
        "Please enter the frequency of expenditure."
      ),
    expendituretype: Yup.string()
      .required(
        "Please select a description of your expenditure."
      ),
    expenditurecategory: Yup.string()
      .required(
        "Please select the expenditure category."
      ),
    expenditurevalue: Yup.string()
      .matches(POSITIVE_NUMBER, {
        message: "Please enter a whole number greater than zero",
      })
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .required("Please enter the amount of your expenditure."),
    totalpayment: Yup.string()
      .matches(POSITIVE_NUMBER, {
        message: "Please enter a whole number greater than zero",
      })
      .matches(ILLEGAL_CHARACTERS, {
        message: "Your entry contains illegal characters",
      })
      .required("Please enter the total amount of your expenditure."),
  });

  const duplicateCheck = (submission, expenditures) => {
    const duplicate = expenditures.filter(
      (expenditure) =>
        expenditure.expendituresafenumber === submission.expendituresafenumber &&
        expenditure.expenditureprovider === submission.expenditureprovider &&
        expenditure.expenditurevalue.toString() === submission.expenditurevalue.toString() &&
        expenditure.expenditurestartdate.slice(0, 10) === submission.expenditurestartdate.toISOString().slice(0, 10) &&
        expenditure.expenditureenddate.slice(0, 10) === submission.expenditureenddate.toISOString().slice(0, 10)
    )

    return duplicate;
  };

  return (
    <DashboardContent>
      <Formik
        initialValues={{
          expendituresafenumber: selectedCompany.safeNo,
          expenditureprovider: selectedCompany.name,
          expenditurecategory: "",
          expendituretype: "",
          expenditurefrequency: "",
          expenditurevalue: "",
          totalpayment: "",
          consent: false,
          expenditurestartdate: new Date(),
          expenditureenddate: new Date(),
        }}
        validationSchema={expenditureValidation}
        onSubmit={async (values) => {
          const duplicate = duplicateCheck(values, expenditures);
          if (duplicate.length < 1) {
            await fetch(`${expressApi}/expenditure`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
              },
              body: JSON.stringify({
                userid: userReport.userid,
                companyid: companyId,
                expenditurevalue: values.expenditurevalue,
                expenditurefrequency: values.expenditurefrequency,
                expenditurestartdate: formatDateToUtc(
                  values.expenditurestartdate
                ),
                expenditureenddate: formatDateToUtc(values.expenditureenddate),
                created_at: new Date(),
                updated_at: new Date(),
                expendituretype: values.expendituretype.toLowerCase(),
                expenditurecategory: values.expenditurecategory.toLowerCase(),
                expenditureprovider: selectedCompany.name,
                expenditureprovidercity: values.expenditureprovidercity,
                safenumber: safeNumber,
                totalpayment: values.totalpayment,
                expendituresafenumber: selectedCompany.safeNo,
                userverified: userReport.userstatus,
                customer_id: profile.customer.id,
              }),
            })
              .then((response) => {
                if (response.status >= 300) {
                  onError(response.status);
                } else {
                  close(companyId);
                }
              })
              .catch((err) => console.error(err));
          } else {
            alert("You have already submitted this expenditure.");
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Card>
              <HelpTitle>
                <CardTitle title="Company Details" />
                <Button>
                  <UndoIcon onClick={backToSearch} />
                </Button>
              </HelpTitle>
              <PaginatedTable
                headings={[
                  { key: "name", text: "Company Name" },
                  { key: "regNo", text: "Registration Number" },
                  { key: "safeNo", text: "Safe Number" },
                  { key: "address.simpleValue", text: "Address" },
                  { key: "status", text: "Status" },
                  { key: "officeType", text: "Location Type" },
                ]}
                data={[selectedCompany]}
                formatters={{
                  name: (data) => data.toLowerCase(),
                  status: (x) =>
                    x && (
                      <StatusCircle
                        status={x}
                        text={`${upperCaseFirstLetter(x)}`}
                      />
                    ),
                }}
              />
            </Card>
            <ExpenditureInputs>
              <Card>
                <HelpTitle>
                  <CardTitle title="Payment Information" />
                </HelpTitle>
                <AttributeRow
                  width={100}
                  name={<label>Category</label>}
                  value={
                    <SelectField name="expenditurecategory">
                      {expenditureOptions()}
                    </SelectField>
                  }
                />
                {values.expenditurecategory === "" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={<SelectField name="expendituretype"></SelectField>}
                  />
                )}
                {values.expenditurecategory === "Building Costs" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(buildingCosts)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Financial Services" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(financialServices)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Utilities" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(utilities)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Vehicles and Equipment" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(vehiclesAndEquipment)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Employees" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(employees)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Business Costs" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(businessCosts)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Taxes" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(taxes)}
                      </SelectField>
                    }
                  />
                )}

                {values.expenditurecategory === "Insurance Premiums" && (
                  <AttributeRow
                    width={100}
                    name={<label>Description</label>}
                    value={
                      <SelectField name="expendituretype">
                        {expendTypes(insurancePremiums)}
                      </SelectField>
                    }
                  />
                )}

                <AttributeRow
                  width={100}
                  name={<label>Payment Frequency</label>}
                  value={
                    <SelectField name="expenditurefrequency">
                      {frequencyOptions()}
                    </SelectField>
                  }
                />
                <AttributeRowDate
                  width={100}
                  name={<label>Start</label>}
                  value={
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        maxDate={new Date()}
                        maxDateMessage="Date can not be set in future"
                        value={values.expenditurestartdate}
                        onChange={(value) =>
                          setFieldValue("expenditurestartdate", value)
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  }
                />
                <AttributeRowDate
                  width={100}
                  name={<label>Finish</label>}
                  value={
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        id="date-picker-dialog"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        clearable
                        value={values.expenditureenddate}
                        minDate={new Date(values.expenditurestartdate)}
                        minDateMessage="Can not be before start date"
                        onChange={(value) =>
                          setFieldValue("expenditureenddate", value)
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Installment Amounts</label>}
                  value={
                    <DollarSign>
                      <InputField
                        onKeyPress={noNumber}
                        type="number"
                        name="expenditurevalue"
                      />
                    </DollarSign>
                  }
                />
                <AttributeRow
                  width={100}
                  name={<label>Total Amount</label>}
                  value={
                    <DollarSign>
                      <InputField
                        onKeyPress={noNumber}
                        type="number"
                        name="totalpayment"
                      />
                    </DollarSign>
                  }
                />
                <CheckboxCell>
                  <input
                    type="checkbox"
                    name="consent"
                    onChange={enableSubmission}
                  />
                  I confirm that all information provided is true and accurate
                </CheckboxCell>
                <ButtonBar>
                  <Button
                    variant="success"
                    type="submit"
                    disabled={disabled || error.outOfCredits}
                  >
                    Submit Expenditure
                  </Button>
                </ButtonBar>
                {error.outOfCredits && (
                  <ErrorMessage
                    errorMessage={
                      "You are out of Expenditure credits, please contact your account manager to add more."
                    }
                  />
                )}
              </Card>
            </ExpenditureInputs>
          </Form>
        )}
      </Formik>
    </DashboardContent >
  );
}

class Expenditure extends Component {
  state = {
    selectedCompany: {},
    searching: false,
    disabled: true,
    error: {},
  };

  componentDidMount() {
    this.props.resetSearch();
  }

  handleClick = (company) => {
    this.setState({ selectedCompany: company });
  };

  noCompanySelected = () => {
    this.setState({
      selectedCompany: {
        address: {
          city: "",
          postCode: "",
          province: "",
          street: "",
          simpleValue: "",
        },
        country: "",
        id: "",
        id2: "",
        name: "",
        officeType: "",
        regNo: "",
        safeNo: "",
        status: "",
        types: "",
      },
    });
  };

  onClose = (companyId) => {
    this.setState({
      searching: false,
      disabled: true,
      error: {},
    });
    this.props.resetSearch();
    this.setState({ selectedCompany: {} });
    this.props.fetchBusinessExpenditures(companyId);
    this.props.fetchPayment(companyId);
  };

  onError = (status) => {
    if (status === 403) {
      this.setState({
        error: { outOfCredits: true },
      });
    }
  };

  handleSearch = () => {
    this.setState({ searching: !this.state.searching });
    if (this.state.searching) {
      this.props.resetSearch();
      this.setState({ selectedCompany: {} });
    }
  };

  backToSearch = () => {
    this.setState({
      selectedCompany: {},
      disabled: true,
    });
  };

  enableSubmission = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  render() {
    const {
      isLoading,
      isSearching,
      email,
      searched,
      safeNumber,
      companyId,
      report,
      payments,
      services,
      expressApi,
      expendituresError,
      userReport,
      profile
    } = this.props;

    const { selectedCompany, searching, disabled, error } = this.state;
    const companyName = report.companySummary.businessName;
    const { expenditures } = payments;

    const hasExpenditures = isValidService(services, MYC_EXPENDITURES_SVC_ID);

    if (expendituresError) {
      return (
        <SomethingWentWrong />
      )
    }

    return !companyId ? (
      <LoadingPage />
    ) : (
      <>
        {!searching && (
          <CertificateHeader>
            <Card id="walkMeExpendSummary">
              <HelpTitle>
                <CardTitle title="Business Expenditure Summary" />
                <Button
                  variant="success"
                  onClick={this.handleSearch}
                  disabled={!hasExpenditures}
                  className="addButton"
                  id="walkMeAddExpend"
                  title={
                    !hasExpenditures
                      ? "You are out of Expenditures. Please contact your account manager to get more."
                      : null
                  }
                >
                  Add Expenditure <AddCircleOutlineIcon />
                </Button>
              </HelpTitle>
              <ExpenditureSummary
                isLoading={isLoading}
                expenditures={expenditures}
              />
            </Card>
            <Card>
              <Codat
                safeNumber={safeNumber}
                companyId={companyId}
                companyName={companyName}
              />
            </Card>
          </CertificateHeader>
        )}

        {searching ? (
          <>
            {!isEmpty(selectedCompany) ? (
              expenditureForm(
                companyId,
                email,
                selectedCompany,
                disabled,
                safeNumber,
                this.backToSearch,
                this.onClose,
                this.enableSubmission,
                // fetchBusinessExpenditures,
                // fetchPayment,
                this.onError,
                error,
                expressApi,
                userReport,
                profile,
                expenditures
              )
            ) : (
              <>
                <Jumbotron>
                  <PageHeader>
                    <Typography variant="h1" font="secondary">
                      Search Company
                    </Typography>
                    <ButtonBar>
                      <Button variant="danger" onClick={this.handleSearch}>
                        Go Back
                      </Button>
                    </ButtonBar>
                  </PageHeader>
                  <SearchWithFilters />
                </Jumbotron>

                {isSearching ? (
                  <InlineLoader
                    isLoading={isSearching}
                    delayMS={0}
                    loadingText={"Searching..."}
                  />
                ) : (
                  <TableWrapper>
                    <PaginatedTable
                      headings={[
                        { key: "name", text: "Name" },
                        { key: "address.simpleValue", text: "Address" },
                      ]}
                      data={searched.results.companies}
                      formatters={{
                        name: (data) => data.toLowerCase(),
                      }}
                      pageSize={PAGE_SIZE_MEDIUM}
                      onClick={this.handleClick}
                      actions={
                        <><p>Unable to find the company you're looking for?</p><p>Please refine the search criteria above or speak to your account manager for help adding a company to our database</p></>}
                    />
                  </TableWrapper>
                )}
              </>
            )}
          </>
        ) : (
          <Expenditures
            isLoading={isLoading}
            report={report}
            email={email}
            expressApi={expressApi}
            onClose={this.onClose}
            onError={this.onError}
            error={error}
          />
        )}
      </>
    );
  }
}

Expenditure.propTypes = {
  searched: PropTypes.object,
  resetSearch: PropTypes.func,
  email: PropTypes.string,
  fetchBusinessExpenditures: PropTypes.func,
  fetchPayment: PropTypes.func,
  payments: PropTypes.object,
  safeNumber: PropTypes.string,
  companyId: PropTypes.string,
  report: PropTypes.object,
  userReport: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoading: state.payment.expendituresLoading,
  searched: state.search,
  isSearching: state.search.isSearching,
  email: state.user.info.email,
  payments: state.payment,
  expendituresError: state.payment.expendituresError,
  safeNumber: state.company.report.report.companySummary.companyNumber,
  companyId: state.company.report.report.companyId,
  report: state.company.report.report,
  services: state.user.services,
  expressApi: state.app.config.EXPRESS.URL,
  userReport: state.user.report,
  profile: state.safeNumber.profile,
});

export default connect(mapStateToProps, {
  resetSearch,
  fetchBusinessExpenditures,
  fetchPayment,
})(Expenditure);
