import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";

export class GaugeBase extends Component {
  drawNeedle = chart => {
    const { width, height, ctx, options, data } = chart.chart;
    if (options.needleData) {
      const cx = width / 2;
      const needleBaseDiameter = Math.floor(height / 15);
      const cy = height - Math.ceil(height / 15);

      ctx.translate(cx, cy);
      ctx.rotate(-data.radianAngle);
      ctx.beginPath();
      ctx.moveTo(0, -Math.ceil(needleBaseDiameter / 2));
      ctx.lineTo(height * options.needleData.ratio, 0);
      ctx.lineTo(height * options.needleData.ratio, 1);
      ctx.lineTo(0, 2);
      ctx.fillStyle = `rgba(${options.needleData.rgbaColour},0.8)`;
      ctx.fill();
      ctx.rotate(data.radianAngle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, needleBaseDiameter, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(${options.needleData.rgbaColour})`;
      ctx.fill();
    }
  };

  render() {
    const {
      data,
      colours,
      animate,
      options,
      radianAngle,
      width,
      height,
      className,
    } = this.props;

    const chartData = {
      radianAngle: radianAngle || 0,
      datasets: [
        {
          data: data || [],
          backgroundColor: colours || [],
        },
      ],
    };

    const defaultOptions = {
      rotation: Math.PI,
      circumference: Math.PI,
      cutoutPercentage: 24,
      animation: { animateRotate: animate || true },
      needleData: { ratio: 0.55, rgbaColour: "105,105,105" },
      tooltips: { enabled: false },
    };

    const plugins = [
      {
        afterDraw: this.drawNeedle,
      },
    ];
    const style = {
      maxHeight: height ? `${height}%` : "none",
      maxWidth: width ? `${width}%` : "none",
    };

    return (
      <div className={className} style={style}>
        <Doughnut
          data={chartData}
          options={{ ...defaultOptions, ...options }}
          plugins={plugins}
          width={width}
          height={height}
        />
      </div>
    );
  }
}

export default styled(GaugeBase)`
  top: -30px;
  margin-left: auto;
  margin-right: auto;
`;
