import React from "react";
import FlagIcon from "../core/FlagIcon";
import get from "lodash/get";
import styled from "styled-components";
import { Typography } from "@creditsafe/ui-components";
import { isBEIdentifier } from "../../utils/companies";

const getCompanyNumber = (report) => {
  if (isBEIdentifier(report.companyId)) {
    return (
      report.companySummary.companyRegistrationNumber ||
      report.companySummary.companyNumber
    );
  } else {
    return (
      report.companySummary.companyNumber ||
      report.companySummary.companyRegistrationNumber
    );
  }
};

export const ReportHeader = ({ report, companyReport }) => {
  if (!report || !report.companySummary) {
    return null;
  }

  const country = get(report, "companySummary.country");

  return (
    <Header>
      <FlagIcon code={country} />
      <Typography
        variant="h4"
        component="h1"
        font="secondary"
        data-testid="businessName"
        className="business-name"
      >
        <div>{companyReport.companySummary.businessName}</div>
      </Typography>
      <Typography
        variant="h4"
        component="h2"
        font="secondary"
        data-testid="companyNumber"
      >
        {getCompanyNumber(report)}
      </Typography>
    </Header>
  );
};

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .business-name {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    > div {
      text-overflow: ellipsis;
      width: inherit;
      overflow: hidden;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpointLg}px) {
    flex-direction: row;
    margin: 4px 4px 0px 0px;
    h1 {
      text-align: left;
      flex: 1;
    }
    > * {
      margin: 0 5px;
    }
  }
`;

export default ReportHeader;
