import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card } from "@creditsafe/ui-components";
import styled from "styled-components";

import { displayValueOrSeparator } from "../../utils/string";
import Currency from "../../utils/Currency";
import { formatDateL } from "../../utils/date";
import InfoTile from "../InfoTile";
import ScoreImprovement from "./ScoreImprovement";
import ReportPopulation from "./ReportPopulation";
import StatusColorTen from "./utils/StatusColorTen";
import DaysSinceUpdate from "./DaysSinceUpdate";
import MycScoreGauge from "../core/MycScoreGauge";
import ExplainMyc from "../core/ExplainMyc";
import ExplainUpdate from "../core/ExplainUpdate";
import UpdatedGauge from "../core/UpdatedGauge";
import ReportHeader from "../header/ReportHeader";
import PopupModal from "../core/PopupModal";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

export const ReportHeaderContent = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${(props) => props.theme.spacingUnit}px 0;

  ${InfoTile} {
    width: 50%;
    height: 65px;
    margin: 1rem 0;
    > div {
      width: 80%;
      > div {
        > canvas {
          margin-top: -20px;
        }
      }
    }
    :nth-child(1n + 1) {
      border-right: 1px solid ${(props) => props.theme.greyAdobe1};
    }
    :nth-child(even) {
      border-right: none;
    }
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpointTablet}px) {
    ${InfoTile} {
      width: 25%;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :nth-child(4n + 4) {
        border-right: none;
      }
      :last-child {
        border-right: none;
        margin-right: 4px;
      }
    }
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpointLg}px) {
    flex-wrap: nowrap;
    ${InfoTile} {
      max-width: 200px;
      :nth-child(1n + 1) {
        border-right: 1px solid ${(props) => props.theme.greyAdobe1};
      }
      :last-child {
        border-right: none;
        margin-right: 4px;
      }
    }
  }
`;

class CompanyReportHeader extends Component {
  render() {
    const {
      report,
      companyReport,
      userReport,
      payments,
      references,
      expenditures,
      codat,
      provenir
    } = this.props;

    const updatedBy = provenir.bo_user_name;

    const ratingBeforeMyc = userReport.ratingbeforemyc;
    const limitBeforeMyc = userReport.limitbeforemyc;
    const creditScore =
      report.creditScore.currentCreditRating.providerValue.value;
    const limitRating =
      report.creditScore.currentCreditRating.creditLimit.value;
    const updatedAt = userReport.updated_at;

    const reportPopulation = ReportPopulation(
      report,
      payments,
      references,
      expenditures,
      codat
    );

    return (
      <Card>
        <ReportHeader 
          report={report} 
          companyReport={companyReport}
        />
        <ReportHeaderContent>
          <InfoTile
            title="MYC Score"
            titleHelper={
              <PopupModal
                title="MYC Score"
                trigger={<HelpOutlineIcon style={{ fontSize: 12 }} />}
              >
                {(close) => <ExplainMyc close={close} />}
              </PopupModal>
            }
            gauge={<MycScoreGauge mycScore={reportPopulation} />}
          />
          <InfoTile
            title="Report Population"
            status={StatusColorTen(reportPopulation)}
          >
            {reportPopulation}%
          </InfoTile>
          {ScoreImprovement(ratingBeforeMyc, creditScore) === 0 ? (
            " "
          ) : (
            <InfoTile title="Score Improvement" status="excellent">
              {ScoreImprovement(ratingBeforeMyc, creditScore)}
            </InfoTile>
          )} 

          {ScoreImprovement(limitBeforeMyc, limitRating) === 0 ? (
            " "
          ) : (
            <InfoTile
              title="Limit Improvement"
              status="excellent"
              children={displayValueOrSeparator(
                ScoreImprovement(limitBeforeMyc, limitRating),
                (value) => (
                  <Currency value={value} code="USD" truncate={false} />
                )
              )}
            />
          )}
          <InfoTile
            title="Last Updated"
            status="excellent"
            children={formatDateL(updatedAt)}
          />
          {updatedBy ? ( <InfoTile title="Updated By" status="excellent" children={updatedBy} />) : " "}
         
          <InfoTile
            title="Updated Frequency"
            titleHelper={
              <PopupModal
                title="Updated Frequency"
                trigger={<HelpOutlineIcon style={{ fontSize: 12 }} />}
              >
                {(close) => <ExplainUpdate close={close} />}
              </PopupModal>
            }
            gauge={<UpdatedGauge updatedAt={DaysSinceUpdate(updatedAt)} />}
          />
        </ReportHeaderContent>
      </Card>
    );
  }
}

CompanyReportHeader.propTypes = {
  report: PropTypes.object,
  copmanyReport: PropTypes.object,
  userReport: PropTypes.object,
  profile: PropTypes.object,
};

export default CompanyReportHeader;
