import React from "react";
import { toCurrencyDisplay } from "./currencyFormatter";
import isNil from "lodash/isNil";

const showFullNumber = value => Math.abs(value) <= 999;

const Currency = ({ value, code, truncate = true, options = {}, ...rest }) => {
  return (
    <span
      data-testid={rest["data-testid"]}
      title={
        truncate ? toCurrency(value, { code, truncate: false, ...options }) : ""
      }
      style={{ whiteSpace: "nowrap" }}
    >
      {toCurrency(value, { code, truncate, ...options })}
    </span>
  );
};

export const toCurrency = (value, options = {}) => {
  const truncate = isNil(options.truncate) ? true : options.truncate;
  const mantissa = isNil(options.mantissa) ? 1 : options.mantissa;

  return toCurrencyDisplay(value, {
    currencyCode: options.code,
    spaceSeparated: false,
    optionalMantissa: true,
    mantissa: mantissa,
    average: showFullNumber(value) ? false : truncate,
  });
};

export const USDFormat = x => toCurrency(x, { code: "USD" });

export const USDFormatWithFullValue = x =>
  toCurrency(x, { code: "USD", truncate: false });

export default Currency;
