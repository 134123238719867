import { FETCH_APP, EDIT_MODE, WINDOW_RESIZE } from "../actions/types";

import { initConfig } from "../config";

const config = initConfig();

const initialState = {
  isLoading: false,
  isMobile: false,
  isEditing: false,
  config: config,
  windowWidth: window.innerWidth,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_APP:
      return {
        ...state,
      };
    case EDIT_MODE:
      return {
        ...state,
        isEditing: action.payload,
      };
    case WINDOW_RESIZE:
      return {
        ...state,
        windowWidth: window.innerWidth,
      };
    default:
      return state;
  }
}
