import React from "react"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

const styles = () => ({
  root: {
    fontSize: "13px",
    padding: "11px 12px",
  },
});

function AppDrawerNavItem({ text, icon, classes, ...rest }) {
  if (!text) {
    return null;
  }

  return (
    <ListItem
      component={rest.target ? "a" : NavLink}
      href={rest.to}
      button
      {...rest}
      className={classes.root}
    >
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      {text}
    </ListItem>
  );
}

export default withStyles(styles)(AppDrawerNavItem);