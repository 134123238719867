import React from "react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import Card from "../core/Card";
import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_EXTRA_LARGE } from "../../utils/constants";
import { startCasing } from "../../utils/string";

export const formatTableData = (corpBusinessName) => {
  if (!corpBusinessName) {
    return [];
  }

  return corpBusinessName.reduce((r, corpClass) => {
    const businessLegalNameText = get(corpClass, "businessLegalName", "");
    const sosCharterText = get(corpClass, "sosCharterNumber", "");
    const streetAdressText = get(corpClass, "primaryAddress.street", "");
    const cityText = get(corpClass, "primaryAddress.city", "");
    const stateText = get(corpClass, "primaryAddress.province", "");
    const postalCodeText = get(corpClass, "primaryAddress.postalCode", "");

    r = r.concat([
      {
        businessLegalName: startCasing(businessLegalNameText),
        sosCharterNumber: sosCharterText,
        street: startCasing(streetAdressText),
        city: startCasing(cityText),
        province: stateText,
        postalCode: startCasing(postalCodeText),
      },
    ]);

    return r;
  }, []);
};

export const corporateRecords = ({ report, options = {}, t }) => {
  const corpRecordData = get(
    report,
    "additionalInformation.otherCorporateRecords"
  );
  const data = sortBy(formatTableData(corpRecordData), "businessLegalName");

  return (
    <Card
      scrollContent
      title="CorporateRecords"
      data-testid={"corporateRecordsCard"}
    >
      <PaginatedTable
        data-testid={"corporateRecordsTable"}
        headings={[
          { key: "businessLegalName", text: "Name" },
          { key: "sosCharterNumber", text: "Charter Number" },
          { key: "street", text: "Street Address" },
          { key: "city", text: "City" },
          { key: "province", text: "State" },
          { key: "postalCode", text: "Zip Code" },
        ]}
        data={data}
        pageSize={PAGE_SIZE_EXTRA_LARGE}
        options={options}
      />
    </Card>
  );
};

export default corporateRecords;
