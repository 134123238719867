import React from "react";
import CountryOptions from "../components/core/CountryOptions";
import FlagIcon from "../components/core/FlagIcon";
import { getFavouriteCountries } from "./favoriteCountries";
import isNil from "lodash/isNil";
import sortBy from "lodash/sortBy";
import {
  isFeatureEnabled,
  UPP_1516_VERIFICATION_REPORTS,
} from "../components/core/features/features";

export const groupCountriesByOnlineStatus = ({
  countries = [],
  t,
  homeCountry,
  features,
}) => {
  const countriesList = countries
    .filter(x => CountryOptions[x.countryCode])
    .map(x => ({
      ...x,
      group: getGroup(x, homeCountry, features),
      text: t(`countryNames.${x.countryCode}`),
      icon: <FlagIcon code={x.countryCode} className="flag" height={24} />,
    }));

  const favouriteCountries = getFavouriteCountries(homeCountry);

  const favourites = countriesList.filter(x => x.group === "favourites");
  const others = countriesList.filter(x => x.group !== "favourites");

  return [
    ...sortBy(favourites, [
      function(item) {
        return favouriteCountries.indexOf(item.countryCode);
      },
    ]),
    ...sortBy(others, "text"),
  ];
};

const getGroup = (option, homeCountry, features) => {
  const favourites = getFavouriteCountries(homeCountry);

  if (!isNil(favourites) && favourites.includes(option.countryCode)) {
    return "favourites";
  }

  if (
    option.vrStatus &&
    isFeatureEnabled(features, UPP_1516_VERIFICATION_REPORTS)
  ) {
    return "online";
  }

  return option.isOnline ? "online" : "offline";
};

export const countriesDefault = (countries = [], t) => {
  return countries
    .filter(x => CountryOptions[x.countryCode])
    .map(x => ({
      ...x,
      text: t(`global:countryNames.${x.countryCode}`),
      icon: <FlagIcon code={x.countryCode} className="flag" height={24} />,
    }))
    .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
};

export const getDefaultCountryToDirectorSearch = country => {
  switch (country) {
    case "CA":
      return "US";
    default:
      return country;
  }
};
