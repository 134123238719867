import React from "react";
import styled from "styled-components";

import Button from "../core/Button";
import { InputField, SelectField } from "../core/FormComponents";
import { Formik, Form, FieldArray } from "formik";

import sortBy from "lodash/sortBy";
import * as Yup from "yup";
import { ILLEGAL_CHARACTERS } from "../../utils/regexConstants";

import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { usStatePackages } from "../../utils/usStatePackages";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

function AddCorporateRecord({
  data,
  close,
  companyReport,
  additionalInformation,
  otherCorporateRecords,
  email,
  mycID,
  companyId,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  accessToken,
  language,
  report,
  expressApi,
  safeNumber,
  userReport,
  fullName,
  boId,
  profile
}) {
  const index = data.length;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        accessToken: accessToken,
        language: language,
        report: report,
        companyId: mycID,
        userid: email,
        safeNumber: safeNumber,
        companyReport: companyReport,
        additionalInformation: additionalInformation,
        otherCorporateRecords: sortBy(
          otherCorporateRecords,
          "businessLegalName"
        ),
      }}
      validationSchema={corporateRecordSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const corpRecords = values.otherCorporateRecords;
        const lastCorpRecord = corpRecords.slice(-1);
        const duplicate = corpRecords.filter(
          (corpRecord) =>
            corpRecord.businessLegalName.trim().toUpperCase() ===
              lastCorpRecord[0].businessLegalName.trim().toUpperCase() &&
            corpRecord.sosCharterNumber.trim().toUpperCase() ===
              lastCorpRecord[0].sosCharterNumber.trim().toUpperCase() &&
            corpRecord.primaryAddress.street.trim().toUpperCase() ===
              lastCorpRecord[0].primaryAddress.street.trim().toUpperCase() &&
            corpRecord.primaryAddress.city.trim().toUpperCase() ===
              lastCorpRecord[0].primaryAddress.city.trim().toUpperCase() &&
            corpRecord.primaryAddress.province.trim().toUpperCase() ===
              lastCorpRecord[0].primaryAddress.province.trim().toUpperCase() &&
            corpRecord.primaryAddress.postalCode.trim().toUpperCase() ===
              lastCorpRecord[0].primaryAddress.postalCode.trim().toUpperCase()
        );
        if (duplicate.length <= 1) {
          await setReportLoading("corpRecord");
          await fetch(`${expressApi}/reports`, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify({
              mycID: values.companyId,
              email: values.userid,
              report: values.report,
              reportjson: {
                ...companyReport,
                additionalInformation: {
                  ...additionalInformation,
                  otherCorporateRecords: values.otherCorporateRecords,
                },
              },
              reportupdated_at: new Date(),
              updated_at: new Date(),
              created_at: new Date(),
              verifiedstatus: "changed",
              language: values.language,
              detType: 'update_corporate_info',
              detInfo: {
                safe_number: values.safeNumber,
                company_id: values.companyId,
                company_name: lastCorpRecord[0].businessLegalName,
                state_of_incorporation: lastCorpRecord[0].primaryAddress.province,
                sos_charter_number: lastCorpRecord[0].sosCharterNumber,
                status:'fail',
                myc_id: userReport.id,
                user_id: userReport.userid,
                user_verified: userReport.userstatus,
                customer_id: profile.customer.id
              },
              bo_id: boId,
              bo_name: fullName,
            }),
          })
            .then((response) => response.json())
            .then(setSubmitting(false))
            .then(() => fetchReportJson(companyId, "corpRecord"))
            .then(() => fetchUserReport(companyId))
            .then(() => close())
            .catch((err) => console.log(err));
        } else {
          close();
          alert("You have already added this corporate record.");
        }
      }}
      render={({ values, handleSubmit }) => (
        <Form>
          <FieldArray
            name="otherCorporateRecords"
            render={(arrayHelpers) => (
              <>
                <Container key={index}>
                  <AttributeRow
                    width={100}
                    name={<label>Business Name</label>}
                    value={
                      <InputField
                        name={`otherCorporateRecords[${index}].businessLegalName`}
                      />
                    }
                  />
                  <AttributeRow
                    width={100}
                    name={<label>Charter Number</label>}
                    value={
                      <InputField
                        name={`otherCorporateRecords[${index}].sosCharterNumber`}
                      />
                    }
                  />
                  <AttributeRow
                    width={100}
                    name={<label>Street</label>}
                    value={
                      <InputField
                        name={`otherCorporateRecords[${index}].primaryAddress.street`}
                      />
                    }
                  />
                  <AttributeRow
                    width={100}
                    name={<label>City</label>}
                    value={
                      <InputField
                        name={`otherCorporateRecords[${index}].primaryAddress.city`}
                      />
                    }
                  />
                  <AttributeRow
                    width={100}
                    name={<label>State</label>}
                    value={
                      <SelectField
                        name={`otherCorporateRecords[${index}].primaryAddress.province`}
                      >
                        {provinceOptions()}
                      </SelectField>
                    }
                  />
                  <AttributeRow
                    width={100}
                    name={<label>Zip Code</label>}
                    value={
                      <InputField
                        name={`otherCorporateRecords[${index}].primaryAddress.postalCode`}
                      />
                    }
                  />
                  <ButtonBar>
                    <Button
                      variant="success"
                      type="button"
                      data-testid={`corp-record-submit-button`}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </ButtonBar>
                </Container>
              </>
            )}
          />
        </Form>
      )}
    ></Formik>
  );
}

const corporateRecordSchema = Yup.object().shape({
  otherCorporateRecords: Yup.array().of(
    Yup.object().shape({
      businessLegalName: Yup.string()
        .required("Please enter the company name")
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      sosCharterNumber: Yup.string()
        .required("Please enter the charter number")
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
      primaryAddress: Yup.object().shape({
        street: Yup.string()
        .matches(ILLEGAL_CHARACTERS, {
          message: "Your entry contains illegal characters",
        }),
        city: Yup.string()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          }),
        province: Yup.string()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          }),
        postalCode: Yup.string()
          .matches(ILLEGAL_CHARACTERS, {
            message: "Your entry contains illegal characters",
          }),
      })
    })
  ),
});

export default AddCorporateRecord;
