import React from "react";
import accounting from "accounting";
import { currencyObjects } from "../config/currency";
import numbro from "numbro";
import mergeWith from "lodash/mergeWith";
import isNil from "lodash/isNil";
import { CURRENCY_ABBREVIATION } from "./regexConstants";
import Currency from "./Currency";

const separator = "-";

export const toFormatCurrency = (value, data) => (
  <Currency value={value} code={data.currency} />
);

export function toCurrencyDisplay(
  value,
  options = {
    currencyCode: null,
    average: true,
    spaceSeparated: false,
    thousandSeparated: null,
    optionalMantissa: true,
    mantissa: 2,
  }
) {
  const numbroValue = numbro(value);
  if (isNil(value) || isNaN(numbroValue.value())) {
    return separator;
  }
  const currencyOptions = currencyObjects[options.currencyCode];
  if (currencyOptions && currencyOptions.symbol) {
    options.currencySymbol = currencyOptions.symbol;
  }
  if (currencyOptions && currencyOptions.currencyPosition) {
    options.currencyPosition = currencyOptions.currencyPosition;
  }
  const currentLocaleLanguageData = numbro.languageData();
  let merged = mergeWith(
    {},
    //Not all languages have an average property, force this in here to avoid any null errors when merging
    { average: false },
    currentLocaleLanguageData.currencyFormat,
    options,
    (a, b) => (b === null ? a : undefined)
  );
  //currencyCode is not a known numbro property, passing this to the format method causes errors
  delete merged.currencyCode;
  if (!merged.average || merged.optionalMantissa) {
    delete merged.totalLength;
  }

  const formattedCurrencyNumber =
    currencyOptions && currencyOptions.symbol
      ? numbro(value).formatCurrency(merged)
      : numbro(value)
          .format(merged)
          .toUpperCase();

  const matchToFindAbbreviation = formattedCurrencyNumber.match(
    CURRENCY_ABBREVIATION
  );

  return matchToFindAbbreviation && options.average
    ? formattedCurrencyNumber.replace(
        CURRENCY_ABBREVIATION,
        matchToFindAbbreviation[0].toUpperCase()
      )
    : formattedCurrencyNumber;
}

export function formatNumber(number) {
  return accounting.formatNumber(number);
}
