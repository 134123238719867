import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { theme } from "./theme";
import { initResizeListener } from "./eventListeners";

import { Reset, GlobalStyle } from "@creditsafe/ui-components";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Hidden from "./components/Hidden";
import AppBar from "./components/core/AppBar";
import Footer from "./components/core/Footer";
import AppDrawer from "./components/core/AppDrawer";
import DrawerMobile from "./components/core/DrawerMobile";
import LoadingPage from "./components/core/LoadingPage";
import SomethingWentWrong from "./components/core/SomethingWentWrong";

import Report from "./components/report/Report";
import Payment from "./components/payment/Payment";
import Trade from "./components/payment/Trade";
import Expenditure from "./components/payment/Expenditure";
import Certificate from "./components/report/Certificate";
import NavRedirect from "./components/vendor/NavRedirect"

import { windowResizeAction } from "../src/actions/appActions";

import get from "lodash/get"

import {
  fetchUser,
  fetchUserReport,
  fetchUserServices,
  fetchSearchableCountries,
  resetReportJSON
} from "./actions/userActions";
import { fetchCompanyReport } from "./actions/companyActions";
import { fetchReportJson, setReportLoading } from "./actions/mergeActions";
import {
  fetchPayment,
  fetchTradeReferences,
  fetchBusinessExpenditures,
  fetchCodatCompany,
} from "./actions/paymentActions";
import { fetchSafeNumber, fetchReportBySafeNumber } from "./actions/safeNumberActions";
import { fetchDisclaimer, fetchIpAddress } from "./actions/disclaimerActions";
import { fetchProvenirHistory } from "./actions/provenirActions";
import { fetchNavAuthToken, fetchNavAccount, updateNavAccount, fetchNavCta, createNavAccount } from "./actions/navActions";

import { businessContactModel, navModel } from "./utils/navModel"

import Auth from "@creditsafe/upp-authentication";

Auth.configure({ appName: "My Creditsafe" });

const defaultTheme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: theme.typography.fontFamilyPrimary,
    fontSize: 12,
    button: {
      textTransform: "none",
    },
  },
});

export const Main = styled.main`
  text-align: center;
  padding-top: ${(props) => props.theme.appbarHeight}px;
  display: ${(props) => (props.mobileOpen ? "none" : "block")};
  @media (min-width: ${(props) => props.theme.breakpointMd}px) {
    margin-left: ${(props) => props.theme.drawerWidth};
  }
`;

class App extends Component {
  state = {
    mobileOpen: false
  }

  componentDidMount() {
    if (this.props.isAuthed) {
      this.props.setReportLoading("totalReport")
      this.props.fetchIpAddress()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthed !== this.props.isAuthed) {
      this.props.setReportLoading("totalReport")
      this.props.fetchIpAddress()
    }

    if (this.props.isAuthed) {

      if (prevProps.ipAddress !== this.props.ipAddress) {

        this.props.fetchSafeNumber(this.props.sub)
        this.props.fetchUserServices(this.props.sub)

        this.props.fetchSearchableCountries(this.props.country)

        clearInterval(this.servicesInterval);
        this.servicesInterval = setInterval(() => {
          this.props.fetchUserServices(this.props.sub)
        }, 300000);
      }

      if (prevProps.safeNumber !== this.props.safeNumber) {
        this.props.fetchReportBySafeNumber(this.props.safeNumber)
      }

      if (prevProps.companyId !== this.props.companyId) {
        this.props.fetchUserReport(this.props.companyId)

        // THIS IS SETTING COMPANYID FOR PAGE LOADER
        this.props.fetchCompanyReport(this.props.companyId);
        this.props.fetchProvenirHistory(this.props.companyId)
        this.props.fetchPayment(this.props.companyId);
        this.props.fetchTradeReferences(this.props.companyId);
        this.props.fetchBusinessExpenditures(this.props.companyId);
        this.props.fetchDisclaimer(
          this.props.companyId,
          this.props.customerId,
          this.props.sub,
          this.props.ipAddress
        );
        this.props.fetchNavAuthToken(this.props.companyId)
        this.props.fetchNavAccount(this.props.companyId)
      }

      if (prevProps.report !== this.props.report) {
        const businessFacts = navModel(this.props.report)

        const createAccount = businessContactModel(this.props.report, this.props.email)

        if (this.props.navReportSuccess) {
          if (this.props.navAccount.errors && this.props.navAccount.errors.length > 0) {
            this.props.createNavAccount(businessFacts, createAccount, this.props.companyId)
          } else {
            this.props.updateNavAccount(businessFacts, createAccount, this.props.companyId)
          }
        }
      }

      if (prevProps.navCreateOrUpdated !== this.props.navCreateOrUpdated) {
        this.props.fetchNavCta(this.props.companyId, "creditsafe_dashboard")
      }

      // if(this.props.lastDataUpdate && prevProps.lastDataUpdate !== this.props.lastDataUpdate){
      //   if(new Date(this.props.userVerifiedAt) < this.props.lastDataUpdate && this.props.userVerifiedStatus === 'verified'){
      //     this.props.resetReportJSON(this.props.companyId)
      //   }
      // }

      if (
        prevProps.creditLimit !== this.props.creditLimit &&
        prevProps.creditScore !== this.props.creditScore &&
        prevProps.creditScoreDescription !== this.props.creditScoreDescription
      ) {

        /* Back Office will only return active service lines, if they expired we will received [] after the filter */

        const { services, connectReport, companyId } = this.props;

        const lastMycDate = get(connectReport, "additionalInformation.mycData.lastDataUpdate", null);

        if (lastMycDate) {
          if (new Date(this.props.userVerifiedAt) < lastMycDate && this.props.userVerifiedStatus === 'verified') {
            this.props.resetReportJSON(this.props.companyId)
          }
        }

        const mycService = services.filter(service => service.serviceId === 937)

        let expiryDate

        if (mycService.length >= 1) {
          expiryDate = mycService[0].expireDate
        } else {
          expiryDate = null
        }

        let ratingBeforeMyc

        this.props.creditScoreDescription === "Not Rated" ? ratingBeforeMyc = this.props.negativeRating : ratingBeforeMyc = this.props.creditScore

        // creditScore: state.company.report.report.creditScore.currentCreditRating.providerValue.value,
        // creditScoreDescription: state.company.report.report.creditScore.currentCreditRating.providerDescription,
        // creditLimit: state.company.report.report.creditScore.currentCreditRating.creditLimit.value,

        // When we call this later in the did update we are seeing the report switch from the connect report to the merged report
        this.props.fetchReportJson(
          this.props.companyId,
          "totalReport",
          this.props.email,
          ratingBeforeMyc,
          this.props.creditLimit,
          expiryDate
        );

      }

      if (prevProps.businessName !== this.props.businessName) {
        const name = this.props.safeNumber + " - " + this.props.businessName;
        this.props.fetchCodatCompany(name, "Linked");
      }
    }
  }

  UNSAFE_componentWillMount() {
    initResizeListener(this.props.windowResizeAction);
  }

  componentWillUnmount() {
    clearInterval(this.servicesInterval);
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { isAuthed, userError, disclaimerError, reportError, ipError, ctaError } = this.props;

    const { mobileOpen } = this.state;

    const isErroring = userError || disclaimerError || reportError || ipError || ctaError;

    if (!isAuthed) {
      if (isErroring) {
        return (
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={defaultTheme}>
              <GlobalStyle />
              <SomethingWentWrong />
            </MuiThemeProvider>
          </ThemeProvider>
        )
      }
      return (
        <ThemeProvider theme={theme}>
          <LoadingPage />
        </ThemeProvider>
      );
    }

    if (isErroring) {
      return (
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <SomethingWentWrong />
          </MuiThemeProvider>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={defaultTheme}>
          <Reset />
          <GlobalStyle />
          <div className="App">
            <header className="App-header"></header>
            <Router>
              <AppBar
                handleDrawerToggle={this.handleDrawerToggle}
              />
              <Hidden mdDown>
                <AppDrawer
                  handleDrawerToggle={this.handleDrawerToggle}
                />
              </Hidden>
              <Hidden mdUp>
                <DrawerMobile
                  open={mobileOpen}
                  onClose={this.handleDrawerToggle}
                />
              </Hidden>
              <Main>
                <Switch>
                  <Route exact path="/" component={Report} />
                  <Route exact path="/payments" component={Payment} />
                  <Route exact path="/trade" component={Trade} />
                  <Route exact path="/expenditure" component={Expenditure} />
                  <Route exact path="/certificate" component={Certificate} />
                  <Route exact path="/navredirect" component={NavRedirect} />
                </Switch>
              </Main>
            </Router>
          </div>
          <Footer />
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  windowSize: PropTypes.number,
  windowResizeAction: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchUserReport: PropTypes.func.isRequired,
  fetchCompanyReport: PropTypes.func.isRequired,
  fetchReportJson: PropTypes.func.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  fetchPayment: PropTypes.func.isRequired,
  fetchTradeReferences: PropTypes.func.isRequired,
  fetchBusinessExpenditures: PropTypes.func.isRequired,
  fetchSafeNumber: PropTypes.func.isRequired,
  fetchReportBySafeNumber: PropTypes.func.isRequired,
  fetchCodatCompany: PropTypes.func.isRequired,
  fetchDisclaimer: PropTypes.func.isRequired,
  fetchIpAddress: PropTypes.func.isRequired,
  fetchProvenirHistory: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  isAuthed: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  report: state.company.report,
  customerId: state.user.info.customerId,
  sub: state.user.info.sub,
  country: state.user.info.country,
  email: state.user.info.email,
  services: state.user.services,
  companyId: state.safeNumber.company.content[0].id,
  safeNumber: state.safeNumber.profile.registrationNumber,
  businessName: state.company.report.report.companySummary.businessName,
  creditScore:
    state.company.report.report.creditScore.currentCreditRating.providerValue
      .value,
  creditScoreDescription:
    state.company.report.report.creditScore.currentCreditRating.providerValue.value,
  creditLimit:
    state.company.report.report.creditScore.currentCreditRating.creditLimit
      .value,
  negativeRating: state.company.report.report.additionalInformation.misc.negativeRating,
  userVerifiedAt: state.user.report.verified_at,
  userVerifiedStatus: state.user.report.verifiedstatus,
  connectReport: state.company.report.report,
  isAuthed: state.user.isAuthed,
  userError: state.user.error,
  reportError: state.company.reportError,
  disclaimerError: state.disclaimer.disclaimerError,
  ipError: state.disclaimer.ipError,
  isLoading: state.company.isLoading,
  windowSize: state.app.windowWidth,
  ipAddress: state.disclaimer.ip.ip,
  navAccount: state.nav.account,
  navReportSuccess: state.nav.accountSuccess,
  navCreateOrUpdated: state.nav.createOrUpdated,
  ctaError: state.nav.ctaError
});

export default compose(
  connect(mapStateToProps, {
    windowResizeAction,
    fetchUser,
    fetchSearchableCountries,
    fetchUserReport,
    fetchSafeNumber,
    fetchReportBySafeNumber,
    fetchCompanyReport,
    fetchReportJson,
    fetchPayment,
    fetchTradeReferences,
    fetchBusinessExpenditures,
    fetchCodatCompany,
    fetchDisclaimer,
    fetchUserServices,
    fetchIpAddress,
    fetchProvenirHistory,
    setReportLoading,
    resetReportJSON,
    fetchNavAccount,
    fetchNavAuthToken,
    updateNavAccount,
    fetchNavCta,
    createNavAccount
  })
)(App);
