export const sicCodes = [
  {value:"0111 Wheat", label:"0111 Wheat"},
{value:"0112 Rice", label:"0112 Rice"},
{value:"0115 Corn", label:"0115 Corn"},
{value:"0116 Soybeans", label:"0116 Soybeans"},
{value:"0119 Cash Grains  Not Elsewhere Classified", label:"0119 Cash Grains  Not Elsewhere Classified"},
{value:"0131 Cotton", label:"0131 Cotton"},
{value:"0132 Tobacco", label:"0132 Tobacco"},
{value:"0133 Sugarcane and Sugar Beets", label:"0133 Sugarcane and Sugar Beets"},
{value:"0134 Irish Potatoes", label:"0134 Irish Potatoes"},
{value:"0139 Field Crops  Except Cash Grains  Not Elsewhere Classified", label:"0139 Field Crops  Except Cash Grains  Not Elsewhere Classified"},
{value:"0161 Vegetables and Melons", label:"0161 Vegetables and Melons"},
{value:"0171 Berry Crops", label:"0171 Berry Crops"},
{value:"0172 Grapes", label:"0172 Grapes"},
{value:"0173 Tree Nuts", label:"0173 Tree Nuts"},
{value:"0174 Citrus Fruits", label:"0174 Citrus Fruits"},
{value:"0175 Deciduous Tree Fruits", label:"0175 Deciduous Tree Fruits"},
{value:"0179 Fruits and Tree Nuts  Not Elsewhere Classified", label:"0179 Fruits and Tree Nuts  Not Elsewhere Classified"},
{value:"0181 Ornamental Floriculture and Nursery Products", label:"0181 Ornamental Floriculture and Nursery Products"},
{value:"0182 Food Crops Grown Under Cover", label:"0182 Food Crops Grown Under Cover"},
{value:"0191 General Farms  Primarily Crop", label:"0191 General Farms  Primarily Crop"},
{value:"0211 Beef Cattle Feedlots", label:"0211 Beef Cattle Feedlots"},
{value:"0212 Beef Cattle  Except Feedlots", label:"0212 Beef Cattle  Except Feedlots"},
{value:"0213 Hogs", label:"0213 Hogs"},
{value:"0214 Sheep and Goats", label:"0214 Sheep and Goats"},
{value:"0219 General Livestock  Except Dairy and Poultry", label:"0219 General Livestock  Except Dairy and Poultry"},
{value:"0241 Dairy Farms", label:"0241 Dairy Farms"},
{value:"0251 Broiler  Fryer  and Roaster Chickens", label:"0251 Broiler  Fryer  and Roaster Chickens"},
{value:"0252 Chicken Eggs", label:"0252 Chicken Eggs"},
{value:"0253 Turkeys $and Turkey Eggs", label:"0253 Turkeys $and Turkey Eggs"},
{value:"0254 Poultry Hatcheries", label:"0254 Poultry Hatcheries"},
{value:"0259 Poultry and Eggs  Not Elsewhere Classified", label:"0259 Poultry and Eggs  Not Elsewhere Classified"},
{value:"0271 Fur-Bearing Animals and Rabbits", label:"0271 Fur-Bearing Animals and Rabbits"},
{value:"0272 Horses and Other Equines", label:"0272 Horses and Other Equines"},
{value:"0273 Animal Aquaculture", label:"0273 Animal Aquaculture"},
{value:"0279 Animal Specialties  Not Elsewhere Classified", label:"0279 Animal Specialties  Not Elsewhere Classified"},
{value:"0291 General Farms  Primarily Livestock and Animal Specialties", label:"0291 General Farms  Primarily Livestock and Animal Specialties"},
{value:"0711 Soil Preparation Services", label:"0711 Soil Preparation Services"},
{value:"0721 Crop Planting  Cultivating  and Protecting", label:"0721 Crop Planting  Cultivating  and Protecting"},
{value:"0722 Crop Harvesting  Primarily by Machine", label:"0722 Crop Harvesting  Primarily by Machine"},
{value:"0723 Crop Preparation Services for Market  Except Cotton Ginning", label:"0723 Crop Preparation Services for Market  Except Cotton Ginning"},
{value:"0724 Cotton Ginning", label:"0724 Cotton Ginning"},
{value:"0741 Veterinary Services for Livestock", label:"0741 Veterinary Services for Livestock"},
{value:"0742 Veterinary Services for Animal Specialties", label:"0742 Veterinary Services for Animal Specialties"},
{value:"0751 Livestock Services  Except Veterinary", label:"0751 Livestock Services  Except Veterinary"},
{value:"0752 Animal Specialty Services  Except Veterinary", label:"0752 Animal Specialty Services  Except Veterinary"},
{value:"0761 Farm Labor Contractors and Crew Leaders", label:"0761 Farm Labor Contractors and Crew Leaders"},
{value:"0762 Farm Management Services", label:"0762 Farm Management Services"},
{value:"0781 Landscape Counseling and Planning", label:"0781 Landscape Counseling and Planning"},
{value:"0782 Lawn and Garden Services", label:"0782 Lawn and Garden Services"},
{value:"0783 Ornamental Shrub and Tree Services", label:"0783 Ornamental Shrub and Tree Services"},
{value:"0811 Timber Tracts", label:"0811 Timber Tracts"},
{value:"0831 Forest Nurseries and Gathering of Forest Products", label:"0831 Forest Nurseries and Gathering of Forest Products"},
{value:"0851 Forestry Services", label:"0851 Forestry Services"},
{value:"0912 Finfish", label:"0912 Finfish"},
{value:"0913 Shellfish", label:"0913 Shellfish"},
{value:"0919 Miscellaneous Marine Products", label:"0919 Miscellaneous Marine Products"},
{value:"0921 Fish Hatcheries and Preserves", label:"0921 Fish Hatcheries and Preserves"},
{value:"0971 Hunting and Trapping  and Game Propagation", label:"0971 Hunting and Trapping  and Game Propagation"},
{value:"1011 Iron Ores", label:"1011 Iron Ores"},
{value:"1021 Copper Ores", label:"1021 Copper Ores"},
{value:"1031 Lead and Zinc Ores", label:"1031 Lead and Zinc Ores"},
{value:"1041 Gold Ores", label:"1041 Gold Ores"},
{value:"1044 Silver Ores", label:"1044 Silver Ores"},
{value:"1061 Ferroalloy Ores  Except Vanadium", label:"1061 Ferroalloy Ores  Except Vanadium"},
{value:"1081 Metal Mining Services", label:"1081 Metal Mining Services"},
{value:"1094 Uranium-Radium-Vanadium Ores", label:"1094 Uranium-Radium-Vanadium Ores"},
{value:"1099 Miscellaneous Metal Ores  Not Elsewhere Classified", label:"1099 Miscellaneous Metal Ores  Not Elsewhere Classified"},
{value:"1221 Bituminous Coal and Lignite Surface Mining", label:"1221 Bituminous Coal and Lignite Surface Mining"},
{value:"1222 Bituminous Coal Underground Mining", label:"1222 Bituminous Coal Underground Mining"},
{value:"1231 Anthracite Mining", label:"1231 Anthracite Mining"},
{value:"1241 Coal Mining Services", label:"1241 Coal Mining Services"},
{value:"1311 Crude Petroleum and Natural Gas", label:"1311 Crude Petroleum and Natural Gas"},
{value:"1321 Natural Gas Liquids", label:"1321 Natural Gas Liquids"},
{value:"1381 Drilling Oil and Gas Wells", label:"1381 Drilling Oil and Gas Wells"},
{value:"1382 Oil and Gas Field Exploration Services", label:"1382 Oil and Gas Field Exploration Services"},
{value:"1389 Oil and Gas Field Services  Not Elsewhere Classified", label:"1389 Oil and Gas Field Services  Not Elsewhere Classified"},
{value:"1411 Dimension Stone", label:"1411 Dimension Stone"},
{value:"1422 Crushed and Broken Limestone", label:"1422 Crushed and Broken Limestone"},
{value:"1423 Crushed and Broken Granite", label:"1423 Crushed and Broken Granite"},
{value:"1429 Crushed and Broken Stone  Not Elsewhere Classified", label:"1429 Crushed and Broken Stone  Not Elsewhere Classified"},
{value:"1442 Construction Sand and Gravel", label:"1442 Construction Sand and Gravel"},
{value:"1446 Industrial Sand", label:"1446 Industrial Sand"},
{value:"1455 Kaolin and Ball Clay", label:"1455 Kaolin and Ball Clay"},
{value:"1459 Clay  Ceramic  and Refractory Minerals  Not Elsewhere Classified", label:"1459 Clay  Ceramic  and Refractory Minerals  Not Elsewhere Classified"},
{value:"1474 Potash  Soda  and Borate Minerals", label:"1474 Potash  Soda  and Borate Minerals"},
{value:"1475 Phosphate Rock", label:"1475 Phosphate Rock"},
{value:"1479 Chemical and Fertilizer Mineral Mining  Not Elsewhere Classified", label:"1479 Chemical and Fertilizer Mineral Mining  Not Elsewhere Classified"},
{value:"1481 Nonmetallic Minerals Services  Except Fuels", label:"1481 Nonmetallic Minerals Services  Except Fuels"},
{value:"1499 Miscellaneous Nonmetallic Minerals  Except Fuels", label:"1499 Miscellaneous Nonmetallic Minerals  Except Fuels"},
{value:"1521 General Contractors-Single-Family Houses", label:"1521 General Contractors-Single-Family Houses"},
{value:"1522 General Contractors-Residential Buildings  Other Than Single-Family", label:"1522 General Contractors-Residential Buildings  Other Than Single-Family"},
{value:"1531 Operative Builders", label:"1531 Operative Builders"},
{value:"1541 General Contractors-Industrial Buildings and Warehouses", label:"1541 General Contractors-Industrial Buildings and Warehouses"},
{value:"1542 General Contractors-Nonresidential Buildings  Other than Industrial Buildings and Warehouses", label:"1542 General Contractors-Nonresidential Buildings  Other than Industrial Buildings and Warehouses"},
{value:"1611 Highway and Street Construction  Except Elevated Highways", label:"1611 Highway and Street Construction  Except Elevated Highways"},
{value:"1622 Bridge  Tunnel  and Elevated Highway Construction", label:"1622 Bridge  Tunnel  and Elevated Highway Construction"},
{value:"1623 Water  Sewer  Pipeline  and Communications and Power Line Construction", label:"1623 Water  Sewer  Pipeline  and Communications and Power Line Construction"},
{value:"1629 Heavy Construction  Not Elsewhere Classified", label:"1629 Heavy Construction  Not Elsewhere Classified"},
{value:"1711 Plumbing  Heating and Air-Conditioning", label:"1711 Plumbing  Heating and Air-Conditioning"},
{value:"1721 Painting and Paper Hanging", label:"1721 Painting and Paper Hanging"},
{value:"1731 Electrical Work", label:"1731 Electrical Work"},
{value:"1741 Masonry  Stone Setting  and Other Stone Work", label:"1741 Masonry  Stone Setting  and Other Stone Work"},
{value:"1742 Plastering  Drywall  Acoustical  and Insulation Work", label:"1742 Plastering  Drywall  Acoustical  and Insulation Work"},
{value:"1743 Terrazzo  Tile  Marble  and Mosaic Work", label:"1743 Terrazzo  Tile  Marble  and Mosaic Work"},
{value:"1751 Carpentry Work", label:"1751 Carpentry Work"},
{value:"1752 Floor Laying and Other Floor Work  Not Elsewhere Classified", label:"1752 Floor Laying and Other Floor Work  Not Elsewhere Classified"},
{value:"1761 Roofing  Siding  and Sheet Metal Work", label:"1761 Roofing  Siding  and Sheet Metal Work"},
{value:"1771 Concrete Work", label:"1771 Concrete Work"},
{value:"1781 Water Well Drilling", label:"1781 Water Well Drilling"},
{value:"1791 Structural Steel Erection", label:"1791 Structural Steel Erection"},
{value:"1793 Glass and Glazing Work", label:"1793 Glass and Glazing Work"},
{value:"1794 Excavation Work", label:"1794 Excavation Work"},
{value:"1795 Wrecking and Demolition Work", label:"1795 Wrecking and Demolition Work"},
{value:"1796 Installation or Erection of Building Equipment  Not Elsewhere", label:"1796 Installation or Erection of Building Equipment  Not Elsewhere"},
{value:"1799 Special Trade Contractors  Not Elsewhere Classified", label:"1799 Special Trade Contractors  Not Elsewhere Classified"},
{value:"2011 Meat Packing Plants", label:"2011 Meat Packing Plants"},
{value:"2013 Sausages and Other Prepared Meat Products", label:"2013 Sausages and Other Prepared Meat Products"},
{value:"2015 Poultry Slaughtering and Processing", label:"2015 Poultry Slaughtering and Processing"},
{value:"2021 Creamery Butter", label:"2021 Creamery Butter"},
{value:"2022 Natural  Processed  and Imitation Cheese", label:"2022 Natural  Processed  and Imitation Cheese"},
{value:"2023 Dry  Condensed  and Evaporated Dairy Products", label:"2023 Dry  Condensed  and Evaporated Dairy Products"},
{value:"2024 Ice Cream and Frozen Desserts", label:"2024 Ice Cream and Frozen Desserts"},
{value:"2026 Fluid Milk", label:"2026 Fluid Milk"},
{value:"2032 Canned Specialties", label:"2032 Canned Specialties"},
{value:"2033 Canned Fruits  Vegetables  Preserves  Jams  and Jellies", label:"2033 Canned Fruits  Vegetables  Preserves  Jams  and Jellies"},
{value:"2034 Dried and Dehydrated Fruits  Vegetables  and Soup Mixes", label:"2034 Dried and Dehydrated Fruits  Vegetables  and Soup Mixes"},
{value:"2035 Pickled Fruits and Vegetables  Vegetable Sauces and Seasonings  and Salad Dressings", label:"2035 Pickled Fruits and Vegetables  Vegetable Sauces and Seasonings  and Salad Dressings"},
{value:"2037 Frozen Fruits  Fruit Juices  and Vegetables", label:"2037 Frozen Fruits  Fruit Juices  and Vegetables"},
{value:"2038 Frozen Specialties  Not Elsewhere Classified", label:"2038 Frozen Specialties  Not Elsewhere Classified"},
{value:"2041 Flour and Other Grain Mill Products", label:"2041 Flour and Other Grain Mill Products"},
{value:"2043 Cereal Breakfast Foods", label:"2043 Cereal Breakfast Foods"},
{value:"2044 Rice Milling", label:"2044 Rice Milling"},
{value:"2045 Prepared Flour Mixes and Doughs", label:"2045 Prepared Flour Mixes and Doughs"},
{value:"2046 Wet Corn Milling", label:"2046 Wet Corn Milling"},
{value:"2047 Dog and Cat Food", label:"2047 Dog and Cat Food"},
{value:"2048 Prepared Feed and Feed Ingredients for Animals and Fowls  Except Dogs and Cats", label:"2048 Prepared Feed and Feed Ingredients for Animals and Fowls  Except Dogs and Cats"},
{value:"2051 Bread and Other Bakery Products  Except Cookies and Crackers", label:"2051 Bread and Other Bakery Products  Except Cookies and Crackers"},
{value:"2052 Cookies and Crackers", label:"2052 Cookies and Crackers"},
{value:"2053 Frozen Bakery Products  Except Bread", label:"2053 Frozen Bakery Products  Except Bread"},
{value:"2061 Cane Sugar  Except Refining", label:"2061 Cane Sugar  Except Refining"},
{value:"2062 Cane Sugar Refining", label:"2062 Cane Sugar Refining"},
{value:"2063 Beet Sugar", label:"2063 Beet Sugar"},
{value:"2064 Candy and Other Confectionery Products", label:"2064 Candy and Other Confectionery Products"},
{value:"2066 Chocolate and Cocoa Products", label:"2066 Chocolate and Cocoa Products"},
{value:"2067 Chewing Gum", label:"2067 Chewing Gum"},
{value:"2068 Salted and Roasted Nuts and Seeds", label:"2068 Salted and Roasted Nuts and Seeds"},
{value:"2074 Cottonseed Oil Mills", label:"2074 Cottonseed Oil Mills"},
{value:"2075 Soybean Oil Mills", label:"2075 Soybean Oil Mills"},
{value:"2076 Vegetable Oil Mills  Except Corn  Cottonseed  and Soybean", label:"2076 Vegetable Oil Mills  Except Corn  Cottonseed  and Soybean"},
{value:"2077 Animal and Marine Fats and Oils", label:"2077 Animal and Marine Fats and Oils"},
{value:"2079 Shortening  Table Oils  Margarine  and Other Edible Fats and Oils  Not Elsewhere Classified", label:"2079 Shortening  Table Oils  Margarine  and Other Edible Fats and Oils  Not Elsewhere Classified"},
{value:"2082 Malt Beverages", label:"2082 Malt Beverages"},
{value:"2083 Malt", label:"2083 Malt"},
{value:"2084 Wines  Brandy  and Brandy Spirits", label:"2084 Wines  Brandy  and Brandy Spirits"},
{value:"2085 Distilled and Blended Liquors", label:"2085 Distilled and Blended Liquors"},
{value:"2086 Bottled and Canned Soft Drinks and Carbonated Waters", label:"2086 Bottled and Canned Soft Drinks and Carbonated Waters"},
{value:"2087 Flavoring Extracts and Flavoring Syrups  Not Elsewhere Classified", label:"2087 Flavoring Extracts and Flavoring Syrups  Not Elsewhere Classified"},
{value:"2091 Canned and Cured Fish and Seafoods", label:"2091 Canned and Cured Fish and Seafoods"},
{value:"2092 Prepared Fresh or Frozen Fish and Seafoods", label:"2092 Prepared Fresh or Frozen Fish and Seafoods"},
{value:"2095 Roasted Coffee", label:"2095 Roasted Coffee"},
{value:"2096 Potato Chips  Corn Chips  and Similar Snacks", label:"2096 Potato Chips  Corn Chips  and Similar Snacks"},
{value:"2097 Manufactured Ice", label:"2097 Manufactured Ice"},
{value:"2098 Macaroni  Spaghetti  Vermicelli  and Noodles", label:"2098 Macaroni  Spaghetti  Vermicelli  and Noodles"},
{value:"2099 Food Preparations  Not Elsewhere Classified", label:"2099 Food Preparations  Not Elsewhere Classified"},
{value:"2111 Cigarettes", label:"2111 Cigarettes"},
{value:"2121 Cigars", label:"2121 Cigars"},
{value:"2131 Chewing and Smoking Tobacco and Snuff", label:"2131 Chewing and Smoking Tobacco and Snuff"},
{value:"2141 Tobacco Stemming and Redrying", label:"2141 Tobacco Stemming and Redrying"},
{value:"2211 Broadwoven Fabric Mills  Cotton", label:"2211 Broadwoven Fabric Mills  Cotton"},
{value:"2221 Broadwoven Fabric Mills  Manmade Fiber and Silk", label:"2221 Broadwoven Fabric Mills  Manmade Fiber and Silk"},
{value:"2231 Broadwoven Fabric Mills  Wool (Including Dyeing and Finishing)", label:"2231 Broadwoven Fabric Mills  Wool (Including Dyeing and Finishing)"},
{value:"2241 Narrow Fabric and Other Smallware Mills: Cotton  Wool  Silk  and Manmade Fiber", label:"2241 Narrow Fabric and Other Smallware Mills: Cotton  Wool  Silk  and Manmade Fiber"},
{value:"2251 Women's Full-Length and Knee-Length Hosiery  Except Socks", label:"2251 Women's Full-Length and Knee-Length Hosiery  Except Socks"},
{value:"2252 Hosiery  Not Elsewhere Classified", label:"2252 Hosiery  Not Elsewhere Classified"},
{value:"2253 Knit Outerwear Mills", label:"2253 Knit Outerwear Mills"},
{value:"2254 Knit Underwear and Nightwear Mills", label:"2254 Knit Underwear and Nightwear Mills"},
{value:"2257 Weft Knit Fabric Mills", label:"2257 Weft Knit Fabric Mills"},
{value:"2258 Lace and Warp Knit Fabric Mills", label:"2258 Lace and Warp Knit Fabric Mills"},
{value:"2259 Knitting Mills  Not Elsewhere Classified", label:"2259 Knitting Mills  Not Elsewhere Classified"},
{value:"2261 Finishers of Broadwoven Fabrics of Cotton", label:"2261 Finishers of Broadwoven Fabrics of Cotton"},
{value:"2262 Finishers of Broadwoven Fabrics of Manmade Fiber and Silk", label:"2262 Finishers of Broadwoven Fabrics of Manmade Fiber and Silk"},
{value:"2269 Finishers of Textiles  Not elsewhere Classified", label:"2269 Finishers of Textiles  Not elsewhere Classified"},
{value:"2273 Carpets and Rugs", label:"2273 Carpets and Rugs"},
{value:"2281 Yarn Spinning Mills", label:"2281 Yarn Spinning Mills"},
{value:"2282 Yarn Texturizing  Throwing  Twisting  and Winding Mills", label:"2282 Yarn Texturizing  Throwing  Twisting  and Winding Mills"},
{value:"2284 Thread Mills", label:"2284 Thread Mills"},
{value:"2295 Coated Fabrics  Not Rubberized", label:"2295 Coated Fabrics  Not Rubberized"},
{value:"2296 Tire Cord and Fabrics", label:"2296 Tire Cord and Fabrics"},
{value:"2297 Non-woven Fabrics", label:"2297 Non-woven Fabrics"},
{value:"2298 Cordage and Twine", label:"2298 Cordage and Twine"},
{value:"2299 Textile goods  Not Elsewhere Classified", label:"2299 Textile goods  Not Elsewhere Classified"},
{value:"2311 Men's and Boys' Suits  Coats  and Overcoats", label:"2311 Men's and Boys' Suits  Coats  and Overcoats"},
{value:"2321 Men's and Boys' Shirts  Except Work Shirts", label:"2321 Men's and Boys' Shirts  Except Work Shirts"},
{value:"2322 Men's and Boys' Underwear and Nightwear", label:"2322 Men's and Boys' Underwear and Nightwear"},
{value:"2323 Men's and Boys' Neckwear", label:"2323 Men's and Boys' Neckwear"},
{value:"2325 Men's and Boys' Separate Trousers and Slacks", label:"2325 Men's and Boys' Separate Trousers and Slacks"},
{value:"2326 Men's and Boys' Work Clothing", label:"2326 Men's and Boys' Work Clothing"},
{value:"2329 Men's and Boys' Clothing  Not Elsewhere Classified", label:"2329 Men's and Boys' Clothing  Not Elsewhere Classified"},
{value:"2331 Women's  Misses'  and Juniors' Blouses and Shirts", label:"2331 Women's  Misses'  and Juniors' Blouses and Shirts"},
{value:"2335 Women's  Misses'  and Juniors' Dresses", label:"2335 Women's  Misses'  and Juniors' Dresses"},
{value:"2337 Women's  Misses'  and Juniors' Suits  Skirts  and Coats", label:"2337 Women's  Misses'  and Juniors' Suits  Skirts  and Coats"},
{value:"2339 Women's  Misses'  and Juniors' Outerwear  Not Elsewhere Classified", label:"2339 Women's  Misses'  and Juniors' Outerwear  Not Elsewhere Classified"},
{value:"2341 Women's  Misses'  Children's  and Infants' Underwear and Nightwear", label:"2341 Women's  Misses'  Children's  and Infants' Underwear and Nightwear"},
{value:"2342 Brassieres  Girdles  and Allied Garments", label:"2342 Brassieres  Girdles  and Allied Garments"},
{value:"2353 Hats  Caps  and Millinery", label:"2353 Hats  Caps  and Millinery"},
{value:"2361 Girls'  Children's  and Infants' Dresses  Blouses  and Shirts", label:"2361 Girls'  Children's  and Infants' Dresses  Blouses  and Shirts"},
{value:"2369 Girls'  Children's  and Infants' Outerwear  Not Elsewhere Classified", label:"2369 Girls'  Children's  and Infants' Outerwear  Not Elsewhere Classified"},
{value:"2371 Fur Goods", label:"2371 Fur Goods"},
{value:"2381 Dress and Work Gloves  Except Knit and All-Leather", label:"2381 Dress and Work Gloves  Except Knit and All-Leather"},
{value:"2384 Robes and Dressing Gowns", label:"2384 Robes and Dressing Gowns"},
{value:"2385 Waterproof Outerwear", label:"2385 Waterproof Outerwear"},
{value:"2386 Leather and Sheep-Lined Clothing", label:"2386 Leather and Sheep-Lined Clothing"},
{value:"2387 Apparel belts", label:"2387 Apparel belts"},
{value:"2389 Apparel and Accessories  Not Elsewhere Classified", label:"2389 Apparel and Accessories  Not Elsewhere Classified"},
{value:"2391 Curtains and Draperies", label:"2391 Curtains and Draperies"},
{value:"2392 House furnishing  Except Curtains and Draperies", label:"2392 House furnishing  Except Curtains and Draperies"},
{value:"2393 Textile Bags", label:"2393 Textile Bags"},
{value:"2394 Canvas and Related Products", label:"2394 Canvas and Related Products"},
{value:"2395 Pleating  Decorative and Novelty Stitching  and Tucking for the Trade", label:"2395 Pleating  Decorative and Novelty Stitching  and Tucking for the Trade"},
{value:"2396 Automotive Trimmings  Apparel Findings  and Related Products", label:"2396 Automotive Trimmings  Apparel Findings  and Related Products"},
{value:"2397 Schiffli Machine Embroideries", label:"2397 Schiffli Machine Embroideries"},
{value:"2399 Fabricated Textile Products  Not Elsewhere Classified", label:"2399 Fabricated Textile Products  Not Elsewhere Classified"},
{value:"2411 Logging", label:"2411 Logging"},
{value:"2421 Sawmills and Planing Mills  General", label:"2421 Sawmills and Planing Mills  General"},
{value:"2426 Hardwood Dimension and Flooring Mills", label:"2426 Hardwood Dimension and Flooring Mills"},
{value:"2429 Special Product Sawmills  Not Elsewhere Classified", label:"2429 Special Product Sawmills  Not Elsewhere Classified"},
{value:"2431 Millwork", label:"2431 Millwork"},
{value:"2434 Wood Kitchen Cabinets", label:"2434 Wood Kitchen Cabinets"},
{value:"2435 Hardwood Veneer and Plywood", label:"2435 Hardwood Veneer and Plywood"},
{value:"2436 Softwood Veneer and Plywood", label:"2436 Softwood Veneer and Plywood"},
{value:"2439 Structural Wood Members  Not Elsewhere Classified", label:"2439 Structural Wood Members  Not Elsewhere Classified"},
{value:"2441 Nailed and Lock Corner Wood Boxes and Shook", label:"2441 Nailed and Lock Corner Wood Boxes and Shook"},
{value:"2448 Wood Pallets and Skids", label:"2448 Wood Pallets and Skids"},
{value:"2449 Wood Containers  Not Elsewhere Classified", label:"2449 Wood Containers  Not Elsewhere Classified"},
{value:"2451 Mobile Homes", label:"2451 Mobile Homes"},
{value:"2452 Prefabricated Wood Buildings and Components", label:"2452 Prefabricated Wood Buildings and Components"},
{value:"2491 Wood Preserving", label:"2491 Wood Preserving"},
{value:"2493 Reconstituted Wood Products", label:"2493 Reconstituted Wood Products"},
{value:"2499 Wood Products  Not Elsewhere Classified", label:"2499 Wood Products  Not Elsewhere Classified"},
{value:"2511 Wood Household Furniture  Except Upholstered", label:"2511 Wood Household Furniture  Except Upholstered"},
{value:"2512 Wood Household Furniture  Upholstered", label:"2512 Wood Household Furniture  Upholstered"},
{value:"2514 Metal Household Furniture", label:"2514 Metal Household Furniture"},
{value:"2515 Mattresses  Foundations  and Convertible Beds", label:"2515 Mattresses  Foundations  and Convertible Beds"},
{value:"2517 Wood Television  Radio  Phonograph  and Sewing Machine Cabinets", label:"2517 Wood Television  Radio  Phonograph  and Sewing Machine Cabinets"},
{value:"2519 Household Furniture  Not Elsewhere Classified", label:"2519 Household Furniture  Not Elsewhere Classified"},
{value:"2521 Wood Office Furniture", label:"2521 Wood Office Furniture"},
{value:"2522 Office Furniture  Except Wood", label:"2522 Office Furniture  Except Wood"},
{value:"2531 Public Building and Related Furniture", label:"2531 Public Building and Related Furniture"},
{value:"2541 Wood Office and Store Fixtures  Partitions  Shelving  and Lockers", label:"2541 Wood Office and Store Fixtures  Partitions  Shelving  and Lockers"},
{value:"2542 Office and Store Fixtures  Partitions  Shelving  and Lockers  Except Wood", label:"2542 Office and Store Fixtures  Partitions  Shelving  and Lockers  Except Wood"},
{value:"2591 Drapery Hardware and Window Blinds and Shades", label:"2591 Drapery Hardware and Window Blinds and Shades"},
{value:"2599 Furniture and Fixtures  Not Elsewhere Classified", label:"2599 Furniture and Fixtures  Not Elsewhere Classified"},
{value:"2611 Pulp Mills", label:"2611 Pulp Mills"},
{value:"2621 Paper Mills", label:"2621 Paper Mills"},
{value:"2631 Paperboard Mills", label:"2631 Paperboard Mills"},
{value:"2652 Setup Paperboard Boxes", label:"2652 Setup Paperboard Boxes"},
{value:"2653 Corrugated and Solid Fiber Boxes", label:"2653 Corrugated and Solid Fiber Boxes"},
{value:"2655 Fiber Cans  Tubes  Drums  and Similar Products", label:"2655 Fiber Cans  Tubes  Drums  and Similar Products"},
{value:"2656 Sanitary Food Containers  Except Folding", label:"2656 Sanitary Food Containers  Except Folding"},
{value:"2657 Folding Paperboard Boxes  Including Sanitary", label:"2657 Folding Paperboard Boxes  Including Sanitary"},
{value:"2671 Packaging Paper and Plastics Film  Coated and Laminated", label:"2671 Packaging Paper and Plastics Film  Coated and Laminated"},
{value:"2672 Coated and Laminated Paper  Not Elsewhere Classified", label:"2672 Coated and Laminated Paper  Not Elsewhere Classified"},
{value:"2673 Plastics  Foil  and Coated Paper Bags", label:"2673 Plastics  Foil  and Coated Paper Bags"},
{value:"2674 Uncoated Paper and Multiwall Bags", label:"2674 Uncoated Paper and Multiwall Bags"},
{value:"2675 Die-Cut Paper and Paperboard and Cardboard", label:"2675 Die-Cut Paper and Paperboard and Cardboard"},
{value:"2676 Sanitary Paper Products", label:"2676 Sanitary Paper Products"},
{value:"2677 Envelopes", label:"2677 Envelopes"},
{value:"2678 Stationery  Tablets  and Related Products", label:"2678 Stationery  Tablets  and Related Products"},
{value:"2679 Converted Paper and Paperboard Products  Not Elsewhere Classified", label:"2679 Converted Paper and Paperboard Products  Not Elsewhere Classified"},
{value:"2711 Newspapers: Publishing  or Publishing and Printing", label:"2711 Newspapers: Publishing  or Publishing and Printing"},
{value:"2721 Periodicals: Publishing  or Publishing and Printing", label:"2721 Periodicals: Publishing  or Publishing and Printing"},
{value:"2731 Books: Publishing  or Publishing and Printing", label:"2731 Books: Publishing  or Publishing and Printing"},
{value:"2732 Book Printing", label:"2732 Book Printing"},
{value:"2741 Miscellaneous Publishing", label:"2741 Miscellaneous Publishing"},
{value:"2752 Commercial Printing  Lithographic", label:"2752 Commercial Printing  Lithographic"},
{value:"2754 Commercial Printing  Gravure", label:"2754 Commercial Printing  Gravure"},
{value:"2759 Commercial Printing  Not Elsewhere Classified", label:"2759 Commercial Printing  Not Elsewhere Classified"},
{value:"2761 Manifold Business Forms", label:"2761 Manifold Business Forms"},
{value:"2771 Greeting Cards", label:"2771 Greeting Cards"},
{value:"2782 Blankbooks  Looseleaf Binders and Devices", label:"2782 Blankbooks  Looseleaf Binders and Devices"},
{value:"2789 Bookbinding and Related Work", label:"2789 Bookbinding and Related Work"},
{value:"2791 Typesetting", label:"2791 Typesetting"},
{value:"2796 Platemaking and Related Services", label:"2796 Platemaking and Related Services"},
{value:"2812 Alkalies and Chlorine", label:"2812 Alkalies and Chlorine"},
{value:"2813 Industrial Gases", label:"2813 Industrial Gases"},
{value:"2816 Inorganic Pigments", label:"2816 Inorganic Pigments"},
{value:"2819 Industrial Inorganic Chemicals  Not Elsewhere Classified", label:"2819 Industrial Inorganic Chemicals  Not Elsewhere Classified"},
{value:"2821 Plastics Materials  Synthetic Resins  and Nonvulcanizable Elastomers", label:"2821 Plastics Materials  Synthetic Resins  and Nonvulcanizable Elastomers"},
{value:"2822 Synthetic Rubber (Vulcanizable Elastomers)", label:"2822 Synthetic Rubber (Vulcanizable Elastomers)"},
{value:"2823 Cellulosic Manmade Fibers", label:"2823 Cellulosic Manmade Fibers"},
{value:"2824 Manmade Organic Fibers  Except Cellulosic", label:"2824 Manmade Organic Fibers  Except Cellulosic"},
{value:"2833 Medicinal Chemicals and Botanical Products", label:"2833 Medicinal Chemicals and Botanical Products"},
{value:"2834 Pharmaceutical Preparations", label:"2834 Pharmaceutical Preparations"},
{value:"2835 In Vitro and In Vivo Diagnostic Substances", label:"2835 In Vitro and In Vivo Diagnostic Substances"},
{value:"2836 Biological Products  Except Diagnostic Substances", label:"2836 Biological Products  Except Diagnostic Substances"},
{value:"2841 Soap and Other Detergents  Except Specialty Cleaners", label:"2841 Soap and Other Detergents  Except Specialty Cleaners"},
{value:"2842 Specialty Cleaning  Polishing  and Sanitation Preparations", label:"2842 Specialty Cleaning  Polishing  and Sanitation Preparations"},
{value:"2843 Surface Active Agents  Finishing Agents  Sulfonated Oils  and Assistants", label:"2843 Surface Active Agents  Finishing Agents  Sulfonated Oils  and Assistants"},
{value:"2844 Perfumes  Cosmetics  and Other Toilet Preparations", label:"2844 Perfumes  Cosmetics  and Other Toilet Preparations"},
{value:"2851 Paints  Varnishes  Lacquers  Enamels  and Allied Products", label:"2851 Paints  Varnishes  Lacquers  Enamels  and Allied Products"},
{value:"2861 Gum and Wood Chemicals", label:"2861 Gum and Wood Chemicals"},
{value:"2865 Cyclic Organic Crudes and Intermediates  and Organic Dyes and Pigments", label:"2865 Cyclic Organic Crudes and Intermediates  and Organic Dyes and Pigments"},
{value:"2869 Industrial Organic Chemicals  Not Elsewhere Classified", label:"2869 Industrial Organic Chemicals  Not Elsewhere Classified"},
{value:"2873 Nitrogenous Fertilizers", label:"2873 Nitrogenous Fertilizers"},
{value:"2874 Phosphatic Fertilizers", label:"2874 Phosphatic Fertilizers"},
{value:"2875 Fertilizers  Mixing Only", label:"2875 Fertilizers  Mixing Only"},
{value:"2879 Pesticides and Agricultural Chemicals  Not Elsewhere Classified", label:"2879 Pesticides and Agricultural Chemicals  Not Elsewhere Classified"},
{value:"2891 Adhesives and Sealants", label:"2891 Adhesives and Sealants"},
{value:"2892 Explosives", label:"2892 Explosives"},
{value:"2893 Printing Ink", label:"2893 Printing Ink"},
{value:"2895 Carbon Black", label:"2895 Carbon Black"},
{value:"2899 Chemicals and Chemical Preparations  Not Elsewhere Classified", label:"2899 Chemicals and Chemical Preparations  Not Elsewhere Classified"},
{value:"2911 Petroleum Refining", label:"2911 Petroleum Refining"},
{value:"2951 Asphalt Paving Mixtures and Blocks", label:"2951 Asphalt Paving Mixtures and Blocks"},
{value:"2952 Asphalt Felts and Coatings", label:"2952 Asphalt Felts and Coatings"},
{value:"2992 Lubricating Oils and Greases", label:"2992 Lubricating Oils and Greases"},
{value:"2999 Products of Petroleum and Coal  Not Elsewhere Classified", label:"2999 Products of Petroleum and Coal  Not Elsewhere Classified"},
{value:"3011 Tires and Inner Tubes", label:"3011 Tires and Inner Tubes"},
{value:"3021 Rubber and Plastics Footwear", label:"3021 Rubber and Plastics Footwear"},
{value:"3052 Rubber and Plastics Hose and Belting", label:"3052 Rubber and Plastics Hose and Belting"},
{value:"3053 Gaskets  Packing  and Sealing Devices", label:"3053 Gaskets  Packing  and Sealing Devices"},
{value:"3061 Molded  Extruded  and Lathe-Cut Mechanical Rubber Goods", label:"3061 Molded  Extruded  and Lathe-Cut Mechanical Rubber Goods"},
{value:"3069 Fabricated Rubber Products  Not Elsewhere Classified", label:"3069 Fabricated Rubber Products  Not Elsewhere Classified"},
{value:"3081 Unsupported Plastics Film and Sheet", label:"3081 Unsupported Plastics Film and Sheet"},
{value:"3082 Unsupported Plastics Profile Shapes", label:"3082 Unsupported Plastics Profile Shapes"},
{value:"3083 Laminated Plastics Plate  Sheet  and Profile Shapes", label:"3083 Laminated Plastics Plate  Sheet  and Profile Shapes"},
{value:"3084 Plastics Pipe", label:"3084 Plastics Pipe"},
{value:"3085 Plastics Bottles", label:"3085 Plastics Bottles"},
{value:"3086 Plastics Foam Products", label:"3086 Plastics Foam Products"},
{value:"3087 Custom Compounding of Purchased Plastics Resins", label:"3087 Custom Compounding of Purchased Plastics Resins"},
{value:"3088 Plastics Plumbing Fixtures", label:"3088 Plastics Plumbing Fixtures"},
{value:"3089 Plastics Products  Not Elsewhere Classified", label:"3089 Plastics Products  Not Elsewhere Classified"},
{value:"3111 Leather Tanning and Finishing", label:"3111 Leather Tanning and Finishing"},
{value:"3131 Boot and Shoe Cut Stock and Findings", label:"3131 Boot and Shoe Cut Stock and Findings"},
{value:"3142 House Slippers", label:"3142 House Slippers"},
{value:"3143 Men's Footwear  Except Athletic", label:"3143 Men's Footwear  Except Athletic"},
{value:"3144 Women's Footwear  Except Athletic", label:"3144 Women's Footwear  Except Athletic"},
{value:"3149 Footwear  Except Rubber  Not Elsewhere Classified", label:"3149 Footwear  Except Rubber  Not Elsewhere Classified"},
{value:"3151 Leather Gloves and Mittens", label:"3151 Leather Gloves and Mittens"},
{value:"3161 Luggage", label:"3161 Luggage"},
{value:"3171 Women's Handbags and Purses", label:"3171 Women's Handbags and Purses"},
{value:"3172 Personal Leather Goods  Except Women's Handbags and Purses", label:"3172 Personal Leather Goods  Except Women's Handbags and Purses"},
{value:"3199 Leather Goods  Not Elsewhere Classified", label:"3199 Leather Goods  Not Elsewhere Classified"},
{value:"3211 Flat Glass", label:"3211 Flat Glass"},
{value:"3221 Glass Containers", label:"3221 Glass Containers"},
{value:"3229 Pressed and Blown Glass and Glassware  Not Elsewhere Classified", label:"3229 Pressed and Blown Glass and Glassware  Not Elsewhere Classified"},
{value:"3231 Glass Products  Made of Purchased Glass", label:"3231 Glass Products  Made of Purchased Glass"},
{value:"3241 Cement  Hydraulic", label:"3241 Cement  Hydraulic"},
{value:"3251 Brick and Structural Clay Tile", label:"3251 Brick and Structural Clay Tile"},
{value:"3253 Ceramic Wall and Floor Tile", label:"3253 Ceramic Wall and Floor Tile"},
{value:"3255 Clay Refractories", label:"3255 Clay Refractories"},
{value:"3259 Structural Clay Products  Not Elsewhere Classified", label:"3259 Structural Clay Products  Not Elsewhere Classified"},
{value:"3261 Vitreous China Plumbing Fixtures and China and Earthenware Fittings and Bathroom Accessories", label:"3261 Vitreous China Plumbing Fixtures and China and Earthenware Fittings and Bathroom Accessories"},
{value:"3262 Vitreous China Table and Kitchen Articles", label:"3262 Vitreous China Table and Kitchen Articles"},
{value:"3263 Fine Earthenware (Whiteware) Table and Kitchen Articles", label:"3263 Fine Earthenware (Whiteware) Table and Kitchen Articles"},
{value:"3264 Porcelain Electrical Supplies", label:"3264 Porcelain Electrical Supplies"},
{value:"3269 Pottery Products  Not Elsewhere Classified", label:"3269 Pottery Products  Not Elsewhere Classified"},
{value:"3271 Concrete Block and Brick", label:"3271 Concrete Block and Brick"},
{value:"3272 Concrete Products  Except Block and Brick", label:"3272 Concrete Products  Except Block and Brick"},
{value:"3273 Ready-Mixed Concrete", label:"3273 Ready-Mixed Concrete"},
{value:"3274 Lime", label:"3274 Lime"},
{value:"3275 Gypsum Products", label:"3275 Gypsum Products"},
{value:"3281 Cut Stone and Stone Products", label:"3281 Cut Stone and Stone Products"},
{value:"3291 Abrasive Products", label:"3291 Abrasive Products"},
{value:"3292 Asbestos Products", label:"3292 Asbestos Products"},
{value:"3295 Minerals and Earths  Ground or Otherwise Treated", label:"3295 Minerals and Earths  Ground or Otherwise Treated"},
{value:"3296 Mineral Wool", label:"3296 Mineral Wool"},
{value:"3297 Nonclay Refractories", label:"3297 Nonclay Refractories"},
{value:"3299 Nonmetallic Mineral Products  Not Elsewhere Classified", label:"3299 Nonmetallic Mineral Products  Not Elsewhere Classified"},
{value:"3312 Steel Works  Blast Furnaces (Including Coke Ovens)  and Rolling Mills", label:"3312 Steel Works  Blast Furnaces (Including Coke Ovens)  and Rolling Mills"},
{value:"3313 Electrometallurgical Products  Except Steel", label:"3313 Electrometallurgical Products  Except Steel"},
{value:"3315 Steel Wiredrawing and Steel Nails and Spikes", label:"3315 Steel Wiredrawing and Steel Nails and Spikes"},
{value:"3316 Cold-Rolled Steel Sheet  Strip  and Bars", label:"3316 Cold-Rolled Steel Sheet  Strip  and Bars"},
{value:"3317 Steel Pipe and Tubes", label:"3317 Steel Pipe and Tubes"},
{value:"3321 Gray and Ductile Iron Foundries", label:"3321 Gray and Ductile Iron Foundries"},
{value:"3322 Malleable Iron Foundries", label:"3322 Malleable Iron Foundries"},
{value:"3324 Steel Investment Foundries", label:"3324 Steel Investment Foundries"},
{value:"3325 Steel Foundries  Not Elsewhere Classified", label:"3325 Steel Foundries  Not Elsewhere Classified"},
{value:"3331 Primary Smelting and Refining of Copper", label:"3331 Primary Smelting and Refining of Copper"},
{value:"3334 Primary Production of Aluminum", label:"3334 Primary Production of Aluminum"},
{value:"3339 Primary Smelting and Refining of Nonferrous Metals  Except Copper and Aluminum", label:"3339 Primary Smelting and Refining of Nonferrous Metals  Except Copper and Aluminum"},
{value:"3341 Secondary Smelting and Refining of Nonferrous Metals", label:"3341 Secondary Smelting and Refining of Nonferrous Metals"},
{value:"3351 Rolling  Drawing  and Extruding Of Copper", label:"3351 Rolling  Drawing  and Extruding Of Copper"},
{value:"3353 Aluminum Sheet  Plate  and Foil", label:"3353 Aluminum Sheet  Plate  and Foil"},
{value:"3354 Aluminum Extruded Products", label:"3354 Aluminum Extruded Products"},
{value:"3355 Aluminum Rolling and Drawing  Not Elsewhere Classified", label:"3355 Aluminum Rolling and Drawing  Not Elsewhere Classified"},
{value:"3356 Rolling  Drawing  and Extruding of Nonferrous Metals  Except Copper and Aluminum", label:"3356 Rolling  Drawing  and Extruding of Nonferrous Metals  Except Copper and Aluminum"},
{value:"3357 Drawing and Insulating of Nonferrous Wire", label:"3357 Drawing and Insulating of Nonferrous Wire"},
{value:"3363 Aluminum Die-Castings", label:"3363 Aluminum Die-Castings"},
{value:"3364 Nonferrous Die-Castings  Except Aluminum", label:"3364 Nonferrous Die-Castings  Except Aluminum"},
{value:"3365 Aluminum Foundries", label:"3365 Aluminum Foundries"},
{value:"3366 Copper Foundries", label:"3366 Copper Foundries"},
{value:"3369 Nonferrous Foundries  Except Aluminum and Copper", label:"3369 Nonferrous Foundries  Except Aluminum and Copper"},
{value:"3398 Metal Heat Treating", label:"3398 Metal Heat Treating"},
{value:"3399 Primary Metal Products  Not Elsewhere Classified", label:"3399 Primary Metal Products  Not Elsewhere Classified"},
{value:"3411 Metal Cans", label:"3411 Metal Cans"},
{value:"3412 Metal Shipping Barrels  Drums  Kegs  and Pails", label:"3412 Metal Shipping Barrels  Drums  Kegs  and Pails"},
{value:"3421 Cutlery", label:"3421 Cutlery"},
{value:"3423 Hand and Edge Tools  Except Machine Tools and Handsaws", label:"3423 Hand and Edge Tools  Except Machine Tools and Handsaws"},
{value:"3425 Saw Blades and Handsaws", label:"3425 Saw Blades and Handsaws"},
{value:"3429 Hardware  Not Elsewhere Classified", label:"3429 Hardware  Not Elsewhere Classified"},
{value:"3431 Enameled Iron and Metal Sanitary Ware", label:"3431 Enameled Iron and Metal Sanitary Ware"},
{value:"3432 Plumbing Fixture Fittings and Trim", label:"3432 Plumbing Fixture Fittings and Trim"},
{value:"3433 Heating Equipment  Except Electric and Warm Air Furnaces", label:"3433 Heating Equipment  Except Electric and Warm Air Furnaces"},
{value:"3441 Fabricated Structural Metal", label:"3441 Fabricated Structural Metal"},
{value:"3442 Metal Doors  Sash  Frames  Molding  and Trim Manufacturing", label:"3442 Metal Doors  Sash  Frames  Molding  and Trim Manufacturing"},
{value:"3443 Fabricated Plate Work (Boiler Shops)", label:"3443 Fabricated Plate Work (Boiler Shops)"},
{value:"3444 Sheet Metal Work", label:"3444 Sheet Metal Work"},
{value:"3446 Architectural and Ornamental Metal Work", label:"3446 Architectural and Ornamental Metal Work"},
{value:"3448 Prefabricated Metal Buildings and Components", label:"3448 Prefabricated Metal Buildings and Components"},
{value:"3449 Miscellaneous Structural Metal Work", label:"3449 Miscellaneous Structural Metal Work"},
{value:"3451 Screw Machine Products", label:"3451 Screw Machine Products"},
{value:"3452 Bolts  Nuts  Screws  Rivets  and Washers", label:"3452 Bolts  Nuts  Screws  Rivets  and Washers"},
{value:"3462 Iron and Steel Forgings", label:"3462 Iron and Steel Forgings"},
{value:"3463 Nonferrous Forgings", label:"3463 Nonferrous Forgings"},
{value:"3465 Automotive Stampings", label:"3465 Automotive Stampings"},
{value:"3466 Crowns and Closures", label:"3466 Crowns and Closures"},
{value:"3469 Metal Stampings  Not Elsewhere Classified", label:"3469 Metal Stampings  Not Elsewhere Classified"},
{value:"3471 Electroplating  Plating  Polishing  Anodizing  and Coloring", label:"3471 Electroplating  Plating  Polishing  Anodizing  and Coloring"},
{value:"3479 Coating  Engraving  and Allied Services  Not Elsewhere Classified", label:"3479 Coating  Engraving  and Allied Services  Not Elsewhere Classified"},
{value:"3482 Small Arms Ammunition", label:"3482 Small Arms Ammunition"},
{value:"3483 Ammunition  Except for Small Arms", label:"3483 Ammunition  Except for Small Arms"},
{value:"3484 Small Arms", label:"3484 Small Arms"},
{value:"3489 Ordnance and Accessories  Not Elsewhere Classified", label:"3489 Ordnance and Accessories  Not Elsewhere Classified"},
{value:"3491 Industrial Valves", label:"3491 Industrial Valves"},
{value:"3492 Fluid Power Valves and Hose Fittings", label:"3492 Fluid Power Valves and Hose Fittings"},
{value:"3493 Steel Springs  Except Wire", label:"3493 Steel Springs  Except Wire"},
{value:"3494 Valves and Pipe Fittings  Not Elsewhere Classified", label:"3494 Valves and Pipe Fittings  Not Elsewhere Classified"},
{value:"3495 Wire Springs", label:"3495 Wire Springs"},
{value:"3496 Miscellaneous Fabricated Wire Products", label:"3496 Miscellaneous Fabricated Wire Products"},
{value:"3497 Metal Foil and Leaf", label:"3497 Metal Foil and Leaf"},
{value:"3498 Fabricated Pipe and Pipe Fittings", label:"3498 Fabricated Pipe and Pipe Fittings"},
{value:"3499 Fabricated Metal Products  Not Elsewhere Classified", label:"3499 Fabricated Metal Products  Not Elsewhere Classified"},
{value:"3511 Steam  Gas  and Hydraulic Turbines  and Turbine Generator Set Units", label:"3511 Steam  Gas  and Hydraulic Turbines  and Turbine Generator Set Units"},
{value:"3519 Internal Combustion Engines  Not Elsewhere Classified", label:"3519 Internal Combustion Engines  Not Elsewhere Classified"},
{value:"3523 Farm Machinery and Equipment", label:"3523 Farm Machinery and Equipment"},
{value:"3524 Lawn and Garden Tractors and Home Lawn and Garden Equipment", label:"3524 Lawn and Garden Tractors and Home Lawn and Garden Equipment"},
{value:"3531 Construction Machinery and Equipment", label:"3531 Construction Machinery and Equipment"},
{value:"3532 Mining Machinery and Equipment  Except Oil and Gas Field Machinery and Equipment", label:"3532 Mining Machinery and Equipment  Except Oil and Gas Field Machinery and Equipment"},
{value:"3533 Oil and Gas Field Machinery and Equipment", label:"3533 Oil and Gas Field Machinery and Equipment"},
{value:"3534 Elevators and Moving Stairways", label:"3534 Elevators and Moving Stairways"},
{value:"3535 Conveyors and Conveying Equipment", label:"3535 Conveyors and Conveying Equipment"},
{value:"3536 Overhead Traveling Cranes  Hoists  and Monorail Systems", label:"3536 Overhead Traveling Cranes  Hoists  and Monorail Systems"},
{value:"3537 Industrial Trucks  Tractors  Trailers  and Stackers", label:"3537 Industrial Trucks  Tractors  Trailers  and Stackers"},
{value:"3541 Machine Tools  Metal Cutting Types", label:"3541 Machine Tools  Metal Cutting Types"},
{value:"3542 Machine Tools  Metal Forming Types", label:"3542 Machine Tools  Metal Forming Types"},
{value:"3543 Industrial Patterns", label:"3543 Industrial Patterns"},
{value:"3544 Special Dies and Tools  Die Sets  Jigs and Fixtures  and Industrial Molds", label:"3544 Special Dies and Tools  Die Sets  Jigs and Fixtures  and Industrial Molds"},
{value:"3545 Cutting Tools  Machine Tool Accessories  and Machinists' Precision Measuring Devices", label:"3545 Cutting Tools  Machine Tool Accessories  and Machinists' Precision Measuring Devices"},
{value:"3546 Power-Driven Handtools", label:"3546 Power-Driven Handtools"},
{value:"3547 Rolling Mill Machinery and Equipment", label:"3547 Rolling Mill Machinery and Equipment"},
{value:"3548 Electric and Gas Welding and Soldering Equipment", label:"3548 Electric and Gas Welding and Soldering Equipment"},
{value:"3549 Metalworking Machinery  Not Elsewhere Classified", label:"3549 Metalworking Machinery  Not Elsewhere Classified"},
{value:"3552 Textile Machinery", label:"3552 Textile Machinery"},
{value:"3553 Woodworking Machinery", label:"3553 Woodworking Machinery"},
{value:"3554 Paper Industries Machinery", label:"3554 Paper Industries Machinery"},
{value:"3555 Printing Trades Machinery and Equipment", label:"3555 Printing Trades Machinery and Equipment"},
{value:"3556 Food Products Machinery", label:"3556 Food Products Machinery"},
{value:"3559 Special Industry Machinery  Not Elsewhere Classified", label:"3559 Special Industry Machinery  Not Elsewhere Classified"},
{value:"3561 Pumps and Pumping Equipment", label:"3561 Pumps and Pumping Equipment"},
{value:"3562 Ball and Roller Bearings", label:"3562 Ball and Roller Bearings"},
{value:"3563 Air and Gas Compressors", label:"3563 Air and Gas Compressors"},
{value:"3564 Industrial and Commercial Fans and Blowers and Air Purification Equipment", label:"3564 Industrial and Commercial Fans and Blowers and Air Purification Equipment"},
{value:"3565 Packaging Machinery", label:"3565 Packaging Machinery"},
{value:"3566 Speed Changers  Industrial High-Speed Drives  and Gears", label:"3566 Speed Changers  Industrial High-Speed Drives  and Gears"},
{value:"3567 Industrial Process Furnaces and Ovens", label:"3567 Industrial Process Furnaces and Ovens"},
{value:"3568 Mechanical Power Transmission Equipment  Not Elsewhere Classified", label:"3568 Mechanical Power Transmission Equipment  Not Elsewhere Classified"},
{value:"3569 General Industrial Machinery and Equipment  Not Elsewhere", label:"3569 General Industrial Machinery and Equipment  Not Elsewhere"},
{value:"3571 Electronic Computers", label:"3571 Electronic Computers"},
{value:"3572 Computer Storage Devices", label:"3572 Computer Storage Devices"},
{value:"3575 Computer Terminals", label:"3575 Computer Terminals"},
{value:"3577 Computer Peripheral Equipment  Not Elsewhere Classified", label:"3577 Computer Peripheral Equipment  Not Elsewhere Classified"},
{value:"3578 Calculating and Accounting Machines  Except Electronic Computers", label:"3578 Calculating and Accounting Machines  Except Electronic Computers"},
{value:"3579 Office Machines  Not Elsewhere Classified", label:"3579 Office Machines  Not Elsewhere Classified"},
{value:"3581 Automatic Vending Machines", label:"3581 Automatic Vending Machines"},
{value:"3582 Commercial Laundry  Drycleaning  and Pressing Machines", label:"3582 Commercial Laundry  Drycleaning  and Pressing Machines"},
{value:"3585 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment", label:"3585 Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment"},
{value:"3586 Measuring and Dispensing Pumps", label:"3586 Measuring and Dispensing Pumps"},
{value:"3589 Service Industry Machinery  Not Elsewhere Classified", label:"3589 Service Industry Machinery  Not Elsewhere Classified"},
{value:"3592 Carburetors  Pistons  Piston Rings  and Valves", label:"3592 Carburetors  Pistons  Piston Rings  and Valves"},
{value:"3593 Fluid Power Cylinders and Actuators", label:"3593 Fluid Power Cylinders and Actuators"},
{value:"3594 Fluid Power Pumps and Motors", label:"3594 Fluid Power Pumps and Motors"},
{value:"3596 Scales and Balances  Except Laboratory", label:"3596 Scales and Balances  Except Laboratory"},
{value:"3599 Industrial and Commercial Machinery and Equipment  Not Elsewhere Classified", label:"3599 Industrial and Commercial Machinery and Equipment  Not Elsewhere Classified"},
{value:"3612 Power  Distribution  and Specialty Transformers", label:"3612 Power  Distribution  and Specialty Transformers"},
{value:"3613 Switchgear and Switchboard Apparatus", label:"3613 Switchgear and Switchboard Apparatus"},
{value:"3621 Motors and Generators", label:"3621 Motors and Generators"},
{value:"3624 Carbon and Graphite Products", label:"3624 Carbon and Graphite Products"},
{value:"3625 Relays and Industrial Controls", label:"3625 Relays and Industrial Controls"},
{value:"3629 Electrical Industrial Apparatus  Not Elsewhere Classified", label:"3629 Electrical Industrial Apparatus  Not Elsewhere Classified"},
{value:"3631 Household Cooking Equipment", label:"3631 Household Cooking Equipment"},
{value:"3632 Household Refrigerators and HOme and Farm Freezers", label:"3632 Household Refrigerators and HOme and Farm Freezers"},
{value:"3633 Household Laundry Equipment", label:"3633 Household Laundry Equipment"},
{value:"3634 Electric Housewares and Fans", label:"3634 Electric Housewares and Fans"},
{value:"3635 Household Vacuum Cleaners", label:"3635 Household Vacuum Cleaners"},
{value:"3639 Household Appliances  Not Elsewhere Classified", label:"3639 Household Appliances  Not Elsewhere Classified"},
{value:"3641 Electric Lamp Bulbs and Tubes", label:"3641 Electric Lamp Bulbs and Tubes"},
{value:"3643 Current-Carrying Wiring Devices", label:"3643 Current-Carrying Wiring Devices"},
{value:"3644 Noncurrent-Carrying Wiring Devices", label:"3644 Noncurrent-Carrying Wiring Devices"},
{value:"3645 Residential Electric Lighting Fixtures", label:"3645 Residential Electric Lighting Fixtures"},
{value:"3646 Commercial  Industrial  and Institutional Electric Lighting Fixtures", label:"3646 Commercial  Industrial  and Institutional Electric Lighting Fixtures"},
{value:"3647 Vehicular Lighting Equipment", label:"3647 Vehicular Lighting Equipment"},
{value:"3648 Lighting Equipment  Not Elsewhere Classified", label:"3648 Lighting Equipment  Not Elsewhere Classified"},
{value:"3651 Household Audio and Video Equipment", label:"3651 Household Audio and Video Equipment"},
{value:"3652 Phonograph Records and Prerecorded Audio Tapes and Disks", label:"3652 Phonograph Records and Prerecorded Audio Tapes and Disks"},
{value:"3661 Telephone and Telegraph Apparatus", label:"3661 Telephone and Telegraph Apparatus"},
{value:"3663 Radio and Television Broadcasting and Communications Equipment", label:"3663 Radio and Television Broadcasting and Communications Equipment"},
{value:"3669 Communications Equipment  Not Elsewhere Classified", label:"3669 Communications Equipment  Not Elsewhere Classified"},
{value:"3671 Electron Tubes", label:"3671 Electron Tubes"},
{value:"3672 Printed Circuit Boards", label:"3672 Printed Circuit Boards"},
{value:"3674 Semiconductors and Related Devices", label:"3674 Semiconductors and Related Devices"},
{value:"3675 Electronic Capacitors", label:"3675 Electronic Capacitors"},
{value:"3676 Electronic Resistors", label:"3676 Electronic Resistors"},
{value:"3677 Electronic Coils  Transformers  and Other Inductors", label:"3677 Electronic Coils  Transformers  and Other Inductors"},
{value:"3678 Electronic Connectors", label:"3678 Electronic Connectors"},
{value:"3679 Electronic Components  Not Elsewhere Classified", label:"3679 Electronic Components  Not Elsewhere Classified"},
{value:"3691 Storage Batteries", label:"3691 Storage Batteries"},
{value:"3692 Primary Batteries  Dry and Wet", label:"3692 Primary Batteries  Dry and Wet"},
{value:"3694 Electrical Equipment for Internal Combustion Engines", label:"3694 Electrical Equipment for Internal Combustion Engines"},
{value:"3695 Magnetic And Optical Recording Media", label:"3695 Magnetic And Optical Recording Media"},
{value:"3699 Electrical Machinery  Equipment  and Supplies  Not Elsewhere", label:"3699 Electrical Machinery  Equipment  and Supplies  Not Elsewhere"},
{value:"3711 Motor Vehicles and Passenger Car Bodies", label:"3711 Motor Vehicles and Passenger Car Bodies"},
{value:"3713 Truck and Bus Bodies", label:"3713 Truck and Bus Bodies"},
{value:"3714 Motor Vehicle Parts and Accessories", label:"3714 Motor Vehicle Parts and Accessories"},
{value:"3715 Truck Trailers", label:"3715 Truck Trailers"},
{value:"3716 Motor Homes", label:"3716 Motor Homes"},
{value:"3721 Aircraft", label:"3721 Aircraft"},
{value:"3724 Aircraft Engines and Engine Parts", label:"3724 Aircraft Engines and Engine Parts"},
{value:"3728 Aircraft Parts and Auxiliary Equipment  Not Elsewhere Classified", label:"3728 Aircraft Parts and Auxiliary Equipment  Not Elsewhere Classified"},
{value:"3731 Ship Building and Repairing", label:"3731 Ship Building and Repairing"},
{value:"3732 Boat Building and Repairing", label:"3732 Boat Building and Repairing"},
{value:"3743 Railroad Equipment", label:"3743 Railroad Equipment"},
{value:"3751 Motorcycles  Bicycles  and Parts", label:"3751 Motorcycles  Bicycles  and Parts"},
{value:"3761 Guided Missiles and Space Vehicles", label:"3761 Guided Missiles and Space Vehicles"},
{value:"3764 Guided Missile and Space Vehicle Propulsion Units and Propulsion Unit Parts", label:"3764 Guided Missile and Space Vehicle Propulsion Units and Propulsion Unit Parts"},
{value:"3769 Guided Missile Space Vehicle Parts and Auxiliary Equipment  Not Elsewhere Classified", label:"3769 Guided Missile Space Vehicle Parts and Auxiliary Equipment  Not Elsewhere Classified"},
{value:"3792 Travel Trailers and Campers", label:"3792 Travel Trailers and Campers"},
{value:"3795 Tanks and Tank Components", label:"3795 Tanks and Tank Components"},
{value:"3799 Transportation Equipment  Not Elsewhere Classified", label:"3799 Transportation Equipment  Not Elsewhere Classified"},
{value:"3812 Search  Detection  Navigation  Guidance  Aeronautical  and Nautical Systems and Instruments", label:"3812 Search  Detection  Navigation  Guidance  Aeronautical  and Nautical Systems and Instruments"},
{value:"3821 Laboratory Apparatus and Furniture", label:"3821 Laboratory Apparatus and Furniture"},
{value:"3822 Automatic Controls for Regulating Residential and Commercial Environments and Appliances", label:"3822 Automatic Controls for Regulating Residential and Commercial Environments and Appliances"},
{value:"3823 Industrial Instruments for Measurement  Display  and Control of Process Variables; and Related Products", label:"3823 Industrial Instruments for Measurement  Display  and Control of Process Variables; and Related Products"},
{value:"3824 Totalizing Fluid Meters and Counting Devices", label:"3824 Totalizing Fluid Meters and Counting Devices"},
{value:"3825 Instruments for Measuring and Testing of Electricity and Electrical Signals", label:"3825 Instruments for Measuring and Testing of Electricity and Electrical Signals"},
{value:"3826 Laboratory Analytical Instruments", label:"3826 Laboratory Analytical Instruments"},
{value:"3827 Optical Instruments and Lenses", label:"3827 Optical Instruments and Lenses"},
{value:"3829 Measuring and Controlling Devices  Not Elsewhere Classified", label:"3829 Measuring and Controlling Devices  Not Elsewhere Classified"},
{value:"3841 Surgical and Medical Instruments and Apparatus", label:"3841 Surgical and Medical Instruments and Apparatus"},
{value:"3842 Orthopedic  Prosthetic  and Surgical Appliances and Supplies", label:"3842 Orthopedic  Prosthetic  and Surgical Appliances and Supplies"},
{value:"3843 Dental Equipment and Supplies", label:"3843 Dental Equipment and Supplies"},
{value:"3844 X-Ray Apparatus and Tubes and Related Irradiation Apparatus", label:"3844 X-Ray Apparatus and Tubes and Related Irradiation Apparatus"},
{value:"3845 Electromedical and Electrotherapeutic Apparatus", label:"3845 Electromedical and Electrotherapeutic Apparatus"},
{value:"3851 Ophthalmic Goods", label:"3851 Ophthalmic Goods"},
{value:"3861 Photographic Equipment and Supplies", label:"3861 Photographic Equipment and Supplies"},
{value:"3873 Watches  Clocks  Clockwork Operated Devices  and Parts", label:"3873 Watches  Clocks  Clockwork Operated Devices  and Parts"},
{value:"3911 Jewelry  Precious Metal", label:"3911 Jewelry  Precious Metal"},
{value:"3914 Silverware  Plated Ware  and Stainless Steel Ware", label:"3914 Silverware  Plated Ware  and Stainless Steel Ware"},
{value:"3915 Jewelers' Findings and Materials  and Lapidary Work", label:"3915 Jewelers' Findings and Materials  and Lapidary Work"},
{value:"3931 Musical Instruments", label:"3931 Musical Instruments"},
{value:"3942 Dolls and Stuffed Toys", label:"3942 Dolls and Stuffed Toys"},
{value:"3944 Games  Toys  and Children's Vehicles  Except Dolls and Bicycles", label:"3944 Games  Toys  and Children's Vehicles  Except Dolls and Bicycles"},
{value:"3949 Sporting and Athletic Goods  Not Elsewhere Classified", label:"3949 Sporting and Athletic Goods  Not Elsewhere Classified"},
{value:"3951 Pens  Mechanical Pencils  and Parts", label:"3951 Pens  Mechanical Pencils  and Parts"},
{value:"3952 Lead Pencils  Crayons  and Artists' Materials", label:"3952 Lead Pencils  Crayons  and Artists' Materials"},
{value:"3953 Marking Devices", label:"3953 Marking Devices"},
{value:"3955 Carbon Paper and Inked Ribbons", label:"3955 Carbon Paper and Inked Ribbons"},
{value:"3961 Costume Jewelry and Costume Novelties  Except Precious Metal", label:"3961 Costume Jewelry and Costume Novelties  Except Precious Metal"},
{value:"3965 Fasteners  Buttons  Needles  and Pins", label:"3965 Fasteners  Buttons  Needles  and Pins"},
{value:"3991 Brooms and Brushes", label:"3991 Brooms and Brushes"},
{value:"3993 Signs and Advertising Specialties", label:"3993 Signs and Advertising Specialties"},
{value:"3995 Burial Caskets", label:"3995 Burial Caskets"},
{value:"3996 Linoleum  Asphalted-Felt-Base  and Other Hard Surface Floor Coverings  Not Elsewhere Classified", label:"3996 Linoleum  Asphalted-Felt-Base  and Other Hard Surface Floor Coverings  Not Elsewhere Classified"},
{value:"3999 Manufacturing Industries  Not Elsewhere Classified", label:"3999 Manufacturing Industries  Not Elsewhere Classified"},
{value:"4011 Railroads  Line-Haul Operating", label:"4011 Railroads  Line-Haul Operating"},
{value:"4013 Railroad Switching and Terminal Establishments", label:"4013 Railroad Switching and Terminal Establishments"},
{value:"4111 Local and Suburban Transit", label:"4111 Local and Suburban Transit"},
{value:"4119 Local Passenger Transportation  Not Elsewhere Classified", label:"4119 Local Passenger Transportation  Not Elsewhere Classified"},
{value:"4121 Taxicabs", label:"4121 Taxicabs"},
{value:"4131 Intercity and Rural Bus Transportation", label:"4131 Intercity and Rural Bus Transportation"},
{value:"4141 Local Bus Charter Service", label:"4141 Local Bus Charter Service"},
{value:"4142 Bus Charter Service  Except Local", label:"4142 Bus Charter Service  Except Local"},
{value:"4151 School Buses", label:"4151 School Buses"},
{value:"4173 Terminal and Service Facilities for Motor Vehicle Passenger Transportation", label:"4173 Terminal and Service Facilities for Motor Vehicle Passenger Transportation"},
{value:"4212 Local Trucking Without Storage", label:"4212 Local Trucking Without Storage"},
{value:"4213 Trucking  Except Local", label:"4213 Trucking  Except Local"},
{value:"4214 Local Trucking With Storage", label:"4214 Local Trucking With Storage"},
{value:"4215 Courier Services  Except by Air", label:"4215 Courier Services  Except by Air"},
{value:"4221 Farm Product Warehousing and Storage", label:"4221 Farm Product Warehousing and Storage"},
{value:"4222 Refrigerated Warehousing and Storage", label:"4222 Refrigerated Warehousing and Storage"},
{value:"4225 General Warehousing and Storage", label:"4225 General Warehousing and Storage"},
{value:"4226 Special Warehousing and Storage  Not Elsewhere Classified", label:"4226 Special Warehousing and Storage  Not Elsewhere Classified"},
{value:"4231 Terminal and Joint Terminal Maintenance Facilities for Motor Freight Transportation", label:"4231 Terminal and Joint Terminal Maintenance Facilities for Motor Freight Transportation"},
{value:"4311 United States Postal Service", label:"4311 United States Postal Service"},
{value:"4412 Deep Sea Foreign Transportation of Freight", label:"4412 Deep Sea Foreign Transportation of Freight"},
{value:"4424 Deep Sea Domestic Transportation of Freight", label:"4424 Deep Sea Domestic Transportation of Freight"},
{value:"4432 Freight Transportation on the Great Lakes-St. Lawrence Seaway", label:"4432 Freight Transportation on the Great Lakes-St. Lawrence Seaway"},
{value:"4449 Water Transportation of Freight  Not Elsewhere Classified", label:"4449 Water Transportation of Freight  Not Elsewhere Classified"},
{value:"4481 Deep Sea Transportation of Passengers  Except by Ferry", label:"4481 Deep Sea Transportation of Passengers  Except by Ferry"},
{value:"4482 Ferries", label:"4482 Ferries"},
{value:"4489 Water Transportation of Passengers  Not Elsewhere Classified", label:"4489 Water Transportation of Passengers  Not Elsewhere Classified"},
{value:"4491 Marine Cargo Handling", label:"4491 Marine Cargo Handling"},
{value:"4492 Towing and Tugboat Services", label:"4492 Towing and Tugboat Services"},
{value:"4493 Marinas", label:"4493 Marinas"},
{value:"4499 Water Transportation Services  Not Elsewhere Classified", label:"4499 Water Transportation Services  Not Elsewhere Classified"},
{value:"4512 Air Transportation  Scheduled", label:"4512 Air Transportation  Scheduled"},
{value:"4513 Air Courier Services", label:"4513 Air Courier Services"},
{value:"4522 Air Transportation  Nonscheduled", label:"4522 Air Transportation  Nonscheduled"},
{value:"4581 Airports  Flying Fields  and Airport Terminal Services", label:"4581 Airports  Flying Fields  and Airport Terminal Services"},
{value:"4612 Crude Petroleum Pipelines", label:"4612 Crude Petroleum Pipelines"},
{value:"4613 Refined Petroleum Pipelines", label:"4613 Refined Petroleum Pipelines"},
{value:"4619 Pipelines  Not Elsewhere Classified", label:"4619 Pipelines  Not Elsewhere Classified"},
{value:"4724 Travel Agencies", label:"4724 Travel Agencies"},
{value:"4725 Tour Operators", label:"4725 Tour Operators"},
{value:"4729 Arrangement of Passenger Transportation  Not Elsewhere Classified", label:"4729 Arrangement of Passenger Transportation  Not Elsewhere Classified"},
{value:"4731 Arrangement of Transportation of Freight and Cargo", label:"4731 Arrangement of Transportation of Freight and Cargo"},
{value:"4741 Rental of Railroad Cars", label:"4741 Rental of Railroad Cars"},
{value:"4783 Packing and Crating", label:"4783 Packing and Crating"},
{value:"4785 Fixed Facilities and Inspection and Weighing Services for Motor Vehicle Transportation", label:"4785 Fixed Facilities and Inspection and Weighing Services for Motor Vehicle Transportation"},
{value:"4789 Transportation Services  Not Elsewhere Classified", label:"4789 Transportation Services  Not Elsewhere Classified"},
{value:"4812 Radiotelephone Communications", label:"4812 Radiotelephone Communications"},
{value:"4813 Telephone Communications  Except Radiotelephone", label:"4813 Telephone Communications  Except Radiotelephone"},
{value:"4822 Telegraph and Other Message Communications", label:"4822 Telegraph and Other Message Communications"},
{value:"4832 Radio Broadcasting Stations", label:"4832 Radio Broadcasting Stations"},
{value:"4833 Television Broadcasting Stations", label:"4833 Television Broadcasting Stations"},
{value:"4841 Cable and Other Pay Television Services", label:"4841 Cable and Other Pay Television Services"},
{value:"4899 Communications Services  Not Elsewhere Classified", label:"4899 Communications Services  Not Elsewhere Classified"},
{value:"4911 Electric Services", label:"4911 Electric Services"},
{value:"4922 Natural Gas Transmission", label:"4922 Natural Gas Transmission"},
{value:"4923 Natural Gas Transmission and Distribution", label:"4923 Natural Gas Transmission and Distribution"},
{value:"4924 Natural Gas Distribution", label:"4924 Natural Gas Distribution"},
{value:"4925 Mixed  Manufactured  or Liquefied Petroleum Gas Production and/or", label:"4925 Mixed  Manufactured  or Liquefied Petroleum Gas Production and/or"},
{value:"4931 Electric and Other Services Combined", label:"4931 Electric and Other Services Combined"},
{value:"4932 Gas and Other Services Combined", label:"4932 Gas and Other Services Combined"},
{value:"4939 Combination Utilities  Not Elsewhere Classified", label:"4939 Combination Utilities  Not Elsewhere Classified"},
{value:"4941 Water Supply", label:"4941 Water Supply"},
{value:"4952 Sewerage Systems", label:"4952 Sewerage Systems"},
{value:"4953 Refuse Systems", label:"4953 Refuse Systems"},
{value:"4959 Sanitary Services  Not Elsewhere Classified", label:"4959 Sanitary Services  Not Elsewhere Classified"},
{value:"4961 Steam and Air-Conditioning Supply", label:"4961 Steam and Air-Conditioning Supply"},
{value:"4971 Irrigation Systems", label:"4971 Irrigation Systems"},
{value:"5012 Automobiles and Other Motor Vehicles", label:"5012 Automobiles and Other Motor Vehicles"},
{value:"5013 Motor Vehicle Supplies and New Parts", label:"5013 Motor Vehicle Supplies and New Parts"},
{value:"5014 Tires and Tubes", label:"5014 Tires and Tubes"},
{value:"5015 Motor Vehicle Parts  Used", label:"5015 Motor Vehicle Parts  Used"},
{value:"5021 Furniture", label:"5021 Furniture"},
{value:"5023 Home furnishings", label:"5023 Home furnishings"},
{value:"5031 Lumber  Plywood  Millwork  and Wood Panels", label:"5031 Lumber  Plywood  Millwork  and Wood Panels"},
{value:"5032 Brick  Stone  and Related Construction Materials", label:"5032 Brick  Stone  and Related Construction Materials"},
{value:"5033 Roofing  Siding  and Insulation Materials", label:"5033 Roofing  Siding  and Insulation Materials"},
{value:"5039 Construction Materials  Not Elsewhere Classified", label:"5039 Construction Materials  Not Elsewhere Classified"},
{value:"5043 Photographic Equipment and Supplies", label:"5043 Photographic Equipment and Supplies"},
{value:"5044 Office Equipment", label:"5044 Office Equipment"},
{value:"5045 Computers and Computer Peripheral Equipment and Software", label:"5045 Computers and Computer Peripheral Equipment and Software"},
{value:"5046 Commercial Equipment  Not Elsewhere Classified", label:"5046 Commercial Equipment  Not Elsewhere Classified"},
{value:"5047 Medical  Dental  and Hospital Equipment and Supplies", label:"5047 Medical  Dental  and Hospital Equipment and Supplies"},
{value:"5048 Ophthalmic Goods", label:"5048 Ophthalmic Goods"},
{value:"5049 Professional Equipment and Supplies  Not Elsewhere Classified", label:"5049 Professional Equipment and Supplies  Not Elsewhere Classified"},
{value:"5051 Metals Service Centers and Offices", label:"5051 Metals Service Centers and Offices"},
{value:"5052 Coal and Other Minerals and Ores", label:"5052 Coal and Other Minerals and Ores"},
{value:"5063 Electrical Apparatus and Equipment Wiring Supplies  and Construction Materials", label:"5063 Electrical Apparatus and Equipment Wiring Supplies  and Construction Materials"},
{value:"5064 Electrical Appliances  Television and Radio Sets", label:"5064 Electrical Appliances  Television and Radio Sets"},
{value:"5065 Electronic Parts and Equipment  Not Elsewhere Classified", label:"5065 Electronic Parts and Equipment  Not Elsewhere Classified"},
{value:"5072 Hardware", label:"5072 Hardware"},
{value:"5074 Plumbing and Heating Equipment and Supplies (Hydronics)", label:"5074 Plumbing and Heating Equipment and Supplies (Hydronics)"},
{value:"5075 Warm Air Heating and Air-Conditioning Equipment and Supplies", label:"5075 Warm Air Heating and Air-Conditioning Equipment and Supplies"},
{value:"5078 Refrigeration Equipment and Supplies", label:"5078 Refrigeration Equipment and Supplies"},
{value:"5082 Construction and Mining (Except Petroleum) Machinery and Equipment", label:"5082 Construction and Mining (Except Petroleum) Machinery and Equipment"},
{value:"5083 Farm and Garden Machinery and Equipment", label:"5083 Farm and Garden Machinery and Equipment"},
{value:"5084 Industrial Machinery and Equipment", label:"5084 Industrial Machinery and Equipment"},
{value:"5085 Industrial Supplies", label:"5085 Industrial Supplies"},
{value:"5087 Service Establishment Equipment and Supplies", label:"5087 Service Establishment Equipment and Supplies"},
{value:"5088 Transportation Equipment and Supplies  Except Motor Vehicles", label:"5088 Transportation Equipment and Supplies  Except Motor Vehicles"},
{value:"5091 Sporting and Recreational Goods and Supplies", label:"5091 Sporting and Recreational Goods and Supplies"},
{value:"5092 Toys and Hobby Goods and Supplies", label:"5092 Toys and Hobby Goods and Supplies"},
{value:"5093 Scrap and Waste Materials", label:"5093 Scrap and Waste Materials"},
{value:"5094 Jewelry  Watches  Precious Stones  and Precious Metals", label:"5094 Jewelry  Watches  Precious Stones  and Precious Metals"},
{value:"5099 Durable Goods  Not Elsewhere Classified", label:"5099 Durable Goods  Not Elsewhere Classified"},
{value:"5111 Printing and Writing Paper", label:"5111 Printing and Writing Paper"},
{value:"5112 Stationery and Office Supplies", label:"5112 Stationery and Office Supplies"},
{value:"5113 Industrial and Personal Service Paper", label:"5113 Industrial and Personal Service Paper"},
{value:"5122 Drugs  Drug Proprietaries  and Druggists' Sundries", label:"5122 Drugs  Drug Proprietaries  and Druggists' Sundries"},
{value:"5131 Piece Goods  Notions  and Other Dry Good", label:"5131 Piece Goods  Notions  and Other Dry Good"},
{value:"5136 Men's and Boy's Clothing and Furnishings", label:"5136 Men's and Boy's Clothing and Furnishings"},
{value:"5137 Women's  Children's  and Infants' Clothing and Accessories", label:"5137 Women's  Children's  and Infants' Clothing and Accessories"},
{value:"5139 Footwear", label:"5139 Footwear"},
{value:"5141 Groceries  General Line", label:"5141 Groceries  General Line"},
{value:"5142 Packaged Frozen Foods", label:"5142 Packaged Frozen Foods"},
{value:"5143 Dairy Products  Except Dried or Canned", label:"5143 Dairy Products  Except Dried or Canned"},
{value:"5144 Poultry and Poultry Products", label:"5144 Poultry and Poultry Products"},
{value:"5145 Confectionery", label:"5145 Confectionery"},
{value:"5146 Fish and Seafoods", label:"5146 Fish and Seafoods"},
{value:"5147 Meats and Meat Products", label:"5147 Meats and Meat Products"},
{value:"5148 Fresh Fruits and Vegetables", label:"5148 Fresh Fruits and Vegetables"},
{value:"5149 Groceries and Related Products  Not Elsewhere Classified", label:"5149 Groceries and Related Products  Not Elsewhere Classified"},
{value:"5153 Grain and Field Beans", label:"5153 Grain and Field Beans"},
{value:"5154 Livestock", label:"5154 Livestock"},
{value:"5159 Farm-Product Raw Materials  Not Elsewhere Classified", label:"5159 Farm-Product Raw Materials  Not Elsewhere Classified"},
{value:"5162 Plastics Materials and Basic Forms and Shapes", label:"5162 Plastics Materials and Basic Forms and Shapes"},
{value:"5169 Chemicals and Allied Products  Not Elsewhere Classified", label:"5169 Chemicals and Allied Products  Not Elsewhere Classified"},
{value:"5171 Petroleum Bulk stations and Terminals", label:"5171 Petroleum Bulk stations and Terminals"},
{value:"5172 Petroleum and Petroleum Products Wholesalers  Except Bulk Stations and Terminals", label:"5172 Petroleum and Petroleum Products Wholesalers  Except Bulk Stations and Terminals"},
{value:"5181 Beer and Ale", label:"5181 Beer and Ale"},
{value:"5182 Wine and Distilled Alcoholic Beverages", label:"5182 Wine and Distilled Alcoholic Beverages"},
{value:"5191 Farm Supplies", label:"5191 Farm Supplies"},
{value:"5192 Books  Periodicals  and Newspapers", label:"5192 Books  Periodicals  and Newspapers"},
{value:"5193 Flowers  Nursery Stock  and Florists' Supplies", label:"5193 Flowers  Nursery Stock  and Florists' Supplies"},
{value:"5194 Tobacco and Tobacco Products", label:"5194 Tobacco and Tobacco Products"},
{value:"5198 Paints  Varnishes  and Supplies", label:"5198 Paints  Varnishes  and Supplies"},
{value:"5199 Nondurable Goods  Not Elsewhere Classified", label:"5199 Nondurable Goods  Not Elsewhere Classified"},
{value:"5211 Lumber and Other Building Materials Dealers", label:"5211 Lumber and Other Building Materials Dealers"},
{value:"5231 Paint  Glass  and Wallpaper Stores", label:"5231 Paint  Glass  and Wallpaper Stores"},
{value:"5251 Hardware Stores", label:"5251 Hardware Stores"},
{value:"5261 Retail Nurseries  Lawn and Garden Supply Stores", label:"5261 Retail Nurseries  Lawn and Garden Supply Stores"},
{value:"5271 Mobile Home Dealers", label:"5271 Mobile Home Dealers"},
{value:"5311 Department Stores", label:"5311 Department Stores"},
{value:"5331 Variety Stores", label:"5331 Variety Stores"},
{value:"5399 Miscellaneous General Merchandise Stores", label:"5399 Miscellaneous General Merchandise Stores"},
{value:"5411 Grocery Stores", label:"5411 Grocery Stores"},
{value:"5421 Meat and Fish (Seafood) Markets  Including Freezer Provisioners", label:"5421 Meat and Fish (Seafood) Markets  Including Freezer Provisioners"},
{value:"5431 Fruit and Vegetable Markets", label:"5431 Fruit and Vegetable Markets"},
{value:"5441 Candy  Nut  and Confectionery Stores", label:"5441 Candy  Nut  and Confectionery Stores"},
{value:"5451 Dairy Products Stores", label:"5451 Dairy Products Stores"},
{value:"5461 Retail Bakeries", label:"5461 Retail Bakeries"},
{value:"5499 Miscellaneous Food Stores", label:"5499 Miscellaneous Food Stores"},
{value:"5511 Motor Vehicle Dealers (New and Used)", label:"5511 Motor Vehicle Dealers (New and Used)"},
{value:"5521 Motor Vehicle Dealers (Used Only)", label:"5521 Motor Vehicle Dealers (Used Only)"},
{value:"5531 Auto and Home Supply Stores", label:"5531 Auto and Home Supply Stores"},
{value:"5541 Gasoline Service Stations", label:"5541 Gasoline Service Stations"},
{value:"5551 Boat Dealers", label:"5551 Boat Dealers"},
{value:"5561 Recreational Vehicle Dealers", label:"5561 Recreational Vehicle Dealers"},
{value:"5571 Motorcycle Dealers", label:"5571 Motorcycle Dealers"},
{value:"5599 Automotive Dealers  Not Elsewhere Classified", label:"5599 Automotive Dealers  Not Elsewhere Classified"},
{value:"5611 Men's and Boys' Clothing and Accessory Stores", label:"5611 Men's and Boys' Clothing and Accessory Stores"},
{value:"5621 Women's Clothing Stores", label:"5621 Women's Clothing Stores"},
{value:"5632 Women's Accessory and Specialty Stores", label:"5632 Women's Accessory and Specialty Stores"},
{value:"5641 Children's and Infants' Wear Stores", label:"5641 Children's and Infants' Wear Stores"},
{value:"5651 Family Clothing Stores", label:"5651 Family Clothing Stores"},
{value:"5661 Shoe Stores", label:"5661 Shoe Stores"},
{value:"5699 Miscellaneous Apparel and Accessory Stores", label:"5699 Miscellaneous Apparel and Accessory Stores"},
{value:"5712 Furniture Stores", label:"5712 Furniture Stores"},
{value:"5713 Floor Covering Stores", label:"5713 Floor Covering Stores"},
{value:"5714 Drapery  Curtain  and Upholstery Stores", label:"5714 Drapery  Curtain  and Upholstery Stores"},
{value:"5719 Miscellaneous home furnishings Stores", label:"5719 Miscellaneous home furnishings Stores"},
{value:"5722 Household Appliance Stores", label:"5722 Household Appliance Stores"},
{value:"5731 Radio  Television  and Consumer Electronics Stores", label:"5731 Radio  Television  and Consumer Electronics Stores"},
{value:"5734 Computer and Computer Software Stores", label:"5734 Computer and Computer Software Stores"},
{value:"5735 Record and Prerecorded Tape Stores", label:"5735 Record and Prerecorded Tape Stores"},
{value:"5736 Musical Instrument Stores", label:"5736 Musical Instrument Stores"},
{value:"5812 Eating Places", label:"5812 Eating Places"},
{value:"5813 Drinking Places (alcoholic Beverages)", label:"5813 Drinking Places (alcoholic Beverages)"},
{value:"5912 Drug Stores and Proprietary Stores", label:"5912 Drug Stores and Proprietary Stores"},
{value:"5921 Liquor Stores", label:"5921 Liquor Stores"},
{value:"5932 Used Merchandise Stores", label:"5932 Used Merchandise Stores"},
{value:"5941 Sporting Goods Stores and Bicycle Shops", label:"5941 Sporting Goods Stores and Bicycle Shops"},
{value:"5942 Book Stores", label:"5942 Book Stores"},
{value:"5943 Stationery Stores", label:"5943 Stationery Stores"},
{value:"5944 Jewelry Stores", label:"5944 Jewelry Stores"},
{value:"5945 Hobby  Toy  and Game Shops", label:"5945 Hobby  Toy  and Game Shops"},
{value:"5946 Camera and Photographic Supply Stores", label:"5946 Camera and Photographic Supply Stores"},
{value:"5947 Gift  Novelty  and Souvenir Shops", label:"5947 Gift  Novelty  and Souvenir Shops"},
{value:"5948 Luggage and Leather Goods Stores", label:"5948 Luggage and Leather Goods Stores"},
{value:"5949 Sewing  Needlework  and Piece Goods Stores", label:"5949 Sewing  Needlework  and Piece Goods Stores"},
{value:"5961 Catalog and Mail-Order Houses", label:"5961 Catalog and Mail-Order Houses"},
{value:"5962 Automatic Merchandising Machine Operators", label:"5962 Automatic Merchandising Machine Operators"},
{value:"5963 Direct Selling Establishments", label:"5963 Direct Selling Establishments"},
{value:"5983 Fuel Oil Dealers", label:"5983 Fuel Oil Dealers"},
{value:"5984 Liquefied Petroleum Gas (Bottled Gas) Dealers", label:"5984 Liquefied Petroleum Gas (Bottled Gas) Dealers"},
{value:"5989 Fuel Dealers  Not Elsewhere Classified", label:"5989 Fuel Dealers  Not Elsewhere Classified"},
{value:"5992 Florists", label:"5992 Florists"},
{value:"5993 Tobacco Stores and Stands", label:"5993 Tobacco Stores and Stands"},
{value:"5994 News Dealers and Newsstands", label:"5994 News Dealers and Newsstands"},
{value:"5995 Optical Goods Stores", label:"5995 Optical Goods Stores"},
{value:"5999 Miscellaneous Retail Stores  Not Elsewhere Classified", label:"5999 Miscellaneous Retail Stores  Not Elsewhere Classified"},
{value:"6011 Federal Reserve Banks", label:"6011 Federal Reserve Banks"},
{value:"6019 Central Reserve Depository Institutions  Not Elsewhere Classified", label:"6019 Central Reserve Depository Institutions  Not Elsewhere Classified"},
{value:"6021 National Commercial Banks", label:"6021 National Commercial Banks"},
{value:"6022 State Commercial Banks", label:"6022 State Commercial Banks"},
{value:"6029 Commercial Banks  Not Elsewhere Classified", label:"6029 Commercial Banks  Not Elsewhere Classified"},
{value:"6035 Savings Institutions  Federally Chartered", label:"6035 Savings Institutions  Federally Chartered"},
{value:"6036 Savings Institutions  Not Federally Chartered", label:"6036 Savings Institutions  Not Federally Chartered"},
{value:"6061 Credit Unions  Federally Chartered", label:"6061 Credit Unions  Federally Chartered"},
{value:"6062 Credit Unions  Not Federally Chartered", label:"6062 Credit Unions  Not Federally Chartered"},
{value:"6081 Branches and Agencies of Foreign Banks", label:"6081 Branches and Agencies of Foreign Banks"},
{value:"6082 Foreign Trade and International Banking Institutions", label:"6082 Foreign Trade and International Banking Institutions"},
{value:"6091 Non-deposit Trust Facilities ", label:"6091 Non-deposit Trust Facilities "},
{value:"6099 Functions Related to Depository Banking  Not Elsewhere Classified", label:"6099 Functions Related to Depository Banking  Not Elsewhere Classified"},
{value:"6111 Federal and Federally-Sponsored Credit Agencies", label:"6111 Federal and Federally-Sponsored Credit Agencies"},
{value:"6141 Personal Credit Institutions", label:"6141 Personal Credit Institutions"},
{value:"6153 Short-Term Business Credit Institutions  Except Agricultural", label:"6153 Short-Term Business Credit Institutions  Except Agricultural"},
{value:"6159 Miscellaneous business Credit Institutions", label:"6159 Miscellaneous business Credit Institutions"},
{value:"6162 Mortgage Bankers and Loan Correspondents", label:"6162 Mortgage Bankers and Loan Correspondents"},
{value:"6163 Loan Brokers", label:"6163 Loan Brokers"},
{value:"6211 Security Brokers  Dealers  and Flotation Companies", label:"6211 Security Brokers  Dealers  and Flotation Companies"},
{value:"6221 Commodity Contracts Brokers and Dealers", label:"6221 Commodity Contracts Brokers and Dealers"},
{value:"6231 Security and Commodity Exchanges", label:"6231 Security and Commodity Exchanges"},
{value:"6282 Investment Advice", label:"6282 Investment Advice"},
{value:"6289 Services Allied With the Exchange of Securities or Commodities  Not Elsewhere Classified", label:"6289 Services Allied With the Exchange of Securities or Commodities  Not Elsewhere Classified"},
{value:"6311 Life Insurance", label:"6311 Life Insurance"},
{value:"6321 Accident and Health Insurance", label:"6321 Accident and Health Insurance"},
{value:"6324 Hospital and Medical Service Plans", label:"6324 Hospital and Medical Service Plans"},
{value:"6331 Fire  Marine  and Casualty Insurance", label:"6331 Fire  Marine  and Casualty Insurance"},
{value:"6351 Surety Insurance", label:"6351 Surety Insurance"},
{value:"6361 Title Insurance", label:"6361 Title Insurance"},
{value:"6371 Pension  Health  and Welfare Funds", label:"6371 Pension  Health  and Welfare Funds"},
{value:"6399 Insurance Carriers  Not Elsewhere Classified", label:"6399 Insurance Carriers  Not Elsewhere Classified"},
{value:"6411 Insurance Agents  Brokers  and Service", label:"6411 Insurance Agents  Brokers  and Service"},
{value:"6512 Operators of Nonresidential Buildings", label:"6512 Operators of Nonresidential Buildings"},
{value:"6513 Operators of Apartment Buildings", label:"6513 Operators of Apartment Buildings"},
{value:"6514 Operators of Dwellings Other Than Apartment Buildings", label:"6514 Operators of Dwellings Other Than Apartment Buildings"},
{value:"6515 Operators of Residential Mobile Home Sites", label:"6515 Operators of Residential Mobile Home Sites"},
{value:"6517 Lessors of Railroad Property", label:"6517 Lessors of Railroad Property"},
{value:"6519 Lessors of Real Property  Not Elsewhere Classified", label:"6519 Lessors of Real Property  Not Elsewhere Classified"},
{value:"6531 Real Estate Agents and Managers", label:"6531 Real Estate Agents and Managers"},
{value:"6541 Title Abstract Offices", label:"6541 Title Abstract Offices"},
{value:"6552 Land Subdividers and Developers  Except Cemeteries", label:"6552 Land Subdividers and Developers  Except Cemeteries"},
{value:"6553 Cemetery Subdividers and Developers", label:"6553 Cemetery Subdividers and Developers"},
{value:"6712 Offices of Bank Holding Companies", label:"6712 Offices of Bank Holding Companies"},
{value:"6719 Offices of Holding Companies  Not Elsewhere Classified", label:"6719 Offices of Holding Companies  Not Elsewhere Classified"},
{value:"6722 Management Investment Offices  Open-End", label:"6722 Management Investment Offices  Open-End"},
{value:"6726 Unit Investment Trusts  Face-Amount Certificate Offices  and Closed-End Management Investment Offices", label:"6726 Unit Investment Trusts  Face-Amount Certificate Offices  and Closed-End Management Investment Offices"},
{value:"6732 Educational  Religious  and Charitable Trusts", label:"6732 Educational  Religious  and Charitable Trusts"},
{value:"6733 Trusts  Except Educational  Religious  and Charitable", label:"6733 Trusts  Except Educational  Religious  and Charitable"},
{value:"6792 Oil Royalty Traders", label:"6792 Oil Royalty Traders"},
{value:"6794 Patent Owners and Lessors", label:"6794 Patent Owners and Lessors"},
{value:"6798 Real Estate Investment Trusts", label:"6798 Real Estate Investment Trusts"},
{value:"6799 Investors  Not Elsewhere Classified", label:"6799 Investors  Not Elsewhere Classified"},
{value:"7011 Hotels and Motels", label:"7011 Hotels and Motels"},
{value:"7021 Rooming and Boarding Houses", label:"7021 Rooming and Boarding Houses"},
{value:"7032 Sporting and Recreational Camps", label:"7032 Sporting and Recreational Camps"},
{value:"7033 Recreational Vehicle Parks and Campsites", label:"7033 Recreational Vehicle Parks and Campsites"},
{value:"7041 Organization Hotels and Lodging Houses  on Membership Basis", label:"7041 Organization Hotels and Lodging Houses  on Membership Basis"},
{value:"7211 Power Laundries  Family and Commercial", label:"7211 Power Laundries  Family and Commercial"},
{value:"7212 Garment Pressing  and Agents for Laundries and Drycleaners", label:"7212 Garment Pressing  and Agents for Laundries and Drycleaners"},
{value:"7213 Linen Supply", label:"7213 Linen Supply"},
{value:"7215 Coin-Operated Laundries and Drycleaning", label:"7215 Coin-Operated Laundries and Drycleaning"},
{value:"7216 Drycleaning Plants  Except Rug Cleaning", label:"7216 Drycleaning Plants  Except Rug Cleaning"},
{value:"7217 Carpet and Upholstery Cleaning", label:"7217 Carpet and Upholstery Cleaning"},
{value:"7218 Industrial Launderers", label:"7218 Industrial Launderers"},
{value:"7219 Laundry and Garment Services  Not Elsewhere Classified", label:"7219 Laundry and Garment Services  Not Elsewhere Classified"},
{value:"7221 Photographic Studios  Portrait", label:"7221 Photographic Studios  Portrait"},
{value:"7231 Beauty Shops", label:"7231 Beauty Shops"},
{value:"7241 Barber Shops", label:"7241 Barber Shops"},
{value:"7251 Shoe Repair Shops and Shoeshine Parlors", label:"7251 Shoe Repair Shops and Shoeshine Parlors"},
{value:"7261 Funeral Service and Crematories", label:"7261 Funeral Service and Crematories"},
{value:"7291 Tax Return Preparation Services", label:"7291 Tax Return Preparation Services"},
{value:"7299 Miscellaneous Personal Services  Not Elsewhere Classified", label:"7299 Miscellaneous Personal Services  Not Elsewhere Classified"},
{value:"7311 Advertising Agencies", label:"7311 Advertising Agencies"},
{value:"7312 Outdoor Advertising Services", label:"7312 Outdoor Advertising Services"},
{value:"7313 Radio  Television  and Publishers' Advertising Representatives", label:"7313 Radio  Television  and Publishers' Advertising Representatives"},
{value:"7319 Advertising  Not Elsewhere Classified", label:"7319 Advertising  Not Elsewhere Classified"},
{value:"7322 Adjustment and Collection Services", label:"7322 Adjustment and Collection Services"},
{value:"7323 Credit Reporting Services", label:"7323 Credit Reporting Services"},
{value:"7331 Direct Mail Advertising Services", label:"7331 Direct Mail Advertising Services"},
{value:"7334 Photocopying and Duplicating Services", label:"7334 Photocopying and Duplicating Services"},
{value:"7335 Commercial Photography", label:"7335 Commercial Photography"},
{value:"7336 Commercial Art and Graphic Design", label:"7336 Commercial Art and Graphic Design"},
{value:"7338 Secretarial and Court Reporting Services", label:"7338 Secretarial and Court Reporting Services"},
{value:"7342 Disinfecting and Pest Control Services", label:"7342 Disinfecting and Pest Control Services"},
{value:"7349 Building Cleaning and Maintenance Services  Not Elsewhere", label:"7349 Building Cleaning and Maintenance Services  Not Elsewhere"},
{value:"7352 Medical Equipment Rental and Leasing", label:"7352 Medical Equipment Rental and Leasing"},
{value:"7353 Heavy Construction Equipment Rental and Leasing", label:"7353 Heavy Construction Equipment Rental and Leasing"},
{value:"7359 Equipment Rental and Leasing  Not Elsewhere Classified", label:"7359 Equipment Rental and Leasing  Not Elsewhere Classified"},
{value:"7361 Employment Agencies", label:"7361 Employment Agencies"},
{value:"7363 Help Supply Services", label:"7363 Help Supply Services"},
{value:"7371 Computer Programming Services", label:"7371 Computer Programming Services"},
{value:"7372 Prepackaged Software", label:"7372 Prepackaged Software"},
{value:"7373 Computer Integrated Systems Design", label:"7373 Computer Integrated Systems Design"},
{value:"7374 Computer Processing and Data Preparation and Processing Services", label:"7374 Computer Processing and Data Preparation and Processing Services"},
{value:"7375 Information Retrieval Services", label:"7375 Information Retrieval Services"},
{value:"7376 Computer Facilities Management Services", label:"7376 Computer Facilities Management Services"},
{value:"7377 Computer Rental and Leasing", label:"7377 Computer Rental and Leasing"},
{value:"7378 Computer Maintenance and Repair", label:"7378 Computer Maintenance and Repair"},
{value:"7379 Computer Related Services  Not Elsewhere Classified", label:"7379 Computer Related Services  Not Elsewhere Classified"},
{value:"7381 Detective  Guard  and Armored Car Services", label:"7381 Detective  Guard  and Armored Car Services"},
{value:"7382 Security Systems Services", label:"7382 Security Systems Services"},
{value:"7383 News Syndicates", label:"7383 News Syndicates"},
{value:"7384 Photofinishing Laboratories", label:"7384 Photofinishing Laboratories"},
{value:"7389 Business Services  Not Elsewhere Classified", label:"7389 Business Services  Not Elsewhere Classified"},
{value:"7513 Truck Rental and Leasing  Without Drivers", label:"7513 Truck Rental and Leasing  Without Drivers"},
{value:"7514 Passenger Car Rental", label:"7514 Passenger Car Rental"},
{value:"7515 Passenger Car Leasing", label:"7515 Passenger Car Leasing"},
{value:"7519 Utility Trailer and Recreational Vehicle Rental", label:"7519 Utility Trailer and Recreational Vehicle Rental"},
{value:"7521 Automobile Parking", label:"7521 Automobile Parking"},
{value:"7532 Top  Body  and Upholstery Repair Shops and Paint Shops", label:"7532 Top  Body  and Upholstery Repair Shops and Paint Shops"},
{value:"7533 Automotive Exhaust System Repair Shops", label:"7533 Automotive Exhaust System Repair Shops"},
{value:"7534 Tire Retreading and Repair Shops", label:"7534 Tire Retreading and Repair Shops"},
{value:"7536 Automotive Glass Replacement Shops", label:"7536 Automotive Glass Replacement Shops"},
{value:"7537 Automotive Transmission Repair Shops", label:"7537 Automotive Transmission Repair Shops"},
{value:"7538 General Automotive Repair Shops", label:"7538 General Automotive Repair Shops"},
{value:"7539 Automotive Repair Shops  Not Elsewhere Classified", label:"7539 Automotive Repair Shops  Not Elsewhere Classified"},
{value:"7542 Carwashes", label:"7542 Carwashes"},
{value:"7549 Automotive Services  Except Repair and Carwashes", label:"7549 Automotive Services  Except Repair and Carwashes"},
{value:"7622 Radio and Television Repair Shops", label:"7622 Radio and Television Repair Shops"},
{value:"7623 Refrigeration and Air-Conditioning Service and Repair Shops", label:"7623 Refrigeration and Air-Conditioning Service and Repair Shops"},
{value:"7629 Electrical and Electronic Repair Shops  Not Elsewhere Classified", label:"7629 Electrical and Electronic Repair Shops  Not Elsewhere Classified"},
{value:"7631 Watch  Clock  and Jewelry Repair", label:"7631 Watch  Clock  and Jewelry Repair"},
{value:"7641 Reupholstery and Furniture Repair", label:"7641 Reupholstery and Furniture Repair"},
{value:"7692 Welding Repair", label:"7692 Welding Repair"},
{value:"7694 Armature Rewinding Shops", label:"7694 Armature Rewinding Shops"},
{value:"7699 Repair Shops and Related Services  Not Elsewhere Classified", label:"7699 Repair Shops and Related Services  Not Elsewhere Classified"},
{value:"7812 Motion Picture and Video Tape Production", label:"7812 Motion Picture and Video Tape Production"},
{value:"7819 Services Allied to Motion Picture Production", label:"7819 Services Allied to Motion Picture Production"},
{value:"7822 Motion Picture and Video Tape Distribution", label:"7822 Motion Picture and Video Tape Distribution"},
{value:"7829 Services Allied to Motion Picture Distribution", label:"7829 Services Allied to Motion Picture Distribution"},
{value:"7832 Motion Picture Theaters  Except Drive-In", label:"7832 Motion Picture Theaters  Except Drive-In"},
{value:"7833 Drive-In Motion Picture Theaters", label:"7833 Drive-In Motion Picture Theaters"},
{value:"7841 Video Tape Rental", label:"7841 Video Tape Rental"},
{value:"7911 Dance Studios  Schools  and Halls", label:"7911 Dance Studios  Schools  and Halls"},
{value:"7922 Theatrical Producers (Except Motion Picture) and Miscellaneous Theatrical Services", label:"7922 Theatrical Producers (Except Motion Picture) and Miscellaneous Theatrical Services"},
{value:"7929 Bands  Orchestras  Actors  and Other Entertainers and Entertainment Groups", label:"7929 Bands  Orchestras  Actors  and Other Entertainers and Entertainment Groups"},
{value:"7933 Bowling Centers", label:"7933 Bowling Centers"},
{value:"7941 Professional Sports Clubs and Promoters", label:"7941 Professional Sports Clubs and Promoters"},
{value:"7948 Racing  Including Track Operation", label:"7948 Racing  Including Track Operation"},
{value:"7991 Physical Fitness Facilities", label:"7991 Physical Fitness Facilities"},
{value:"7992 Public Golf Courses", label:"7992 Public Golf Courses"},
{value:"7993 Coin-Operated Amusement Devices", label:"7993 Coin-Operated Amusement Devices"},
{value:"7996 Amusement Parks", label:"7996 Amusement Parks"},
{value:"7997 Membership Sports and Recreation Clubs", label:"7997 Membership Sports and Recreation Clubs"},
{value:"7999 Amusement and Recreation Services  Not Elsewhere Classified", label:"7999 Amusement and Recreation Services  Not Elsewhere Classified"},
{value:"8011 Offices and Clinics of Doctors of Medicine", label:"8011 Offices and Clinics of Doctors of Medicine"},
{value:"8021 Offices and Clinics of Dentists", label:"8021 Offices and Clinics of Dentists"},
{value:"8031 Offices and Clinics of Doctors of Osteopathy", label:"8031 Offices and Clinics of Doctors of Osteopathy"},
{value:"8041 Offices and Clinics of Chiropractors", label:"8041 Offices and Clinics of Chiropractors"},
{value:"8042 Offices and Clinics of Optometrists", label:"8042 Offices and Clinics of Optometrists"},
{value:"8043 Offices and Clinics of Podiatrists", label:"8043 Offices and Clinics of Podiatrists"},
{value:"8049 Offices and Clinics of Health Practitioners  Not Elsewhere Classified", label:"8049 Offices and Clinics of Health Practitioners  Not Elsewhere Classified"},
{value:"8051 Skilled Nursing Care Facilities", label:"8051 Skilled Nursing Care Facilities"},
{value:"8052 Intermediate Care Facilities", label:"8052 Intermediate Care Facilities"},
{value:"8059 Nursing and Personal Care Facilities  Not Elsewhere Classified", label:"8059 Nursing and Personal Care Facilities  Not Elsewhere Classified"},
{value:"8062 General Medical and Surgical Hospitals", label:"8062 General Medical and Surgical Hospitals"},
{value:"8063 Psychiatric Hospitals", label:"8063 Psychiatric Hospitals"},
{value:"8069 Specialty Hospitals  Except Psychiatric", label:"8069 Specialty Hospitals  Except Psychiatric"},
{value:"8071 Medical Laboratories", label:"8071 Medical Laboratories"},
{value:"8072 Dental Laboratories", label:"8072 Dental Laboratories"},
{value:"8082 Home Health Care Services", label:"8082 Home Health Care Services"},
{value:"8092 Kidney Dialysis Centers", label:"8092 Kidney Dialysis Centers"},
{value:"8093 Specialty Outpatient Facilities  Not Elsewhere Classified", label:"8093 Specialty Outpatient Facilities  Not Elsewhere Classified"},
{value:"8099 Health and Allied Services  Not Elsewhere Classified", label:"8099 Health and Allied Services  Not Elsewhere Classified"},
{value:"8111 Legal Services", label:"8111 Legal Services"},
{value:"8211 Elementary and Secondary Schools", label:"8211 Elementary and Secondary Schools"},
{value:"8221 Colleges  Universities  and Professional Schools", label:"8221 Colleges  Universities  and Professional Schools"},
{value:"8222 Junior Colleges and Technical Institutes", label:"8222 Junior Colleges and Technical Institutes"},
{value:"8231 Libraries", label:"8231 Libraries"},
{value:"8243 Data Processing Schools", label:"8243 Data Processing Schools"},
{value:"8244 Business and Secretarial Schools", label:"8244 Business and Secretarial Schools"},
{value:"8249 Vocational Schools  Not Elsewhere Classified", label:"8249 Vocational Schools  Not Elsewhere Classified"},
{value:"8299 Schools and Educational Services  Not Elsewhere Classified", label:"8299 Schools and Educational Services  Not Elsewhere Classified"},
{value:"8322 Individual and Family Social Services", label:"8322 Individual and Family Social Services"},
{value:"8331 Job Training and Vocational Rehabilitation Services", label:"8331 Job Training and Vocational Rehabilitation Services"},
{value:"8351 Child Day Care Services", label:"8351 Child Day Care Services"},
{value:"8361 Residential Care", label:"8361 Residential Care"},
{value:"8399 Social Services  Not Elsewhere Classified", label:"8399 Social Services  Not Elsewhere Classified"},
{value:"8412 Museums and Art Galleries", label:"8412 Museums and Art Galleries"},
{value:"8422 Arboreta and Botanical or Zoological Gardens", label:"8422 Arboreta and Botanical or Zoological Gardens"},
{value:"8611 Business Associations", label:"8611 Business Associations"},
{value:"8621 Professional Membership Organizations", label:"8621 Professional Membership Organizations"},
{value:"8631 Labor Unions and Similar Labor Organizations", label:"8631 Labor Unions and Similar Labor Organizations"},
{value:"8641 Civic  Social  and Fraternal Associations", label:"8641 Civic  Social  and Fraternal Associations"},
{value:"8651 Political Organizations", label:"8651 Political Organizations"},
{value:"8661 Religious Organizations", label:"8661 Religious Organizations"},
{value:"8699 Membership Organizations  Not Elsewhere Classified", label:"8699 Membership Organizations  Not Elsewhere Classified"},
{value:"8711 Engineering Services", label:"8711 Engineering Services"},
{value:"8712 Architectural Services", label:"8712 Architectural Services"},
{value:"8713 Surveying Services", label:"8713 Surveying Services"},
{value:"8721 Accounting  Auditing  and Bookkeeping Services", label:"8721 Accounting  Auditing  and Bookkeeping Services"},
{value:"8731 Commercial Physical and Biological Research", label:"8731 Commercial Physical and Biological Research"},
{value:"8732 Commercial Economic  Sociological  and Educational Research", label:"8732 Commercial Economic  Sociological  and Educational Research"},
{value:"8733 Noncommercial Research Organizations", label:"8733 Noncommercial Research Organizations"},
{value:"8734 Testing Laboratories", label:"8734 Testing Laboratories"},
{value:"8741 Management Services", label:"8741 Management Services"},
{value:"8742 Management Consulting Services", label:"8742 Management Consulting Services"},
{value:"8743 Public Relations Services", label:"8743 Public Relations Services"},
{value:"8744 Facilities Support Management Services", label:"8744 Facilities Support Management Services"},
{value:"8748 Business Consulting Services  Not Elsewhere Classified", label:"8748 Business Consulting Services  Not Elsewhere Classified"},
{value:"8811 Private Households", label:"8811 Private Households"},
{value:"8999 Services  Not Elsewhere Classified", label:"8999 Services  Not Elsewhere Classified"},
{value:"9111 Executive Offices", label:"9111 Executive Offices"},
{value:"9121 Legislative Bodies", label:"9121 Legislative Bodies"},
{value:"9131 Executive and Legislative Offices Combined", label:"9131 Executive and Legislative Offices Combined"},
{value:"9199 General Government  Not Elsewhere Classified", label:"9199 General Government  Not Elsewhere Classified"},
{value:"9211 Courts", label:"9211 Courts"},
{value:"9221 Police Protection", label:"9221 Police Protection"},
{value:"9222 Legal Counsel and Prosecution", label:"9222 Legal Counsel and Prosecution"},
{value:"9223 Correctional Institutions", label:"9223 Correctional Institutions"},
{value:"9224 Fire Protection", label:"9224 Fire Protection"},
{value:"9229 Public Order and Safety  Not Elsewhere Classified", label:"9229 Public Order and Safety  Not Elsewhere Classified"},
{value:"9311 Public Finance  Taxation  and Monetary Policy", label:"9311 Public Finance  Taxation  and Monetary Policy"},
{value:"9411 Administration of Educational Programs", label:"9411 Administration of Educational Programs"},
{value:"9431 Administration of Public Health Programs", label:"9431 Administration of Public Health Programs"},
{value:"9441 Administration of Social  Human Resource and Income Maintenance Programs", label:"9441 Administration of Social  Human Resource and Income Maintenance Programs"},
{value:"9451 Administration of Veterans' Affairs  Except Health and Insurance", label:"9451 Administration of Veterans' Affairs  Except Health and Insurance"},
{value:"9511 Air and Water Resource and Solid Waste Management", label:"9511 Air and Water Resource and Solid Waste Management"},
{value:"9512 Land  Mineral  Wildlife  and Forest Conservation", label:"9512 Land  Mineral  Wildlife  and Forest Conservation"},
{value:"9531 Administration of Housing Programs", label:"9531 Administration of Housing Programs"},
{value:"9532 Administration of Urban Planning and Community and Rural Development", label:"9532 Administration of Urban Planning and Community and Rural Development"},
{value:"9611 Administration of General Economic Programs", label:"9611 Administration of General Economic Programs"},
{value:"9621 Regulation and Administration of Transportation Programs", label:"9621 Regulation and Administration of Transportation Programs"},
{value:"9631 Regulation and Administration of Communications  Electric  Gas  and Other Utilities", label:"9631 Regulation and Administration of Communications  Electric  Gas  and Other Utilities"},
{value:"9641 Regulation of Agricultural Marketing and Commodities", label:"9641 Regulation of Agricultural Marketing and Commodities"},
{value:"9651 Regulation  Licensing  and Inspection of Miscellaneous Commercial Sectors", label:"9651 Regulation  Licensing  and Inspection of Miscellaneous Commercial Sectors"},
{value:"9661 Space and Research and Technology", label:"9661 Space and Research and Technology"},
{value:"9711 National Security", label:"9711 National Security"},
{value:"9721 International Affairs", label:"9721 International Affairs"},
{value:"9999 Nonclassifiable Establishments", label:"9999 Nonclassifiable Establishments"},
]