import React from "react";
import OnlineIndicator from "./CountrySelectorIndicator";

export const CountrySelectorItem = ({
  item,
  getItemProps,
  selectedItems,
  currentLocation,
}) => {
  const classNameActive = `dropdown-item--clickable${
    selectedItems.includes(item.countryCode) ? " active" : ""
  }`;
  const classNameDisabled = `dropdown-item--clickable${
    item.isOnline === false ? " disabled" : ""
  }`;
  console.log(item)
  console.log(item.text)
  return (
    <li
      {...getItemProps({
        item,
        className:
          item.isOnline === false ? classNameDisabled : classNameActive,
        disabled: item.isOnline === false,
      })}
      data-testid={`country-dropdown-item-${item.countryCode}`}
    >
      {item.icon}
      <span className="text">{item.text}</span>
      <OnlineIndicator
        currentLocation={currentLocation}
        isOnline={item.isOnline}
        item={item.countryCode}
      />
    </li>
  );
};

export default CountrySelectorItem;
