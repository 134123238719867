export const expenditureCategory = [
  "- Select Category -",
  "Building Costs",
  "Financial Services",
  "Utilities",
  "Vehicles and Equipment",
  "Employees",
  "Business Costs",
  "Taxes",
  "Insurance Premiums"
]