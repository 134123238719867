import { isArrayWithValues } from "./validation";
import get from "lodash/get";
import isNil from "lodash/isNil";
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import { identity } from "./identity";

export const lowerCaseFirstLetter = value => {
  if (!value) {
    return "";
  }
  return value.charAt(0).toLowerCase() + value.substr(1);
};

export const upperCaseFirstLetter = value => {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.substr(1);
};

export const startCasing = value => {
  if (!value) {
    return ""
  }
  return startCase(lowerCase(value))
}

export const lowerCasing = (value) => {
  if (!value) {
    return ""
  }
  return value.toLowerCase();
}

export const compare = (stringA, stringB) => {
  if (!stringA || !stringB) {
    return false;
  }

  return (
    stringA.localeCompare(stringB, undefined, { sensitivity: "base" }) === 0
  );
};

export const displayValueOrSeparator = (
  value,
  formatter = identity,
  separator = "-"
) => {
  if (isNil(value) || value === "") {
    return separator;
  }
  return formatter(value);
};

export const displayMergedValuesOrEmptyValue = (
  value,
  keys,
  emptyValue = "-",
  separator = ", "
) => {
  if (!value || !isArrayWithValues(keys)) {
    return emptyValue;
  }
  const values = keys
    .map(x => {
      return get(value, x);
    })
    .filter(x => !!x);

  if (!isArrayWithValues(values)) {
    return emptyValue;
  }
  return values.join(separator);
};

export const percentage = (x, digits) =>
  !isNaN(parseFloat(x)) && isFinite(x)
    ? `${digits ? Math.abs(x).toFixed(digits) : x}%`
    : "";

export const trimAllStrings = values =>
  Object.keys(values).reduce(
    (accum, key) => ({
      ...accum,
      ...(typeof values[key] === "string"
        ? { [key]: values[key].trim() }
        : { [key]: values[key] }),
    }),
    {}
  );

export const titleCase = str =>
  str.split(' ').map(str => {
    const word = str.toLowerCase()
    return word.charAt(0).toUpperCase() + word.slice(1)
  })
    .join(' ');

export const toTitleCase = str => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  const wordSeparators = /([ :–—-])/

  return str.split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('')
}


export const formatBoolean = (x, t) => (x ? t("global:yes") : t("global:no"));
