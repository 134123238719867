import React from "react";
import cslogo from "../../assets/img/cslogo.png";
import styled from "styled-components";
import { InlineLoader } from "./Loading";
import { withTranslation } from "react-i18next";

export const LoadingPage = ({ t }) => {
  return (
    <Container>
      <CSLogo data-testid="loading-logo" />
      <div>
        <Loader
          data-testid="loading-dots"
          isLoading={true}
          loadingText={"Loading Report...."}
        />
      </div>
    </Container>
  );
};

const CSLogo = styled.img.attrs({
  src: cslogo,
  alt: "Creditsafe",
})`
  width: 210px;
  padding: 0 0 0 12px;
  height: 45px;
  width: 235px;
`;

const Loader = styled(InlineLoader)`
  top: 0;
  position: unset;
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default withTranslation()(LoadingPage);
