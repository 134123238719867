
const ScoreImprovement = (before, current) => {
  let a = parseInt(before)
  const b = parseInt(current)

  if(a < 0){
    a = 0
  }

  let c = a-b
  if(c < 0){
    c =  Math.abs(c)
  } else{
    c = 0
  }
  return c
}

export default ScoreImprovement
