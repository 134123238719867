import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import get from "lodash/get";

import BasicTable from "../core/BasicTable";
import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import FlagIcon from "../core/FlagIcon";
import { InlineLoader } from "../core/Loading";
import PopupModal from "../core/PopupModal";

import AddUltimateParent from "../form/AddUltimateParent";

import BusinessIcon from "@material-ui/icons/Business";
import Tooltip from '@material-ui/core/Tooltip';

export const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class UltimateParent extends Component {
  render() {
    const {
      mycID,
      companyId,
      email,
      companyReport,
      fetchReportJson,
      fetchUserReport,
      setReportLoading,
      accessToken,
      language,
      report,
      isLoading,
      loadingType,
      expressApi,
      userReport,
      safeNumber,
      fullName,
      boId,
      profile
    } = this.props;

    const { groupStructure } = companyReport;

    const isLoadingUltimateParent =
      isLoading && loadingType === "ultimateParent";

    const data = get(groupStructure, "ultimateParent");
    const ultimateCompany = get(groupStructure, "ultimateParent", { name: "" });
    const flagCountry = get(data, "country");

    const hasUltimate = ultimateCompany.name !== "" ? true : false

    const modalTitle = hasUltimate
      ? "Edit Ultimate Parent Company"
      : "Add Ultimate Parent Company";

    return (
      <Card scrollContent>
        {isLoadingUltimateParent ? (
          <InlineLoader
            isLoading={isLoadingUltimateParent}
            delayMS={0}
            loadingText={"Updating"}
          />
        ) : (
          <>
            <HelpTitle>
              <CardTitle title="Ultimate Parent Company" dataTestId="Ultimate Parent Company" />
              <PopupModal title={modalTitle} trigger={<Button>
                <Tooltip title="Add Ultimate Parent">
                  <BusinessIcon data-testid="add-ultimate-parent-icon" />
                </Tooltip>
              </Button>} >
                {(close) => (
                  <AddUltimateParent
                    hasUltimate={ultimateCompany}
                    close={close}
                    companyReport={companyReport}
                    groupStructure={groupStructure}
                    email={email}
                    mycID={mycID}
                    companyId={companyId}
                    fetchReportJson={fetchReportJson}
                    fetchUserReport={fetchUserReport}
                    setReportLoading={setReportLoading}
                    accessToken={accessToken}
                    language={language}
                    report={report}
                    expressApi={expressApi}
                    userReport={userReport}
                    safeNumber={safeNumber}
                    fullName={fullName}
                    boId={boId}
                    profile={profile}
                  />
                )}
              </PopupModal>
            </HelpTitle>
            <BasicTable
              headings={[
                { key: "name", text: "Business Name" },
                { key: "safeNumber", text: "Safe Number" },
                { key: "registrationNumber", text: "Registration Number" },
                { key: "vatNumber", text: "Vat Number" },
                { key: "country", text: "Country" },
                { key: "flag", text: "" },
              ]}
              data={[data]}
              formatters={{
                flag: () => <FlagIcon code={flagCountry} />,
                name: (name) => (name ? name.toLowerCase() : "-"),
              }}
            />
          </>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  expressApi: state.app.config.EXPRESS.URL,
});

export default connect(mapStateToProps, null)(UltimateParent);
