import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_REPORT,
  FETCH_USER_REPORT_SUCCESS,
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_SEARCHABLE_COUNTRIES,
  FETCH_SEARCHABLE_COUNTRIES_SUCCESS,
  FETCH_SEARCHABLE_COUNTRIES_ERROR,
  RESET_REPORT_JSON,
  RESET_REPORT_JSON_SUCCESS,
  RESET_REPORT_JSON_ERROR
} from "./types";
import jwt from "jwt-decode";

import { initConfig } from "../config";
import { fetchReportJson } from "./mergeActions";

const config = initConfig();

export const fetchUser = () => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  dispatch({ type: FETCH_USER });
  if (accessToken) {
    const payload = jwt(accessToken);
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: payload,
    });
  } else (
    dispatch({
      type: FETCH_USER_ERROR
    })
  )
};

export const fetchUserReport = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;
  // const url = "http://localhost:3001/reports/US001-X-" + companyId;
  const url = `${api}/reports/${companyId}`;
  
  dispatch({ type: FETCH_USER_REPORT });
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((response) => response.json())
    .then((payload) =>
      dispatch({
        type: FETCH_USER_REPORT_SUCCESS,
        payload: payload,
      }),
    )
    .catch((err) => {
      console.log(err.message);
    });
};

export const fetchUserServices = (userId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.DOTNET.URL;

  const url = `${api}/ubo/users/${userId}/services`;
  dispatch({ type: FETCH_SERVICES });
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((res) => res.json())
    .then((payload) =>{
      checkUserMycService(payload);
      dispatch({
        type: FETCH_SERVICES_SUCCESS,
        payload,
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_USER_ERROR
      })
      console.log(err.message);
    });
};

const checkUserMycService = (services) => {
  const mycService = services.filter(service => service.serviceId === 937);
  
  if(mycService.length === 0 || mycService.every(myc => new Date(myc.expireDate) < new Date())) {
    window.location = config.UPP_REDIRECT.URL;
  }
}

export const fetchSearchableCountries = (country) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const api = config.UBO_API.URL
  const url = `${api}/Reference/mapinfo/${country}`

  dispatch({ type: FETCH_SEARCHABLE_COUNTRIES});
  fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    }),
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({
        type: FETCH_SEARCHABLE_COUNTRIES_SUCCESS,
        payload
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SEARCHABLE_COUNTRIES_ERROR
      })
      console.log(err.message)
    })
}


export const resetReportJSON = (companyId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;

  // const url = `http://localhost:3001/reports/reset`;
  const url = `${api}/reports/reset`;

  dispatch({ type: RESET_REPORT_JSON});
  fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    },
    body: JSON.stringify({
      companyid: companyId,
      updated_at: new Date()
    }),
  })
  .then((response) => response.json())
  .then(()=> fetchReportJson(companyId)(dispatch))
  .then(() =>
    dispatch({
      type: RESET_REPORT_JSON_SUCCESS,
    }),
  )
  .catch((err) => {
    dispatch({
      type: RESET_REPORT_JSON_ERROR
    });
    console.log(err.message);
  });
}
