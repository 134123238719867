import React from "react";
import styled from "styled-components";

import Button from "../core/Button";
import get from "lodash/get";
import { Formik } from "formik";
import { InputField, SelectField } from "../core/FormComponents";
import {
  Attribute,
  AttributeName,
  AttributeValue,
} from "../styled/DisplayAttribute";

import { noNumber } from "../../utils/noNumber";
import { usStatePackages } from "../../utils/usStatePackages";
import { locationTypePackages } from "../../utils/locationTypePackages";

import * as Yup from "yup";
import { ILLEGAL_CHARACTERS, PHONE_NUMBER } from "../../utils/regexConstants";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Attribute} {
    align-items: center;
  }

  @media only screen and (min-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const provinceOptions = () =>
  usStatePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

export const locationOptions = () =>
  locationTypePackages.map((val) => (
    <option value={val} key={val}>
      {val}
    </option>
  ));

function AttributeRow({ name, value, ...rest }) {
  return (
    <Attribute {...rest}>
      <AttributeName children={name} />
      <AttributeValue children={value} />
    </Attribute>
  );
}

const EditContactInformation = ({
  mycID,
  companyId,
  safeNumber,
  email,
  companyReport,
  additionalInformation,
  companyIdentification,
  contactInformation,
  otherInformation,
  companySummary,
  fetchReportJson,
  fetchUserReport,
  setReportLoading,
  edit,
  accessToken,
  language,
  report,
  expressApi,
  sVAArray,
  userReport,
  fullName,
  boId,
  profile
}) => (
  <div>
    <Formik
      initialValues={{
        accessToken: accessToken,
        language: language,
        companyId: mycID,
        safeNumber: safeNumber,
        userid: email,
        report: report,
        companyType: get(
          companyIdentification,
          "basicInformation.legalForm.providerCode"
        ),
        simpleValue: get(
          companyIdentification,
          "basicInformation.contactAddress.simpleValue"
        ),
        street: get(
          companyIdentification,
          "basicInformation.contactAddress.street", sVAArray[0]
        ),
        city: get(
          companyIdentification,
          "basicInformation.contactAddress.city", sVAArray[1]
        ),
        province: get(
          companyIdentification,
          "basicInformation.contactAddress.province"
        ),
        postalCode: get(
          companyIdentification,
          "basicInformation.contactAddress.postalCode", sVAArray[3]
        ),
        locationType: get(additionalInformation, "misc.locationType"),
        website: get(contactInformation, "websites", [""]),
        phoneNumber: get(
          companyIdentification,
          "basicInformation.contactAddress.telephone", ""
        ),
        businessName: get(companySummary, "businessName",""),
        establishmentDate: get(
          additionalInformation,
          "misc.establishmentDate",
          ""
        ),
        registrationDate: get(
          companyIdentification,
          "basicInformation.companyRegistrationDate",
          ""
        ),
        operationsStartDate: get(
          companyIdentification,
          "basicInformation.operationsStartDate",
          ""
        ),
        taxID: get(additionalInformation, "misc.taxID", ""),
        addressType: get(additionalInformation, "misc.addressType",""),
        annualSalesAmount: get(companySummary, "latestTurnoverFigure.value", ""),
        numberEmployees: get(
          otherInformation,
          "employeesInformation[0].numberOfEmployees"
        ),
      }}
      validationSchema={contactValidation}
      onSubmit={async (values, actions) => {
        await edit();
        await setReportLoading("contactInformation");
        await fetch(`${expressApi}/reports`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            mycID: values.companyId,
            email: values.userid,
            report: values.report,
            reportjson: {
              ...companyReport,
              companyIdentification: {
                ...companyReport.companyIdentification,
                basicInformation: {
                  ...companyReport.companyIdentification.basicInformation,
                  contactAddress: {
                    simpleValue:
                      values.street +
                      ", " +
                      values.city +
                      ", " +
                      values.province +
                      ", " +
                      values.postalCode,
                    street: values.street,
                    city: values.city,
                    postalCode: values.postalCode,
                    province: values.province,
                    telephone: values.phoneNumber,
                  },
                },
              },
              additionalInformation: {
                ...companyReport.additionalInformation,
                misc: {
                  ...companyReport.additionalInformation.misc,
                  addressType: values.addressType,
                  locationType: values.locationType,
                },
              },
              contactInformation: {
                websites: values.website,
              },
            },
            reportupdated_at: new Date(),
            updated_at: new Date(),
            created_at: new Date(),
            verifiedstatus: "changed",
            language: values.language,
            detType: 'update_company_info',
            detInfo: {
              myc_id: userReport.id,
              company_id: values.companyId,
              safe_number: values.safeNumber,
              company_name: values.businessName,
              legal_form: values.companyType,
              date_of_company_registration: values.registrationDate,
              date_company_founded: values.establishmentDate,
              operations_start_date: values.operationsStartDate,
              ein: values.taxID,
              street: values.street,
              city: values.city,
              state: values.province,
              postal_code: values.postalCode,
              location_type:values.locationType,
              address_type: values.addressType,
              phone: values.phoneNumber,
              url: values.website,
              annual_sales: values.annualSalesAmount,
              number_of_employees: values.numberEmployees,
              status:'fail',
              user_id: userReport.userid,
              user_verified: userReport.userstatus,
              customer_id: profile.customer.id,
            },
            bo_id: boId,
            bo_name: fullName,
          }),
        })
          .then((response) => response.json())
          .then(actions.setSubmitting(false))
          .then(() => fetchReportJson(companyId, "contactInformation"))
          .then(() => fetchUserReport(companyId))
          .catch((err) => console.log(err));
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <AttributeRow
            width={100}
            name={<label>Location Type</label>}
            value={
              <SelectField name="locationType">{locationOptions()}</SelectField>
            }
          />
          <AttributeRow
            width={100}
            name={<label>Street</label>}
            value={<InputField name="street" />}
          />
          <AttributeRow
            width={100}
            name={<label>City</label>}
            value={<InputField name="city" />}
          />
          <AttributeRow
            width={100}
            name={<label>State</label>}
            value={
              <SelectField name="province">{provinceOptions()}</SelectField>
            }
          />
          <AttributeRow
            width={100}
            name={<label>Zip</label>}
            value={<InputField name="postalCode" />}
          />
          <AttributeRow
            width={100}
            name={<label>Website</label>}
            value={<InputField name="website[0]" />}
          />
          <AttributeRow
            width={100}
            name={<label>Phone Number</label>}
            value={
              <InputField 
                onKeyPress={noNumber}  
                type="number" 
                name="phoneNumber" 
              />
            }
          />
          <ButtonBar>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </ButtonBar>
        </form>
      )}
    </Formik>
  </div>
);

const contactValidation = Yup.object().shape({
  street: Yup.string()
    .matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    })
    .required("Street Address Required"),
  city: Yup.string()
    .matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    })
    .required("City Required"),
  province: Yup.string()
    .matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    })
    .required("State Required"),
  phoneNumber: Yup.string()
    .matches(PHONE_NUMBER, {
      message: "Enter valid 10 digit #",
    }),
  postalCode: Yup.string()
    .matches(ILLEGAL_CHARACTERS, {
      message: "Your entry contains illegal characters",
    })
    .required("Postal Code Required"),
  website: Yup.array().of(Yup.string()
    .matches(ILLEGAL_CHARACTERS,{
      message: "Your entry contains illegal characters",
    })
  )
});

export default EditContactInformation;
