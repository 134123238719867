import {
  FETCH_DISCLAIMER,
  FETCH_DISCLAIMER_SUCCESS,
  FETCH_DISCLAIMER_ERROR,
  FETCH_IP_ADDRESS,
  FETCH_IP_ADDRESS_SUCCESS,
  FETCH_IP_ERROR
} from "./types";

import { initConfig } from "../config";

const config = initConfig();

export const fetchDisclaimer = (companyId, customerId, sub, ip) => (
  dispatch
) => {
  const accessToken = localStorage.getItem("accessToken");

  const api = config.EXPRESS.URL;

  // const url = `http://localhost:3001/disclaimer/US001-X-${companyId}/${customerId}/${sub}/${ip}`;
  const url = `${api}/disclaimer/${companyId}/${customerId}/${sub}/${ip}`;

  if (companyId && customerId && sub && ip) {
    dispatch({ type: FETCH_DISCLAIMER });
    return fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      }),
    })
      .then((res) => res.json())
      .then((payload) =>
        dispatch({
          type: FETCH_DISCLAIMER_SUCCESS,
          payload: payload,
        })
      )
      .catch((err) => {
        dispatch({
          type: FETCH_DISCLAIMER_ERROR
        })
        console.log(err.message);
      });
  } else {
    return dispatch({
      type: FETCH_DISCLAIMER_ERROR
    })
  }
};

export const fetchIpAddress = () => (dispatch) => {
  const url = "https://api.ipify.org/?format=json";
  dispatch({ type: FETCH_IP_ADDRESS });
  fetch(url, {
    method: "GET",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  })
    .then((res) => res.json())
    .then((payload) =>
      dispatch({
        type: FETCH_IP_ADDRESS_SUCCESS,
        payload: payload
      })
    )
    .catch((err) => {
      dispatch({
        type: FETCH_IP_ERROR
      })
      console.log(err)
    });
};
