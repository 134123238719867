import {
  FETCH_NAV_AUTH_TOKEN,
  FETCH_NAV_AUTH_TOKEN_FAILURE,
  FETCH_NAV_AUTH_TOKEN_SUCCESS,
  FETCH_NAV_ACCOUNT,
  FETCH_NAV_ACCOUNT_FAILURE,
  FETCH_NAV_ACCOUNT_SUCCESS,
  FETCH_NAV_CTA,
  FETCH_NAV_CTA_FAILURE,
  FETCH_NAV_CTA_SUCCESS,
  CREATE_NAV_ACCOUNT,
  CREATE_NAV_FAILURE,
  CREATE_OR_UPDATE_NAV_SUCCESS,
  UPDATE_NAV_ACCOUNT,
  UPDATE_NAV_FAILURE
} from './types';

import { initConfig } from "../config";

const config = initConfig();

const api = config.EXPRESS.URL;

export const fetchNavAuthToken = (companyId) => async (dispatch) => {
  // const url = `http://localhost:3001/nav?type=getAuthToken`
  const url = `${api}/nav?type=getAuthToken`

  dispatch({ type: FETCH_NAV_AUTH_TOKEN });
  return await (fetch(url, {
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userID: companyId
    }),
  }))
    .then((res) => res.json())
    .then((payload) => {
      dispatch({
        type: FETCH_NAV_AUTH_TOKEN_SUCCESS,
        payload: payload
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_NAV_AUTH_TOKEN_FAILURE
      })
      console.log(err.message)
    })
}

export const fetchNavAccount = (companyId) => async (dispatch) => {
  // const url = `http://localhost:3001/nav?type=getAccount`
  const url = `${api}/nav?type=getAccount`

  dispatch({ type: FETCH_NAV_ACCOUNT });
  return await fetch(url, {
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userID: companyId
    }),
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({
        type: FETCH_NAV_ACCOUNT_SUCCESS,
        payload: payload
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_NAV_ACCOUNT_FAILURE
      })
      console.log(err.message)
    })
}

export const fetchNavCta = (companyId, contentSpace) => async (dispatch) => {
  // const url = `http://localhost:3001/nav?type=getCTA`
  const url = `${api}/nav?type=getCTA`

  dispatch({ type: FETCH_NAV_CTA });
  return await fetch(url, {
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userID: companyId,
      contentSpace: contentSpace
    }),
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({
        type: FETCH_NAV_CTA_SUCCESS,
        payload: payload
      })
    })
    .catch((err) => {
      dispatch({
        type: FETCH_NAV_CTA_FAILURE
      })
      console.log(err.message)
    })
}

export const createNavAccount = (businessFacts, createAccount, companyId) => async (dispatch) => {

  // const url = `http://localhost:3001/nav?type=createAccount`
  const url = `${api}/nav?type=createAccount`

  dispatch({ type: CREATE_NAV_ACCOUNT });
  return await fetch(url, {
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      businessFacts: businessFacts,
      createAccount: createAccount
    }),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: CREATE_OR_UPDATE_NAV_SUCCESS
      })
    })
    .catch((err) => {
      dispatch({
        type: CREATE_NAV_FAILURE
      })
      console.log(err.message)
    })
}

export const updateNavAccount = (businessFacts, createAccount, companyId) => async (dispatch) => {

  // const url = `http://localhost:3001/nav?type=updateAccount`
  const url = `${api}/nav?type=updateAccount`

  dispatch({ type: UPDATE_NAV_ACCOUNT });
  return await fetch(url, {
    method: "POST",
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      "Content-Type": 'application/json'
    },
    body: JSON.stringify({
      businessFacts: businessFacts,
      createAccount: createAccount
    }),
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: CREATE_OR_UPDATE_NAV_SUCCESS
      })
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_NAV_FAILURE
      })
      console.log(err.message)
    })
}