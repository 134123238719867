import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import Codat from "../core/Codat";
import Button from "../core/Button";
import LoadingPage from "../../components/core/LoadingPage";
import Disclaimer from "../../components/core/Disclaimer";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import { sealUrl } from "../report/utils/seal";
 
export const DashboardContent = styled.div`
  padding: 1rem 3rem;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${(props) =>
      `${props.theme.breakpointSm}px`}) {
    padding: 0.5em;
  }
`;

export const CertificateHeader = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    width: 99%;
  }
  @media (min-width: 1400px) {
    flex-direction: row;

    > div {
      width: 50%;
      :first-child {
        margin-right: 0.75rem;
      }
      :nth-child(2) {
        margin-left: 0.75rem;
      }
    }
  }
`;

export const HelpTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: space-around;
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpointXXl}px) {
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  @media (min-width: 475px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 25%;
  }
`;

export const CardText = styled.span`
  margin: 0.5rem 0;
  width: 100%;
`;

export const CopyIcon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CodeBlock = styled.div`
  display: none;
`;

export const ImageContainer = styled.img`
  display: flex;
  max-height: 300px;
  max-width: 200px;
  flex-grow: 1;
`;

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageBanner = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Banner = styled.img`
  width: 100%;

  @media (min-width: 675px) {
    max-width: 600px;
    max-height: 50px;
  }
`;

export const Code = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  text-align: center;
  overflow-wrap: break-word;

  @media (min-width: ${(props) => props.theme.breakpointXXl}px) {
    text-align: left;
    width: 50%;
  }
`;

class Certificate extends Component {
  copyToClipboard = (route) => {
    const textArea = document.createElement("textarea");
    document.body.appendChild(textArea);
    textArea.value = route;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  render() {
    const {
      safeNumber,
      companyId,
      companyName,
      sub,
      customerId,
      company,
    } = this.props;

    const seal = sealUrl(safeNumber);

    const browserURL = `http://my.creditsafe.com/company/${companyId}.html`;
    const landingRoute = `<a href="${browserURL}" target="blank" border="0"> <img alt="MyCreditsafe Certificate" src="${seal[4]}" ></a>`;

    return !companyId ? (
      <LoadingPage />
    ) : (
      <DashboardContent>
        <Disclaimer sub={sub} customerId={customerId} report={company} />
        <CertificateHeader>
          <Card>
            <HelpTitle>
              <CardTitle title="Certificates" />
              <CardText>
                Are you proud of the work you've done building a strong business
                credit profile?
              </CardText>
              <CardText>
                You can demonstrate your creditworthiness to potential partners,
                vendors and lenders by embedding your Creditsafe Business
                Certificate on your website so they can easily review your full
                report.
              </CardText>
              <CardText>
                Get started by selecting one of the design options below.
              </CardText>
            </HelpTitle>
          </Card>
          <Card>
            <Codat
              safeNumber={safeNumber}
              companyId={companyId}
              companyName={companyName}
            />
          </Card>
        </CertificateHeader>
        <Card id="walkMeCertificate">
          <CopyIcon>
            <Button
              onClick={() => {
                this.copyToClipboard(landingRoute);
              }}
            >
              Copy To Clipboard <FileCopyIcon />
            </Button>
          </CopyIcon>
          <BannerWrapper>
            <ImageBanner>
              <Banner alt="MyCreditsafe Certificate" src={seal[4]} />
            </ImageBanner>
            <Code>
              {`<a href="${browserURL}" target="blank" border="0"</a>`}
              {`<img alt="MyCreditsafe Certificate" src="${seal[4]}">`}
              {`</a>`}
            </Code>
          </BannerWrapper>
        </Card>
        <Card>
          <Wrapper>
            {seal.slice(0, 4).map((image, index) => (
              <Container key={index}>
                <CopyIcon>
                  <Button>
                    <FileCopyIcon
                      onClick={() => {
                        this.copyToClipboard(
                          `<a href="${browserURL}" target="blank" border="0"><img alt="MyCreditsafe Certificate" src="${image}"></a>`
                        );
                      }}
                    />
                  </Button>
                </CopyIcon>
                <ImageBox>
                  <ImageContainer alt="MyCreditsafe Certificates" src={image} />
                </ImageBox>
              </Container>
            ))}
          </Wrapper>
        </Card>
      </DashboardContent>
    );
  }
}

Certificate.propTypes = {
  safeNumber: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  sub: PropTypes.string,
  customerId: PropTypes.string,
  company: PropTypes.object,
};

const mapStateToProps = (state) => ({
  companyName: state.company.report.report.companySummary.businessName,
  safeNumber: state.company.report.report.companySummary.companyNumber,
  companyId: state.company.report.report.companyId,
  sub: state.user.info.sub,
  customerId: state.user.info.customerId,
  company: state.company.report,
});

export default connect(mapStateToProps, null)(Certificate);
