import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import "url-polyfill";
import "./polyfills/CustomEvent";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux'
import store from '../src/store'

import { fetchUser } from "./actions/userActions";

import Auth, { AuthenticationProvider } from "@creditsafe/upp-authentication";
Auth.configure({appName: "myc-upp"});


const Root = () => (
      
  <Provider store={store}>
    <AuthenticationProvider
      onSignin={() => store.dispatch(fetchUser())}
    >
      <App />
    </AuthenticationProvider>
  </Provider>
      
)


ReactDOM.render(<Root />, document.getElementById('root'));


