import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import { withTranslation } from "react-i18next";
import { startCasing } from "../../utils/string";

import Button from "../core/Button";
import Card from "../core/Card";
import CardTitle from "../core/CardTitle";
import { InlineLoader } from "../core/Loading";
import PopupModal from "../core/PopupModal";

import { PaginatedTable } from "../PaginatedTable";
import { PAGE_SIZE_MEDIUM } from "../../utils/constants";


import BusinessIcon from "@material-ui/icons/Business";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from '@material-ui/core/Tooltip';
import EditActivities from "../form/EditActivities";

const HelpTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const formatTableData = (activityClassifications) => {
  if (!activityClassifications) {
    return [];
  }

  return activityClassifications.reduce((r, activityClass) => {
    const classificationText = activityClass.classification;

    if (activityClass.activities) {
      r = r.concat(
        activityClass.activities.map((activity) => {
          return {
            classification: classificationText,
            code: activity.code,
            description: activity.description,
          };
        })
      );
    }
    return r;
  }, []);
};

class Activities extends Component {
  render() {
    const {
      activityClassifications,
      setReportLoading,
      isLoading,
      loadingType,
      fetchReportJson,
      fetchUserReport,
      mycID,
      email,
      report,
      companyReport,
      language,
      companyId,
      expressApi,
      userReport,
      safeNumber,
      fullName,
      boId,
      profile
    } = this.props;

    const data = sortBy(
      formatTableData(activityClassifications),
      "classification"
    ).map((activity, index) => ({ ...activity, index }));


    const isLoadingActivities = isLoading && loadingType === "activities";
    const addIndex = { index: 0 }

    return (
      <Card scrollContent>
        {isLoadingActivities ? (
          <InlineLoader
            isLoading={true}
            delayMS={0}
            loadingText={"Updating..."}
          />
        ) : (
          <>
            <HelpTitle>
              <CardTitle title="Activities" dataTestId="Activities" />
              <PopupModal
                title="Add Activity"
                trigger={(
                  <Button>
                    <Tooltip title="Add Activity">
                      <BusinessIcon data-testid="add-activities-icon" />
                    </Tooltip>
                  </Button>
                )}>
                {(close) => (
                  <EditActivities
                    type="add"
                    row={addIndex}
                    mycID={mycID}
                    email={email}
                    report={report}
                    companyId={companyId}
                    companyReport={companyReport}
                    language={language}
                    activityClassifications={activityClassifications}
                    close={close}
                    fetchReportJson={fetchReportJson}
                    fetchUserReport={fetchUserReport}
                    setReportLoading={setReportLoading}
                    expressApi={expressApi}
                    userReport={userReport}
                    safeNumber={safeNumber}
                    fullName={fullName}
                    boId={boId}
                    profile={profile}
                  />
                )}
              </PopupModal>
            </HelpTitle>
            <PaginatedTable
              headings={[
                { key: "classification", text: "Classification" },
                { key: "code", text: "Code" },
                { key: "description", text: "Description" },
                { key: "edit" },
              ]}
              data={data}
              pageSize={PAGE_SIZE_MEDIUM}
              formatters={{
                description: startCasing,
                edit: (_, row) => (
                  <PopupModal
                    title="Edit Activity"
                    trigger={
                      <Button>
                        <Tooltip title="Edit Activity">
                          <EditOutlinedIcon
                            data-testid={`edit-activities-button-${row.index}`}
                          />
                        </Tooltip>
                      </Button>
                    }
                  >
                    {(close) => (
                      <EditActivities
                        type="edit"
                        row={row}
                        mycID={mycID}
                        email={email}
                        report={report}
                        companyId={companyId}
                        companyReport={companyReport}
                        language={language}
                        activityClassifications={activityClassifications}
                        activity={row}
                        close={close}
                        fetchReportJson={fetchReportJson}
                        fetchUserReport={fetchUserReport}
                        setReportLoading={setReportLoading}
                        expressApi={expressApi}
                        userReport={userReport}
                        safeNumber={safeNumber}
                        fullName={fullName}
                        boId={boId}
                        profile={profile}
                      />
                    )}
                  </PopupModal>
                ),
              }}
            />
          </>
        )}
      </Card>
    );
  }
}

Activities.propTypes = {
  report: PropTypes.object,
  companyId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.merged.isLoading,
  loadingType: state.merged.loadingType,
  report: state.company.report,
  expressApi: state.app.config.EXPRESS.URL,
});

export default compose(
  connect(mapStateToProps, null),
  withTranslation("company-report")
)(Activities);
